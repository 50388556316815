<template>
    <v-layout justify-center v-if="$store.state.isUserLoggedIn">
      <v-speed-dial fab fixed v-model="fab" :bottom="true" :right="true" direction="top" :open-on-hover="false">
        <template v-slot:activator>
          <v-btn v-model="fab" color="blue darken-2" dark large fab>
            <v-icon v-if="fab">mdi-close</v-icon>
            <v-icon v-else>mdi-filter-variant</v-icon>
          </v-btn>
        </template>
        <v-btn fab dark large color="blue" class="mb-2" @click.stop="dialogFiltrarMensagens = true">
          <img :width="45" src="../../assets/icons_aplicacao/Pesquisa.png" alt="Pesquisa">
        </v-btn>
        <v-btn fab dark large color="blue" class="mb-2" to="criarmensagens">
          <img :width="45" src="../../assets/icons_aplicacao/Add_Tarefas.png" alt="Add_Tarefas">
        </v-btn>
      </v-speed-dial>
      <v-flex>
        <v-card>
          <v-toolbar class="mb-2 primary" dark flat>
            <v-toolbar-title>Mensagens</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-col cols="12">
            <v-data-table class="elevation-1" :headers="headersMensagens" :items="mensagens">
              <template v-slot:[`item.data_registo`]="{ item }">
                {{ apresentarData(item.data_registo) }} {{ apresentarHoras(item.data_registo) }}
              </template>
              <template v-slot:[`item.assunto`]="{ item }">
                <span v-if="item.assunto != null" v-html="item.assunto.substring(0,75)"></span>
              </template>
              <template v-slot:[`item.mensagem`]="{ item }">
                <span v-if="item.mensagem != null" v-html="item.mensagem.substring(0,75)"></span>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <img @click="verMensagem(item.id)" class="m-2" :width="25" src="../../assets/icons_aplicacao/Ver.png" alt="Ver">
                <img @click="apagarMensagem(item.id)" class="m-2" :width="25" src="../../assets/icons_aplicacao/Apagar.png" alt="Apagar">
              </template> 
            </v-data-table>
          </v-col>
        </v-card>
        <!-- Dialog filtrar mensagem -->
        <v-dialog v-model="dialogFiltrarMensagens">
          <v-card>
            <v-toolbar class="mb-2" color="black" dark flat>
              <v-toolbar-title>Filtrar mensagens</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-title><v-list-item @click="dialogFiltrarMensagens = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
            </v-toolbar>
            <v-col cols="12">
                <label>Funcionario</label>
                <v-autocomplete v-model="filtrarMensagens.id_funcionario" :items="funcionarios" item-text="nome" item-value="id" clearable filled outlined></v-autocomplete>
            </v-col>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="black" class="white--text" @click="confirmarFiltrarMensagens">Filtrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- Dialog apagar mensagem -->
        <v-dialog v-model="dialogApagarMensagem">
          <v-card>
            <v-toolbar class="mb-2" color="red" dark flat>
                <v-toolbar-title>Apagar mensagem</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-title><v-list-item @click="dialogApagarMensagem = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
            </v-toolbar>
            <v-col cols="12">
                <span>Quer mesmo apagar a mensagem?</span>
            </v-col>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" class="white--text" @click="confirmarApagarMensagem">Apagar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-layout>
  </template>
    
  <script>
  //  Importar o service necessário
  import MensagensService from '@/services/MensagensService'
  import FuncionariosService from '@/services/FuncionariosService'
  import store from '@/store/store'
  import moment from 'moment-timezone'
  export default {
    async mounted () {
      if (!store.state.isUserLoggedIn) {
        this.$router.push({
          name: 'mylogin'
        })
      } else {
        this.mensagens = (await MensagensService.index(this.filtrarMensagens.mensagem_resposta)).data
        this.funcionarios = (await FuncionariosService.index()).data
      }
    },
    data () {
      return {
        fab: false,
        mensagens: [],
        funcionarios: [],
        filtrarMensagens: {
          mensagem_resposta: 0,
          id_funcionario: this.$store.state.funcionarioselect.idfuncionario
        },

        headersMensagens: [
          { text: "Lido", value: "codigo", sortable: false },
          { text: "Data registo", value: "data_registo", sortable: true },
          { text: "Enviado de", value: "enviadoDe.nome", sortable: true },
          { text: "Enviado para", value: "destinatarios", sortable: true },
          { text: "Assunto", value: "assunto", sortable: true },
          { text: "Mensagens", value: "mensagem", sortable: true },
          { text: 'Ações', value: 'actions', sortable: false },
        ],
  
        dialogFiltrarMensagens: false,
        dialogApagarMensagem: false,
  
        idmensagemglobal: '',
      }
    },
    methods: {
      // formatar data
      formatarDataMoment(data) {
        return moment(data).tz('Europe/Lisbon').format('YYYY-MM-DDTHH:mm:ss[Z]');
      },
      apresentarData (date) {
        const options = {year: 'numeric', month: 'numeric', day: 'numeric'}
        return new Date(date).toLocaleDateString('pt-PT', options)
      },
      apresentarHoras (date) {
        return new Date(date).toJSON().slice(11, 16)
      },
      navigateToMensagemEditar (route) {
        this.$store.dispatch('setMensagem', {  
          idmensagem: route.params.idMensagemEnviado,
        })
        this.$router.push(route)
      },
      // filtrar mensagens
      async confirmarFiltrarMensagens () {
        this.mensagens = (await MensagensService.index(this.filtrarMensagens.mensagem_resposta)).data
        this.dialogFiltrarMensagens = false
      },
      //-------------------------------------------mensagens------------------------------//
      //-------------------------------------------mensagens------------------------------//
      //-------------------------------------------mensagens------------------------------//
      async verMensagem (id) {
        this.$store.dispatch('setMensagem', {
          idmensagem: id,
        })
        this.$router.push({
          name: 'chatmensagens'
        })
      },
      async apagarMensagem (id) {
        this.dialogApagarMensagem = true
        this.idmensagemglobal = id
      },
      async confirmarApagarMensagem () {
        try{
            await MensagensService.delete(this.idmensagemglobal)
            this.dialogApagarMensagem = false
            this.mensagens = (await MensagensService.index(this.filtrarMensagens.mensagem_resposta)).data
            this.$store.dispatch("setSnackBar", {
                color: "success",
                text: "Mensagem apagada com sucesso"
            });
        } catch (error) {
            this.$store.dispatch("setSnackBar", {
                color: "error",
                text: "Erro a apagar mensagem, pode ter coisas associadas"
            });
        }
      },
    }
  }
  </script>
  <style>
  </style>