import Api from '@/services/Api'

export default {
  index (id_projeto) {
    return Api().get('projetosobs',{
      params: {
        id_projeto
      }
    })
  },
  post (elementos) {
    return Api().post('projetosobs', elementos)
  },
  put (elementos) {
    return Api().put(`projetosobs/${elementos.id}`, elementos)
  },
  show (id) {
    return Api().get(`projetosobs/${id}`)
  },
  delete (id) {
    return Api().delete(`projetosobs/${id}`)
  }
  
}
