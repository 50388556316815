<template>
    <v-layout justify-center v-if="$store.state.isUserLoggedIn">
      <v-flex>
          <v-card>
            <v-toolbar class="mb-2" color="purple" dark flat>
              <v-toolbar-title>Criar projeto</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-form ref="form">
                <v-row>
                  <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 6">
                    <label>Clientes</label>
                    <v-autocomplete v-model="criarProjetos.numero_cliente" :items="clientes" item-text="nome" item-value="numero" clearable filled outlined :rules="[rules.cliente]"></v-autocomplete>
                    <template v-if="criarProjetos.numero_cliente == empresaTitular.numero_consumidor_final">
                      <label>Nome consumidor final</label>
                      <v-text-field type="text" v-model="criarProjetos.nome_cliente" clearable filled outlined :rules="[rules.consumidorfinal]"></v-text-field>
                    </template>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 8">
                    <v-row>
                      <v-col cols="12">
                        <label>Descrição</label>
                        <wysiwyg v-model="criarProjetos.descricao"/>
                      </v-col>
                      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                        <label>Tópico</label>
                        <v-select v-model="criarProjetos.id_topico" :items="topicos" item-text="descricao" item-value="id" filled outlined></v-select>
                      </v-col>
                      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                        <label>Estado</label>
                        <v-select v-model="criarProjetos.id_estado" :items="estados" item-text="descricao" item-value="id" filled outlined></v-select>
                      </v-col>
                      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                        <label>Data de Inicio</label>
                        <MYdatetime type="date" v-model="criarProjetos.data_inicio"></MYdatetime>
                        <v-text-field class="d-none" type="date" v-model="criarProjetos.data_inicio" clearable filled outlined :rules="[rules.datamenor]"></v-text-field>
                        <br>
                      </v-col>
                      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                        <label>Data de fim</label>
                        <MYdatetime type="date" v-model="criarProjetos.data_fim"></MYdatetime>
                        <span v-if="criarProjetos.data_inicio > criarProjetos.data_fim" class="errodata">Data fim deve ser maior que a data de início.</span>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 4">
                      <v-row>
                        <v-col cols="12" class="text-center">
                          <label>INTERVENIENTES </label>
                          <img @click="criarProjeto" :width="35" src="../../assets/icons_aplicacao/Add_Interveniente.png" alt="Add_Interveniente">
                        </v-col>
                      </v-row>
                    </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <!-- Dialog Criar Interveniente -->
            <v-dialog v-model="dialogCriarInterveniente">
              <v-card>
                <v-toolbar class="mb-2" color="purple" dark flat>
                  <v-toolbar-title>Criar intervenientes</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-toolbar-title><v-list-item @click="dialogCriarInterveniente = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
                </v-toolbar>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-list>
                        <v-list-item-group color="primary">
                          <div v-for="tec in tecnicos" :key="tec.id">
                            <v-divider></v-divider>
                            <v-list-item>
                              <template>
                                <v-list-item-action>
                                  <v-checkbox v-model="intervenientesSelecionados" :value="tec"></v-checkbox>
                                </v-list-item-action>
                                <v-list-item-content>
                                  <v-list-item-title>{{tec.nome}}</v-list-item-title>
                                </v-list-item-content>
                              </template>
                            </v-list-item>
                          </div>
                        </v-list-item-group>
                      </v-list>
                    </v-col>
                  </v-row>
                </v-container>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="purple" class="white--text" @click="confirmarCriarProjeto">Criar</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card>
      </v-flex>
    </v-layout>
  </template>
  
  <script>
  //  Importar o service necessário
  import ProjetosService from '@/services/ProjetosService'
  import ClientesService from '@/services/ClientesService'
  import EstadosService from '@/services/EstadosService'
  import TopicosService from '@/services/TopicosService'
  import FuncionariosService from '@/services/FuncionariosService'
  import ProjetosFuncionarioService from '@/services/ProjetosFuncionarioService'
  import EmpresaTitutarService from '@/services/EmpresaTitutarService'
  import store from '@/store/store'
  import moment from 'moment-timezone'
  
  export default {
    async mounted () {
      if (!store.state.isUserLoggedIn) {
        this.$router.push({
          name: 'mylogin'
        })
      }
      this.tecnicos = (await FuncionariosService.index(false, ' ', null)).data
      this.clientes = (await ClientesService.index(false)).data
      this.estados = (await EstadosService.index()).data
      this.topicos = (await TopicosService.index()).data
      this.empresaTitular = (await EmpresaTitutarService.index()).data
      this.criarProjetos.data_inicio = null
      this.criarProjetos.data_fim = null
    },
    data () {
      return {
        clientes: [],
        estados: [],
        topicos: [],
        tecnicos: [],
        editarTempos: {},
        empresaTitular: {},
        intervenientesSelecionados: [],
        criarProjetos: {
          numero_cliente: null,
          data_registo: this.formatarDataMoment(Date.now()),
          quem_registou: this.$store.state.user.id,
          descricao: '',
          concluido: 0,
          id_topico: null,
          id_estado: 1,
          data_inicio: null,
          data_fim: null,
          nome_cliente: null,
          ano: null,
          numero: null,
        },
        criarIntervenientes: {
          id_projeto: null,
          id_funcionario: null,
          data_registo: this.formatarDataMoment(Date.now()),
          quem_registou: this.$store.state.user.id,
        },
  
        dialogCriarInterveniente: false,
        
        rules: {
          cliente: value => !!value || "Cliente é Obrigatório.",  
          consumidorfinal: value => !!value || "Nome consumidor final é Obrigatório.",
          datamenor: value => {
            if (this.criarProjetos.data_fim && value > this.criarProjetos.data_fim) {
              return "Data fim deve ser maior que a data de início.";
            }
            return true;
          }
        }
      }
    },
    methods: {
      formatarDataMoment(data) {
        return moment(data).tz('Europe/Lisbon').format('YYYY-MM-DDTHH:mm:ss[Z]');
      },
      //-------------------------------------------projeto------------------------------//
      //-------------------------------------------projeto------------------------------//
      //-------------------------------------------projeto------------------------------//
      async criarProjeto () {
        if (this.$refs.form.validate()) {
          this.dialogCriarInterveniente = true
        }
      },
      async confirmarCriarProjeto () {
        try{
          if (this.$refs.form.validate()) {
            this.clienteselecionado = (await ClientesService.show(this.criarProjetos.numero_cliente)).data
            if (this.clienteselecionado.numero != this.empresaTitular.numero_consumidor_final) {
              this.criarProjetos.nome_cliente = this.clienteselecionado.nome
            }
            this.criarProjetos.ano = new Date().getFullYear();

            if (this.empresaTitular.projetos_tipo == 'Anual') {
              //tem de ir buscar o maior numero do ano em que está, e se ainda não houver vai iniciar no número que estiver no projetos_tipo_desde
              this.maiornumeroprojetoano = (await ProjetosService.maiornumeroprojetoano()).data
              if (this.maiornumeroprojetoano.numero != undefined) {
                  this.criarProjetos.numero = this.maiornumeroprojetoano.numero + 1;
              } else {
                  this.criarProjetos.numero = this.empresaTitular.projetos_tipo_desde
              }
            } else {
              //tem de ir buscar o maior numero, e se ainda não houver vai iniciar no número que estiver no projetos_tipo_desde
              this.maiornumeroprojeto = (await ProjetosService.maiornumeroprojeto()).data
              if (this.maiornumeroprojeto.numero != undefined) {
                  this.criarProjetos.numero = this.maiornumeroprojeto.numero + 1;
              } else {
                  this.criarProjetos.numero = this.empresaTitular.projetos_tipo_desde
              }
            }
            await ProjetosService.post(this.criarProjetos)
            this.projetosultimo = (await ProjetosService.projetosultimo()).data
            for (var is = 0; is < this.intervenientesSelecionados.length; is++) {
              this.criarIntervenientes.id_projeto = this.projetosultimo.id
              this.criarIntervenientes.id_funcionario = this.intervenientesSelecionados[is].id
              await ProjetosFuncionarioService.post(this.criarIntervenientes)
            }

            this.$store.dispatch("setSnackBar", {
                color: "success",
                text: "Projeto criada com sucesso"
            });
            this.$store.dispatch('setProjeto', {
              idprojeto: this.projetosultimo.id,
            })
            this.$router.push({
              name: 'editarprojetos'
            })
        } else {
          this.dialogCriarInterveniente = false
        }
        } catch (error) {
            this.$store.dispatch("setSnackBar", {
                color: "error",
                text: "Erro a criar projeto"
            });
        }
      }
    }
  }
  </script>
  <style>
  </style>