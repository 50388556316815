import Api from '@/services/Api'

export default {
  // gerar chaves Vapid
  generateVapidKeys () {
    return Api().get('/notifications/generatevapidkeys');
  },

  // Enviar o token para o backend
  storeDeviceToken (deviceToken) {
    return Api().post('/notifications/store-device-token', deviceToken);
  },

  // Enviar notificação para o backend (caso precise)
  sendNotification (notificationPayload) {
    return Api().post('/notifications/send', notificationPayload);
  },

  getVapidKeys () {
    return Api().get(`notifications/vapidkeys`)
  },
  
}