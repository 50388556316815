import Api from '@/services/Api'

export default {
  index (id_tarefa) {
    return Api().get('tarefasobs',{
      params: {
        id_tarefa
      }
    })
  },
  post (elementos) {
    return Api().post('tarefasobs', elementos)
  },
  put (elementos) {
    return Api().put(`tarefasobs/${elementos.id}`, elementos)
  },
  show (id) {
    return Api().get(`tarefasobs/${id}`)
  },
  delete (id) {
    return Api().delete(`tarefasobs/${id}`)
  }
  
}
