<template>
  <v-layout justify-center v-if="$store.state.isUserLoggedIn && this.$store.state.marcacao != null || this.$store.state.marcacao.idmarcacao != null">
    <v-speed-dial fab fixed v-model="fab" :bottom="true" :right="true" direction="top" :open-on-hover="false">
      <template v-slot:activator>
        <v-btn v-model="fab" color="blue darken-2" dark large fab>
          <v-icon v-if="fab">mdi-close</v-icon>
          <v-icon v-else>mdi-filter-variant</v-icon>
        </v-btn>
      </template>
      <v-btn fab dark large color="blue" class="mb-2" @click="verObservacoes">
        <img :width="45" src="../../assets/icons_aplicacao/Notas.png" alt="Observações">
      </v-btn>
      <v-btn fab dark large color="blue" class="mb-2">
        <img :width="45" src="../../assets/icons_aplicacao/Logs.png" alt="Logs">
      </v-btn>
      <v-btn fab dark large color="blue" class="mb-2">
        <img :width="45" src="../../assets/icons_aplicacao/Mensagens.png" alt="Mensagens">
      </v-btn>
      <v-btn fab dark large color="blue" class="mb-2" @click="navigateToTarefaEditar({name: 'editartarefas', params:{idTarefaEnviado: editarMarcacoes.id_tarefa}})">
        <img :width="45" src="../../assets/icons_aplicacao/Tarefas.png" alt="Tarefas">
      </v-btn>
    </v-speed-dial>
    <v-flex>
        <v-card>
          <v-toolbar class="mb-2" color="green" dark flat>
            <v-toolbar-title>Editar marcacão</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form ref="form">
                <v-row>
                  <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 4">
                    <label>Cliente</label>
                    <v-text-field v-model="editarMarcacoes.nome_cliente" disabled filled outlined></v-text-field>
                  </v-col>
                  <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                    <label>Quem Registou</label>
                    <v-text-field v-model="editarMarcacoes.quemRegistou.nome" disabled filled outlined></v-text-field>
                  </v-col>
                  <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                    <label>Data de Registo</label>
                    <MYdatetime type="date" :value="editarMarcacoes.data_registo" disabled="disabled"></MYdatetime>
                  </v-col>
                  <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                    <label>Quem Pediu</label>
                    <v-text-field type="text" v-model="editarMarcacoes.quem_pediu" clearable filled outlined></v-text-field>
                  </v-col>
                  <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                    <label>Pedido Como</label>
                    <v-select v-model="editarMarcacoes.como_pediu" :items="['E-mail', 'Telefone', 'WhatsApp', 'Formação', 'Ticket', 'Reunião']" filled outlined></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 8">
                    <v-row>
                      <v-col cols="12">
                        <label>Descrição</label>
                        <wysiwyg v-model="editarMarcacoes.descricao"/>
                      </v-col>
                      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                        <label>Localização</label>
                        <v-text-field type="text" v-model="editarMarcacoes.localizacao" clearable filled outlined></v-text-field>
                      </v-col>
                      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                        <label>Tópico</label>
                        <v-select v-model="editarMarcacoes.id_topico" :items="topicos" item-text="descricao" item-value="id" filled outlined></v-select>
                      </v-col>
                      <v-col v-if="editarMarcacoes.concluido == false" :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                        <label>Estado</label>
                        <v-select v-model="editarMarcacoes.id_estado" :items="estados" item-text="descricao" item-value="id" filled outlined></v-select>
                      </v-col>
                      <v-col v-else :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                        <label>Estado</label>
                        <v-text-field type="text" value="Terminada" clearable filled outlined disabled></v-text-field>
                      </v-col>
                      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                        <label>Data de Inicio</label>
                        <MYdatetime type="date" v-model="editarMarcacoes.data_inicio"></MYdatetime>
                        <v-text-field class="d-none" type="date" v-model="editarMarcacoes.data_inicio" clearable filled outlined :rules="[rules.datamenor]"></v-text-field>
                        <br>
                      </v-col>
                      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                        <label>Data de fim</label>
                        <MYdatetime type="date" v-model="editarMarcacoes.data_fim"></MYdatetime>
                        <span v-if="editarMarcacoes.data_inicio > editarMarcacoes.data_fim" class="errodata">Data fim deve ser maior que a data de início.</span>
                      </v-col>
                      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                        <label>Indesmarcável</label>
                        <v-switch v-model="editarMarcacoes.indesmarcavel" color="primary" inset></v-switch>
                      </v-col>
                      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                        <label>Instalações Infordio</label>
                        <v-switch v-model="editarMarcacoes.instalacoes_infordio" color="primary" inset></v-switch>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 4">
                    <v-row>
                      <v-col cols="12" class="text-center">
                        <label>INTERVENIENTES </label>
                        <img v-if="editarMarcacoes.concluido == false" @click="dialogCriarInterveniente = true" :width="35" src="../../assets/icons_aplicacao/Add_Interveniente.png" alt="Add_Interveniente">
                      </v-col>
                      <v-col cols="12 pt-0" v-for="funcionario in editarMarcacoes.Marcacoes_Funcionarios" :key="funcionario.id">
                        <v-list class="interve">
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>{{funcionario.Funcionario.nome}}</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-content>
                              <v-list-item-title>{{ apresentarData(funcionario.data_inicio) }}</v-list-item-title>
                              <v-list-item-title>{{ apresentarHoras(funcionario.hora_inicio) }}</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-content>
                              <v-list-item-title>{{ apresentarData(funcionario.data_fim) }}</v-list-item-title>
                              <v-list-item-title>{{ apresentarHoras(funcionario.hora_fim) }}</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action v-if="funcionario.enviado_mail == true">
                              <img :width="25" src="../../assets/icons_aplicacao/Email_Enviado.png" alt="Email_Enviado" class="mr-5">
                            </v-list-item-action>
                            <v-list-item-action v-if="funcionario.concluido == true" @click="intervenienteNaoTerminada(funcionario.id)">
                              <img :width="18" src="../../assets/icons_aplicacao/Certo.png" alt="Certo">
                            </v-list-item-action>
                            <v-list-item-action v-else @click="intervenienteTerminada(funcionario.id)">
                              <img :width="18" src="../../assets/icons_aplicacao/Errado.png" alt="Errado">
                            </v-list-item-action>
                            <v-list-item-action v-if="editarMarcacoes.concluido == false">
                              <img @click="apagarInterveniente(funcionario.id)" :width="25" src="../../assets/icons_aplicacao/Apagar.png" alt="Apagar">
                            </v-list-item-action>
                          </v-list-item>
                        </v-list>
                        <v-divider></v-divider>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" v-if="editarMarcacoes.concluido == false">
                      <v-card-actions>
                          <v-spacer></v-spacer>
                          <img @click="editarMarcacao" :width="50" src="../../assets/icons_aplicacao/Guardar.png" alt="Guardar">
                      </v-card-actions>
                  </v-col>
                </v-row>
            </v-form>
          </v-card-text>
        </v-card>
        <!-- Dialog ver observações -->
        <v-dialog v-model="dialogVerObservacoes">
          <v-card>
            <v-toolbar class="mb-2 primary" dark flat>
              <v-toolbar-title>Observações</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-title><v-list-item @click="dialogVerObservacoes = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
            </v-toolbar>
            <v-col cols="12 text-right">
              <img class="m-2" @click="criarObservacao" :width="35" src="../../assets/icons_aplicacao/Add_Notas.png" alt="Add_Notas">
            </v-col>
            <v-col cols="12">
              <v-data-table class="elevation-1" :headers="headersObservacoes" :items="observacoes">
                <template v-slot:[`item.enviado_mail`]="{ item }">
                  <img v-if="item.enviado_mail == true" class="m-2" @click="verObsEmailEnviado(item.id)" :width="25" src="../../assets/icons_aplicacao/Email_Enviado.png" alt="Email_Enviado">
                  <img class="m-2" :width="25" src="../../assets/icons_aplicacao/Email_Enviado.png" alt="Email_Enviado">
                </template>
                <template v-slot:[`item.data_registo`]="{ item }">
                  {{ apresentarData(item.data_registo) }}
                </template>
                <template v-slot:[`item.descricao`]="{ item }">
                  <span v-if="item.descricao != null" v-html="item.descricao.substring(0,75)"></span>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <img class="m-2" @click="editarObservacao(item.id)" :width="25" src="../../assets/icons_aplicacao/Editar.png" alt="Editar">
                  <img class="m-2" @click="apagarObservacao(item.id)" :width="25" src="../../assets/icons_aplicacao/Apagar.png" alt="Apagar">
                </template>
              </v-data-table>
            </v-col>
          </v-card>
        </v-dialog>
        <!-- Dialog criar observação-->
        <v-dialog v-model="dialogCriarObservacao">
          <v-card>
            <v-toolbar class="mb-2" color="purple" dark flat>
              <v-toolbar-title>Criar observação</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-title><v-list-item @click="dialogCriarObservacao = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
            </v-toolbar>
            <v-col cols="12">
                <label>Descrição</label>
                <wysiwyg v-model="criarObservacoes.descricao"/>
            </v-col>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="purple" class="white--text" @click="confirmarCriarObservacao">Criar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- Dialog apagar observação -->
        <v-dialog v-model="dialogApagarObservacao">
          <v-card>
            <v-toolbar class="mb-2" color="red" dark flat>
                <v-toolbar-title>Apagar observação</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-title><v-list-item @click="dialogApagarObservacao = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
            </v-toolbar>
            <v-col cols="12">
                <span>Quer mesmo apagar a observação?</span>
            </v-col>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" class="white--text" @click="confirmarApagarObservacao">Apagar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- Dialog editar observação -->
        <v-dialog v-model="dialogEditarObservacao">
          <v-card>
            <v-toolbar class="mb-2" color="green" dark flat>
                <v-toolbar-title>Editar observação</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-title><v-list-item @click="dialogEditarObservacao = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
            </v-toolbar>
            <v-col cols="12">
                <label>Data Registo</label>
                <MYdatetime type="date" :value="editarObservacoes.data_registo" disabled="disabled"></MYdatetime>
            </v-col>
            <v-col cols="12">
                <label>Registada por</label>
                <v-text-field v-model="editarObservacoes.Funcionario.nome" disabled filled outlined></v-text-field>
            </v-col>
            <v-col cols="12">
                <label>Descrição</label>
                <wysiwyg v-model="editarObservacoes.descricao"/>
            </v-col>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green" class="white--text" @click="confirmarEditarObservacao">Editar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- Dialog ver email enviado observação-->
        <v-dialog v-model="dialogVerObsEmailEnviado">
          <v-card>
            <v-toolbar class="mb-2 primary" dark flat>
              <v-toolbar-title>Observações da marcacao enviada por e-mail para o(s) seguinte(s) endereço(s)</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-title><v-list-item @click="dialogVerObsEmailEnviado = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
            </v-toolbar>
            <v-col cols="12">
              <span v-if="observacaoemail.enviado_mail_data != null">{{observacaoemail.enviado_mail_para_quem}} em {{apresentarData(observacaoemail.enviado_mail_data)}} às {{apresentarHoras(observacaoemail.enviado_mail_data)}} horas.</span>
            </v-col>
          </v-card>
        </v-dialog>
        <!-- Dialog Criar Interveniente -->
        <v-dialog v-model="dialogCriarInterveniente">
          <v-card>
            <v-toolbar class="mb-2" color="purple" dark flat>
              <v-toolbar-title>Criar intervenientes</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-title><v-list-item @click="dialogCriarInterveniente = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
            </v-toolbar>
            <v-container>
              <v-row>
                <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 6">
                  <label>Início</label>
                  <MYdatetime type="date" v-model="datainicioinserida" format="dd-MM-yyyy"></MYdatetime>
                  <br>
                  <MYdatetime type="time" v-model="horainicioinserida" format="HH:mm" :hour-step="1" :minute-step="15" :min-datetime="'08'" :max-datetime="'20'"></MYdatetime>
                  <v-text-field class="d-none" type="date" v-model="datainicioinserida" clearable filled outlined :rules="[rules.dataigual]" ></v-text-field>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 6">
                  <label>Fim</label>
                  <MYdatetime type="date" v-model="datafiminserida" format="dd-MM-yyyy"></MYdatetime>
                  <span v-if="datainicioinserida.slice(0,10) != datafiminserida.slice(0,10)" class="errodata">Data fim tem de ser igual à data de início.</span>
                  <br>
                  <MYdatetime type="time" v-model="horafiminserida" format="HH:mm" :hour-step="1" :minute-step="15" :min-datetime="'08'" :max-datetime="'20'"></MYdatetime>
                </v-col>
                <v-col cols="12">
                  <v-list>
                    <v-list-item-group color="primary">
                      <div v-for="tec in tecnicos" :key="tec.id">
                        <v-divider></v-divider>
                        <v-list-item>
                          <template>
                            <v-list-item-action>
                              <v-checkbox v-model="intervenientesSelecionados" :value="tec"></v-checkbox>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>{{tec.nome}}</v-list-item-title>
                            </v-list-item-content>
                            <div v-for="marcacoesdia in marcacoesdiafuncionario" :key="marcacoesdia.id_funcionario">
                              <v-list-item-content v-if="marcacoesdia.id_funcionario == tec.id">
                                <v-list-item-title ><img @click="filtrarMarcacoesDatasFuncionarioVer(tec.id)" class="m-2" :width="25" src="../../assets/icons_aplicacao/Ver.png" alt="Ver"></v-list-item-title>
                              </v-list-item-content>
                            </div>
                          </template>
                        </v-list-item>
                      </div>
                    </v-list-item-group>
                  </v-list>
                </v-col>
              </v-row>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="purple" class="white--text" @click="confirmarcriarInterveniente">Criar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- Dialog apagar interveniente -->
        <v-dialog v-model="dialogApagarInterveniente">
          <v-card>
            <v-toolbar class="mb-2" color="red" dark flat>
                <v-toolbar-title>Apagar interveniente</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-title><v-list-item @click="dialogApagarInterveniente = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
            </v-toolbar>
            <v-col cols="12">
                <span>Quer mesmo apagar o interveniente?</span>
            </v-col>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" class="white--text" @click="confirmarApagarInterveniente">Apagar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- Dialog ver Marcações dia -->
        <v-dialog v-model="dialogVerMarcacoesDia">
          <v-card>
            <v-toolbar class="mb-2 primary" dark flat>
              <v-toolbar-title>Marcações do dia</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-title><v-list-item @click="dialogVerMarcacoesDia = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-data-table class="elevation-1" :headers="headersMarcacoes" :items="marcacoesdiafuncionariover">
                    <template v-slot:[`item.descricao`]="{ item }">
                      <span v-if="item.descricao != null" v-html="item.descricao.substring(0,75)"></span>
                    </template>
                    <template v-slot:[`item.data_registo`]="{ item }">
                          {{ apresentarData(item.data_registo) }}
                    </template>
                    <template v-slot:[`item.Marcacoes_Funcionarios`]="{ item }">
                      <div v-for="func in item.Marcacoes_Funcionarios" :key="func.id">
                          {{ func.Funcionario.nome }}
                      </div>
                    </template>
                    <template v-slot:[`item.inicio`]="{ item }">
                      <div v-for="func in item.Marcacoes_Funcionarios" :key="func.id">
                          {{ apresentarData(func.data_inicio) }} {{ apresentarHoras(func.hora_inicio) }}
                      </div>
                    </template>
                    <template v-slot:[`item.fim`]="{ item }">
                      <div v-for="func in item.Marcacoes_Funcionarios" :key="func.id">
                          {{ apresentarData(func.data_fim) }} {{ apresentarHoras(func.hora_fim) }}
                      </div>
                    </template>
                    <template v-slot:[`item.concluido`]="{ item }">
                      <img v-if="item.concluido == false" class="m-2" :width="25" src="../../assets/icons_aplicacao/Nao_Concluido.png" alt="Nao_Concluido">
                      <img v-else class="m-2" :width="25" src="../../assets/icons_aplicacao/Concluido.png" alt="Concluido">
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card-text> 
          </v-card>
        </v-dialog>
    </v-flex>
  </v-layout>
</template> 
<script>
//  Importar o service necessário
import MarcacoesService from '@/services/MarcacoesService'
import EstadosService from '@/services/EstadosService'
import TopicosService from '@/services/TopicosService'
import FuncionariosService from '@/services/FuncionariosService'
import MarcacoesObsService from '@/services/MarcacoesObsService'
import MarcacoesFuncionarioService from '@/services/MarcacoesFuncionarioService'
import store from '@/store/store'
import moment from 'moment-timezone'

export default {
  async mounted () {
    if (!store.state.isUserLoggedIn) {
      this.$router.push({
        name: 'mylogin'
      })
    } else if (this.$store.state.marcacao == null || this.$store.state.marcacao.idmarcacao == null) {
      this.$router.push({
        name: 'marcacoes'
      })
    } else {
      this.editarMarcacoes = (await MarcacoesService.show(this.$store.state.marcacao.idmarcacao)).data
      this.estados = (await EstadosService.index()).data
      this.topicos = (await TopicosService.index()).data
      this.tecnicos = (await FuncionariosService.index(false, ' ', null)).data
      this.horainicioinserida = '09:00'
      this.horafiminserida = '11:00'
      this.marcacoesdiafuncionario = (await MarcacoesFuncionarioService.marcacoesdiafuncionario(this.datainicioinserida.slice(0, 10) + 'T00:00:00.000Z')).data

      if (this.editarMarcacoes.concluido != true) {
        this.todosIntervenientesTerminados()
      }
    }
  },
  data () {
    return {
      datainicioinserida: this.formatarDataMoment(Date.now()),
      datafiminserida: this.formatarDataMoment(Date.now()),
      horainicioinserida: null,
      horafiminserida: null,
      fab: false,
      estados: [],
      topicos: [],
      tecnicos: [],
      observacoes: [],
      marcacoesdiafuncionario: [],
      marcacoesdiafuncionariover: [],
      intervenientesSelecionados: [],
      verIntervenientes: [],
      observacaoemail: {},
      editarMarcacoes: {
        nome_cliente: null,
        quemRegistou: {},
        data_registo: null,
        quem_pediu: null,
        como_pediu: null,
        descricao: null,
        grau_urgencia: null,
        localizacao: null,
        id_topico: null,
        id_estado: null,
        id_tarefa: null,
        data_inicio: null,
        data_fim: null,
        indesmarcavel: null,
        instalacoes_infordio: null,
      },
      criarObservacoes: {
          descricao: '',
          quem_registou: this.$store.state.user.id,
          data_registo: this.formatarDataMoment(Date.now()),
          id_marcacao: null
      },
      editarObservacoes: {
          descricao: null,
          Funcionario: {}
      },
      criarIntervenientes: {
        id_marcacao: this.$store.state.marcacao.idmarcacao,
        id_funcionario: null,
        atribuido_data: this.formatarDataMoment(Date.now()),
        atribuido_por: this.$store.state.user.id,
        data_inicio: null,
        data_fim: null,
        hora_inicio: null,
        hora_fim: null,
        data_inicio_total: null,
        data_fim_total: null
      },
      editarIntervenientes: {
          concluido: null,
          concluido_data: null
      },

      dialogVerObsEmailEnviado: false,
      dialogVerObservacoes: false,
      dialogCriarObservacao: false,
      dialogApagarObservacao: false,
      dialogEditarObservacao: false,
      dialogCriarInterveniente: false,
      dialogApagarInterveniente: false,
      dialogVerMarcacoesDia: false,

      idmarcacaoglobal: '',
      idobservacaoglobal: '',
      idintervenienteglobal: '',

      headersObservacoes: [
        { text: "Email", value: "enviado_mail", sortable: true },
        { text: "Data Registo", value: "data_registo", sortable: true },
        { text: "Descrição", value: "descricao", sortable: true },
        { text: "Funcionário", value: "Funcionario.nome", sortable: true },
        { text: 'Ações', value: 'actions', sortable: false },
      ],

      headersMarcacoes: [
        { text: "Cliente", value: "nome_cliente", sortable: true },
        { text: "Descrição da Marcacao", value: "descricao", sortable: true },
        { text: "Funcionarios", value: "Marcacoes_Funcionarios", sortable: true },
        { text: "Data Registo", value: "data_registo", sortable: true },
        { text: "Início", value: "inicio", sortable: true },
        { text: "Fim", value: "fim", sortable: true },
      ],

      rules: {
        datamenor: value => {
          if (this.editarMarcacoes.data_fim && value > this.editarMarcacoes.data_fim) {
            return "Data fim deve ser maior que a data de início.";
          }
          return true;
        },    
        dataigual: value => {
          if (this.datafiminserida && value !== this.datafiminserida) {
            return "Data fim tem de ser igual à data de início.";
          }
          return true;
        }
      }

    }
  },
  methods: {
    // formatar data
    formatarDataMoment(data) {
      return moment(data).tz('Europe/Lisbon').format('YYYY-MM-DDTHH:mm:ss[Z]');
    },
    apresentarData (date) {
      const options = {year: 'numeric', month: 'numeric', day: 'numeric'}
      return new Date(date).toLocaleDateString('pt-PT', options)
    },
    apresentarHoras (date) {
      return new Date(date).toJSON().slice(11, 19)
    },
    convertToTime(tempo) {
      const totalsegundos = Math.floor(tempo / 1000);
      const horas = Math.floor(totalsegundos / 3600);
      const minutos = Math.floor((totalsegundos % 3600) / 60);
      const segundos = totalsegundos % 60;
      return `${this.pad(horas)}:${this.pad(minutos)}:${this.pad(segundos)}`;
    },
    pad(number) {
      return number.toString().padStart(2, '0');
    },
    navigateToTarefaEditar (route) {
      this.$store.dispatch('setTarefa', {  
        idtarefa: route.params.idTarefaEnviado,
      })
      this.$router.push(route)
    },
    //-------------------------------------------marcacoes------------------------------//
    //-------------------------------------------marcacoes------------------------------//
    //-------------------------------------------marcacoes------------------------------//
    async editarMarcacao () {
      try{
        if (this.$refs.form.validate()) {
          await MarcacoesService.put(this.editarMarcacoes)
          this.editarMarcacoes = (await MarcacoesService.show(this.$store.state.marcacao.idmarcacao)).data
          this.$store.dispatch("setSnackBar", {
              color: "success",
              text: "Marcação editada com sucesso"
          });
        }
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a editar marcação"
          });
      }
    },
    async simMarcacaoTerminada () {
      try{
        if (this.$refs.form.validate()) {
          this.editarMarcacoes.concluido = true
          this.editarMarcacoes.concluido_data = this.formatarDataMoment(Date.now()),
          this.editarMarcacoes.id_estado = 6
          await MarcacoesService.put(this.editarMarcacoes)
          this.editarMarcacoes = (await MarcacoesService.show(this.$store.state.marcacao.idmarcacao)).data
          this.$store.dispatch("setSnackBar", {
              color: "success",
              text: "Marcação terminada com sucesso"
          });
        }
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a terminar a marcação"
          });
      }
    },
    async todosIntervenientesTerminados () {
      //ver se os intervenientes estão todos concluidos
      this.verIntervenientes = (await MarcacoesFuncionarioService.index(this.$store.state.marcacao.idmarcacao)).data
      var intervconcluido = 0
      for (var vi = 0; vi < this.verIntervenientes.length; vi++) {
        if (this.verIntervenientes[vi].concluido == true) {
          intervconcluido = intervconcluido + 1
        }
        if (intervconcluido == this.verIntervenientes.length) {
          this.simMarcacaoTerminada()
        }
      }
    },
    async todosIntervenientesNaoTerminados () {
      //colocar a marcação como não concluida pois tiramos algum interveniente de concluido
      this.verIntervenientes = (await MarcacoesFuncionarioService.index(this.$store.state.marcacao.idmarcacao)).data
      var intervconcluido = 0
      for (var vi = 0; vi < this.verIntervenientes.length; vi++) {
        if (this.verIntervenientes[vi].concluido == true) {
          intervconcluido = intervconcluido + 1
        }
        if (intervconcluido != this.verIntervenientes.length) {
          this.editarMarcacoes.concluido = false
          this.editarMarcacoes.concluido_data = null
          this.editarMarcacoes.id_estado = 1
          await MarcacoesService.put(this.editarMarcacoes)
          this.editarMarcacoes = (await MarcacoesService.show(this.$store.state.marcacao.idmarcacao)).data
        }
      }
    },
    //-------------------------------------------observações------------------------------//
    //-------------------------------------------observações------------------------------//
    //-------------------------------------------observações------------------------------//
    async verObservacoes () {
      this.dialogVerObservacoes = true
      this.observacoes = (await MarcacoesObsService.index(this.$store.state.marcacao.idmarcacao)).data
    },
    async verObsEmailEnviado (id) {
      this.dialogVerObsEmailEnviado = true
      this.observacaoemail = (await MarcacoesObsService.show(id)).data
    },
    async criarObservacao () {
        this.dialogCriarObservacao = true
    },
    async confirmarCriarObservacao () {
      try{
          this.criarObservacoes.id_marcacao = this.$store.state.marcacao.idmarcacao
          await MarcacoesObsService.post(this.criarObservacoes)
          this.dialogCriarObservacao = false
          this.observacoes = (await MarcacoesObsService.index(this.$store.state.marcacao.idmarcacao)).data
          this.$store.dispatch("setSnackBar", {
              color: "success",
              text: "Observação criada com sucesso"
          });
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a criar observação"
          });
      }
    },
    async apagarObservacao (id) {
      this.dialogApagarObservacao = true
      this.idobservacaoglobal = id
    },
    async confirmarApagarObservacao () {
      try{
          await MarcacoesObsService.delete(this.idobservacaoglobal)
          this.dialogApagarObservacao = false
          this.observacoes = (await MarcacoesObsService.index(this.$store.state.marcacao.idmarcacao)).data
          this.$store.dispatch("setSnackBar", {
              color: "success",
              text: "Observação apagada com sucesso"
          });
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a apagar observação"
          });
      }
    },
    async editarObservacao (id) {
        this.dialogEditarObservacao = true
        this.editarObservacoes = (await MarcacoesObsService.show(id)).data
    },
    async confirmarEditarObservacao () {
      try{
        await MarcacoesObsService.put(this.editarObservacoes)
        this.dialogEditarObservacao = false
        this.observacoes = (await MarcacoesObsService.index(this.$store.state.marcacao.idmarcacao)).data
          this.$store.dispatch("setSnackBar", {
              color: "success",
              text: "Observação editada com sucesso"
          });
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a editar observação"
          });
      }
    },
    //-------------------------------------------intervenientes------------------------------//
    //-------------------------------------------intervenientes------------------------------//
    //-------------------------------------------intervenientes------------------------------//
    async confirmarcriarInterveniente () {
      try{
        for (var is = 0; is < this.intervenientesSelecionados.length; is++) {
          this.criarIntervenientes.id_funcionario = this.intervenientesSelecionados[is].id
          this.criarIntervenientes.data_inicio = this.datainicioinserida
          this.criarIntervenientes.data_fim = this.datafiminserida

          const horainicioescolhida = this.formatarDataMoment('2024-04-10T' + this.horainicioinserida.slice(11,16) + ':00Z')
          const horafimescolhida = this.formatarDataMoment('2024-04-10T' + this.horafiminserida.slice(11,16) + ':00Z')
          const sohorainicioescolhida = horainicioescolhida.slice(11,16)
          const sohorafimescolhida = horafimescolhida.slice(11,16)
          const horainiciodoispontos = sohorainicioescolhida.split(':')
          const horafimdoispontos = sohorafimescolhida.split(':')
          const horainiciotemponumerico = ((parseInt(horainiciodoispontos[0], 10) * 60 * 60) + (parseInt(horainiciodoispontos[1], 10) * 60)) * 1000
          const horafimtemponumerico = ((parseInt(horafimdoispontos[0], 10) * 60 * 60) + (parseInt(horafimdoispontos[1], 10) * 60)) * 1000
          this.criarIntervenientes.hora_inicio = '1899-12-30T' + this.convertToTime(horainiciotemponumerico) + 'Z'
          this.criarIntervenientes.hora_fim = '1899-12-30T' + this.convertToTime(horafimtemponumerico) + 'Z'
        
          this.criarIntervenientes.data_inicio_total = this.datainicioinserida.slice(0,11) + this.convertToTime(horainiciotemponumerico) + 'Z'
          this.criarIntervenientes.data_fim_total = this.datafiminserida.slice(0,11) + this.convertToTime(horafimtemponumerico) + 'Z'
          await MarcacoesFuncionarioService.post(this.criarIntervenientes)
        }
          this.intervenientesSelecionados = []
          this.editarMarcacoes = (await MarcacoesService.show(this.$store.state.marcacao.idmarcacao)).data
          this.dialogCriarInterveniente = false
          this.todosIntervenientesNaoTerminados()
          this.$store.dispatch("setSnackBar", {
              color: "success",
              text: "Interveniente criado com sucesso"
          });
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a criar interveniente"
          });
      }
    },
    async apagarInterveniente (id) {
      this.dialogApagarInterveniente = true
      this.idintervenienteglobal = id
    },
    async confirmarApagarInterveniente () {
      try{
          await MarcacoesFuncionarioService.delete(this.idintervenienteglobal)
          this.dialogApagarInterveniente = false
          this.editarMarcacoes = (await MarcacoesService.show(this.$store.state.marcacao.idmarcacao)).data
          this.todosIntervenientesTerminados()
          this.$store.dispatch("setSnackBar", {
              color: "success",
              text: "Interveniente apagado com sucesso"
          });
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a apagar interveniente"
          });
      }
    },
    async intervenienteNaoTerminada (id) {
      try{
          this.editarIntervenientes = (await MarcacoesFuncionarioService.show(id)).data
          this.editarIntervenientes.concluido = false
          this.editarIntervenientes.concluido_data = null
          await MarcacoesFuncionarioService.put(this.editarIntervenientes)
          this.todosIntervenientesNaoTerminados()
          this.editarMarcacoes = (await MarcacoesService.show(this.$store.state.marcacao.idmarcacao)).data
          this.$store.dispatch("setSnackBar", {
              color: "success",
              text: "Interveniente não concluiu a marcacao"
          });
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a editado interveniente"
          });
      }
    },
    async intervenienteTerminada (id) {
      try{
          this.editarIntervenientes = (await MarcacoesFuncionarioService.show(id)).data
          this.editarIntervenientes.concluido = true
          this.editarIntervenientes.concluido_data = this.formatarDataMoment(Date.now()),
          await MarcacoesFuncionarioService.put(this.editarIntervenientes)
          this.todosIntervenientesTerminados()
          this.editarMarcacoes = (await MarcacoesService.show(this.$store.state.marcacao.idmarcacao)).data
          this.$store.dispatch("setSnackBar", {
              color: "success",
              text: "Interveniente concluiu a marcacao"
          });
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a editado interveniente"
          });
      }
    },
    async filtrarMarcacoesDatasFuncionario () {
      this.datafiminserida = this.datainicioinserida
      this.marcacoesdiafuncionario = (await MarcacoesFuncionarioService.marcacoesdiafuncionario(this.datainicioinserida)).data
    },
    async filtrarMarcacoesDatasFuncionarioVer (id) {
      this.dialogVerMarcacoesDia = true
      this.marcacoesdiafuncionariover = (await MarcacoesService.marcacoesdiafuncionariover(id ,this.datainicioinserida)).data
    },
  }
}
</script>
<style>
</style>