<template>
    <v-layout justify-center v-if="$store.state.isUserLoggedIn">
      <v-flex>
          <v-card>
            <v-toolbar class="mb-2" color="green" dark flat>
              <v-toolbar-title>Configurações empresa</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-form ref="form">
                  <v-row>
                    <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 4">
                      <label>Nome</label>
                      <v-text-field v-model="editarEmpresaTitular.nome" filled outlined></v-text-field>
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 4">
                      <label>Endereço</label>
                      <v-text-field v-model="editarEmpresaTitular.endereco" filled outlined></v-text-field>
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 4">
                      <label>Endereço 2</label>
                      <v-text-field v-model="editarEmpresaTitular.endereco2" filled outlined></v-text-field>
                    </v-col>

                    <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                      <label>Tarefas tipo</label>
                      <v-select v-model="editarEmpresaTitular.tarefas_tipo" :items="['Anual', 'Sequencial']" filled outlined></v-select>
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                      <label>Tarefas tipo número desde</label>
                      <v-text-field type="number" v-model="editarEmpresaTitular.tarefas_tipo_desde" filled outlined></v-text-field>
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                      <label>Projetos tipo</label>
                      <v-select v-model="editarEmpresaTitular.projetos_tipo" :items="['Anual', 'Sequencial']" filled outlined></v-select>
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                      <label>Projetos tipo número desde</label>
                      <v-text-field type="number" v-model="editarEmpresaTitular.projetos_tipo_desde" filled outlined></v-text-field>
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                      <label>Gerar novas chaves de notificações</label>
                      <v-btn color="primary" class="white--text" @click="gerarChavesNotificacoes">Gerar chaves notificações</v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" >
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <img @click="editarEmpresa" :width="50" src="../../assets/icons_aplicacao/Guardar.png" alt="Guardar">
                        </v-card-actions>
                    </v-col>
                  </v-row>
              </v-form>
            </v-card-text>
          </v-card>
      </v-flex>
    </v-layout>
  </template> 
  <script>
  //  Importar o service necessário
  import EmpresaTitutarService from '@/services/EmpresaTitutarService'
  import NotificacoesService from '@/services/NotificacoesService'
  import store from '@/store/store'
  
  export default {
    async mounted () {
      if (!store.state.isUserLoggedIn) {
        this.$router.push({
          name: 'mylogin'
        })
      } else {
        this.editarEmpresaTitular = (await EmpresaTitutarService.index()).data
      }
    },
    data () {
      return {
        fab: false,
        editarEmpresaTitular: {}
      }
    },
    methods: {
      //-------------------------------------------Empresa------------------------------//
      //-------------------------------------------Empresa------------------------------//
      //-------------------------------------------Empresa------------------------------//
      async editarEmpresa () {
        try{
          if (this.$refs.form.validate()) {
            await EmpresaTitutarService.put(this.editarEmpresaTitular)
            this.editarEmpresaTitular = (await EmpresaTitutarService.index()).data
            this.$store.dispatch("setSnackBar", {
                color: "success",
                text: "Empresa titular editada com sucesso"
            });
          }
        } catch (error) {
            this.$store.dispatch("setSnackBar", {
                color: "error",
                text: "Erro a editar empresa titular"
            });
        }
      },      
      async gerarChavesNotificacoes() {
        try {
            console.log("Obtendo o Service Worker...");
            const registration = await navigator.serviceWorker.ready;

            console.log("Verificando assinatura existente...");
            const existingSubscription = await registration.pushManager.getSubscription();

            if (existingSubscription) {
                console.log("Cancelando assinatura existente...");
                await existingSubscription.unsubscribe();
                console.log("Assinatura cancelada.");
            } else {
                console.log("Nenhuma assinatura existente encontrada.");
            }

            console.log("Gerando chaves VAPID...");
            await NotificacoesService.generateVapidKeys();
            console.log("Chaves VAPID geradas com sucesso.");

            this.$store.dispatch("setSnackBar", {
                color: "success",
                text: "Chaves geradas com sucesso",
            });
        } catch (error) {
            console.error("Erro ao gerar chaves:", error);

            this.$store.dispatch("setSnackBar", {
                color: "error",
                text: "Erro a gerar chaves",
            });
        }
      }
    }
  }
  </script>
  <style>
  </style>