import Api from '@/services/Api'

export default {
  index (id_mensagem) {
    return Api().get('mensagensfuncionario', {
      params: {
        id_mensagem
      }
    })
  },
  post (elementos) {
    return Api().post('mensagensfuncionario', elementos)
  },
  show (id) {
    return Api().get(`mensagensfuncionario/${id}`)
  },
  put (elementos) {
    return Api().put(`mensagensfuncionario/${elementos.id}`, elementos)
  },
  delete (id) {
    return Api().delete(`mensagensfuncionario/${id}`)
  },
}
