<template>
  <v-layout justify-center v-if="$store.state.isUserLoggedIn">
    <v-flex>
      <v-card>
        <v-toolbar class="mb-2 primary" dark flat>
          <v-toolbar-title>{{cliente.nome}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 6 : 2">
              <div class="retangulodetalhes text-center" @click="navigateTo({name: 'editarclientes', params:{numeroClienteEnviado: $store.state.cliente.numerocliente}})">
                <v-icon>mdi-account</v-icon><br>
                <span>DADOS</span>
              </div>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 6 : 2">
              <div class="retangulodetalhes text-center" @click="navigateTo({name: 'clientesoutroscontactos', params:{numeroClienteEnviado: $store.state.cliente.numerocliente}})">
                <v-icon>mdi-account</v-icon><br>
                <span>OUTROS CONTACTOS</span>
              </div>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 6 : 2">
              <div class="retangulodetalhes text-center" @click="navigateTo({name: 'clientesoutrasmoradas', params:{numeroClienteEnviado: $store.state.cliente.numerocliente}})">
                <v-icon>mdi-account</v-icon><br>
                <span>OUTRAS MORADAS</span>
              </div>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 6 : 2">
              <a :href="this.cliente.link_maps" target="_blank">
                <div class="retangulodetalhes text-center">
                  <v-icon>mdi-account</v-icon><br>
                  <span>MAPS</span>
                </div>
              </a>
            </v-col>
          </v-row>
          <v-row>
            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 6 : 2">
              <div class="retangulodetalhes text-center" @click="navigateTo({name: 'contascorrentes', params:{numeroClienteEnviado: $store.state.cliente.numerocliente}})">
                <img class="m-2" :width="50" src="../../assets/icons_aplicacao/Pendentes.png" alt="Pendentes"><br>
                <span>PENDENTES</span>
              </div>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 6 : 2">
              <div class="retangulodetalhes text-center" @click="navigateTo({name: 'tarefas', params:{numeroClienteEnviado: $store.state.cliente.numerocliente}})">
                <img class="m-2" :width="50" src="../../assets/icons_aplicacao/Tarefas.png" alt="Tarefas"><br>
                <span>TAREFAS</span>
              </div>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 6 : 2">
              <div class="retangulodetalhes text-center" @click="navigateTo({name: 'marcacoes', params:{numeroClienteEnviado: $store.state.cliente.numerocliente}})">
                <img class="m-2" :width="50" src="../../assets/icons_aplicacao/Marcacoes.png" alt="Marcações"><br>
                <span>MARCAÇÕES</span>
              </div>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 6 : 2">
              <div class="retangulodetalhes text-center" @click="navigateTo({name: 'projetos', params:{numeroClienteEnviado: $store.state.cliente.numerocliente}})">
                <img class="m-2" :width="50" src="../../assets/icons_aplicacao/Projetos.png" alt="Projetos"><br>
                <span>PROJETOS</span>
              </div>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 6 : 2">
              <div class="retangulodetalhes text-center" @click="navigateTo({name: 'tempos', params:{numeroClienteEnviado: $store.state.cliente.numerocliente}})">
                <img class="m-2" :width="50" src="../../assets/icons_aplicacao/Tempos.png" alt="Tempos"><br>
                <span>TEMPOS</span>
              </div>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 6 : 2">
              <div class="retangulodetalhes text-center" @click="navigateTo({name: 'equipamentos', params:{numeroClienteEnviado: $store.state.cliente.numerocliente}})">
                <img class="m-2" :width="50" src="../../assets/icons_aplicacao/Equipamentos.png" alt="Equipamentos"><br>
                <span>EQUIPAMENTOS</span>
              </div>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 6 : 2">
              <div class="retangulodetalhes text-center" @click="navigateTo({name: 'notas', params:{numeroClienteEnviado: $store.state.cliente.numerocliente}})">
                <v-icon>mdi-account</v-icon><br>
                <span>NOTAS</span>
              </div>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 6 : 2">
              <div class="retangulodetalhes text-center">
                <span>ANEXOS</span>
              </div>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 6 : 2">
              <div class="retangulodetalhes text-center">
                <span>COBRANÇAS</span>
              </div>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 6 : 2">
              <div class="retangulodetalhes text-center">
                <span>PASSWORD'S</span>
              </div>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 6 : 2">
              <div class="retangulodetalhes text-center">
                <span>CRM</span>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>
  
<script>
//  Importar o service necessário
import ClientesService from '@/services/ClientesService'
import store from '@/store/store'
export default {
  async mounted () {
    if (!store.state.isUserLoggedIn) {
      this.$router.push({
        name: 'mylogin'
      })
    }
    this.cliente = (await ClientesService.show(this.$store.state.cliente.numerocliente)).data
  },
  data () {
    return {
      cliente: {}
    }
  },
  methods: {
    navigateTo (route) {
      this.$store.dispatch('setCliente', {  
        numerocliente: route.params.numeroClienteEnviado,
      })
      this.$router.push(route)
    }
  }
}
</script>
<style>
</style>