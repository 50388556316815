import Api from '@/services/Api'

export default {
  index (id_equipamentos_marca) {
    return Api().get('equipamentosmodelos',{
      params: {
        id_equipamentos_marca,
      }
    })
  },
  post (elementos) {
    return Api().post('equipamentosmodelos', elementos)
  },
  put (elementos, id) {
    return Api().put(`equipamentosmodelos/${id}`, elementos)
  },
  show (id) {
    return Api().get(`equipamentosmodelos/${id}`)
  },
  deleteall () {
    return Api().delete(`equipamentosmodelos`)
  },
  showmodelo (nome, id_equipamentos_marcas) {
    return Api().get(`showmodelo`,{
      params: {
        nome,
        id_equipamentos_marcas
      }
    })
  },
}
