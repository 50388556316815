<template>
    <v-layout justify-center v-if="$store.state.isUserLoggedIn && this.$store.state.cliente != null || this.$store.state.cliente.numerocliente != null">
        <v-speed-dial fab fixed v-model="fab" :bottom="true" :right="true" direction="top" :open-on-hover="false">
            <template v-slot:activator>
                <v-btn v-model="fab" color="blue darken-2" dark large fab>
                <v-icon v-if="fab">mdi-close</v-icon>
                <v-icon v-else>mdi-filter-variant</v-icon>
                </v-btn>
            </template>
            <v-btn fab dark large color="blue" @click.stop="dialogFiltrarContasCorrentes = true">
                <img :width="45" src="../../assets/icons_aplicacao/Pesquisa.png" alt="Pesquisa">
            </v-btn>
        </v-speed-dial>
        <v-flex>
            <v-card> 
                <v-toolbar class="mb-2 primary" dark flat>
                    <v-toolbar-title>Conta-corrente</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-toolbar-title>{{textobotaopendente}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</v-toolbar-title>
                    <v-switch class="pt-5" @change="filtrarPendentes" v-model="botaopendente" color="green" inset></v-switch>
                    <v-toolbar-title><v-list-item class="v-list-item--link" @click="atualizarContaCorrente"><v-icon x-large >mdi-reload</v-icon></v-list-item></v-toolbar-title>
                </v-toolbar>
                <v-col cols="12">
                    <span>Conta-corrente do cliente {{cliente.nome}}</span>
                </v-col>
                <v-card-text>
                    <v-row> 
                        <v-col cols="12" class="mt-5 text-right">    
                            <span class="totalletra"><b>Total: {{formatNumeroEuro(totalcontascorrente)}}</b></span>
                        </v-col>
                        <v-col cols="12">
                            <v-data-table :item-class="getRowClassAnulado" class="elevation-1" :headers="headersContasCorrente" :items="contascorrente">
                                <template v-slot:[`item.data_registo`]="{ item }">
                                    {{ formatDate(item.data_registo) }}
                                </template>
                                <template v-slot:[`item.debito_valor_documento`]="{ item }">
                                    {{ formatNumeroEuro(item.debito_valor_documento) }}
                                </template>
                                <template v-slot:[`item.debito_valor_liquidado`]="{ item }">
                                    {{ formatNumeroEuro(item.debito_valor_liquidado) }}
                                </template>
                                <template v-slot:[`item.credito_valor_documento`]="{ item }">
                                    {{ formatNumeroEuro(item.credito_valor_documento) }}
                                </template>
                                <template v-slot:[`item.credito_valor_liquidado`]="{ item }">
                                    {{ formatNumeroEuro(item.credito_valor_liquidado) }}
                                </template>
                                <template v-slot:[`item.total`]="{ item }">
                                    {{ formatNumeroEuro(item.total) }}
                                </template>
                                <template v-slot:[`item.actions`]="{ item }">
                                <v-icon color="blue" class="m-2" @click="verDocumentosLinhas(item)">mdi-eye</v-icon>
                                </template>
                            </v-data-table>
                            <!-- Dialog linhas documentos -->
                            <v-dialog v-model="dialogDocumentosLinhas">
                                <v-card>
                                    <v-toolbar class="mb-2 primary" dark flat>
                                        <v-toolbar-title>Detalhes do documento {{textonomedocumentocontacorrente}}</v-toolbar-title>
                                        <v-spacer></v-spacer>
                                        <v-spacer></v-spacer>
                                        <v-toolbar-title><v-list-item @click="dialogDocumentosLinhas = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
                                    </v-toolbar>
                                    <v-col cols="12 text-right">
                                        <span class="totais-size"><b>Total: {{formatNumeroEuro(totaldocumentoslinhas)}}</b></span>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-data-table hide-default-footer :items-per-page="999" class="elevation-1" :headers="headersDocumentosLinhas" :items="documentoslinhas">
                                            <template v-slot:[`item.quantidade`]="{ item }">
                                            <template v-if="item.quantidade != 0">{{ item.quantidade + " " }}</template><template v-if="item.unidade != null">{{ item.unidade }}</template>
                                            </template>
                                            <template v-slot:[`item.valor_unitario_sem_iva`]="{ item }">
                                            <template v-if="item.valor_unitario_sem_iva != null && item.quantidade != 0">{{ formatNumeroEuro(item.valor_unitario_sem_iva) }}</template>
                                            </template>
                                            <template v-slot:[`item.desconto1`]="{ item }">
                                            <template v-if="item.desconto1 != 0 && item.quantidade != 0">{{ parseFloat(item.desconto1).toFixed(2)}} %</template>
                                            <template v-else-if="item.desconto2 != 0 && item.quantidade != 0">{{ parseFloat(item.desconto2).toFixed(2)}} %</template>
                                            <template v-else-if="item.desconto_valor != 0 && item.quantidade != 0">{{ parseFloat(item.desconto_valor).toFixed(2)}} %</template>
                                            <template v-else></template>
                                            </template>
                                            <template v-slot:[`item.valor_liq`]="{ item }">
                                            <template v-if="item.quantidade != 0">{{ formatNumeroEuro(item.valor_total_sem_iva)}}</template>
                                            </template>
                                            <template v-slot:[`item.iva`]="{ item }">
                                            <template v-if="item.iva != 0 && item.quantidade != 0">{{ item.iva}} %</template>
                                            </template>
                                            <template v-slot:[`item.valor_total_sem_iva`]="{ item }">
                                            <template v-if="item.valor_total_sem_iva != 0">{{ formatNumeroEuro(item.valor_total_sem_iva)}}</template>
                                            </template>
                                        </v-data-table>
                                    </v-col>
                                </v-card>
                            </v-dialog>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            <!-- Dialog filtrar contascorrentes -->
            <v-dialog v-model="dialogFiltrarContasCorrentes">
                <v-card>
                <v-toolbar class="mb-2" color="black" dark flat>
                    <v-toolbar-title>Filtrar conta corrente</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-toolbar-title><v-list-item @click="dialogFiltrarContasCorrentes = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
                </v-toolbar>
                <v-col cols="12">
                    <label>Primeiro documento</label>
                    <span @click="filtrarPendentesDatas"><MYdatetime v-model="filtrarContasCorrentes.data_primeiro_documento" zone="local" type="date" label="Data"></MYdatetime></span>
                </v-col>
                <v-col cols="12">
                    <label>Último documento</label>
                    <span @click="filtrarPendentesDatas"><MYdatetime v-model="filtrarContasCorrentes.data_ultimo_documento" type="date" label="Data"></MYdatetime></span>
                </v-col>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="black" class="white--text" @click="confirmarFiltrarContasCorrentes">Confirmar</v-btn>
                </v-card-actions>
                </v-card>
            </v-dialog>
        </v-flex>
    </v-layout>
</template>

<script>
//  Importar o service necessário
import ContasCorrentesService from '@/services/ContasCorrentesService'
import DocumentosLinhasService from '@/services/DocumentosLinhasService'
import ClientesService from '@/services/ClientesService'

import store from '@/store/store'
export default {
    async mounted () {
        if (!store.state.isUserLoggedIn) {
            this.$router.push({
                name: 'mylogin'
            })
        } else if (this.$store.state.cliente == null || this.$store.state.cliente.numerocliente == null) {
            this.$router.push({
                name: 'clientes'
            })
        } else {
            this.filtrarContaCorrente()
        }
    },
    data () {
        return {
            fab: false,
            cliente: {},
            contascorrente: [],
            documentoslinhas: [],
            botaopendente: false,
            textobotaopendente: 'Mostrar Pendentes',
            textonomedocumentocontacorrente: '',
            totalcontascorrente: 0,
            totaldocumentoslinhas: 0,
            dialogDocumentosLinhas: false,
            dialogFiltrarContasCorrentes: false,
            headersContasCorrente: [
                { text: "Documento", value: "nome_documento", sortable: true },
                { text: "Tipo documento", value: "tipo_documento", sortable: true },
                { text: "Data doc.", value: "data_registo", sortable: true },
                { text: "Débito Valor doc.", value: "debito_valor_documento", sortable: true },
                { text: "Débito Valor liq.", value: "debito_valor_liquidado", sortable: true },
                { text: "Crédito Valor doc.", value: "credito_valor_documento", sortable: true },
                { text: "Crédito Valor liq.", value: "credito_valor_liquidado", sortable: true },
                { text: 'Total', value: 'total', sortable: true },
                { text: 'Ações', value: 'actions', sortable: false },
            ],
            headersDocumentosLinhas: [
                { text: "Referência", value: "referencia", sortable: true },
                { text: "Descrição", value: "descricao", sortable: true },
                { text: "Quantidade", value: "quantidade", sortable: true },
                { text: "Valor Unidade", value: "valor_unitario_sem_iva", sortable: true },
                { text: "Desconto", value: "desconto1", sortable: true },
                { text: "Valor liq", value: "valor_liq", sortable: true },
                { text: 'Iva', value: 'iva', sortable: true },
                { text: 'Total', value: 'valor_total_sem_iva', sortable: true }
            ],
            filtrarContasCorrentes: {
                total: 0,
                data_primeiro_documento: '',
                data_ultimo_documento: '',
            },
        }
    },
    methods: {
        formatNumeroEuro(numero) {
            const euro = new Intl.NumberFormat('pt-PT', {
                style: 'currency',
                currency: 'EUR',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
                useGrouping: true, // Adiciona espaços de milhares
            });
            return euro.format(numero);
        },
        formatDate (date) {
            const options = {year: 'numeric', month: 'numeric', day: 'numeric'}
            return new Date(date).toLocaleDateString('pt-PT', options)
        },
        async filtrarContaCorrente () {
            this.cliente = (await ClientesService.show(this.$store.state.cliente.numerocliente)).data
            this.contascorrente = (await ContasCorrentesService.index(this.$store.state.cliente.numerocliente, this.filtrarContasCorrentes.total)).data
            for (var cc = 0; cc < this.contascorrente.length; cc++) {
                this.totalcontascorrente = this.totalcontascorrente + this.contascorrente[cc].total
                if (this.filtrarContasCorrentes.data_primeiro_documento == '') {
                    this.filtrarContasCorrentes.data_primeiro_documento = this.contascorrente[cc].data_registo
                } else if (this.contascorrente[cc].data_registo < this.filtrarContasCorrentes.data_primeiro_documento) {
                    this.filtrarContasCorrentes.data_primeiro_documento = this.contascorrente[cc].data_registo
                }
                if (this.filtrarContasCorrentes.data_ultimo_documento == '') {
                    this.filtrarContasCorrentes.data_ultimo_documento = this.contascorrente[cc].data_registo
                } else if (this.contascorrente[cc].data_registo > this.filtrarContasCorrentes.data_ultimo_documento) {
                    this.filtrarContasCorrentes.data_ultimo_documento = this.contascorrente[cc].data_registo
                }
            }
        },
        async verDocumentosLinhas (item) {
            this.dialogDocumentosLinhas = true
            this.documentoslinhas = (await DocumentosLinhasService.index(item.stamp_documento)).data
            this.textonomedocumentocontacorrente = item.nome_documento
            if (item.debito_valor_documento != 0) {
                this.totaldocumentoslinhas = item.debito_valor_documento
            } else {
                this.totaldocumentoslinhas = Math.abs(item.credito_valor_documento)
            }      
        },
        async filtrarPendentes () {
            if (this.botaopendente == false) {
                this.contascorrente = (await ContasCorrentesService.index(this.$store.state.cliente.numerocliente, this.filtrarContasCorrentes.total)).data
                this.filtrarContasCorrentes.data_primeiro_documento = null
                this.filtrarContasCorrentes.data_ultimo_documento = null
                for (var ccp = 0; ccp < this.contascorrente.length; ccp++) {
                if (this.filtrarContasCorrentes.data_primeiro_documento == null) {
                    this.filtrarContasCorrentes.data_primeiro_documento = this.contascorrente[ccp].data_registo
                } else if (this.contascorrente[ccp].data_registo < this.filtrarContasCorrentes.data_primeiro_documento) {
                    this.filtrarContasCorrentes.data_primeiro_documento = this.contascorrente[ccp].data_registo
                }
                if (this.filtrarContasCorrentes.data_ultimo_documento == null) {
                    this.filtrarContasCorrentes.data_ultimo_documento = this.contascorrente[ccp].data_registo
                } else if (this.contascorrente[ccp].data_registo > this.filtrarContasCorrentes.data_ultimo_documento) {
                    this.filtrarContasCorrentes.data_ultimo_documento = this.contascorrente[ccp].data_registo
                }
                }
                this.contascorrente = (await ContasCorrentesService.index(this.$store.state.cliente.numerocliente, this.filtrarContasCorrentes.total, this.filtrarContasCorrentes.data_primeiro_documento, this.filtrarContasCorrentes.data_ultimo_documento)).data
                this.totalcontascorrente = 0
                for (var cccp = 0; cccp < this.contascorrente.length; cccp++) {
                this.totalcontascorrente = this.totalcontascorrente + this.contascorrente[cccp].total
                }
                this.textobotaopendente = 'Mostrar pendentes'
            }
            if (this.botaopendente == true) {
                this.contascorrente = (await ContasCorrentesService.index(this.$store.state.cliente.numerocliente)).data
                this.filtrarContasCorrentes.data_primeiro_documento = null
                this.filtrarContasCorrentes.data_ultimo_documento = null
                for (var cct = 0; cct < this.contascorrente.length; cct++) {
                if (this.filtrarContasCorrentes.data_primeiro_documento == null) {
                    this.filtrarContasCorrentes.data_primeiro_documento = this.contascorrente[cct].data_registo
                } else if (this.contascorrente[cct].data_registo < this.filtrarContasCorrentes.data_primeiro_documento) {
                    this.filtrarContasCorrentes.data_primeiro_documento = this.contascorrente[cct].data_registo
                }
                if (this.filtrarContasCorrentes.data_ultimo_documento == null) {
                    this.filtrarContasCorrentes.data_ultimo_documento = this.contascorrente[cct].data_registo
                } else if (this.contascorrente[cct].data_registo > this.filtrarContasCorrentes.data_ultimo_documento) {
                    this.filtrarContasCorrentes.data_ultimo_documento = this.contascorrente[cct].data_registo
                }
                }
                this.contascorrente = (await ContasCorrentesService.index(this.$store.state.cliente.numerocliente, null, this.filtrarContasCorrentes.data_primeiro_documento, this.filtrarContasCorrentes.data_ultimo_documento)).data
                this.totalcontascorrente = 0
                for (var ccct = 0; ccct < this.contascorrente.length; ccct++) {
                this.totalcontascorrente = this.totalcontascorrente + this.contascorrente[ccct].total
                }
                this.textobotaopendente = 'Mostrar todas'
            }
        },
        async filtrarPendentesDatas () {
            if (this.botaopendente == false) {
                this.contascorrente = (await ContasCorrentesService.index(this.$store.state.cliente.numerocliente, this.filtrarContasCorrentes.total, this.filtrarContasCorrentes.data_primeiro_documento, this.filtrarContasCorrentes.data_ultimo_documento)).data
                this.totalcontascorrente = 0
                for (var ccdp = 0; ccdp < this.contascorrente.length; ccdp++) {
                this.totalcontascorrente = this.totalcontascorrente + this.contascorrente[ccdp].total
                }
            }
            if (this.botaopendente == true) {
                this.contascorrente = (await ContasCorrentesService.index(this.$store.state.cliente.numerocliente, null, this.filtrarContasCorrentes.data_primeiro_documento, this.filtrarContasCorrentes.data_ultimo_documento)).data
                this.totalcontascorrente = 0
                for (var ccdt = 0; ccdt < this.contascorrente.length; ccdt++) {
                this.totalcontascorrente = this.totalcontascorrente + this.contascorrente[ccdt].total
                }
            }
        },
        getRowClassAnulado(item) {
            if (item.anulado == true) {return 'coranulado'}
        },
        async confirmarFiltrarContasCorrentes () {
            this.dialogFiltrarContasCorrentes = false
        },
        async atualizarContaCorrente () {
            await ContasCorrentesService.importarcontascorrenteserp(this.$store.state.cliente.numerocliente, this.cliente.data_atualizacao_contas_corrente)
            this.filtrarContaCorrente()
        }
    }
}
</script>
<style>
</style>