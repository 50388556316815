import Api from '@/services/Api'

export default {
  index (stamp_documento) {
    return Api().get('documentoslinhas',{
      params: {
        stamp_documento
      }
    })
  },
  post (elementos) {
    return Api().post('documentoslinhas', elementos)
  },
  put (elementos, documentolinhaStamp) {
    return Api().put(`documentoslinhas/${documentolinhaStamp}`, elementos)
  },
  show (documentolinhaStamp) {
    return Api().get(`documentoslinhas/${documentolinhaStamp}`)
  },
  deleteall () {
    return Api().delete(`documentoslinhas`)
  }
}
