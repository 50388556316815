<template>
  <v-layout justify-center v-if="$store.state.isUserLoggedIn">
    <v-flex>
        <v-card>
          <v-toolbar class="mb-2" color="purple" dark flat>
            <v-toolbar-title>Criar tarefa</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form ref="form">
              <v-row>
                <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 6">
                  <label>Clientes</label>
                  <v-autocomplete v-model="criarTarefas.numero_cliente" :items="clientes" item-text="nome" item-value="numero" clearable filled outlined :rules="[rules.cliente]"></v-autocomplete>
                  <template v-if="criarTarefas.numero_cliente == empresaTitular.numero_consumidor_final">
                    <label>Nome consumidor final</label>
                    <v-text-field type="text" v-model="criarTarefas.nome_cliente" clearable filled outlined :rules="[rules.consumidorfinal]"></v-text-field>
                  </template>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                  <label>Quem Pediu</label>
                  <v-text-field type="text" v-model="criarTarefas.quem_pediu" clearable filled outlined></v-text-field>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                  <label>Pedido como</label>
                  <v-select v-model="criarTarefas.como_pediu" :items="['E-mail', 'Telefone', 'WhatsApp', 'Formação', 'Ticket', 'Reunião']" filled outlined></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 8">
                  <v-row>
                    <v-col cols="12">
                      <label>Descrição</label>
                      <wysiwyg v-model="criarTarefas.descricao"/>
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                      <label>Urgência</label>
                      <v-select v-model="criarTarefas.grau_urgencia" :items="['Alta', 'Média', 'Baixa']" filled outlined></v-select>
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                      <label>Tópico</label>
                      <v-select v-model="criarTarefas.id_topico" :items="topicos" item-text="descricao" item-value="id" filled outlined></v-select>
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                      <label>Estado</label>
                      <v-select v-model="criarTarefas.id_estado" :items="estados" item-text="descricao" item-value="id" filled outlined></v-select>
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                      <label>Data de Inicio</label>
                      <MYdatetime type="date" v-model="criarTarefas.data_inicio"></MYdatetime>
                      <v-text-field class="d-none" type="date" v-model="criarTarefas.data_inicio" clearable filled outlined :rules="[rules.datamenor]"></v-text-field>
                      <br>
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                      <label>Data de fim</label>
                      <MYdatetime type="date" v-model="criarTarefas.data_fim"></MYdatetime>
                      <span v-if="criarTarefas.data_inicio > criarTarefas.data_fim" class="errodata">Data fim deve ser maior que a data de início.</span>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 4">
                    <v-row>
                      <v-col cols="12" class="text-center">
                        <label>INTERVENIENTES </label>
                        <img @click="criarTarefa" :width="35" src="../../assets/icons_aplicacao/Add_Interveniente.png" alt="Add_Interveniente">
                      </v-col>
                    </v-row>
                  </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <!-- Dialog Criar Interveniente -->
          <v-dialog v-model="dialogCriarInterveniente">
            <v-card>
              <v-toolbar class="mb-2" color="purple" dark flat>
                <v-toolbar-title>Criar intervenientes</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-title><v-list-item @click="dialogCriarInterveniente = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
              </v-toolbar>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-list>
                      <v-list-item-group color="primary">
                        <div v-for="tec in tecnicos" :key="tec.id">
                          <v-divider></v-divider>
                          <v-list-item>
                            <template>
                              <v-list-item-action>
                                <v-checkbox v-model="intervenientesSelecionados" :value="tec"></v-checkbox>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title>{{tec.nome}}</v-list-item-title>
                              </v-list-item-content>
                            </template>
                          </v-list-item>
                        </div>
                      </v-list-item-group>
                    </v-list>
                  </v-col>
                </v-row>
              </v-container>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="purple" class="white--text" @click="confirmarCriarTarefa">Criar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
//  Importar o service necessário
import TarefasService from '@/services/TarefasService'
import ClientesService from '@/services/ClientesService'
import EstadosService from '@/services/EstadosService'
import TopicosService from '@/services/TopicosService'
import FuncionariosService from '@/services/FuncionariosService'
import TemposService from '@/services/TemposService'
import TarefasFuncionarioService from '@/services/TarefasFuncionarioService'
import EmpresaTitutarService from '@/services/EmpresaTitutarService'
import store from '@/store/store'
import moment from 'moment-timezone'

export default {
  async mounted () {
    if (!store.state.isUserLoggedIn) {
      this.$router.push({
        name: 'mylogin'
      })
    }
    this.tecnicos = (await FuncionariosService.index(false, ' ', null)).data
    this.clientes = (await ClientesService.index(false)).data
    this.estados = (await EstadosService.index()).data
    this.topicos = (await TopicosService.index()).data
    this.empresaTitular = (await EmpresaTitutarService.index()).data
    this.criarTarefas.data_inicio = null
    this.criarTarefas.data_fim = null
    if (this.$store.state.tempo.idtempotarefa != null) {
      this.editarTempos = (await TemposService.show(this.$store.state.tempo.idtempotarefa)).data
      this.criarTarefas.descricao = this.editarTempos.descricao
      this.criarTarefas.numero_cliente = this.editarTempos.numero_cliente
      this.criarTarefas.nome_cliente = this.editarTempos.nome_cliente
      this.$store.dispatch('setTempo', {
        idtempotarefa: null,
        finalizadomobile: null
      })
    } else {
      this.criarTarefas.descricao = ''
      this.criarTarefas.numero_cliente = null
      this.criarTarefas.nome_cliente = null
    }
  },
  data () {
    return {
      clientes: [],
      estados: [],
      topicos: [],
      tecnicos: [],
      editarTempos: {},
      empresaTitular: {},
      intervenientesSelecionados: [],
      criarTarefas: {
        numero_cliente: null,
        data_registo: this.formatarDataMoment(Date.now()),
        quem_registou: this.$store.state.user.id,
        descricao: '',
        concluido: 0,
        id_topico: null,
        id_estado: 1,
        grau_urgencia: 'Média',
        quem_pediu: null,
        como_pediu: null,
        data_inicio: null,
        data_fim: null,
        id_projeto: 0,
        nome_cliente: null,
        ano: null,
        numero: null,
      },
      criarIntervenientes: {
        id_tarefa: null,
        id_funcionario: null,
        data_registo: this.formatarDataMoment(Date.now()),
        quem_registou: this.$store.state.user.id,
      },

      dialogCriarInterveniente: false,
      
      rules: {
        cliente: value => !!value || "Cliente é Obrigatório.",    
        consumidorfinal: value => !!value || "Nome consumidor final é Obrigatório.",
        datamenor: value => {
          if (this.criarTarefas.data_fim && value > this.criarTarefas.data_fim) {
            return "Data fim deve ser maior que a data de início.";
          }
          return true;
        }
      }
    }
  },
  methods: {
    formatarDataMoment(data) {
      return moment(data).tz('Europe/Lisbon').format('YYYY-MM-DDTHH:mm:ss[Z]');
    },
    //-------------------------------------------tarefa------------------------------//
    //-------------------------------------------tarefa------------------------------//
    //-------------------------------------------tarefa------------------------------//
    async criarTarefa () {
      if (this.$refs.form.validate()) {
        this.dialogCriarInterveniente = true
      }
    },
    async confirmarCriarTarefa () {
      try{
        if (this.$refs.form.validate()) {
          this.clienteselecionado = (await ClientesService.show(this.criarTarefas.numero_cliente)).data
          if (this.clienteselecionado.numero != this.empresaTitular.numero_consumidor_final) {
            this.criarTarefas.nome_cliente = this.clienteselecionado.nome
          }
          this.criarTarefas.ano = new Date().getFullYear();

          if (this.empresaTitular.tarefas_tipo == 'Anual') {
            //tem de ir buscar o maior numero do ano em que está, e se ainda não houver vai iniciar no número que estiver no tarefas_tipo_desde
            this.maiornumerotarefaano = (await TarefasService.maiornumerotarefaano()).data
            if (this.maiornumerotarefaano.numero != undefined) {
                this.criarTarefas.numero = this.maiornumerotarefaano.numero + 1;
            } else {
                this.criarTarefas.numero = this.empresaTitular.tarefas_tipo_desde
            }
          } else {
            //tem de ir buscar o maior numero, e se ainda não houver vai iniciar no número que estiver no tarefas_tipo_desde
            this.maiornumerotarefa = (await TarefasService.maiornumerotarefa()).data
            if (this.maiornumerotarefa.numero != undefined) {
                this.criarTarefas.numero = this.maiornumerotarefa.numero + 1;
            } else {
                this.criarTarefas.numero = this.empresaTitular.tarefas_tipo_desde
            }
          }
          await TarefasService.post(this.criarTarefas)
          this.tarefasultima = (await TarefasService.tarefasultima()).data
          for (var is = 0; is < this.intervenientesSelecionados.length; is++) {
            this.criarIntervenientes.id_tarefa = this.tarefasultima.id
            this.criarIntervenientes.id_funcionario = this.intervenientesSelecionados[is].id
            await TarefasFuncionarioService.post(this.criarIntervenientes)
          }
          if (this.editarTempos.id != undefined) {
            this.editarTempos.id_tarefa = this.tarefasultima.id
            await TemposService.put(this.editarTempos)
          }

          this.$store.dispatch("setSnackBar", {
              color: "success",
              text: "Tarefa criada com sucesso"
          });
          this.$store.dispatch('setTarefa', {
            idtarefa: this.tarefasultima.id,
          })
          this.$router.push({
            name: 'editartarefas'
          })
      } else {
        this.dialogCriarInterveniente = false
      }
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a criar tarefa"
          });
      }
    }
  }
}
</script>
<style>
</style>