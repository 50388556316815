import Api from '@/services/Api'

export default {
  index (inativo, numero_serial, numero_cliente, id_equipamentos_marca, id_equipamentos_modelo, id_equipamentos_tipo, data_venda, id) {
    return Api().get('equipamentos',{
      params: {
        inativo,
        numero_serial,
        numero_cliente,
        id_equipamentos_marca,
        id_equipamentos_modelo,
        id_equipamentos_tipo,
        data_venda,
        id
      }
    })
  },
  post (elementos) {
    return Api().post('equipamentos', elementos)
  },
  put (elementos, id) {
    return Api().put(`equipamentos/${id}`, elementos)
  },
  show (id) {
    return Api().get(`equipamentos/${id}`)
  },
  delete (id) {
    return Api().delete(`equipamentos/${id}`)
  },
  deleteall () {
    return Api().delete(`equipamentos`)
  },
  showserial (serial) {
    return Api().get(`showserial`,{
      params: {
        serial
      }
    })
  },


  //IMPORTAR ERP'S
  importarequipamentoserp (numero_cliente, data_atualizacao) {
    return Api().get('importarequipamentoserp', {
      params: {
        numero_cliente,
        data_atualizacao
      }
    })
  },

  criarequipamentoserp (elementos) {
    return Api().post('criarequipamentoserp', elementos)
  },

  apagarequipamentoserp (elementos) {
    return Api().delete(`apagarequipamentoserp/${elementos.stamp_equipamento}`)
  },
}
