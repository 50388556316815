import Api from '@/services/Api'

export default {
  index (id_tarefa) {
    return Api().get('tarefasfuncionario',{
      params: {
        id_tarefa
      }
    })
  },
  post (elementos) {
    return Api().post('tarefasfuncionario', elementos)
  },
  put (elementos) {
    return Api().put(`tarefasfuncionario/${elementos.id}`, elementos)
  },
  show (id) {
    return Api().get(`tarefasfuncionario/${id}`)
  },
  delete (id) {
    return Api().delete(`tarefasfuncionario/${id}`)
  }
}
