import Api from '@/services/Api'

export default {
  index (id_marcacao) {
    return Api().get('marcacoesobs',{
      params: {
        id_marcacao
      }
    })
  },
  post (elementos) {
    return Api().post('marcacoesobs', elementos)
  },
  put (elementos) {
    return Api().put(`marcacoesobs/${elementos.id}`, elementos)
  },
  show (id) {
    return Api().get(`marcacoesobs/${id}`)
  },
  delete (id) {
    return Api().delete(`marcacoesobs/${id}`)
  }
  
}
