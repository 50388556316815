<template>
  <v-layout justify-center v-if="$store.state.isUserLoggedIn && this.$store.state.marcacaotarefa != null || this.$store.state.marcacaotarefa.tarefamarcacao != null">
    <v-flex>
        <v-card>
          <v-toolbar class="mb-2" color="purple" dark flat>
            <v-toolbar-title>Criar marcacão</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form ref="form">
              <v-row>
                <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 6">
                  <label>Clientes</label>
                  <template>
                    <v-text-field v-model="criarMarcacoes.nome_cliente" disabled filled outlined></v-text-field>
                    <!--<v-autocomplete v-model="criarMarcacoes.numero_cliente" :items="clientes" item-text="nome" item-value="numero" clearable filled outlined :rules="[rules.cliente]"></v-autocomplete>-->
                  </template>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                  <label>Quem Pediu</label>
                  <v-text-field type="text" v-model="criarMarcacoes.quem_pediu" clearable filled outlined></v-text-field>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                  <label>Pedido como</label>
                  <v-select v-model="criarMarcacoes.como_pediu" :items="['E-mail', 'Telefone', 'WhatsApp', 'Formação', 'Ticket', 'Reunião']" filled outlined></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 8">
                  <v-row>
                    <v-col cols="12">
                      <label>Descrição</label>
                      <wysiwyg v-model="criarMarcacoes.descricao"/>
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                      <label>Localização</label>
                      <v-text-field type="text" v-model="criarMarcacoes.localizacao" clearable filled outlined></v-text-field>
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                      <label>Tópico</label>
                      <v-select v-model="criarMarcacoes.id_topico" :items="topicos" item-text="descricao" item-value="id" filled outlined></v-select>
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                      <label>Estado</label>
                      <v-select v-model="criarMarcacoes.id_estado" :items="estados" item-text="descricao" item-value="id" filled outlined></v-select>
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                      <label>Data de Inicio</label>
                      <MYdatetime type="date" v-model="criarMarcacoes.data_inicio"></MYdatetime>
                      <v-text-field class="d-none" type="date" v-model="criarMarcacoes.data_inicio" clearable filled outlined :rules="[rules.datamenor]"></v-text-field>
                      <br>
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                      <label>Data de fim</label>
                      <MYdatetime type="date" v-model="criarMarcacoes.data_fim"></MYdatetime>
                      <span v-if="criarMarcacoes.data_inicio > criarMarcacoes.data_fim" class="errodata">Data fim deve ser maior que a data de início.</span>
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                      <label>Indesmarcável</label>
                      <v-switch v-model="criarMarcacoes.indesmarcavel" color="primary" inset></v-switch>
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                      <label>Instalações Infordio</label>
                      <v-switch v-model="criarMarcacoes.instalacoes_infordio" color="primary" inset></v-switch>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 4">
                    <v-row>
                      <v-col cols="12" class="text-center">
                        <label>INTERVENIENTES </label>
                        <img @click="criarMarcacao" :width="35" src="../../assets/icons_aplicacao/Add_Interveniente.png" alt="Add_Interveniente">
                      </v-col>
                    </v-row>
                  </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <!-- Dialog Criar Interveniente -->
          <v-dialog v-model="dialogCriarInterveniente">
            <v-card>
              <v-toolbar class="mb-2" color="purple" dark flat>
                <v-toolbar-title>Criar intervenientes</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-title><v-list-item @click="dialogCriarInterveniente = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
              </v-toolbar>
              <v-container>
                <v-row>
                  <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 6">
                    <label>Início</label>
                    <span @click="filtrarMarcacoesDatasFuncionario"><MYdatetime type="date" v-model="datainicioinserida" format="dd-MM-yyyy"></MYdatetime></span>
                    <br>
                    <MYdatetime type="time" v-model="horainicioinserida" format="HH:mm" :hour-step="1" :minute-step="15" :min-datetime="'08'" :max-datetime="'20'"></MYdatetime>
                    <v-text-field class="d-none" type="date" v-model="datainicioinserida" clearable filled outlined :rules="[rules.dataigual]" ></v-text-field>
                  </v-col>
                  <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 6">
                    <label>Fim</label>
                    <MYdatetime type="date" v-model="datafiminserida" format="dd-MM-yyyy"></MYdatetime>
                    <span v-if="datainicioinserida.slice(0,10) != datafiminserida.slice(0,10)" class="errodata">Data fim tem de ser igual à data de início.</span>
                    <br>
                    <MYdatetime type="time" v-model="horafiminserida" format="HH:mm" :hour-step="1" :minute-step="15" :min-datetime="'08'" :max-datetime="'20'"></MYdatetime>
                  </v-col>
                  <v-col cols="12">
                    <v-list>
                      <v-list-item-group color="primary">
                        <div v-for="tec in tecnicos" :key="tec.id">
                          <v-divider></v-divider>
                          <v-list-item>
                            <template>
                              <v-list-item-action>
                                <v-checkbox v-model="intervenientesSelecionados" :value="tec"></v-checkbox>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title>{{tec.nome}}</v-list-item-title>
                              </v-list-item-content>
                              <div v-for="marcacoesdia in marcacoesdiafuncionario" :key="marcacoesdia.id_funcionario">
                                <v-list-item-content v-if="marcacoesdia.id_funcionario == tec.id">
                                  <v-list-item-title ><img @click="filtrarMarcacoesDatasFuncionarioVer(tec.id)" class="m-2" :width="20" src="../../assets/icons_aplicacao/Ver.png" alt="Ver"></v-list-item-title>
                                </v-list-item-content>
                              </div>
                            </template>
                          </v-list-item>
                        </div>
                      </v-list-item-group>
                    </v-list>
                  </v-col>
                </v-row>
              </v-container>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="purple" class="white--text" @click="confirmarCriarMarcacao">Criar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- Dialog ver Marcações dia -->
          <v-dialog v-model="dialogVerMarcacoesDia">
            <v-card>
              <v-toolbar class="mb-2 primary" dark flat>
                <v-toolbar-title>Marcações do dia</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-title><v-list-item @click="dialogVerMarcacoesDia = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-data-table class="elevation-1" :headers="headersMarcacoes" :items="marcacoesdiafuncionariover">
                      <template v-slot:[`item.descricao`]="{ item }">
                        <span v-if="item.descricao != null" v-html="item.descricao.substring(0,75)"></span>
                      </template>
                      <template v-slot:[`item.data_registo`]="{ item }">
                            {{ apresentarData(item.data_registo) }}
                      </template>
                      <template v-slot:[`item.Marcacoes_Funcionarios`]="{ item }">
                        <div v-for="func in item.Marcacoes_Funcionarios" :key="func.id">
                            {{ func.Funcionario.nome }}
                        </div>
                      </template>
                      <template v-slot:[`item.inicio`]="{ item }">
                        <div v-for="func in item.Marcacoes_Funcionarios" :key="func.id">
                            {{ apresentarData(func.data_inicio) }} {{ apresentarHoras(func.hora_inicio) }}
                        </div>
                      </template>
                      <template v-slot:[`item.fim`]="{ item }">
                        <div v-for="func in item.Marcacoes_Funcionarios" :key="func.id">
                            {{ apresentarData(func.data_fim) }} {{ apresentarHoras(func.hora_fim) }}
                        </div>
                      </template>
                      <template v-slot:[`item.concluido`]="{ item }">
                        <img v-if="item.concluido == false" class="m-2" :width="25" src="../../assets/icons_aplicacao/Nao_Concluido.png" alt="Nao_Concluido">
                        <img v-else class="m-2" :width="25" src="../../assets/icons_aplicacao/Concluido.png" alt="Concluido">
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-card-text> 
            </v-card>
          </v-dialog>
        </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
//  Importar o service necessário
import MarcacoesService from '@/services/MarcacoesService'
import ClientesService from '@/services/ClientesService'
import EstadosService from '@/services/EstadosService'
import TopicosService from '@/services/TopicosService'
import FuncionariosService from '@/services/FuncionariosService'
import MarcacoesFuncionarioService from '@/services/MarcacoesFuncionarioService'
import store from '@/store/store'
import moment from 'moment-timezone'

export default {
  async mounted () {
    if (!store.state.isUserLoggedIn) {
      this.$router.push({
        name: 'mylogin'
      })
    }else if (this.$store.state.marcacaotarefa == null || this.$store.state.marcacaotarefa.tarefamarcacao == null) {
      this.$router.push({
        name: 'myhome'
      })
    } else {
      this.tecnicos = (await FuncionariosService.index(false, ' ', null)).data
      this.clientes = (await ClientesService.index(false)).data
      this.estados = (await EstadosService.index()).data
      this.topicos = (await TopicosService.index()).data
      this.criarMarcacoes.data_inicio = null
      this.criarMarcacoes.data_fim = null
      this.horainicioinserida = '09:00'
      this.horafiminserida = '11:00'
      this.marcacoesdiafuncionario = (await MarcacoesFuncionarioService.marcacoesdiafuncionario(this.datainicioinserida.slice(0, 10) + 'T00:00:00.000Z')).data
      this.$store.dispatch('setMarcacaoTarefa', {  
        tarefamarcacao: null
      })
    }
  },
  data () {
    return {
      datainicioinserida: this.formatarDataMoment(Date.now()),
      datafiminserida: this.formatarDataMoment(Date.now()),
      horainicioinserida: null,
      horafiminserida: null,
      clientes: [],
      estados: [],
      topicos: [],
      tecnicos: [],
      marcacoesdiafuncionario: [],
      marcacoesdiafuncionariover: [],
      intervenientesSelecionados: [],
      criarMarcacoes: {
        numero_cliente: this.$store.state.marcacaotarefa.tarefamarcacao.numero_cliente,
        data_registo: this.formatarDataMoment(Date.now()),
        quem_registou: this.$store.state.user.id,
        descricao: this.$store.state.marcacaotarefa.tarefamarcacao.descricao,
        concluido: 0,
        id_topico: null,
        id_estado: 1,
        localizacao: null,
        quem_pediu: null,
        como_pediu: null,
        data_inicio: null,
        data_fim: null,
        indesmarcavel: false,
        instalacoes_infordio: false,
        nome_cliente: this.$store.state.marcacaotarefa.tarefamarcacao.nome_cliente,
        id_tarefa: this.$store.state.marcacaotarefa.tarefamarcacao.id,
      },
      criarIntervenientes: {
        id_marcacao: null,
        id_funcionario: null,
        atribuido_data: this.formatarDataMoment(Date.now()),
        atribuido_por: this.$store.state.user.id,
        data_inicio: null,
        data_fim: null,
        hora_inicio: null,
        hora_fim: null,
        data_inicio_total: null,
        data_fim_total: null
      },

      dialogCriarInterveniente: false,
      dialogVerMarcacoesDia: false,
      rules: {
        cliente: value => !!value || "Cliente é Obrigatório.",  
        datamenor: value => {
          if (this.criarMarcacoes.data_fim && value > this.criarMarcacoes.data_fim) {
            return "Data fim deve ser maior que a data de início.";
          }
          return true;
        },  
        dataigual: value => {
          if (this.datafiminserida && value !== this.datafiminserida) {
            return "Data fim tem de ser igual à data de início.";
          }
          return true;
        }
      },

      headersMarcacoes: [
        { text: "Cliente", value: "nome_cliente", sortable: true },
        { text: "Descrição da Marcacao", value: "descricao", sortable: true },
        { text: "Funcionarios", value: "Marcacoes_Funcionarios", sortable: true },
        { text: "Data Registo", value: "data_registo", sortable: true },
        { text: "Início", value: "inicio", sortable: true },
        { text: "Fim", value: "fim", sortable: true },
      ],
    }
  },
  methods: {
    formatarDataMoment(data) {
      return moment(data).tz('Europe/Lisbon').format('YYYY-MM-DDTHH:mm:ss[Z]');
    },
    apresentarData (date) {
      const options = {year: 'numeric', month: 'numeric', day: 'numeric'}
      return new Date(date).toLocaleDateString('pt-PT', options)
    },
    apresentarHoras (date) {
      return new Date(date).toJSON().slice(11, 19)
    },
    convertToTime(tempo) {
      const totalsegundos = Math.floor(tempo / 1000);
      const horas = Math.floor(totalsegundos / 3600);
      const minutos = Math.floor((totalsegundos % 3600) / 60);
      const segundos = totalsegundos % 60;
      return `${this.pad(horas)}:${this.pad(minutos)}:${this.pad(segundos)}`;
    },
    pad(number) {
      return number.toString().padStart(2, '0');
    },
    //-------------------------------------------marcacao------------------------------//
    //-------------------------------------------marcacao------------------------------//
    //-------------------------------------------marcacao------------------------------//
    async criarMarcacao () {
      if (this.$refs.form.validate()) {
        this.dialogCriarInterveniente = true
      }
    },
    async confirmarCriarMarcacao () {
      try{
        if (this.$refs.form.validate()) {
          await MarcacoesService.post(this.criarMarcacoes)

          this.marcacoesultima = (await MarcacoesService.marcacoesultima()).data

          for (var is = 0; is < this.intervenientesSelecionados.length; is++) {
            this.criarIntervenientes.id_marcacao = this.marcacoesultima.id
            this.criarIntervenientes.id_funcionario = this.intervenientesSelecionados[is].id
            this.criarIntervenientes.data_inicio = this.datainicioinserida
            this.criarIntervenientes.data_fim = this.datafiminserida

            const horainicioescolhida = this.formatarDataMoment('2024-04-10T' + this.horainicioinserida.slice(11,16) + ':00Z')
            const horafimescolhida = this.formatarDataMoment('2024-04-10T' + this.horafiminserida.slice(11,16) + ':00Z')
            const sohorainicioescolhida = horainicioescolhida.slice(11,16)
            const sohorafimescolhida = horafimescolhida.slice(11,16)
            const horainiciodoispontos = sohorainicioescolhida.split(':')
            const horafimdoispontos = sohorafimescolhida.split(':')
            const horainiciotemponumerico = ((parseInt(horainiciodoispontos[0], 10) * 60 * 60) + (parseInt(horainiciodoispontos[1], 10) * 60)) * 1000
            const horafimtemponumerico = ((parseInt(horafimdoispontos[0], 10) * 60 * 60) + (parseInt(horafimdoispontos[1], 10) * 60)) * 1000
            this.criarIntervenientes.hora_inicio = '1899-12-30T' + this.convertToTime(horainiciotemponumerico) + 'Z'
            this.criarIntervenientes.hora_fim = '1899-12-30T' + this.convertToTime(horafimtemponumerico) + 'Z'
         
            this.criarIntervenientes.data_inicio_total = this.datainicioinserida.slice(0,11) + this.convertToTime(horainiciotemponumerico) + 'Z'
            this.criarIntervenientes.data_fim_total = this.datafiminserida.slice(0,11) + this.convertToTime(horafimtemponumerico) + 'Z'
            await MarcacoesFuncionarioService.post(this.criarIntervenientes)
          }

          this.$store.dispatch("setSnackBar", {
              color: "success",
              text: "Marcação criada com sucesso"
          });
          this.$store.dispatch('setMarcacao', {
            idmarcacao: this.marcacoesultima.id,
          })
          this.$router.push({
            name: 'editarmarcacoes'
          })
      } else {
        this.dialogCriarInterveniente = false
      }
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a criar marcacao"
          });
      }
    },
    async filtrarMarcacoesDatasFuncionario () {
      this.datafiminserida = this.datainicioinserida
      this.marcacoesdiafuncionario = (await MarcacoesFuncionarioService.marcacoesdiafuncionario(this.datainicioinserida)).data
    },
    async filtrarMarcacoesDatasFuncionarioVer (id) {
      this.dialogVerMarcacoesDia = true
      this.marcacoesdiafuncionariover = (await MarcacoesService.marcacoesdiafuncionariover(id ,this.datainicioinserida)).data
    },
  }
}
</script>
<style>
</style>