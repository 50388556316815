import Api from '@/services/Api'

export default {
  index () {
    return Api().get('tempostipo', {})
  },
  post (elementos) {
    return Api().post('tempostipo', elementos)
  },
  show (id) {
    return Api().get(`tempostipo/${id}`)
  },
  put (elementos) {
    return Api().put(`tempostipo/${elementos.id}`, elementos)
  },
  delete (id) {
    return Api().delete(`tempostipo/${id}`)
  }
}
