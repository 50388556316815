self.addEventListener('push', function (event) {
  // Verifica se há dados na notificação
  let data = {};
  if (event.data) {
    data = event.data.json();
  }

  const title = data.notification?.title || 'Nova Notificação';
  const body = data.notification?.body || 'Você tem uma nova mensagem.';
  const icon = data.notification?.icon || '/icon.png'; // Altere para o caminho do ícone
  const actions = data.notification?.actions || [];

  // Exibir a notificação
  event.waitUntil(
    self.registration.showNotification(title, {
      body: body,
      icon: icon,
      actions: actions,
      data: data.notification?.data || {}, // Dados adicionais que podem ser úteis
    })
  );
});