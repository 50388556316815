import Api from '@/services/Api'

export default {
  index (numero_cliente) {
    return Api().get('notas', {
      params: {
        numero_cliente
      }
    })
  },
  post (elementos) {
    return Api().post('notas', elementos)
  },
  show (id) {
    return Api().get(`notas/${id}`)
  },
  put (elementos) {
    return Api().put(`notas/${elementos.id}`, elementos)
  },
  delete (id) {
    return Api().delete(`notas/${id}`)
  }
}
