<template>
    <v-layout justify-center v-if="$store.state.isUserLoggedIn">
        <v-flex>
            <v-card>
                <v-toolbar class="mb-2" color="purple" dark flat>
                    <v-toolbar-title>Criar equipamento</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-form ref="form">
                        <v-row>
                            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 9">
                                <label>Clientes</label>
                                <v-autocomplete v-model="criarEquipamentos.numero_cliente" :items="clientes" item-text="nome" item-value="numero" clearable filled outlined :rules="[rules.cliente]"></v-autocomplete>
                            </v-col>
                            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                                <label>Nº Série</label>
                                <v-text-field type="text" v-model="criarEquipamentos.serial" clearable filled outlined :rules="[rules.serial]"></v-text-field>
                            </v-col>
                            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                                <label>Nº Série 2</label>
                                <v-text-field type="text" v-model="criarEquipamentos.serial2" clearable filled outlined></v-text-field>
                            </v-col>
                            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                                <label>Marca</label><img @click="criarMarcaEquipamento" class="m-2" :width="25" src="../../assets/icons_aplicacao/Add.png" alt="Add">
                                <v-autocomplete @change="filtrarModelos" v-model="criarEquipamentos.id_equipamentos_marca" :rules="[rules.marca]" :items="equipamentosmarcas" item-text="nome" item-value="id" clearable filled outlined></v-autocomplete>
                            </v-col>
                            <v-col v-if="criarEquipamentos.id_equipamentos_marca != null" :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                                <label>Modelo</label><img @click="criarModeloEquipamento" class="m-2" :width="25" src="../../assets/icons_aplicacao/Add.png" alt="Add">
                                <v-autocomplete v-model="criarEquipamentos.id_equipamentos_modelo" :rules="[rules.modelo]" :items="equipamentosmodelos" item-text="nome" item-value="id" clearable filled outlined></v-autocomplete>
                            </v-col>
                            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                                <label>Tipo</label><img @click="criarTipoEquipamento" class="m-2" :width="25" src="../../assets/icons_aplicacao/Add.png" alt="Add">
                                <v-autocomplete v-model="criarEquipamentos.id_equipamentos_tipo" :rules="[rules.tipo]" :items="equipamentostipos" item-text="nome" item-value="id" clearable filled outlined></v-autocomplete>
                            </v-col>  
                            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 6">
                                <label>Local</label>
                                <v-text-field type="text" v-model="criarEquipamentos.local" clearable filled outlined></v-text-field>
                            </v-col>
                            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 6">
                                <label>Descrição</label>
                                <v-text-field type="text" v-model="criarEquipamentos.descricao" clearable filled outlined></v-text-field>
                            </v-col>
                            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                                <label>Data de Venda</label>
                                <MYdatetime type="date" v-model="criarEquipamentos.data_venda"></MYdatetime>
                            </v-col>
                            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                                <label>Data de Fim Garantia</label>
                                <MYdatetime type="date" v-model="criarEquipamentos.data_fim_garantia"></MYdatetime>
                            </v-col>
                            <v-col cols="12">
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="purple" class="white--text" @click="confirmarCriarEquipamentos">Criar</v-btn>
                                </v-card-actions>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
            </v-card>
            <!-- Dialog serial equipamento já existe -->
            <v-dialog v-model="dialogSerialEquipamentoJaExiste">
                <v-card>
                    <v-toolbar color="orange" class="mb-2" dark flat>
                        <v-toolbar-title>Número de série já existe</v-toolbar-title>
                    </v-toolbar>
                    <v-col cols="12">
                        <span>Não pode adicionar o numero de série {{ criarEquipamentos.serial }} porque já existe.</span><br>
                        <span>Por favor escolha outro numero de série.</span>
                    </v-col>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="orange" class="white--text" @click="dialogSerialEquipamentoJaExiste = false">Ok</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- Dialog criar marca -->
            <v-dialog v-model="dialogCriarMarca">
                <v-card>
                    <v-toolbar class="mb-2" color="purple" dark flat>
                        <v-toolbar-title>Criar marca</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                        <v-toolbar-title><v-list-item @click="dialogCriarMarca = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-form ref="form">
                            <v-row>
                                <v-col cols="12">
                                    <label>Marca</label>
                                    <v-text-field type="text" v-model="criarMarca.nome" clearable filled outlined :rules="[rules.marca]"></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="purple" class="white--text" @click="confirmarMarcaEquipamento">Criar</v-btn>
                                    </v-card-actions>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <!-- Dialog marca equipamento já existe -->
            <v-dialog v-model="dialogMarcaEquipamentoJaExiste">
                <v-card>
                    <v-toolbar color="orange" class="mb-2" dark flat>
                        <v-toolbar-title>Marca já existe</v-toolbar-title>
                    </v-toolbar>
                    <v-col cols="12">
                        <span>Não pode adicionar esta marca {{ criarMarca.nome }} porque já existe.</span><br>
                        <span>Por favor insira outra marca.</span>
                    </v-col>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="orange" class="white--text" @click="dialogMarcaEquipamentoJaExiste = false">Ok</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- Dialog criar modelo -->
            <v-dialog v-model="dialogCriarModelo">
                <v-card>
                    <v-toolbar class="mb-2" color="purple" dark flat>
                        <v-toolbar-title>Criar modelo</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                        <v-toolbar-title><v-list-item @click="dialogCriarModelo = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-form ref="form">
                            <v-row>             
                                <v-col cols="12">
                                    <label>Marca</label>
                                    <v-autocomplete v-model="criarModelo.id_equipamentos_marcas" :rules="[rules.marca]" :items="equipamentosmarcas" item-text="nome" item-value="id" clearable filled outlined></v-autocomplete>
                                </v-col>
                                <v-col cols="12">
                                    <label>Modelo</label>
                                    <v-text-field type="text" v-model="criarModelo.nome" clearable filled outlined :rules="[rules.modelo]"></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="purple" class="white--text" @click="confirmarModeloEquipamento">Criar</v-btn>
                                    </v-card-actions>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <!-- Dialog modelo equipamento já existe -->
            <v-dialog v-model="dialogModeloEquipamentoJaExiste">
                <v-card>
                    <v-toolbar color="orange" class="mb-2" dark flat>
                        <v-toolbar-title>Modelo já existe</v-toolbar-title>
                    </v-toolbar>
                    <v-col cols="12">
                        <span>Não pode adicionar este modelo {{ criarModelo.nome }} porque já existe nesta marca.</span><br>
                        <span>Por favor insira outro modelo.</span>
                    </v-col>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="orange" class="white--text" @click="dialogModeloEquipamentoJaExiste = false">Ok</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- Dialog criar tipo -->
            <v-dialog v-model="dialogCriarTipo">
                <v-card>
                    <v-toolbar class="mb-2" color="purple" dark flat>
                        <v-toolbar-title>Criar tipo</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                        <v-toolbar-title><v-list-item @click="dialogCriarTipo = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-form ref="form">
                            <v-row>
                                <v-col cols="12">
                                    <label>Tipo</label>
                                    <v-text-field type="text" v-model="criarTipo.nome" clearable filled outlined :rules="[rules.tipo]"></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="purple" class="white--text" @click="confirmarTipoEquipamento">Criar</v-btn>
                                    </v-card-actions>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <!-- Dialog tipo equipamento já existe -->
            <v-dialog v-model="dialogTipoEquipamentoJaExiste">
                <v-card>
                    <v-toolbar color="orange" class="mb-2" dark flat>
                        <v-toolbar-title>Tipo já existe</v-toolbar-title>
                    </v-toolbar>
                    <v-col cols="12">
                        <span>Não pode adicionar este tipo {{ criarTipo.nome }} porque já existe.</span><br>
                        <span>Por favor insira outro tipo.</span>
                    </v-col>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="orange" class="white--text" @click="dialogTipoEquipamentoJaExiste = false">Ok</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-flex>
    </v-layout>
</template>
  
<script>
//  Importar o service necessário
import ClientesService from '@/services/ClientesService'
import EquipamentosService from '@/services/EquipamentosService'
import EquipamentosTiposService from '@/services/EquipamentosTiposService'
import EquipamentosMarcasService from '@/services/EquipamentosMarcasService'
import EquipamentosModelosService from '@/services/EquipamentosModelosService'
import ConfiguracoesSistemaService from '@/services/ConfiguracoesSistemaService'
import store from '@/store/store'

export default {
    async mounted () {
        this.configuracoessistema = (await ConfiguracoesSistemaService.index()).data
        if (!store.state.isUserLoggedIn) {
            this.$router.push({
                name: 'mylogin'
            })
        } else {
            this.equipamentosmarcas = (await EquipamentosMarcasService.index()).data
            this.equipamentosmodelos = (await EquipamentosModelosService.index()).data
            this.equipamentostipos = (await EquipamentosTiposService.index()).data
            this.clientes = (await ClientesService.index(false)).data
            this.criarEquipamentos.data_venda = null
            this.criarEquipamentos.data_fim_garantia = null
        }
        
    },
    data () {
        return {
            equipamentosmarcas: [],
            equipamentosmodelos: [],
            equipamentostipos: [],
            clientes: [],
            configuracoessistema: {},
            criarEquipamentos: {
                serial: null,
                serial2: null,
                id_equipamentos_tipo: null,
                id_equipamentos_marca: null,
                id_equipamentos_modelo: null,
                data_venda: null,
                data_fim_garantia: null,
                numero_cliente: null,
                descricao: null,
                inativo: false,
                local: null
            },
            criarEquipamentosErp: {
                id_equipamento: null,
                numero_tecnico: this.$store.state.user.numero_tecnico,
                numero_cliente: null,
            },
            criarMarca: {
                nome: null
            },
            criarModelo: {
                id_equipamentos_marcas: null,
                nome: null
            },
            criarTipo: {
                nome: null
            },
            marcaequipamentos: {
                nome: null
            },
            modeloequipamentos: {
                id_equipamentos_marcas: null,
                nome: null
            },
            tipoequipamentos: {
                nome: null
            },
            marcaobrigatorio: false,
            modeloobrigatorio: false,
            tipoobrigatorio: false,
            equipamentoserial: {
                serial: null
            },
            dialogSerialEquipamentoJaExiste: false,
            dialogCriarMarca: false,
            dialogMarcaEquipamentoJaExiste: false,
            dialogCriarModelo: false,
            dialogModeloEquipamentoJaExiste: false,
            dialogCriarTipo: false,
            dialogTipoEquipamentoJaExiste: false,
            rules: {
                serial: value => !!value || "Sérial é Obrigatório.",
                marca: value => !!value || "Marca é Obrigatória.",
                modelo: value => !!value || "Modelo é Obrigatório.",
                tipo: value => !!value || "Tipo é Obrigatório.",
                cliente: value => !!value || "Cliente é Obrigatório.",
            }
        }
    },
    methods: {
        // filtrar modelos
        async filtrarModelos () {
        this.equipamentosmodelos = (await EquipamentosModelosService.index(this.criarEquipamentos.id_equipamentos_marca)).data
        },
        async confirmarCriarEquipamentos () {
            if (this.$refs.form.validate()) { 
                if (this.criarEquipamentos.serial != null) {
                    this.equipamentoserial = (await EquipamentosService.showserial(this.criarEquipamentos.serial)).data
                }
                if (this.equipamentoserial.serial != undefined) {
                    this.dialogSerialEquipamentoJaExiste = true
                } else {
                    try{           
                        if (this.configuracoessistema.modulo_equipamentos == true) {
                            await EquipamentosService.post(this.criarEquipamentos)

                            this.equipamentoserial = (await EquipamentosService.showserial(this.criarEquipamentos.serial)).data
                            this.criarEquipamentosErp.id_equipamento = this.equipamentoserial.id
                            this.criarEquipamentosErp.numero_cliente = this.equipamentoserial.numero_cliente
                            const response =  await EquipamentosService.criarequipamentoserp(this.criarEquipamentosErp)

                            if (response.data == 'ERRO') {
                                await EquipamentosService.delete(this.equipamentoserial.id)
                                this.$store.dispatch("setSnackBar", {
                                    color: "error",
                                    text: "Campos estão a ir errados"
                                });         
                            } else {

                                this.criarEquipamentos.serial = null
                                this.criarEquipamentos.serial2 = null
                                this.criarEquipamentos.id_equipamentos_tipo = null
                                this.criarEquipamentos.id_equipamentos_marca = null
                                this.criarEquipamentos.id_equipamentos_modelo = null
                                this.criarEquipamentos.data_venda = null
                                this.criarEquipamentos.data_fim_garantia = null
                                this.criarEquipamentos.numero_cliente = null
                                this.criarEquipamentos.descricao = null
                                this.criarEquipamentos.inativo = false
                                this.criarEquipamentos.local = null

                                this.$store.dispatch("setSnackBar", {
                                    color: "success",
                                    text: "Equipamento criado no erp com sucesso"
                                });
                            }
                        } else {
                            await EquipamentosService.post(this.criarEquipamentos)

                            this.$store.dispatch("setSnackBar", {
                                color: "success",
                                text: "Equipamento criado com sucesso"
                            });
                            
                            this.criarEquipamentos.serial = null
                            this.criarEquipamentos.serial2 = null
                            this.criarEquipamentos.id_equipamentos_tipo = null
                            this.criarEquipamentos.id_equipamentos_marca = null
                            this.criarEquipamentos.id_equipamentos_modelo = null
                            this.criarEquipamentos.data_venda = null
                            this.criarEquipamentos.data_fim_garantia = null
                            this.criarEquipamentos.numero_cliente = null
                            this.criarEquipamentos.descricao = null
                            this.criarEquipamentos.inativo = false
                            this.criarEquipamentos.local = null
                        }
                    } catch (error) {
                        await EquipamentosService.delete(this.equipamentoserial.id)
                        this.$store.dispatch("setSnackBar", {
                            color: "error",
                            text: "Erro a criar equipamento"
                        });
                    }
                }
            }
        },
        async criarMarcaEquipamento () {
            this.dialogCriarMarca = true
        },
        async confirmarMarcaEquipamento () {
            if (this.$refs.form.validate()) {
                if (this.criarMarca.nome != null) {
                    this.marcaequipamentos = (await EquipamentosMarcasService.showmarca(this.criarMarca.nome)).data
                }
                if (this.marcaequipamentos.nome != undefined) {
                    this.dialogMarcaEquipamentoJaExiste = true
                } else {
                    try {
                        await EquipamentosMarcasService.post(this.criarMarca)
                        this.equipamentosmarcas = (await EquipamentosMarcasService.index()).data
                        this.dialogCriarMarca = false
                        this.$store.dispatch("setSnackBar", {
                            color: "success",
                            text: "Marca criada com sucesso"
                        });
                    } catch (error) {
                        this.$store.dispatch("setSnackBar", {
                            color: "error",
                            text: "Erro a criar marca"
                        });
                    }
                }
                
            }
        },
        async criarModeloEquipamento () {
            this.dialogCriarModelo = true
        },
        async confirmarModeloEquipamento () {
            if (this.$refs.form.validate()) {
                if (this.criarModelo.nome != null) {
                    this.modeloequipamentos = (await EquipamentosModelosService.showmodelo(this.criarModelo.nome, this.criarModelo.id_equipamentos_marcas)).data
                }
                if (this.modeloequipamentos.nome != undefined) {
                    this.dialogModeloEquipamentoJaExiste = true
                } else {
                    try {
                        await EquipamentosModelosService.post(this.criarModelo)
                        this.equipamentosmodelos = (await EquipamentosModelosService.index()).data
                        this.dialogCriarModelo = false
                        this.$store.dispatch("setSnackBar", {
                            color: "success",
                            text: "Modelo criado com sucesso"
                        });
                    } catch (error) {
                        this.$store.dispatch("setSnackBar", {
                            color: "error",
                            text: "Erro a criar modelo"
                        });
                    }
                }
                
            }
        },
        async criarTipoEquipamento () {
            this.dialogCriarTipo = true
        },
        async confirmarTipoEquipamento () {
            if (this.$refs.form.validate()) {
                if (this.criarTipo.nome != null) {
                    this.tipoequipamentos = (await EquipamentosTiposService.showtipo(this.criarTipo.nome)).data
                }
                if (this.tipoequipamentos.nome != undefined) {
                    this.dialogTipoEquipamentoJaExiste = true
                } else {
                    try {
                        await EquipamentosTiposService.post(this.criarTipo)
                        this.equipamentostipos = (await EquipamentosTiposService.index()).data
                        this.dialogCriarTipo = false
                        this.$store.dispatch("setSnackBar", {
                            color: "success",
                            text: "Tipo criado com sucesso"
                        });
                    } catch (error) {
                        this.$store.dispatch("setSnackBar", {
                            color: "error",
                            text: "Erro a criar Tipo"
                        });
                    }
                }
                
            }
        }
    }
}
</script>
<style>
.v-text-field.v-text-field--enclosed .v-text-field__details {
    display: block !important; 
}
</style>