import Api from '@/services/Api'

export default {
  index () {
    return Api().get('clientesoutros')
  },
  post (elementos) {
    return Api().post('clientesoutros', elementos)
  },
  put (elementos, id) {
    return Api().put(`clientesoutros/${id}`, elementos)
  },
  show (id) {
    return Api().get(`clientesoutros/${id}`)
  },
  deleteall () {
    return Api().delete(`clientesoutros`)
  },
  clienteoutroexiste (clienteoutro) {
    return Api().get(`clienteoutroexiste`,{
      params: {
        clienteoutro
      }
    })
  }
}
