import Api from '@/services/Api'

export default {
  index (id_projeto) {
    return Api().get('projetosfuncionario',{
      params: {
        id_projeto
      }
    })
  },
  post (elementos) {
    return Api().post('projetosfuncionario', elementos)
  },
  put (elementos) {
    return Api().put(`projetosfuncionario/${elementos.id}`, elementos)
  },
  show (id) {
    return Api().get(`projetosfuncionario/${id}`)
  },
  delete (id) {
    return Api().delete(`projetosfuncionario/${id}`)
  },
}
