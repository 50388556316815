<template>
    <v-layout justify-center v-if="$store.state.isUserLoggedIn">
      <v-speed-dial fab fixed v-model="fab" :bottom="true" :right="true" direction="top" :open-on-hover="false">
        <template v-slot:activator>
          <v-btn v-model="fab" color="blue darken-2" dark large fab>
            <v-icon v-if="fab">mdi-close</v-icon>
            <v-icon v-else>mdi-filter-variant</v-icon>
          </v-btn>
        </template>
        <v-btn fab dark large color="blue" class="mb-2" @click.stop="dialogFiltrarArtigos = true">
          <img :width="45" src="../../assets/icons_aplicacao/Pesquisa.png" alt="Pesquisa">
        </v-btn>
      </v-speed-dial>
      <v-flex>
        <v-card>
          <v-toolbar class="mb-2 primary" dark flat>
            <v-toolbar-title>Artigos</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-title>{{textobotaoinativo}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</v-toolbar-title>
            <v-switch class="pt-5" @change="filtrarConcluidos" v-model="botaoinativo" color="green" inset></v-switch>
          </v-toolbar>
          <v-col cols="12">
            <v-data-table class="elevation-1" :headers="headersArtigos" :items="artigos">
              <template v-slot:[`item.descricao`]="{ item }">
                <span v-if="item.descricao != null" v-html="item.descricao.substring(0,75)"></span>
              </template>
              <template v-slot:[`item.preco_venda_sem_iva`]="{ item }">
                {{ formatNumeroEuro(item.preco_venda_sem_iva) }}
              </template>
              <template v-slot:[`item.preco_venda_com_iva`]="{ item }">
                {{ formatNumeroEuro(item.preco_venda_com_iva) }}
              </template>
              <template v-slot:[`item.iva`]="{ item }">
                {{ item.iva }} %
              </template>
              <template v-slot:[`item.propriedade`]="{ item }">
                <img v-if="item.propriedade == false" class="m-2" :width="25" src="../../assets/icons_aplicacao/Nao_Concluido.png" alt="Sem propriedade">
                <img v-else class="m-2" :width="25" src="../../assets/icons_aplicacao/Concluido.png" alt="Com propriedade">
              </template>
              <template v-slot:[`item.inativo`]="{ item }">
                <img v-if="item.inativo == true" class="m-2" :width="25" src="../../assets/icons_aplicacao/Nao_Concluido.png" alt="Nao_Concluido">
                <img v-else class="m-2" :width="25" src="../../assets/icons_aplicacao/Concluido.png" alt="Concluido">
              </template>
            </v-data-table>
          </v-col>
        </v-card>
        <!-- Dialog filtrar artigo -->
        <v-dialog v-model="dialogFiltrarArtigos">
          <v-card>
            <v-toolbar class="mb-2" color="black" dark flat>
              <v-toolbar-title>Filtrar artigos</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-title><v-list-item @click="dialogFiltrarArtigos = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
            </v-toolbar>
            <v-col cols="12">
              <label>Codigo</label>
              <v-text-field type="text" v-model="filtrarArtigos.codigo" clearable filled outlined></v-text-field>
            </v-col>
            <v-col cols="12">
              <label>Descrição</label>
              <v-text-field type="text" v-model="filtrarArtigos.descricao" clearable filled outlined></v-text-field>
            </v-col>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="black" class="white--text" @click="confirmarFiltrarArtigos">Filtrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- Dialog apagar artigo -->
        <v-dialog v-model="dialogApagarArtigo">
          <v-card>
            <v-toolbar class="mb-2" color="red" dark flat>
                <v-toolbar-title>Apagar artigo</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-title><v-list-item @click="dialogApagarArtigo = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
            </v-toolbar>
            <v-col cols="12">
                <span>Quer mesmo apagar a artigo?</span>
            </v-col>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" class="white--text" @click="confirmarApagarArtigo">Apagar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-layout>
  </template>
    
  <script>
  //  Importar o service necessário
  import ArtigosService from '@/services/ArtigosService'
  import store from '@/store/store'
  import moment from 'moment-timezone'
  export default {
    async mounted () {
      if (!store.state.isUserLoggedIn) {
        this.$router.push({
          name: 'mylogin'
        })
      } else {
        this.artigos = (await ArtigosService.index(this.filtrarArtigos.inativo)).data
      }
    },
    data () {
      return {
        fab: false,
        artigos: [],
        filtrarArtigos: {
          inativo: false,
          codigo: null,
          descricao: null
        },

        headersArtigos: [
          { text: "Referência", value: "codigo", sortable: false },
          { text: "Descricao", value: "descricao", sortable: true },
          { text: "Propriedade", value: "propriedade", sortable: true },
          { text: "Preço sem iva (€)", value: "preco_venda_sem_iva", sortable: true },
          { text: "IVA (%)", value: "iva", sortable: true },
          { text: "Preço com iva (€)", value: "preco_venda_com_iva", sortable: true },
          { text: "Stock", value: "stock", sortable: true },
          { text: "Localização", value: "localizacao", sortable: true },
          { text: "Uni", value: "unidade", sortable: true },
          { text: "Ativo", value: "inativo", sortable: true },
          { text: 'Ações', value: 'actions', sortable: false },
        ],
  
        dialogFiltrarArtigos: false,
        dialogApagarArtigo: false,
  
        idartigoglobal: '',
        
        textobotaoinativo: 'Mostrar activos',
        botaoinativo: false,
      }
    },
    methods: {
      formatNumeroEuro(numero) {
        const euro = new Intl.NumberFormat('pt-PT', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            useGrouping: true, // Adiciona espaços de milhares
        });
        return euro.format(numero);
      },
      // formatar data
      formatarDataMoment(data) {
        return moment(data).tz('Europe/Lisbon').format('YYYY-MM-DDTHH:mm:ss[Z]');
      },
      apresentarData (date) {
        const options = {year: 'numeric', month: 'numeric', day: 'numeric'}
        return new Date(date).toLocaleDateString('pt-PT', options)
      },
      apresentarHoras (date) {
        return new Date(date).toJSON().slice(11, 19)
      },
      navigateToArtigoEditar (route) {
        this.$store.dispatch('setArtigo', {  
          idartigo: route.params.idArtigoEnviado,
        })
        this.$router.push(route)
      },
      // filtrar artigos
      async confirmarFiltrarArtigos () {
        this.artigos = (await ArtigosService.index(this.filtrarArtigos.inativo, this.filtrarArtigos.codigo, this.filtrarArtigos.descricao)).data
        this.dialogFiltrarArtigos = false
      },
      // filtrar inativos
      async filtrarConcluidos () {
        if (this.botaoinativo == false) {
            this.filtrarArtigos.inativo = false
            this.textobotaoinativo = 'Mostrar activos'
        }
        if (this.botaoinativo == true) {
            this.filtrarArtigos.inativo = null
            this.textobotaoinativo = 'Mostrar todos'
        }
        this.artigos = (await ArtigosService.index(this.filtrarArtigos.inativo, this.filtrarArtigos.codigo, this.filtrarArtigos.descricao)).data
      },
      //-------------------------------------------artigos------------------------------//
      //-------------------------------------------artigos------------------------------//
      //-------------------------------------------artigos------------------------------//
      async apagarArtigo (id) {
        this.dialogApagarArtigo = true
        this.idartigoglobal = id
      },
      async confirmarApagarArtigo () {
        try{
            await ArtigosService.delete(this.idartigoglobal)
            this.dialogApagarArtigo = false
            this.artigos = (await ArtigosService.index(this.filtrarArtigos.inativo, this.filtrarArtigos.codigo, this.filtrarArtigos.descricao)).data
            this.$store.dispatch("setSnackBar", {
                color: "success",
                text: "Artigo apagada com sucesso"
            });
        } catch (error) {
            this.$store.dispatch("setSnackBar", {
                color: "error",
                text: "Erro a apagar artigo, pode ter coisas associadas"
            });
        }
      },
    }
  }
  </script>
  <style>
  </style>