<template>
  <v-layout justify-center v-if="$store.state.isUserLoggedIn">
    <v-speed-dial fab fixed v-model="fab" :bottom="true" :right="true" direction="top" :open-on-hover="false">
      <template v-slot:activator>
        <v-btn v-model="fab" color="blue darken-2" dark large fab>
          <v-icon v-if="fab">mdi-close</v-icon>
          <v-icon v-else>mdi-filter-variant</v-icon>
        </v-btn>
      </template>
      <v-btn fab dark large color="blue" @click.stop="dialogFiltrarClientes = true">
        <img class="m-2" :width="45" src="../../assets/icons_aplicacao/Pesquisa.png" alt="Pesquisa">
      </v-btn>
      <v-btn v-if="configuracoessistema.modulo_clientes == false" fab dark large color="blue" class="mb-2" to="criarclientes">
        <img class="m-2" :width="45" src="../../assets/icons_aplicacao/Add_Cliente.png" alt="Add_Cliente">
      </v-btn>
    </v-speed-dial>
    <v-flex>
      <v-card>
        <v-toolbar class="mb-2 primary" dark flat>
          <v-toolbar-title>Clientes</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-title>{{textobotaoinativo}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</v-toolbar-title>
          <v-switch class="pt-5" @change="filtrarInativos" v-model="botaoinativo" color="green" inset></v-switch>
        </v-toolbar>
        <v-col cols="12">
          <v-data-table class="elevation-1" :headers="headersClientes" :items="clientes">
            <template v-slot:[`item.inativo`]="{ item }">
              <img v-if="item.inativo == true" class="m-2" :width="25" src="../../assets/icons_aplicacao/Nao_Concluido.png" alt="Nao_Concluido">
              <img v-else class="m-2" :width="25" src="../../assets/icons_aplicacao/Concluido.png" alt="Concluido">
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <img @click="navigateToCliente({name: 'editarclientes', params:{numeroClienteEnviado: item.numero}})" class="m-2" :width="25" src="../../assets/icons_aplicacao/Editar.png" alt="Editar">
              <img @click="navigateToCliente({name: 'verclientes', params:{numeroClienteEnviado: item.numero}})" class="m-2" :width="25" src="../../assets/icons_aplicacao/Ver.png" alt="Ver">
              <img @click="apagarCliente(item.numero)" class="m-2" :width="25" src="../../assets/icons_aplicacao/Apagar.png" alt="Apagar">
              <img @click="navigateToTempoCriar({name: 'criartemposclientes', params:{numeroClienteEnviado: item.numero}})" class="m-2" :width="25" src="../../assets/icons_aplicacao/Add_Tempos.png" alt="Add_Tempos">
            </template>
          </v-data-table>
        </v-col>
      </v-card>
      <!-- Dialog filtrar cliente -->
      <v-dialog v-model="dialogFiltrarClientes">
        <v-card>
          <v-toolbar class="mb-2" color="black" dark flat>
            <v-toolbar-title>Filtrar clientes</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-title><v-list-item @click="dialogFiltrarClientes = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
          </v-toolbar>
          <v-col cols="12">
            <label>Número</label>
            <v-text-field type="text" v-model="filtrarClientes.numero" clearable filled outlined></v-text-field>
          </v-col>
          <v-col cols="12">
            <label>Nome</label>
            <v-autocomplete v-model="filtrarClientes.numero" :items="clientes" item-text="nome" item-value="numero" clearable filled outlined></v-autocomplete>
          </v-col>
          <v-col cols="12">
            <label>Contribuinte</label>
            <v-text-field type="text" v-model="filtrarClientes.contribuinte" clearable filled outlined></v-text-field>
          </v-col>
          <v-col cols="12">
            <label>Telefone</label>
            <v-text-field type="text" v-model="filtrarClientes.telefone" clearable filled outlined></v-text-field>
          </v-col>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="black" class="white--text" @click="confirmarFiltrarClientes">Filtrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Dialog apagar cliente -->
      <v-dialog v-model="dialogApagarCliente">
        <v-card>
          <v-toolbar class="mb-2" color="red" dark flat>
              <v-toolbar-title>Apagar cliente</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-title><v-list-item @click="dialogApagarCliente = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
          </v-toolbar>
          <v-col cols="12">
              <span>Quer mesmo apagar o cliente?</span>
          </v-col>
          <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red" class="white--text" @click="confirmarApagarCliente">Apagar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-flex>
  </v-layout>
</template>
  
<script>
//  Importar o service necessário
import ClientesService from '@/services/ClientesService'
import ConfiguracoesSistemaService from '@/services/ConfiguracoesSistemaService'
import store from '@/store/store'
export default {
  async mounted () {
    if (!store.state.isUserLoggedIn) {
      this.$router.push({
        name: 'mylogin'
      })
    } else {
      this.configuracoessistema = (await ConfiguracoesSistemaService.index()).data
      this.clientes = (await ClientesService.index(this.filtrarClientes.inativo)).data
    }
  },
  data () {
    return {
      fab: false,
      clientes: [],
      configuracoessistema: {},
      filtrarClientes: {
        inativo: false,
        numero: null,
        contribuinte: null,
        telefone: null,
      },
      headersClientes: [
        { text: "Número", value: "numero", sortable: true },
        { text: "Nome", value: "nome", sortable: true },
        { text: "Contribuinte", value: "contribuinte", sortable: true },
        { text: "Telefone", value: "telefone_empresa", sortable: true },
        { text: "E-mail", value: "email_empresa", sortable: true },
        { text: "Ativo", value: "inativo", sortable: false },
        { text: 'Ações', value: 'actions', sortable: false },
      ],

      dialogFiltrarClientes: false,
      dialogApagarCliente: false,

      idclienteglobal: '',
      
      textobotaoinativo: 'Mostrar activos',
      botaoinativo: false
    }
  },
  methods: {
    navigateToCliente (route) {
      this.$store.dispatch('setCliente', {  
        numerocliente: route.params.numeroClienteEnviado,
      })
      this.$router.push(route)
    },
    navigateToTempoCriar (route) {
      this.$store.dispatch('setTempoCliente', {  
        numeroclientetempo: route.params.numeroClienteEnviado,
      })
      this.$router.push(route)
    },
    // filtrar clientes
    async confirmarFiltrarClientes () {
      this.clientes = (await ClientesService.index(this.filtrarClientes.inativo, this.filtrarClientes.numero, this.filtrarClientes.contribuinte, this.filtrarClientes.telefone)).data     
      this.dialogFiltrarClientes = false
    },
    // filtrar inativos
    async filtrarInativos () {
        if (this.botaoinativo == false) {
            this.filtrarClientes.inativo = false
            this.textobotaoinativo = 'Mostrar activos'
        }
        if (this.botaoinativo == true) {
          this.filtrarClientes.inativo = null
          this.textobotaoinativo = 'Mostrar todos'
        }
        this.clientes = (await ClientesService.index(this.filtrarClientes.inativo, this.filtrarClientes.numero, this.filtrarClientes.contribuinte, this.filtrarClientes.telefone)).data
    },
    //-------------------------------------------clientes------------------------------//
    //-------------------------------------------clientes------------------------------//
    //-------------------------------------------clientes------------------------------//
    async apagarCliente (numero) {
      this.dialogApagarCliente = true
      this.idclienteglobal = numero
    },
    async confirmarApagarCliente () {
      try{
        await ClientesService.delete(this.idclienteglobal)
        this.dialogApagarCliente = false
        this.clientes = (await ClientesService.index(this.filtrarClientes.inativo)).data
        this.$store.dispatch("setSnackBar", {
          color: "success",
          text: "Cliente apagado com sucesso"
        });
      } catch (error) {
        this.$store.dispatch("setSnackBar", {
          color: "error",
          text: "Erro a apagar cliente, pode ter coisas associadas"
        });
      }
    },
  }
}
</script>
<style>
</style>