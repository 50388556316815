<template>
    <v-layout justify-center v-if="$store.state.isUserLoggedIn && this.$store.state.cliente != null || this.$store.state.cliente.numerocliente != null">
        <v-speed-dial fab fixed v-model="fab" :bottom="true" :right="true" direction="top" :open-on-hover="false">
        <template v-slot:activator>
            <v-btn v-model="fab" color="blue darken-2" dark large fab>
            <v-icon v-if="fab">mdi-close</v-icon>
            <v-icon v-else>mdi-filter-variant</v-icon>
            </v-btn>
        </template>
        <v-btn fab dark large color="blue" class="mb-2" @click="criarContacto">
            <img class="m-2" :width="45" src="../../assets/icons_aplicacao/Add.png" alt="Add">
        </v-btn>
        </v-speed-dial>
        <v-flex>
            <v-card> 
                <v-toolbar class="mb-2 primary" dark flat>
                    <v-toolbar-title>Outros contactos</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                </v-toolbar>
                <v-col cols="12">
                    <span>Contactos do cliente {{cliente.nome}}</span>
                </v-col>
                <v-card-text>
                    <h3 v-if="this.clientecontactos != ''" class="pb-3"></h3>
                    <h3 v-else class="pb-3"><b>Este cliente não tem outros contactos</b></h3>
                    <br>
                    <v-row v-for="cliecont in clientecontactos" :key="cliecont.id">
                        <v-col cols="12 col-md-6">
                            <h3 class="pb-3"><b>{{ cliecont.nome }}</b></h3>
                            <span class="pb-3"><b>Tipo:</b> {{ cliecont.tipo }}</span><br>
                            <span class="pb-3"><b>Telefone:</b> <a :href="`tel:${cliecont.telefone}`"> {{ cliecont.telefone }}</a></span><br>
                            <span class="pb-3"><b>Telemóvel:</b> <a :href="`tel:${cliecont.telemovel}`"> {{ cliecont.telemovel }}</a></span><br>
                            <span class="pb-3"><b>Telefone 2:</b> <a :href="`tel:${cliecont.telefone2}`"> {{ cliecont.telefone2 }}</a></span><br>
                            <span class="pb-3"><b>Telemóvel 2:</b> <a :href="`tel:${cliecont.telemovel2}`"> {{ cliecont.telemovel2 }}</a></span><br>
                            <span class="pb-3"><b>Email:</b> <a :href="`mailto:${cliecont.email}`"> {{ cliecont.email }}</a></span>
                            <v-switch v-if="cliecont.inativo == false" v-model="switchtrue" color="green" label="Ativo" disabled></v-switch>
                            <v-switch v-else v-model="switchtrue" color="red" label="Inativo" disabled></v-switch>
                        </v-col>
                        <v-col cols="12 col-md-6">
                            <span class="pb-3 text-center"><b>Observações</b></span><br>
                            <textarea rows="5" v-model="cliecont.observacoes"></textarea>
                            <div class="text-right">
                                <img @click="editarContacto(cliecont.id)" class="m-2" :width="25" src="../../assets/icons_aplicacao/Editar.png" alt="Editar">
                                <img @click="apagarContacto(cliecont.id)" class="m-2" :width="25" src="../../assets/icons_aplicacao/Apagar.png" alt="Apagar">
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            <!-- Dialog editar contactos -->
            <v-dialog v-model="dialogEditarContacto">
                <v-card>
                    <v-toolbar class="mb-2" color="green" dark flat>
                        <v-toolbar-title>Editar contacto</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-title><v-list-item @click="dialogEditarContacto = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
                    </v-toolbar>
                    <v-form ref="form">
                        <v-col cols="12">
                            <label>Inativo</label>
                            <v-switch v-model="editarContactos.inativo" color="red"></v-switch>
                        </v-col>
                        <v-col cols="12">
                            <label>Nome</label>
                            <v-text-field type="text" v-model="editarContactos.nome" clearable filled outlined></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <label>Tipo</label>
                            <v-text-field type="text" v-model="editarContactos.tipo" clearable filled outlined></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <label>Email</label>
                            <v-text-field type="text" v-model="editarContactos.email" clearable filled outlined :rules="[rules.email]"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <label>Telefone</label>
                            <v-text-field type="text" v-model="editarContactos.telefone" clearable filled outlined></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <label>Telemóvel</label>
                            <v-text-field type="text" v-model="editarContactos.telemovel" clearable filled outlined></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <label>Telefone 2</label>
                            <v-text-field type="text" v-model="editarContactos.telefone2" clearable filled outlined></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <label>Telemovel 2</label>
                            <v-text-field type="text" v-model="editarContactos.telemovel2" clearable filled outlined></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <label>Observações</label>
                            <wysiwyg v-model="editarContactos.observacoes"/>
                        </v-col>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <img @click="confirmarEditarContacto" :width="50" src="../../assets/icons_aplicacao/Guardar.png" alt="Guardar">
                        </v-card-actions>
                    </v-form>
                </v-card>
            </v-dialog>
            <!-- Dialog criar contactos -->
            <v-dialog v-model="dialogCriarContacto">
                <v-card>
                    <v-toolbar class="mb-2" color="purple" dark flat>
                        <v-toolbar-title>Criar contacto</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-title><v-list-item @click="dialogCriarContacto = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
                    </v-toolbar>
                    <v-form ref="form">
                        <v-col cols="12">
                            <label>Nome</label>
                            <v-text-field type="text" v-model="criarContactos.nome" clearable filled outlined></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <label>Tipo</label>
                            <v-text-field type="text" v-model="criarContactos.tipo" clearable filled outlined></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <label>Email</label>
                            <v-text-field type="text" v-model="criarContactos.email" clearable filled outlined :rules="[rules.email]"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <label>Telefone</label>
                            <v-text-field type="text" v-model="criarContactos.telefone" clearable filled outlined></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <label>Telemovel</label>
                            <v-text-field type="text" v-model="criarContactos.telemovel" clearable filled outlined></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <label>Telefone 2</label>
                            <v-text-field type="text" v-model="criarContactos.telefone2" clearable filled outlined></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <label>Telemovel 2</label>
                            <v-text-field type="text" v-model="criarContactos.telemovel2" clearable filled outlined></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <label>Observações</label>
                            <wysiwyg v-model="criarContactos.observacoes"/>
                        </v-col>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="purple" class="white--text" @click="confirmarCriarContacto">Criar</v-btn>
                        </v-card-actions>
                    </v-form>
                </v-card>
            </v-dialog>
            <!-- Dialog apagar contactos -->
            <v-dialog v-model="dialogApagarContacto">
                <v-card>
                    <v-toolbar class="mb-2" color="red" dark flat>
                        <v-toolbar-title>Apagar contacto</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-title><v-list-item @click="dialogApagarContacto = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
                    </v-toolbar>
                    <v-col cols="12">
                        <span>Quer mesmo apagar o contacto?</span>
                    </v-col>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="red" class="white--text" @click="confirmarApagarContacto">Apagar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-flex>
    </v-layout>
</template>

<script>
//  Importar o service necessário
import ClientesContactosService from '@/services/ClientesContactosService'
import ClientesService from '@/services/ClientesService'

import store from '@/store/store'
export default {
    async mounted () {
        if (!store.state.isUserLoggedIn) {
            this.$router.push({
                name: 'mylogin'
            })
        } else if (this.$store.state.cliente == null || this.$store.state.cliente.numerocliente == null) {
            this.$router.push({
                name: 'clientes'
            })
        } else {
            this.cliente = (await ClientesService.show(this.$store.state.cliente.numerocliente)).data
            this.clientecontactos = (await ClientesContactosService.clientecontactoexiste(this.$store.state.cliente.numerocliente)).data
        }
        
    },
    data () {
        return {
            switchtrue: true,
            fab: false,
            cliente: {},
            editarContactos: {
                nome: null,
                tipo: null,
                email: null,
                telefone: null,
                telefone2: null,
                telemovel: null,
                telemovel2: null,
                observacoes: null,
                inativo: null,
            },
            criarContactos: {
                numero_cliente: this.$store.state.cliente.numerocliente,
                nome: null,
                tipo: null,
                email: null,
                telefone: null,
                telefone2: null,
                telemovel: null,
                telemovel2: null,
                observacoes: null,
                inativo: false,
            },
            clientecontactos: [],
            dialogEditarContacto: false,
            dialogCriarContacto: false,
            dialogApagarContacto: false,
            idcontactoglobal: null,
            rules: {
                email: value => {if (value && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {return "Por favor, insira um endereço de email válido.";}return true;}
            }
        }
    },
    methods: {
        //-------------------------------------------contactos------------------------------//
        //-------------------------------------------contactos------------------------------//
        //-------------------------------------------contactos------------------------------//
        async editarContacto (id) {
            this.dialogEditarContacto = true
            this.editarContactos = (await ClientesContactosService.show(id)).data
        },
        async confirmarEditarContacto () {
            if (this.$refs.form.validate()) {
                try{
                    await ClientesContactosService.put(this.editarContactos)
                    this.dialogEditarContacto = false
                    this.clientecontactos = (await ClientesContactosService.clientecontactoexiste(this.$store.state.cliente.numerocliente)).data
                    this.$store.dispatch("setSnackBar", {
                    color: "success",
                    text: "Contacto editado com sucesso"
                    });
                } catch (error) {
                    this.$store.dispatch("setSnackBar", {
                    color: "error",
                    text: "Erro a editar contacto"
                    });
                }
            }
        },
        async criarContacto () {
            this.dialogCriarContacto = true
        },
        async confirmarCriarContacto () {
            if (this.$refs.form.validate()) {
                try {
                    await ClientesContactosService.post(this.criarContactos)          
                    this.dialogCriarContacto = false
                    this.clientecontactos = (await ClientesContactosService.clientecontactoexiste(this.$store.state.cliente.numerocliente)).data
                    this.$store.dispatch("setSnackBar", {
                        color: "success",
                        text: "Contacto criado com sucesso"
                    });
                } catch (error) {
                    this.$store.dispatch("setSnackBar", {
                        color: "error",
                        text: "Erro a criar contacto"
                    });
                }
            }
        },
        async apagarContacto (id) {
            this.dialogApagarContacto = true
            this.idcontactoglobal = id;
        },
        async confirmarApagarContacto () {
            try{
                await ClientesContactosService.delete(this.idcontactoglobal)
                this.dialogApagarContacto = false
                this.clientecontactos = (await ClientesContactosService.clientecontactoexiste(this.$store.state.cliente.numerocliente)).data
                this.$store.dispatch("setSnackBar", {
                color: "success",
                text: "Contacto apagado com sucesso"
                });
            } catch (error) {
                this.$store.dispatch("setSnackBar", {
                color: "error",
                text: "Erro a apagar contacto"
                });
            }
        },
    }
}
</script>
<style>
</style>