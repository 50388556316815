import Api from '@/services/Api'

export default {
  index () {
    return Api().get('clientesmoradas')
  },
  post (elementos) {
    return Api().post('clientesmoradas', elementos)
  },
  put (elementos, id) {
    return Api().put(`clientesmoradas/${id}`, elementos)
  },
  show (id) {
    return Api().get(`clientesmoradas/${id}`)
  },
  delete (id) {
    return Api().delete(`clientesmoradas/${id}`)
  },
  deleteall () {
    return Api().delete(`clientesmoradas`)
  },
  clientemoradaexiste (numero_cliente) {
    return Api().get(`clientemoradaexiste`,{
      params: {
        numero_cliente
      }
    })
  }
}
