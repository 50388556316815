<template>
  <v-layout justify-center v-if="$store.state.isUserLoggedIn">
    <v-flex>
      <v-card>
        <v-toolbar class="mb-2 primary" dark flat>
          <v-toolbar-title>Home</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 6 : 2">
              <div class="retangulodetalhes text-center" @click="navigateTo({name: 'tarefas'})">
                <img class="m-2" :width="50" src="../assets/icons_aplicacao/Tarefas.png" alt="Tarefas"><br>
                <span>Minhas Tarefas</span><br>
                <!--<span>10</span><br>-->
              </div>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 6 : 2">
              <div class="retangulodetalhes text-center" @click="navigateTo({name: 'marcacoes'})">
                <img class="m-2" :width="50" src="../assets/icons_aplicacao/Marcacoes.png" alt="Marcações"><br>
                <span>Minhas Marcações</span><br>
                <!--<span>6</span>-->
              </div>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 6 : 2">
              <div class="retangulodetalhes text-center" @click="navigateTo({name: 'projetos'})">
                <img class="m-2" :width="50" src="../assets/icons_aplicacao/Projetos.png" alt="Projetos"><br>
                <span>Meus Projetos</span><br>
                <!--<span>5</span>-->
              </div>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 6 : 2">
              <div class="retangulodetalhes text-center" @click="navigateTo({name: 'tempos'})">
                <img class="m-2" :width="50" src="../assets/icons_aplicacao/Tempos.png" alt="Tempos"><br>
                <span>Meus Tempos</span><br>
                <!--<span>1</span>-->
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
//  Importar o service necessário
import store from '@/store/store'
export default {
  async mounted () {
    if (!store.state.isUserLoggedIn) {
      this.$router.push({
        name: 'mylogin'
      })
    }
  },
  data () {
    return {
    }
  },
  methods: {
    navigateTo (route) {
      this.$store.dispatch('setFuncionarioSelect', {  
        idfuncionario: this.$store.state.user.id,
      })
      this.$router.push(route)
    },
  }
}
</script>
<style>
</style>