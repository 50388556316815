import Api from '@/services/Api'

export default {
  index (id_marcacao) {
    return Api().get('marcacoesfuncionario',{
      params: {
        id_marcacao
      }
    })
  },
  post (elementos) {
    return Api().post('marcacoesfuncionario', elementos)
  },
  put (elementos) {
    return Api().put(`marcacoesfuncionario/${elementos.id}`, elementos)
  },
  show (id) {
    return Api().get(`marcacoesfuncionario/${id}`)
  },
  delete (id) {
    return Api().delete(`marcacoesfuncionario/${id}`)
  },
  marcacoesdiafuncionario (data_inicio) {
    return Api().get('marcacoesdiafuncionario',{
      params: {
        data_inicio
      }
    })
  },
}
