<template>
    <v-layout justify-center v-if="$store.state.isUserLoggedIn && configuracoessistema.modulo_clientes == false">
        <v-flex>
            <v-card>
                <v-toolbar class="mb-2" color="purple" dark flat>
                    <v-toolbar-title>Criar cliente</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-form ref="form">
                        <v-row>
                            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                                <label>Número</label>
                                <v-text-field type="number" v-model="criarClientes.numero" clearable filled outlined :rules="[rules.numero]"></v-text-field>
                            </v-col>
                            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 4">
                                <label>Nome</label>
                                <v-text-field type="text" v-model="criarClientes.nome" clearable filled outlined :rules="[rules.nome]"></v-text-field>
                            </v-col>
                            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 4">
                                <label>Nome2</label>
                                <v-text-field type="text" v-model="criarClientes.nome2" clearable filled outlined></v-text-field>
                            </v-col>
                            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                                <label>Saldo (€)</label>
                                <v-text-field type="text" v-model="criarClientes.saldo" clearable filled outlined></v-text-field>
                            </v-col>
                            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                                <label>Contribuinte</label>
                                <v-text-field type="number" v-model="criarClientes.contribuinte" clearable filled outlined></v-text-field>
                            </v-col>
                            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                                <label>Telefone</label>
                                <v-text-field type="number" v-model="criarClientes.telefone_empresa" clearable filled outlined></v-text-field>
                            </v-col>
                            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                                <label>Telemóvel</label>
                                <v-text-field type="number" v-model="criarClientes.telemovel_empresa" clearable filled outlined></v-text-field>
                            </v-col>
                            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 4">
                                <label>E-mail</label>
                                <v-text-field type="text" v-model="criarClientes.email_empresa" clearable filled outlined :rules="[rules.email]"></v-text-field>
                            </v-col>
                            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                                <label>Site</label>
                                <v-text-field type="text" v-model="criarClientes.site" clearable filled outlined></v-text-field>
                            </v-col>
                            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 6">
                                <label>Morada</label>
                                <v-text-field type="text" v-model="criarClientes.morada" clearable filled outlined></v-text-field>
                            </v-col>
                            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                                <label>Código postal</label>
                                <v-text-field type="text" v-model="criarClientes.codigo_postal" clearable filled outlined></v-text-field>
                            </v-col>
                            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 4">
                                <label>Localidade</label>
                                <v-text-field type="text" v-model="criarClientes.localidade" clearable filled outlined></v-text-field>
                            </v-col>
                            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                                <label>Vencimento</label>
                                <v-text-field type="text" v-model="criarClientes.vencimento" clearable filled outlined></v-text-field>
                            </v-col>
                            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                                <label>Limite dias</label>
                                <v-text-field type="text" v-model="criarClientes.limite_dias" clearable filled outlined></v-text-field>
                            </v-col>
                            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                                <label>Desconto</label>
                                <v-text-field type="text" v-model="criarClientes.desconto" clearable filled outlined></v-text-field>
                            </v-col>
                            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                                <label>Técnico</label>
                                <v-select v-model="criarClientesOutros.numero_tecnico" :items="tecnicos" item-text="nome" item-value="numero_tecnico" filled outlined></v-select>
                            </v-col>
                            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                                <label>Vendedor</label>
                                <v-select v-model="criarClientes.numero_vendedor" :items="vendedores" item-text="nome" item-value="numero_vendedor" filled outlined></v-select>
                            </v-col>
                            <v-col cols="12">
                                <label>Observações</label>
                                <wysiwyg v-model="criarClientes.observacoes"/>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <h3>Contacto 1</h3>
                            </v-col>
                            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                                <label>Contacto</label>
                                <v-text-field type="text" v-model="criarClientes.contacto1" clearable filled outlined></v-text-field>
                            </v-col>
                            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                                <label>Telefone</label>
                                <v-text-field type="number" v-model="criarClientes.telefone1" clearable filled outlined></v-text-field>
                            </v-col>
                            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                                <label>Telemóvel</label>
                                <v-text-field type="number" v-model="criarClientes.telemovel1" clearable filled outlined></v-text-field>
                            </v-col>
                            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                                <label>E-mail</label>
                                <v-text-field type="text" v-model="criarClientes.email1" clearable filled outlined></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <h3>Contacto 2</h3>
                            </v-col>
                            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                                <label>Contacto</label>
                                <v-text-field type="text" v-model="criarClientes.contacto2" clearable filled outlined></v-text-field>
                            </v-col>
                            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                                <label>Telefone</label>
                                <v-text-field type="number" v-model="criarClientes.telefone2" clearable filled outlined></v-text-field>
                            </v-col>
                            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                                <label>Telemóvel</label>
                                <v-text-field type="number" v-model="criarClientes.telemovel2" clearable filled outlined></v-text-field>
                            </v-col>
                            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                                <label>E-mail</label>
                                <v-text-field type="text" v-model="criarClientes.email2" clearable filled outlined></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <h3>Contacto 3</h3>
                            </v-col>
                            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                                <label>Contacto</label>
                                <v-text-field type="text" v-model="criarClientes.contacto3" clearable filled outlined></v-text-field>
                            </v-col>
                            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                                <label>Telefone</label>
                                <v-text-field type="number" v-model="criarClientes.telefone3" clearable filled outlined></v-text-field>
                            </v-col>
                            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                                <label>Telemóvel</label>
                                <v-text-field type="number" v-model="criarClientes.telemovel3" clearable filled outlined></v-text-field>
                            </v-col>
                            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                                <label>E-mail</label>
                                <v-text-field type="text" v-model="criarClientes.email3" clearable filled outlined></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="purple" class="white--text" @click="confirmarCriarCliente">Criar</v-btn>
                                </v-card-actions>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
            </v-card>
            <!-- Dialog numero cliente já existe -->
            <v-dialog v-model="dialogNumeroClienteJaExiste">
                <v-card>
                <v-toolbar color="orange" class="mb-2" dark flat>
                    <v-toolbar-title>Número já existe</v-toolbar-title>
                </v-toolbar>
                <v-col cols="12">
                    <span>Não pode adicionar o cliente com o número {{ criarClientes.numero }} porque já existe.</span><br>
                    <span>Por favor escolha outro número de cliente.</span>
                </v-col>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="orange" class="white--text" @click="okCriarClienteComNumero">Ok</v-btn>
                </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- Dialog numero contribuinte já existe -->
            <v-dialog v-model="dialogNumeroContribuinteJaExiste">
                <v-card>
                <v-toolbar color="orange" class="mb-2" dark flat>
                    <v-toolbar-title>Contribuinte já existe</v-toolbar-title>
                </v-toolbar>
                <v-col cols="12">
                    <span>Quer criar o cliente com o contribuinte {{ criarClientes.contribuinte }}?</span>
                </v-col>
                <v-card-actions>
                    <v-btn color="orange" class="white--text" @click="dialogNumeroContribuinteJaExiste = false">Cancelar</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="orange" class="white--text" @click="confirmarCriarClienteComContribuinte">Criar</v-btn>
                </v-card-actions>
                </v-card>
            </v-dialog>
        </v-flex>
    </v-layout>
</template>
  
<script>
//  Importar o service necessário
import ClientesService from '@/services/ClientesService'
import FuncionariosService from '@/services/FuncionariosService'
import ClientesOutrosService from '@/services/ClientesOutrosService'
import ConfiguracoesSistemaService from '@/services/ConfiguracoesSistemaService'
import store from '@/store/store'

export default {
async mounted () {
    this.configuracoessistema = (await ConfiguracoesSistemaService.index()).data
    if (!store.state.isUserLoggedIn) {
        this.$router.push({
            name: 'mylogin'
        })
    } else if (this.configuracoessistema.modulo_clientes == true) {
        this.$router.push({
            name: 'myhome'
        })
    } else {
        this.ultimocliente = (await ClientesService.ultimocliente()).data
        this.criarClientes.numero = this.ultimocliente.numero + 1
        this.tecnicos = (await FuncionariosService.index(false, ' ', null)).data
        this.vendedores = (await FuncionariosService.index(false, null, ' ')).data 
    }
    
},
data () {
    return {
        tecnicos: [],
        vendedores: [],
        configuracoessistema: {},
        criarClientes: {
            numero: null,
            nome: null,
            nome2: null,
            contribuinte: null,
            morada: null,
            codigo_postal: null,
            localidade: null,
            telefone_empresa: null,
            telemovel_empresa: null,
            email_empresa: null,
            site: null,
            numero_vendedor: null,
            observacoes: null,
            contacto1: null,
            telefone1: null,
            telemovel1: null,
            email1: null,
            contacto2: null,
            telefone2: null,
            telemovel2: null,
            email2: null,
            contacto3: null,
            telefone3: null,
            telemovel3: null,
            email3: null,
            estabelecimento: 0,
            inativo: false,
            vencimento: 0,
            limite_dias: 0,
            saldo: 0,
            desconto: 0
        },
        clientenumero: {
            numero: null
        },
        clientecontribuinte: {
            contribuinte: null
        },
        criarClientesOutros: {
            numero_cliente: null,
            numero_tecnico: null,
        },
        dialogNumeroClienteJaExiste: false,
        dialogNumeroContribuinteJaExiste: false,
        rules: {
            numero: value => !!value || "Número é Obrigatório.",
            nome: value => !!value || "Nome é Obrigatório.",
            email: value => {if (value && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {return "Por favor, insira um endereço de email válido.";}return true;}
        }
    }
},
methods: {
    async confirmarCriarCliente () {
        if (this.$refs.form.validate()) {
            if (this.criarClientes.numero != null) {
                this.clientenumero = (await ClientesService.show(this.criarClientes.numero)).data
            }
            if (this.clientenumero.numero != undefined) {
                this.dialogNumeroClienteJaExiste = true
            } else {
                if (this.criarClientes.contribuinte != null) {
                    this.clientecontribuinte = (await ClientesService.showcontribuinte(this.criarClientes.contribuinte)).data
                } 
                if (this.clientecontribuinte.contribuinte != undefined) {
                    this.dialogNumeroContribuinteJaExiste = true
                } else {
                    try{
                        await ClientesService.post(this.criarClientes)
                        if (this.criarClientesOutros.numero_tecnico != null) {
                            this.criarClientesOutros.numero_cliente = this.criarClientes.numero
                            await ClientesOutrosService.post(this.criarClientesOutros)
                        }

                        this.$store.dispatch('setCliente', {  
                            numerocliente: this.criarClientes.numero
                        })
                        this.$router.push({
                            name: 'verclientes'
                        })
                        this.$store.dispatch("setSnackBar", {
                            color: "success",
                            text: "Cliente criado com sucesso"
                        });
                    } catch (error) {
                        this.$store.dispatch("setSnackBar", {
                            color: "error",
                            text: "Erro a criar cliente"
                        });
                    }
                }
            }
        } 
    },
    async okCriarClienteComNumero () {
        this.dialogNumeroClienteJaExiste = false
        this.dialogNumeroContribuinteJaExiste = false
        this.ultimocliente = (await ClientesService.ultimocliente()).data
        this.criarClientes.numero = this.ultimocliente.numero + 1
    },
    async confirmarCriarClienteComContribuinte () {
        if (this.criarClientes.numero != null) {
            this.clientenumero = (await ClientesService.show(this.criarClientes.numero)).data
        }
        if (this.clientenumero.numero != undefined) {
            this.dialogNumeroClienteJaExiste = true
        } else {
            try {
                await ClientesService.post(this.criarClientes)
                if (this.criarClientesOutros.numero_tecnico != null) {
                    this.criarClientesOutros.numero_cliente = this.criarClientes.numero
                    await ClientesOutrosService.post(this.criarClientesOutros)
                }

                this.$store.dispatch('setCliente', {  
                    numerocliente: this.criarClientes.numero
                })
                this.$router.push({
                    name: 'verclientes'
                })
                this.$store.dispatch("setSnackBar", {
                    color: "success",
                    text: "Cliente criado com sucesso"
                });
            } catch (error) {
                this.$store.dispatch("setSnackBar", {
                    color: "error",
                    text: "Erro a criar cliente"
                });
            }
        }
    },
}
}
</script>
<style>
.v-text-field.v-text-field--enclosed .v-text-field__details {
    display: block !important; 
}
</style>