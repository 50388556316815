import { register } from 'register-service-worker'

register(`${process.env.BASE_URL}service-worker.js`, {
  ready () {
    console.log('O app está sendo servido a partir do cache pelo Service Worker.');
  },
  registered () {
    console.log('Service Worker registrado com sucesso.');
  },
  cached () {
    console.log('O conteúdo foi armazenado no cache para uso offline.');
  },
  updatefound () {
    console.log('Novo conteúdo está sendo baixado.');
  },
  updated () {
    console.log('Novo conteúdo está disponível. Recarregue a página.');
    if (confirm('Novo conteúdo está disponível. Deseja recarregar agora?')) {
      window.location.reload();
    }
  },
  offline () {
    console.log('Nenhuma conexão com a internet foi encontrada. O app está funcionando no modo offline.');
  },
  error (error) {
    console.error('Erro durante o registro do Service Worker:', error);
    if (error.message.includes('404')) {
      console.error('Verifique se o arquivo service-worker.js está no caminho correto.');
    }
  }
});