<template>
  <v-layout justify-center v-if="$store.state.isUserLoggedIn && this.$store.state.tempo != null || this.$store.state.tempo.idtempo != null">
    <v-flex>
        <v-card>
          <v-toolbar class="mb-2" color="green" dark flat>
            <v-toolbar-title>Editar tempo</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form ref="form">
              <v-row>
                <v-col cols="12 text-center">
                  <span>Tempo para o cliente {{editarTempos.nome_cliente}}?</span>
                </v-col>
                <v-col cols="12 text-center">
                  <h1>{{ formatTime }}</h1>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 6" class="text-center mt-5">
                  <v-btn v-if="editarTempos.pausa != 0 && editarTempos.concluido != 1" class="mr-5 mt-5"><v-icon x-large @click="playTempo" color="green">mdi-play-circle</v-icon></v-btn>
                  <v-btn v-else disabled class="mr-5 mt-5"><v-icon x-large color="grey">mdi-play-circle</v-icon></v-btn>

                  <v-btn v-if="editarTempos.pausa == 0 && editarTempos.concluido != 1" class="mr-5 mt-5"><v-icon x-large @click="pauseTempo" color="blue">mdi-pause-circle</v-icon></v-btn>
                  <v-btn v-else disabled class="mr-5 mt-5"><v-icon x-large color="grey">mdi-pause-circle</v-icon></v-btn>

                  <v-btn v-if="editarTempos.pausa == 0 && editarTempos.concluido != 1" class="mr-5 mt-5"><v-icon x-large @click="stopTempo" color="red">mdi-stop-circle</v-icon></v-btn>
                  <v-btn v-else disabled class="mr-5 mt-5"><v-icon x-large color="grey">mdi-stop-circle</v-icon></v-btn>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 6" class="text-center mt-5">
                  <label>Deslocação/Remoto/Telefone do funcionário</label><br>
                  <v-btn v-if="editarFuncionarios.telefone != true" class="mr-5 mt-5" @click="telefone"><v-icon x-large>mdi-phone</v-icon></v-btn>
                  <v-btn v-else class="mr-5 mt-5" @click="offTelefone"><v-icon x-large color="green">mdi-phone</v-icon></v-btn>
                  <v-btn v-if="editarFuncionarios.remoto != true" class="mr-5 mt-5" @click="remoto"><v-icon x-large>mdi-laptop</v-icon></v-btn>
                  <v-btn v-else class="mr-5 mt-5" @click="offRemoto"><v-icon x-large color="green">mdi-laptop</v-icon></v-btn>
                  <v-btn v-if="editarFuncionarios.deslocacao != true" class="mr-5 mt-5" @click="deslocacao"><v-icon x-large>mdi-car</v-icon></v-btn>
                  <v-btn v-else class="mr-5 mt-5" @click="offDeslocacao"><v-icon x-large color="green">mdi-car</v-icon></v-btn>
                </v-col>
                <v-col cols="12 text-center mt-5">
                  <label>Deslocação/Remoto/Telefone do tempo</label><br>
                  <v-icon v-if="editarTempos.deslocacao == 1" color="green" class="pr-2">mdi-car</v-icon>
                  <v-icon v-if="editarTempos.remoto == 1" color="green" class="pr-2">mdi-laptop</v-icon>
                  <v-icon v-if="editarTempos.telefone == 1" color="green" class="pr-2">mdi-phone</v-icon>
                </v-col>
                <v-col cols="12">
                  <label>Tipo de Tempo</label>
                  <v-autocomplete v-model="editarTempos.tipo_tempo" :items="tempostipo" item-text="descricao" item-value="id" clearable filled outlined></v-autocomplete>  
                </v-col>
                <v-col cols="12">
                  <label>Descrição</label>
                  <wysiwyg v-model="editarTempos.descricao"/>
                </v-col>
                <v-col cols="12">
                  <label>Trabalho realizado</label>
                  <wysiwyg v-model="editarTempos.trabalho_realizado"/>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                    <v-card-actions>
                        <img v-if="editarTempos.id_tarefa != null && editarTempos.id_tarefa != 0" class="m-2" :width="50" @click="verArtigosTarefa(editarTempos.id_tarefa)" src="../../assets/icons_aplicacao/Add_Artigos.png" alt="Add_Artigos">
                        <v-spacer></v-spacer>
                        <img @click="editarTempo" :width="50" src="../../assets/icons_aplicacao/Guardar.png" alt="Guardar">
                    </v-card-actions>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
        <!-- Dialog ver artigos -->
        <v-dialog v-model="dialogVerArtigosTarefas">
          <v-card>
            <v-toolbar class="mb-2 primary" dark flat>
              <v-toolbar-title>Artigos</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-title><v-list-item @click="dialogVerArtigosTarefas = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
            </v-toolbar>
            <v-col cols="12 text-right">
              <img @click="AdicionarArtigoTarefa" class="m-2" :width="35" src="../../assets/icons_aplicacao/Add_Artigos.png" alt="Add_Artigos">
            </v-col>
            <v-col cols="12">
              <v-data-table class="elevation-1" :headers="headersArtigosTarefas" :items="artigostarefa">
                <template v-slot:[`item.descricao`]="{ item }">
                  <span v-if="item.descricao != null" v-html="item.descricao.substring(0,75)"></span>
                </template>
                
                <template v-slot:[`item.preco_sem_iva`]="{ item }">
                  {{ formatNumeroEuro(item.preco_sem_iva) }}
                </template>
                <template v-slot:[`item.preco_com_iva`]="{ item }">
                  {{ formatNumeroEuro(item.preco_com_iva) }}
                </template>
                <template v-slot:[`item.total_linha_sem_iva`]="{ item }">
                  {{ formatNumeroEuro(item.total_linha_sem_iva) }}
                </template>
                <template v-slot:[`item.total_linha_com_iva`]="{ item }">
                  {{ formatNumeroEuro(item.total_linha_com_iva) }}
                </template>
                <template v-slot:[`item.iva`]="{ item }">
                  {{ item.iva }} %
                </template>
                <template v-slot:[`item.desconto`]="{ item }">
                  {{ item.desconto }} %
                </template>
                <template v-slot:[`item.tipo_stock`]="{ item }">
                  <v-btn v-if="item.tipo_stock == 'Saída'" style="width: 75% !important;" color="red" rounded>{{ item.tipo_stock }}</v-btn>
                  <v-btn v-else color="green" style="width: 75% !important;" rounded>{{ item.tipo_stock }}</v-btn>
                </template>
                <template v-slot:[`item.propriedade`]="{ item }">
                  <img v-if="item.propriedade == false" class="m-2" :width="25" src="../../assets/icons_aplicacao/Nao_Concluido.png" alt="Sem propriedade">
                  <img v-else class="m-2" :width="25" src="../../assets/icons_aplicacao/Concluido.png" alt="Com propriedade">
                </template>
                <template v-slot:[`item.inativo`]="{ item }">
                  <img v-if="item.inativo == true" class="m-2" :width="25" src="../../assets/icons_aplicacao/Nao_Concluido.png" alt="Nao_Concluido">
                  <img v-else class="m-2" :width="25" src="../../assets/icons_aplicacao/Concluido.png" alt="Concluido">
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <img v-if="item.documento_erp == null" @click="editarArtigoTarefa(item.id)" class="m-2" :width="25" src="../../assets/icons_aplicacao/Editar.png" alt="Editar">
                  <img v-if="item.documento_erp == null" @click="apagarArtigoTarefa(item.id)" class="m-2" :width="25" src="../../assets/icons_aplicacao/Apagar.png" alt="Apagar">
                </template>
              </v-data-table>
            </v-col>
          </v-card>
        </v-dialog>
        <!-- Dialog adicionar artigo tarefa -->
        <v-dialog v-model="dialogAdicionarArtigoTarefa">
          <v-card>
            <v-toolbar class="mb-2" color="orange" dark flat>
                <v-toolbar-title>Adicionar artigo tarefa</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-title><v-list-item @click="dialogAdicionarArtigoTarefa = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <v-form ref="form">
                    <v-row>
                      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                          <label>Tipo de stock</label>
                          <v-select v-model="adicionarArtigosTarefas.tipo_stock" item-text="text" item-value="value" :items="tipos_stock" filled outlined :rules="[rules.tipostock]"></v-select>
                      </v-col>
                      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                          <label>Referência</label>
                          <v-autocomplete v-model="adicionarArtigosTarefas.id_artigo" :items="artigos" item-text="codigo" item-value="id" clearable filled outlined @change="artigoTarefaReferencia"></v-autocomplete>
                      </v-col>
                      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 6">
                        <label>Descrição</label>
                        <v-autocomplete v-model="adicionarArtigosTarefas.id_artigo" :items="artigos" item-text="descricao" item-value="id" clearable filled outlined @change="artigoTarefaDescricao"></v-autocomplete>
                      </v-col>
                      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                          <label>Qtd</label>
                          <v-text-field v-model="adicionarArtigosTarefas.qtd" @blur="atualizarQuantidade" filled outlined></v-text-field>
                      </v-col>
                      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                          <label>Uni</label>
                          <v-text-field v-model="adicionarArtigosTarefas.unidade" filled outlined disabled></v-text-field>
                      </v-col>
                      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                          <label>Sem Iva (€)</label>
                          <v-text-field v-model="adicionarArtigosTarefas.preco_sem_iva" @blur="atualizarSemIva" filled outlined></v-text-field>
                      </v-col>
                      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                          <label>Iva (%)</label>
                          <v-text-field v-model="adicionarArtigosTarefas.iva" filled outlined disabled></v-text-field>
                      </v-col>
                      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                          <label>Com Iva (€)</label>
                          <v-text-field v-model="adicionarArtigosTarefas.preco_com_iva" @blur="atualizarComIva" filled outlined></v-text-field>
                      </v-col>
                      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                          <label>Desconto (%)</label>
                          <v-text-field v-model="adicionarArtigosTarefas.desconto" @blur="atualizarDesconto" filled outlined></v-text-field>
                      </v-col>
                      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                          <label>Total sem Iva (€)</label>
                          <v-text-field v-model="adicionarArtigosTarefas.total_linha_sem_iva" filled outlined></v-text-field>
                      </v-col>
                      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                          <label>Total com Iva (€)</label>
                          <v-text-field v-model="adicionarArtigosTarefas.total_linha_com_iva" filled outlined></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="orange" class="white--text" @click="confirmarAdicionarArtigoTarefa">Adicionar</v-btn>
                        </v-card-actions>
                      </v-col>
                    </v-row>
                </v-form>
            </v-card-text>       
          </v-card>
        </v-dialog>
        <!-- Dialog apagar artigo tarefa -->
        <v-dialog v-model="dialogApagarArtigoTarefa">
          <v-card>
            <v-toolbar class="mb-2" color="red" dark flat>
                <v-toolbar-title>Apagar artigo tarefa</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-title><v-list-item @click="dialogApagarArtigoTarefa = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
            </v-toolbar>
            <v-col cols="12">
                <span>Quer mesmo apagar o artigo da tarefa?</span>
            </v-col>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" class="white--text" @click="confirmarApagarArtigoTarefa">Apagar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- Dialog editar artigo tarefa -->
        <v-dialog v-model="dialogEditarArtigoTarefa">
          <v-card>
            <v-toolbar class="mb-2" color="green" dark flat>
                <v-toolbar-title>Editar artigo tarefa</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-title><v-list-item @click="dialogEditarArtigoTarefa = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
            </v-toolbar>
            <v-col cols="12">
                <label>Descrição</label>
                <v-text-field v-model="editarArtigosTarefas.descricao" filled outlined></v-text-field>
            </v-col>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green" class="white--text" @click="confirmarEditarArtigoTarefa">Editar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </v-flex>
  </v-layout>
</template>

<script>
//  Importar o service necessário
import TemposService from '@/services/TemposService'
import FuncionariosService from '@/services/FuncionariosService'
import TemposTipoService from '@/services/TemposTipoService'
import TarefasArtigosService from '@/services/TarefasArtigosService'
import ArtigosService from '@/services/ArtigosService'
import moment from 'moment-timezone'
import store from '@/store/store'

export default {
  async mounted () {
    if (!store.state.isUserLoggedIn) {
      this.$router.push({
        name: 'mylogin'
      })
    } else if (this.$store.state.tempo == null || this.$store.state.tempo.idtempo == null) {
      this.$router.push({
        name: 'myhome'
      })
    } else {
      this.editarTempos = (await TemposService.show(this.$store.state.tempo.idtempo)).data
      this.editarFuncionarios = (await FuncionariosService.show(this.$store.state.user.id)).data
      this.tempostipo = (await TemposTipoService.index()).data
      if (this.editarTempos.tipo_tempo == 0) {
        this.editarTempos.tipo_tempo = 1
      }
      
      // Vai verificar a cada 1 segundo se houve atualização
      this.timer2 = setInterval(async () => {
          if (this.$store.state.tempo.idtempo != null && this.$store.state.tempo.finalizadomobile != null) {
            this.tempoaosegundo = (await TemposService.show(this.$store.state.tempo.idtempo)).data
              if (this.tempoaosegundo.updatedAt != this.editarTempos.updatedAt) {
                this.editarTempos = (await TemposService.show(this.$store.state.tempo.idtempo)).data
                clearInterval(this.timer);
                this.crono()
              }
              if (this.tempoaosegundo.concluido == true && this.rotatarefas != 1) {
                this.$store.dispatch('setTempo', {  
                  idtempo: null
                })
                this.$router.push({
                  name: 'tarefas'
                })
              }
          }
      }, 1000);
      this.crono()
    }
  },
  data () {
    return {
      tempostipo: [],
      artigostarefa: [],
      artigos: [],
      artigo: {},
      editarTempos: {
          data_fim: null,
          tempo_gasto: null,
          concluido: null,
          descricao: null,
          trabalho_realizado: null,
          telefone: null,
          remoto: null,
          deslocacao: null,
          tempo_descontar: null,
          tempo_efetivo: null,
          inicio_pausa: null,
          pausa: null,
          tipo_tempo: 1,
          updatedAt: null
      },
      editarFuncionarios: {
        telefone: null,
        remoto: null,
        deslocacao: null,
        estado: null
      },
      tempoaosegundo: {
        concluido: null
      },
      headersTarefas: [
        { text: "Nº tarefa", value: "numero", sortable: true },
        { text: "Descrição da Tarefa", value: "descricao", sortable: true },
        { text: "Funcionarios", value: "Funcionarios", sortable: true },
        { text: "Data Registo", value: "data_registo", sortable: true },
        { text: "Estado", value: "Estado.descricao", sortable: true },
        { text: 'Ações', value: 'actions', sortable: false },
      ],
      filtrarTarefas: {
        concluido: null,
        numero_cliente: null,
        concluido_funcionario: null,
        id_funcionario: null
      },

      tipos_stock: [
        {text: 'Saída', value: 'Saída'},
        {text: 'Entrada', value: 'Entrada'}
      ],

      adicionarArtigosTarefas: {
          tipo_stock: null,
          codigo_artigo: null,
          id_artigo: null,
          qtd: null,
          unidade: null,
          preco_sem_iva: null,
          iva: null,
          preco_com_iva: null,
          desconto: null,
          total_linha_sem_iva: null,
          total_linha_com_iva: null,
          descricao: '',
          quem_registou: this.$store.state.user.id,
          data_registo: this.formatarDataMoment(Date.now()),
          id_tarefa: null
      },
      editarArtigosTarefas: {
          descricao: null
      },

      dialogVerArtigosTarefas: false,
      dialogAdicionarArtigoTarefa: false,
      dialogApagarArtigoTarefa: false,
      dialogEditarArtigoTarefa: false,

      idartigotarefaglobal: '',
      idtarefaglobal: '',

      //cronometro
      running: false,
      time: 0,
      timer: null,
      timer2: null,
      rotatarefas: 0,

      headersArtigosTarefas: [
        { text: "Referência", value: "codigo_artigo", sortable: false },
        { text: "Descricao", value: "descricao", sortable: true },
        { text: "Qtd", value: "qtd", sortable: true },
        { text: "Uni", value: "unidade", sortable: true },
        { text: "Sem Iva (€)", value: "preco_sem_iva", sortable: true },
        { text: "Iva", value: "iva", sortable: true },
        { text: "Com Iva (€)", value: "preco_com_iva", sortable: true },
        { text: "Desc (%)", value: "desconto", sortable: true },
        { text: "Total sem iva (€)", value: "total_linha_sem_iva", sortable: true },
        { text: "Total com iva (€)", value: "total_linha_com_iva", sortable: true },
        { text: "Tipo de stock", value: "tipo_stock", sortable: true },
        { text: "Documento ERP", value: "documento_erp", sortable: true },
        { text: "Ações", value: "actions", sortable: true }
      ],

      rules: {
        tipostock: value => {
          if (!value) {
            return "O campo tipo de stock é obrigatório.";
          }
          return true;
        }
      }
    }
  },
  computed: {
    formatTime: function() {
      const totalSeconds = Math.floor(this.time / 1000);
      const hours = Math.floor(totalSeconds / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const seconds = totalSeconds % 60;
      return `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    }
  },
  methods: {
    formatNumeroEuro(numero) {
      const euro = new Intl.NumberFormat('pt-PT', {
          style: 'currency',
          currency: 'EUR',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
          useGrouping: true, // Adiciona espaços de milhares
      });
      return euro.format(numero);
    },
    // formatar data
    formatDate (date) {
      const options = {year: 'numeric', month: 'numeric', day: 'numeric'}
      return new Date(date).toLocaleDateString('pt-PT', options)
    },
    formatarDataMoment(data) {
      return moment(data).tz('Europe/Lisbon').format('YYYY-MM-DDTHH:mm:ss[Z]');
    },
    convertToTime(tempo) {
      const totalsegundos = Math.floor(tempo / 1000);
      const horas = Math.floor(totalsegundos / 3600);
      const minutos = Math.floor((totalsegundos % 3600) / 60);
      const segundos = totalsegundos % 60;
      return `${this.pad(horas)}:${this.pad(minutos)}:${this.pad(segundos)}`;
    },
    pad(number) {
      return number.toString().padStart(2, '0');
    },
    async crono () {
      //iniciar tempo
      //ir buscar só a parte das horas destas datas e converter para numérico
      const dataagora = this.formatarDataMoment(Date.now()).slice(11,19).split(':')
      const datainicio = this.editarTempos.data_inicio.slice(11,19).split(':')
      const tempodescontar = this.editarTempos.tempo_descontar.slice(11,19).split(':')
      const iniciopausa = this.editarTempos.inicio_pausa.slice(11,19).split(':')

      const dataagoratemponumerico = ((parseInt(dataagora[0], 10) * 60 * 60) + (parseInt(dataagora[1], 10) * 60) + (parseInt(dataagora[2], 10))) * 1000
      const datainiciotemponumerico = ((parseInt(datainicio[0], 10) * 60 * 60) + (parseInt(datainicio[1], 10) * 60) + (parseInt(datainicio[2], 10))) * 1000
      const tempodescontartemponumerico = ((parseInt(tempodescontar[0], 10) * 60 * 60) + (parseInt(tempodescontar[1], 10) * 60) + (parseInt(tempodescontar[2], 10))) * 1000
      const iniciopausatemponumerico = ((parseInt(iniciopausa[0], 10) * 60 * 60) + (parseInt(iniciopausa[1], 10) * 60) + (parseInt(iniciopausa[2], 10))) * 1000

      var emquetempovaimili = 0
      if (this.editarTempos.pausa == true) {
        emquetempovaimili = dataagoratemponumerico - datainiciotemponumerico - tempodescontartemponumerico - (dataagoratemponumerico - iniciopausatemponumerico);
        this.time = emquetempovaimili
        clearInterval(this.timer);
      } else if (this.editarTempos.concluido == true) { 
        emquetempovaimili = dataagoratemponumerico - datainiciotemponumerico - tempodescontartemponumerico
        this.time = emquetempovaimili
        clearInterval(this.timer);
      } else {
        emquetempovaimili = dataagoratemponumerico - datainiciotemponumerico - tempodescontartemponumerico
        this.time = emquetempovaimili
        this.timer = setInterval(() => {
          this.time += 1000;
        }, 1000);
      }
      
    },
    async editarTempo () {
      /*em tudo só deixa editar se não tiver concluido*/
      try{
        this.editarTempos.updatedAt = this.formatarDataMoment(Date.now())
        await TemposService.put(this.editarTempos)
        this.editarTempos = (await TemposService.show(this.$store.state.tempo.idtempo)).data
          this.$store.dispatch("setSnackBar", {
              color: "success",
              text: "Tempo guardado com sucesso"
          });
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a guardar tempo"
          });
      }
    },
    async playTempo () {
      try{
        this.editarTempos.pausa = false

        //data atual menos data de quando foi dada a pausa para sabermos quanto tempo tivemos em pausa
        const playdataagora = this.formatarDataMoment(Date.now()).slice(11,19).split(':')    
        const playdatapausa = this.editarTempos.inicio_pausa.slice(11,19).split(':')
        const playdataagoratemponumerico = ((parseInt(playdataagora[0], 10) * 60 * 60) + (parseInt(playdataagora[1], 10) * 60) + (parseInt(playdataagora[2], 10))) * 1000
        const playdatapausatemponumerico = ((parseInt(playdatapausa[0], 10) * 60 * 60) + (parseInt(playdatapausa[1], 10) * 60) + (parseInt(playdatapausa[2], 10))) * 1000
        const tempoempausatemponumerico =   playdataagoratemponumerico - playdatapausatemponumerico

        //agora somamos o tempo que estava no tempo_descontar mais o tempo que tivemos em pausa
        const playtempodescontar = this.editarTempos.tempo_descontar.slice(11,19).split(':')
        const playtempodescontartemponumerico = ((parseInt(playtempodescontar[0], 10) * 60 * 60) + (parseInt(playtempodescontar[1], 10) * 60) + (parseInt(playtempodescontar[2], 10))) * 1000
        const somatempopausamaistempodescontar = tempoempausatemponumerico + playtempodescontartemponumerico

        this.editarTempos.tempo_descontar = '1899-12-30T' + this.convertToTime(somatempopausamaistempodescontar) + 'Z'
        this.editarTempos.inicio_pausa = '1899-12-30T00:00:00Z'
        await TemposService.put(this.editarTempos)
        this.editarTempos = (await TemposService.show(this.$store.state.tempo.idtempo)).data
        
        //iniciar tempo
        this.crono()

        this.$store.dispatch("setSnackBar", {
            color: "success",
            text: "Tempo iniciado com sucesso"
        });
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a iniciar tempo"
          });
      }
    },
    async pauseTempo () {
      try{
        this.editarTempos.inicio_pausa = this.formatarDataMoment(Date.now())
        this.editarTempos.pausa = true
        this.editarTempos.updatedAt = this.formatarDataMoment(Date.now())
        await TemposService.put(this.editarTempos)
        this.editarTempos = (await TemposService.show(this.$store.state.tempo.idtempo)).data

        this.crono()

        this.$store.dispatch("setSnackBar", {
            color: "success",
            text: "Tempo pausado com sucesso"
        });
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a pausar tempo"
          });
      }
    },
    async stopTempo () {
      try{
        this.rotatarefas = 1
        this.editarTempos.concluido = true
        this.editarTempos.data_fim = this.formatarDataMoment(Date.now())

        //tempos_gasto
        const stopdatainicio = this.editarTempos.data_inicio.slice(11,19).split(':')
        const stopdatafim = this.editarTempos.data_fim.slice(11,19).split(':')
        const stopdatainiciotemponumerico = ((parseInt(stopdatainicio[0], 10) * 60 * 60) + (parseInt(stopdatainicio[1], 10) * 60) + (parseInt(stopdatainicio[2], 10))) * 1000
        const stopdatafimtemponumerico = ((parseInt(stopdatafim[0], 10) * 60 * 60) + (parseInt(stopdatafim[1], 10) * 60) + (parseInt(stopdatafim[2], 10))) * 1000
        const stoptempogastotemponumerico = stopdatafimtemponumerico - stopdatainiciotemponumerico
        this.editarTempos.tempo_gasto = '1899-12-30T' + this.convertToTime(stoptempogastotemponumerico) + 'Z'

        //tempos_efetivo
        const stoptempodesconto = this.editarTempos.tempo_descontar.slice(11,19).split(':')
        const stoptempodescontotemponumerico = ((parseInt(stoptempodesconto[0], 10) * 60 * 60) + (parseInt(stoptempodesconto[1], 10) * 60) + (parseInt(stoptempodesconto[2], 10))) * 1000
        const stoptempoefetivotemponumerico = stoptempogastotemponumerico - stoptempodescontotemponumerico
        this.editarTempos.tempo_efetivo = '1899-12-30T' + this.convertToTime(stoptempoefetivotemponumerico) + 'Z'

        this.editarTempos.updatedAt = this.formatarDataMoment(Date.now())

        await TemposService.put(this.editarTempos)
        this.editarTempos = (await TemposService.show(this.$store.state.tempo.idtempo)).data
        this.$store.dispatch('setTempo', {
          idtempo: this.editarTempos.id,
          finalizadomobile: null
        })
        this.crono()
        
        //ver se este utilizador tem tempos abertos, se não tiver mete na tabela dos Funcionarios telefone, remoto, deslocao a false, Livre e cliente vazio, se não for vazio mete na coluna cliente os clientes que estão a ser tratados
        const temposabertos = (await TemposService.index(null, null, null, this.$store.state.user.id, null, false)).data
        if (temposabertos == '') {
          this.editarFuncionarios.telefone = false
          this.editarFuncionarios.remoto = false
          this.editarFuncionarios.deslocacao = false
          this.editarFuncionarios.estado = 'Livre'
          this.editarFuncionarios.cliente = ''
          await FuncionariosService.put(this.editarFuncionarios)
        } else {
          this.editarFuncionarios.cliente = ''
          await FuncionariosService.put(this.editarFuncionarios)
          for (var i = 0; i < temposabertos.length; i++) {
            this.editarFuncionarios.cliente = this.editarFuncionarios.cliente + temposabertos[i].nome_cliente + '; '
          }  
          await FuncionariosService.put(this.editarFuncionarios) 
        }

        this.$router.push({
            name: 'modificartempos'
        })

        this.$store.dispatch("setSnackBar", {
            color: "success",
            text: "Tempo terminado com sucesso"
        });
      } catch (error) {
        this.$store.dispatch("setSnackBar", {
            color: "error",
            text: "Erro a terminar tempo"
        });
      }
    },
    async deslocacao () {
      try{
        this.editarTempos.deslocacao = true
        this.editarTempos.tempo_deslocacao = '1899-12-30T01:00:00Z'
        this.editarTempos.updatedAt = this.formatarDataMoment(Date.now())
        await TemposService.put(this.editarTempos)

        this.editarFuncionarios.deslocacao = true
        await FuncionariosService.put(this.editarFuncionarios)

        this.editarTempos = (await TemposService.show(this.$store.state.tempo.idtempo)).data
        this.editarFuncionarios = (await FuncionariosService.show(this.$store.state.user.id)).data

          this.$store.dispatch("setSnackBar", {
              color: "success",
              text: "Deslocação funcionario iniciada"
          });
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a iniciar funcionario deslocação"
          });
      }
    },
    async telefone () {
      try{
        this.editarTempos.telefone = true
        this.editarTempos.updatedAt = this.formatarDataMoment(Date.now())
        await TemposService.put(this.editarTempos)

        this.editarFuncionarios.telefone = true
        await FuncionariosService.put(this.editarFuncionarios)

        this.editarTempos = (await TemposService.show(this.$store.state.tempo.idtempo)).data
        this.editarFuncionarios = (await FuncionariosService.show(this.$store.state.user.id)).data

          this.$store.dispatch("setSnackBar", {
              color: "success",
              text: "Telefone funcionario iniciado"
          });
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a iniciar funcionario telefone"
          });
      }
    },
    async remoto () {
      try{
        this.editarTempos.remoto = true
        this.editarTempos.updatedAt = this.formatarDataMoment(Date.now())
        await TemposService.put(this.editarTempos)

        this.editarFuncionarios.remoto = true
        await FuncionariosService.put(this.editarFuncionarios)

        this.editarTempos = (await TemposService.show(this.$store.state.tempo.idtempo)).data
        this.editarFuncionarios = (await FuncionariosService.show(this.$store.state.user.id)).data

          this.$store.dispatch("setSnackBar", {
              color: "success",
              text: "Remoto funcionario iniciado"
          });
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a iniciar funcionario remoto"
          });
      }
    },
    async offDeslocacao () {
      try{
        this.editarFuncionarios.deslocacao = false
        await FuncionariosService.put(this.editarFuncionarios)

        this.editarFuncionarios = (await FuncionariosService.show(this.$store.state.user.id)).data

          this.$store.dispatch("setSnackBar", {
              color: "success",
              text: "Deslocação funcionario terminada"
          });
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a terminar funcionario deslocação"
          });
      }
    },
    async offTelefone () {
      try{
        this.editarFuncionarios.telefone = false
        await FuncionariosService.put(this.editarFuncionarios)

        this.editarFuncionarios = (await FuncionariosService.show(this.$store.state.user.id)).data

          this.$store.dispatch("setSnackBar", {
              color: "success",
              text: "Telefone funcionario terminado"
          });
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a terminar funcionario telefone"
          });
      }
    },
    async offRemoto () {
      try{
        this.editarFuncionarios.remoto = false
        await FuncionariosService.put(this.editarFuncionarios)

        this.editarFuncionarios = (await FuncionariosService.show(this.$store.state.user.id)).data

          this.$store.dispatch("setSnackBar", {
              color: "success",
              text: "Remoto funcionario terminado"
          });
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a terminar funcionario remoto"
          });
      }
    },
    //-------------------------------------------artigos------------------------------//
    //-------------------------------------------artigos------------------------------//
    //-------------------------------------------artigos------------------------------//
    async verArtigosTarefa (id_tarefa) {
      this.editarTempo()
      this.dialogVerArtigosTarefas = true
      this.artigostarefa = (await TarefasArtigosService.index(id_tarefa)).data
      this.idtarefaglobal = id_tarefa
    },
    async AdicionarArtigoTarefa () {
        this.dialogAdicionarArtigoTarefa = true
        this.artigos = (await ArtigosService.index(false)).data
    },
    async confirmarAdicionarArtigoTarefa () {
      try{
        if (this.$refs.form.validate()) {
          this.adicionarArtigosTarefas.id_tarefa = this.idtarefaglobal
          await TarefasArtigosService.post(this.adicionarArtigosTarefas)
          this.dialogAdicionarArtigoTarefa = false
          this.artigostarefa = (await TarefasArtigosService.index(this.idtarefaglobal)).data
          this.$store.dispatch("setSnackBar", {
              color: "success",
              text: "Artigo adicionado com sucesso"
          });
          this.clearAdicionarArtigosTarefas();
        }
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a adicionar artigo tarefa"
          });
      }
    },
    async apagarArtigoTarefa (id) {
      this.dialogApagarArtigoTarefa = true
      this.idartigotarefaglobal = id
    },
    async confirmarApagarArtigoTarefa () {
      try{
          await TarefasArtigosService.delete(this.idartigotarefaglobal)
          this.dialogApagarArtigoTarefa = false
          this.artigostarefa = (await TarefasArtigosService.index(this.$store.state.tarefa.idtarefa)).data
          this.$store.dispatch("setSnackBar", {
              color: "success",
              text: "Artigo tarefa apagado com sucesso"
          });
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a apagar artigo tarefa"
          });
      }
    },
    async editarArtigoTarefa (id) {
        this.dialogEditarArtigoTarefa = true
        this.editarArtigosTarefas = (await TarefasArtigosService.show(id)).data
    },
    async confirmarEditarArtigoTarefa () {
      try{
        await TarefasArtigosService.put(this.editarArtigosTarefas)
        this.dialogEditarArtigoTarefa = false
        this.artigostarefa = (await TarefasArtigosService.index(this.$store.state.tarefa.idtarefa)).data
          this.$store.dispatch("setSnackBar", {
              color: "success",
              text: "Artigo tarefa editado com sucesso"
          });
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a editar artigo tarefa"
          });
      }
    },
    async artigoTarefaReferencia (item) {
      if (item != null) {
        this.artigo = (await ArtigosService.show(item)).data
        this.adicionarArtigosTarefas.codigo_artigo = this.artigo.codigo
        this.adicionarArtigosTarefas.id_artigo = this.artigo.id
        this.adicionarArtigosTarefas.qtd = 1
        this.adicionarArtigosTarefas.unidade = this.artigo.unidade
        this.adicionarArtigosTarefas.preco_sem_iva = this.artigo.preco_venda_sem_iva
        this.adicionarArtigosTarefas.iva = this.artigo.iva
        this.adicionarArtigosTarefas.preco_com_iva = this.artigo.preco_venda_com_iva
        this.adicionarArtigosTarefas.desconto = 0
        this.adicionarArtigosTarefas.total_linha_sem_iva = (this.artigo.preco_venda_sem_iva * this.adicionarArtigosTarefas.qtd).toFixed(2);
        this.adicionarArtigosTarefas.total_linha_com_iva = (this.artigo.preco_venda_com_iva * this.adicionarArtigosTarefas.qtd).toFixed(2);
        this.adicionarArtigosTarefas.descricao = this.artigo.descricao
      }
    },
    async artigoTarefaDescricao (item) {
      if (item != null) {
        this.artigo = (await ArtigosService.show(item)).data
        this.adicionarArtigosTarefas.codigo_artigo = this.artigo.codigo
        this.adicionarArtigosTarefas.id_artigo = this.artigo.id
        this.adicionarArtigosTarefas.qtd = 1
        this.adicionarArtigosTarefas.unidade = this.artigo.unidade
        this.adicionarArtigosTarefas.preco_sem_iva = this.artigo.preco_venda_sem_iva
        this.adicionarArtigosTarefas.iva = this.artigo.iva
        this.adicionarArtigosTarefas.preco_com_iva = this.artigo.preco_venda_com_iva
        this.adicionarArtigosTarefas.desconto = 0
        this.adicionarArtigosTarefas.total_linha_sem_iva = this.artigo.preco_venda_sem_iva
        this.adicionarArtigosTarefas.total_linha_com_iva = this.artigo.preco_venda_com_iva
        this.adicionarArtigosTarefas.descricao = this.artigo.descricao
      }
    },
    atualizarQuantidade () {
      this.adicionarArtigosTarefas.total_linha_sem_iva = ((this.adicionarArtigosTarefas.preco_sem_iva * this.adicionarArtigosTarefas.qtd) - (this.adicionarArtigosTarefas.preco_sem_iva * this.adicionarArtigosTarefas.qtd * (this.adicionarArtigosTarefas.desconto / 100))).toFixed(2);
      this.adicionarArtigosTarefas.total_linha_com_iva = ((this.adicionarArtigosTarefas.preco_com_iva * this.adicionarArtigosTarefas.qtd) - (this.adicionarArtigosTarefas.preco_com_iva * this.adicionarArtigosTarefas.qtd * (this.adicionarArtigosTarefas.desconto / 100))).toFixed(2);
    },
    atualizarDesconto () {
      this.adicionarArtigosTarefas.total_linha_sem_iva = ((this.adicionarArtigosTarefas.preco_sem_iva * this.adicionarArtigosTarefas.qtd) - (this.adicionarArtigosTarefas.preco_sem_iva * this.adicionarArtigosTarefas.qtd * (this.adicionarArtigosTarefas.desconto / 100))).toFixed(2);
      this.adicionarArtigosTarefas.total_linha_com_iva = ((this.adicionarArtigosTarefas.preco_com_iva * this.adicionarArtigosTarefas.qtd) - (this.adicionarArtigosTarefas.preco_com_iva * this.adicionarArtigosTarefas.qtd * (this.adicionarArtigosTarefas.desconto / 100))).toFixed(2);
    },
    atualizarSemIva () {
      this.adicionarArtigosTarefas.preco_com_iva = (this.adicionarArtigosTarefas.preco_sem_iva * (1 + (this.adicionarArtigosTarefas.iva / 100))).toFixed(2);
      this.adicionarArtigosTarefas.total_linha_sem_iva = ((this.adicionarArtigosTarefas.preco_sem_iva * this.adicionarArtigosTarefas.qtd) - (this.adicionarArtigosTarefas.preco_sem_iva * this.adicionarArtigosTarefas.qtd * (this.adicionarArtigosTarefas.desconto / 100))).toFixed(2);
      this.adicionarArtigosTarefas.total_linha_com_iva = ((this.adicionarArtigosTarefas.preco_com_iva * this.adicionarArtigosTarefas.qtd) - (this.adicionarArtigosTarefas.preco_com_iva * this.adicionarArtigosTarefas.qtd * (this.adicionarArtigosTarefas.desconto / 100))).toFixed(2);
    },
    atualizarComIva () {
      this.adicionarArtigosTarefas.preco_sem_iva = (this.adicionarArtigosTarefas.preco_com_iva / (1 + (this.adicionarArtigosTarefas.iva / 100))).toFixed(2);
      this.adicionarArtigosTarefas.total_linha_sem_iva = ((this.adicionarArtigosTarefas.preco_sem_iva * this.adicionarArtigosTarefas.qtd) - (this.adicionarArtigosTarefas.preco_sem_iva * this.adicionarArtigosTarefas.qtd * (this.adicionarArtigosTarefas.desconto / 100))).toFixed(2);
      this.adicionarArtigosTarefas.total_linha_com_iva = ((this.adicionarArtigosTarefas.preco_com_iva * this.adicionarArtigosTarefas.qtd) - (this.adicionarArtigosTarefas.preco_com_iva * this.adicionarArtigosTarefas.qtd * (this.adicionarArtigosTarefas.desconto / 100))).toFixed(2);
    },
    clearAdicionarArtigosTarefas () {
      this.adicionarArtigosTarefas.tipo_stock = null,
      this.adicionarArtigosTarefas.codigo_artigo = null,
      this.adicionarArtigosTarefas.id_artigo = null,
      this.adicionarArtigosTarefas.qtd = null,
      this.adicionarArtigosTarefas.unidade = null,
      this.adicionarArtigosTarefas.preco_sem_iva = null,
      this.adicionarArtigosTarefas.iva = null,
      this.adicionarArtigosTarefas.preco_com_iva = null,
      this.adicionarArtigosTarefas.desconto = null,
      this.adicionarArtigosTarefas.total_linha_sem_iva = null,
      this.adicionarArtigosTarefas.total_linha_com_iva = null,
      this.adicionarArtigosTarefas.descricao = '',
      this.adicionarArtigosTarefas.id_tarefa = null
    }
  },
    
}
</script>
<style>
</style>