import Api from '@/services/Api'

export default {
  index () {
    return Api().get('configuracoessistema')
  },
  post (elementos) {
    return Api().post('configuracoessistema', elementos)
  }
}
