import Api from '@/services/Api'

export default {
  index () {
    return Api().get('empresatitular')
  },
  put (elementos) {
    return Api().put(`empresatitular/${elementos.id}`, elementos)
  },
}
