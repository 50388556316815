<template>
  <v-layout justify-center v-if="$store.state.isUserLoggedIn  && this.$store.state.cliente != null || this.$store.state.cliente.numerocliente != null">
    <v-flex>
        <v-card>
          <v-toolbar class="mb-2" color="green" dark flat>
            <v-toolbar-title>Editar cliente</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-title><v-list-item class="v-list-item--link" @click="atualizarCliente"><v-icon x-large >mdi-reload</v-icon></v-list-item></v-toolbar-title>
          </v-toolbar>
          <v-col cols="12">
            <span>Dados do cliente {{editarClientes.nome}}</span>
          </v-col>
          <v-card-text>
            <v-form ref="form">
                <v-row>
                    <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 1">
                        <label>Inativo</label>
                        <v-switch v-model="editarClientes.inativo" color="red"></v-switch>
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                        <label>Número</label>
                        <v-text-field type="number" v-model="editarClientes.numero" filled outlined disabled :rules="[rules.numero]"></v-text-field>
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 4">
                        <label>Nome</label>
                        <v-text-field type="text" v-model="editarClientes.nome" clearable filled outlined :rules="[rules.nome]"></v-text-field>
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 4">
                        <label>Nome2</label>
                        <v-text-field type="text" v-model="editarClientes.nome2" clearable filled outlined></v-text-field>
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 1">
                        <label>Saldo (€)</label>
                        <v-text-field type="text" v-model="editarClientes.saldo" clearable filled outlined></v-text-field>
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                        <label>Contribuinte</label>
                        <v-text-field type="number" v-model="editarClientes.contribuinte" clearable filled outlined></v-text-field>
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                        <label>Telefone</label>
                        <v-text-field type="number" v-model="editarClientes.telefone_empresa" clearable filled outlined></v-text-field>
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                        <label>Telemóvel</label>
                        <v-text-field type="number" v-model="editarClientes.telemovel_empresa" clearable filled outlined></v-text-field>
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 4">
                        <label>E-mail</label>
                        <v-text-field type="text" v-model="editarClientes.email_empresa" clearable filled outlined></v-text-field>
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                        <label>Site</label>
                        <v-text-field type="text" v-model="editarClientes.site" clearable filled outlined></v-text-field>
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 6">
                        <label>Morada</label>
                        <v-text-field type="text" v-model="editarClientes.morada" clearable filled outlined></v-text-field>
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                        <label>Código postal</label>
                        <v-text-field type="text" v-model="editarClientes.codigo_postal" clearable filled outlined></v-text-field>
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 4">
                        <label>Localidade</label>
                        <v-text-field type="text" v-model="editarClientes.localidade" clearable filled outlined></v-text-field>
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 1">
                        <label>Vencimento</label>
                        <v-text-field type="text" v-model="editarClientes.vencimento" clearable filled outlined></v-text-field>
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 1">
                        <label>Limite dias</label>
                        <v-text-field type="text" v-model="editarClientes.limite_dias" clearable filled outlined></v-text-field>
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 1">
                        <label>Desconto</label>
                        <v-text-field type="text" v-model="editarClientes.desconto" clearable filled outlined></v-text-field>
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                        <label>Nome comercial</label>
                        <v-text-field type="text" v-model="editarClientes.nome_comercial" clearable filled outlined></v-text-field>
                    </v-col>
                    <v-col cols="12 col-md-3">
                        <span class="pb-3"><b>Vendedor:</b>
                            <template v-for="funcvend in funcionariosvendedores">
                                <template v-if="funcvend.numero_vendedor == editarClientes.numero_vendedor">
                                    {{funcvend.nome}}
                                </template>
                            </template>
                            <img @click="editarVendedor" class="m-2" :width="25" src="../../assets/icons_aplicacao/Editar.png" alt="Editar">
                        </span>
                        <!-- Dialog editar vendedor -->
                        <v-dialog v-model="dialogEditarVendedor">
                            <v-card>
                                <v-toolbar class="mb-2" color="green" dark flat>
                                    <v-toolbar-title>Editar vendedor</v-toolbar-title>
                                    <v-spacer></v-spacer>
                                    <v-spacer></v-spacer>
                                    <v-toolbar-title><v-list-item @click="dialogEditarVendedor = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
                                </v-toolbar>
                                <v-col cols="12">
                                    <label>Vendedor</label>
                                    <v-select v-model="editarClientes.numero_vendedor" :items="vendedores" item-text="nome" item-value="numero_vendedor" filled outlined></v-select>
                                </v-col>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <img @click="confirmarEditarVendedor" :width="50" src="../../assets/icons_aplicacao/Guardar.png" alt="Guardar">
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-col>
                    <v-col cols="12 col-md-3">
                        <span class="pb-3"><b>Técnico:</b>
                            <template v-if="editarClientes.Clientes_Outro != null">
                                <template v-for="functec in funcionariostecnicos">
                                    <template v-if="functec.numero_tecnico == editarClientes.Clientes_Outro.numero_tecnico">
                                        {{functec.nome}}
                                    </template>
                                </template>
                            </template>
                            <img @click="editarTecnico" class="m-2" :width="25" src="../../assets/icons_aplicacao/Editar.png" alt="Editar">
                        </span>
                        <!-- Dialog editar tecnico -->
                        <v-dialog v-model="dialogEditarTecnico">
                            <v-card>
                                <v-toolbar class="mb-2" color="green" dark flat>
                                    <v-toolbar-title>Editar técnico</v-toolbar-title>
                                    <v-spacer></v-spacer>
                                    <v-spacer></v-spacer>
                                    <v-toolbar-title><v-list-item @click="dialogEditarTecnico = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
                                </v-toolbar>
                                <v-col cols="12">
                                    <label>Técnico</label>
                                    <v-select v-model="criareditarTecnicos.numero_tecnico" :items="tecnicos" item-text="nome" item-value="numero_tecnico" filled outlined></v-select>
                                </v-col>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <img @click="confirmarEditarTecnico" :width="50" src="../../assets/icons_aplicacao/Guardar.png" alt="Guardar">
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-col>
                    <v-col cols="12">
                        <label>Link maps</label>
                        <v-text-field type="text" v-model="editarClientes.link_maps" clearable filled outlined></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <label>Observações</label>
                        <wysiwyg v-model="editarClientes.observacoes"/>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <h3>Contacto 1</h3>
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                        <label>Contacto</label>
                        <v-text-field type="text" v-model="editarClientes.contacto1" clearable filled outlined></v-text-field>
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                        <label>Telefone</label>
                        <v-text-field type="number" v-model="editarClientes.telefone1" clearable filled outlined></v-text-field>
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                        <label>Telemóvel</label>
                        <v-text-field type="number" v-model="editarClientes.telemovel1" clearable filled outlined></v-text-field>
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                        <label>E-mail</label>
                        <v-text-field type="text" v-model="editarClientes.email1" clearable filled outlined></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <h3>Contacto 2</h3>
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                        <label>Contacto</label>
                        <v-text-field type="text" v-model="editarClientes.contacto2" clearable filled outlined></v-text-field>
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                        <label>Telefone</label>
                        <v-text-field type="number" v-model="editarClientes.telefone2" clearable filled outlined></v-text-field>
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                        <label>Telemóvel</label>
                        <v-text-field type="number" v-model="editarClientes.telemovel2" clearable filled outlined></v-text-field>
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                        <label>E-mail</label>
                        <v-text-field type="text" v-model="editarClientes.email2" clearable filled outlined></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <h3>Contacto 3</h3>
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                        <label>Contacto</label>
                        <v-text-field type="text" v-model="editarClientes.contacto3" clearable filled outlined></v-text-field>
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                        <label>Telefone</label>
                        <v-text-field type="number" v-model="editarClientes.telefone3" clearable filled outlined></v-text-field>
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                        <label>Telemóvel</label>
                        <v-text-field type="number" v-model="editarClientes.telemovel3" clearable filled outlined></v-text-field>
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                        <label>E-mail</label>
                        <v-text-field type="text" v-model="editarClientes.email3" clearable filled outlined></v-text-field>
                    </v-col>
                    <v-col cols="12" v-if="this.configuracoessistema.modulo_clientes == false">
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <img @click="editarCliente" :width="50" src="../../assets/icons_aplicacao/Guardar.png" alt="Guardar">
                        </v-card-actions>
                    </v-col>
                </v-row>
            </v-form>
          </v-card-text>
        </v-card>
        <!-- Dialog numero contribuinte já existe -->
        <v-dialog v-model="dialogNumeroContribuinteJaExiste">
            <v-card>
              <v-toolbar color="orange" class="mb-2" dark flat>
                  <v-toolbar-title>Contribuinte já existe</v-toolbar-title>
              </v-toolbar>
              <v-col cols="12">
                  <span>Quer editar o cliente com o contribuinte {{ editarClientes.contribuinte }}?</span>
              </v-col>
              <v-card-actions>
                  <v-btn color="orange" class="white--text" @click="dialogNumeroContribuinteJaExiste = false">Cancelar</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn color="orange" class="white--text" @click="confirmarCriarClienteComContribuinte">Editar</v-btn>
              </v-card-actions>
            </v-card>
        </v-dialog>
    </v-flex>
  </v-layout>
</template> 
<script>
//  Importar o service necessário
import ClientesService from '@/services/ClientesService'
import FuncionariosService from '@/services/FuncionariosService'
import ClientesOutrosService from '@/services/ClientesOutrosService'
import ConfiguracoesSistemaService from '@/services/ConfiguracoesSistemaService'
import store from '@/store/store'
export default {
  async mounted () {
    this.configuracoessistema = (await ConfiguracoesSistemaService.index()).data
    if (!store.state.isUserLoggedIn) {
      this.$router.push({
        name: 'mylogin'
      })
    } else if (this.$store.state.cliente == null || this.$store.state.cliente.numerocliente == null) {
      this.$router.push({
        name: 'clientes'
      })
    } else {
      this.editarClientes = (await ClientesService.show(this.$store.state.cliente.numerocliente)).data
      this.funcionariosvendedores = (await FuncionariosService.index(null, null, ' ')).data
      this.funcionariostecnicos = (await FuncionariosService.index(null, ' ', null)).data
      this.vendedores = (await FuncionariosService.index(false, null, ' ')).data
      this.tecnicos = (await FuncionariosService.index(false, ' ', null)).data
      this.criareditarTecnicos.numero_cliente = this.editarClientes.numero
      if (this.editarClientes.Clientes_Outro != null) {
          this.criareditarTecnicos.numero_tecnico = this.editarClientes.Clientes_Outro.numero_tecnico
      }
    }
  },
  data () {
    return {
      fab: false,
      configuracoessistema: {},
      editarClientes: {
          numero: null,
          nome: null,
          nome2: null,
          contribuinte: null,
          morada: null,
          codigo_postal: null,
          localidade: null,
          telefone_empresa: null,
          telemovel_empresa: null,
          email_empresa: null,
          site: null,
          numero_vendedor: null,
          observacoes: null,
          contacto1: null,
          telefone1: null,
          telemovel1: null,
          email1: null,
          contacto2: null,
          telefone2: null,
          telemovel2: null,
          email2: null,
          contacto3: null,
          telefone3: null,
          telemovel3: null,
          email3: null,
          inativo: false,
          vencimento: null,
          limite_dias: null,
          saldo: null,
          desconto: null,
          nome_comercial: null,
          link_maps: null
      },
      criareditarTecnicos: {
          numero_cliente: null,
          numero_tecnico: null
      },
      clientecontribuinte: {
          contribuinte: null
      },

      funcionariosvendedores: [],
      funcionariostecnicos: [],
      vendedores: [],
      tecnicos: [],
      clienteserp: [],

      dialogEditarTecnico: false,
      dialogEditarVendedor: false,
      dialogNumeroContribuinteJaExiste: false,

      rules: {
          numero: value => !!value || "Número é Obrigatório.",
          nome: value => !!value || "Nome é Obrigatório."
      }
    }
  },
  methods: {
    //-------------------------------------------clientes------------------------------//
    //-------------------------------------------clientes------------------------------//
    //-------------------------------------------clientes------------------------------//
    async editarCliente () {
      if (this.$refs.form.validate()) {
        if (this.editarClientes.contribuinte != null) {
            this.clientecontribuinte = (await ClientesService.showcontribuinte(this.editarClientes.contribuinte)).data
        } 
        if (this.clientecontribuinte.contribuinte != undefined) {
            this.dialogNumeroContribuinteJaExiste = true
        } else {
          try{
              await ClientesService.put(this.editarClientes)
              this.editarClientes = (await ClientesService.show(this.$store.state.cliente.numerocliente)).data
              this.$store.dispatch("setSnackBar", {
                  color: "success",
                  text: "Cliente editado com sucesso"
              });
          } catch (error) {
              this.$store.dispatch("setSnackBar", {
                  color: "error",
                  text: "Erro a editar cliente"
              });
          }
        }
      }
    },
    async confirmarCriarClienteComContribuinte () {
      try {
        await ClientesService.put(this.editarClientes)
        this.editarClientes = (await ClientesService.show(this.$store.state.cliente.numerocliente)).data
        this.dialogNumeroContribuinteJaExiste = false
        this.$store.dispatch("setSnackBar", {
            color: "success",
            text: "Cliente editado com sucesso"
        });
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a editar cliente"
          });
      }
    },
    async editarVendedor () {
        this.dialogEditarVendedor = true
    },
    async confirmarEditarVendedor () {
        try {
            await ClientesService.put(this.editarClientes, this.editarClientes.numero)
            this.dialogEditarVendedor = false
            this.$store.dispatch("setSnackBar", {
            color: "success",
            text: "Vendedor editado com sucesso"
            });
        } catch (error) {
            this.$store.dispatch("setSnackBar", {
            color: "error",
            text: "Erro a editar Vendedor"
            });
        }
    },
    async editarTecnico () {
        this.dialogEditarTecnico = true
    },
    async confirmarEditarTecnico () {
        if (this.editarClientes.Clientes_Outro != null) {
            try {
                this.clienteoutroexiste = (await ClientesOutrosService.clienteoutroexiste(this.criareditarTecnicos)).data
                await ClientesOutrosService.put(this.criareditarTecnicos, this.clienteoutroexiste.id)
                this.$store.dispatch("setSnackBar", {
                color: "success",
                text: "Técnico editado com sucesso"
                });
            } catch (error) {
                this.$store.dispatch("setSnackBar", {
                color: "error",
                text: "Erro a editar técnico"
                });
            }
        } else {
            try {
                this.criareditarTecnicos.numero_cliente = this.$store.state.cliente.numerocliente
                await ClientesOutrosService.post(this.criareditarTecnicos)
                this.$store.dispatch("setSnackBar", {
                color: "success",
                text: "Técnico adicionado com sucesso"
                });
            } catch (error) {
                this.$store.dispatch("setSnackBar", {
                color: "error",
                text: "Erro a adicionar técnico"
                });
            }
        }
        this.dialogEditarTecnico = false
        this.editarClientes = (await ClientesService.show(this.$store.state.cliente.numerocliente)).data
    },
    async atualizarCliente () {
        this.clienteserp = (await ClientesService.importarclienteserp(this.$store.state.cliente.numerocliente, this.editarClientes.data_atualizacao_contas_corrente)).data
        this.editarClientes = (await ClientesService.show(this.$store.state.cliente.numerocliente)).data
    }
  }
}
</script>
<style>
</style>