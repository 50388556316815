<template>
  <v-layout justify-center v-if="$store.state.isUserLoggedIn">
    <v-speed-dial fab fixed v-model="fab" :bottom="true" :right="true" direction="top" :open-on-hover="false">
      <template v-slot:activator>
        <v-btn v-model="fab" color="blue darken-2" dark large fab>
          <v-icon v-if="fab">mdi-close</v-icon>
          <v-icon v-else>mdi-filter-variant</v-icon>
        </v-btn>
      </template>
      <v-btn fab dark large color="blue" class="mb-2" @click.stop="dialogFiltrarTarefas = true">
        <img :width="45" src="../../assets/icons_aplicacao/Pesquisa.png" alt="Pesquisa">
      </v-btn>
      <v-btn fab dark large color="blue" class="mb-2" to="criartarefas">
        <img :width="45" src="../../assets/icons_aplicacao/Add_Tarefas.png" alt="Add_Tarefas">
      </v-btn>
    </v-speed-dial>
    <v-flex>
      <v-card>
        <v-toolbar class="mb-2 primary" dark flat>
          <v-toolbar-title>Tarefas</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-title>{{textobotaoconcluido}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</v-toolbar-title>
          <v-switch class="pt-5" @change="filtrarConcluidos" v-model="botaoconcluido" color="green" inset></v-switch>
        </v-toolbar>
        <v-col cols="12">
            <span v-if="cliente.nome != null">Tarefas do cliente {{cliente.nome}}</span>
            <span v-else>Tarefas de todos os clientes</span>
        </v-col>
        <v-col cols="12">
          <v-data-table class="elevation-1" :headers="headersTarefas" :items="tarefas">
            <template v-slot:[`item.Tarefas_Obs`]="{ item }">
              <template v-for="(obs, index) in item.Tarefas_Obs">
                <img :key="index" v-if="index === 0" @click="verObservacoes(item.id)" class="m-2" :width="25" src="../../assets/icons_aplicacao/Ver.png" alt="Ver">
              </template>
            </template>
            <template v-slot:[`item.numero`]="{ item }">
              {{ item.numero + '/' + item.ano }}
            </template>
            <template v-slot:[`item.descricao`]="{ item }">
              <span v-if="item.descricao != null" v-html="item.descricao.substring(0,75)"></span>
            </template>
            <template v-slot:[`item.data_registo`]="{ item }">
              {{ apresentarData(item.data_registo) }}
            </template>
            <template v-slot:[`item.Funcionarios`]="{ item }">
              <div v-for="func in item.Funcionarios" :key="func.id">
                  {{ func.nome }}
              </div>
            </template>
            <template v-slot:[`item.grau_urgencia`]="{ item }">
              <v-chip :color="getColor(item.grau_urgencia)" dark>{{ item.grau_urgencia }}</v-chip>
            </template>
            <template v-slot:[`item.concluido`]="{ item }">
              <img v-if="item.concluido == false" class="m-2" :width="25" src="../../assets/icons_aplicacao/Nao_Concluido.png" alt="Nao_Concluido">
              <img v-else class="m-2" :width="25" src="../../assets/icons_aplicacao/Concluido.png" alt="Concluido">
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <img @click="verTempos(item.id)" class="m-2" :width="25" src="../../assets/icons_aplicacao/Tempos.png" alt="Tempos">
              <img @click="criarObservacao(item.id)" class="m-2" :width="25" src="../../assets/icons_aplicacao/Add_Notas.png" alt="Add_Notas">
              <img @click="navigateToTarefaEditar({name: 'editartarefas', params:{idTarefaEnviado: item.id}})" class="m-2" :width="25" src="../../assets/icons_aplicacao/Editar.png" alt="Editar">
              <img v-if="item.concluido == false" @click="apagarTarefa(item.id)" class="m-2" :width="25" src="../../assets/icons_aplicacao/Apagar.png" alt="Apagar">
              <img v-if="item.concluido == false" @click="navigateToTempoCriar({name: 'criartempostarefas', params:{idTarefaEnviado: item.id}})" class="m-2" :width="25" src="../../assets/icons_aplicacao/Add_Tempos.png" alt="Add_Tempos">
            </template>
          </v-data-table>
        </v-col>
      </v-card>
      <!-- Dialog filtrar tarefa -->
      <v-dialog v-model="dialogFiltrarTarefas">
        <v-card>
          <v-toolbar class="mb-2" color="black" dark flat>
            <v-toolbar-title>Filtrar tarefas</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-title><v-list-item @click="dialogFiltrarTarefas = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
          </v-toolbar>
          <v-col cols="12">
            <label>Funcionario</label>
            <v-autocomplete v-model="filtrarTarefas.id_funcionario" :items="funcionarios" item-text="nome" item-value="id" clearable filled outlined></v-autocomplete>
          </v-col>
          <v-col cols="12">
            <label>Cliente</label>
            <v-autocomplete v-model="filtrarTarefas.numero_cliente" :items="clientes" item-text="nome" item-value="numero" clearable filled outlined></v-autocomplete>
          </v-col>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="black" class="white--text" @click="confirmarFiltrarTarefas">Filtrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Dialog apagar tarefa -->
      <v-dialog v-model="dialogApagarTarefa">
        <v-card>
          <v-toolbar class="mb-2" color="red" dark flat>
              <v-toolbar-title>Apagar tarefa</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-title><v-list-item @click="dialogApagarTarefa = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
          </v-toolbar>
          <v-col cols="12">
              <span>Quer mesmo apagar a tarefa?</span>
          </v-col>
          <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red" class="white--text" @click="confirmarApagarTarefa">Apagar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Dialog ver observações -->
      <v-dialog v-model="dialogVerObservacoes">
        <v-card>
          <v-toolbar class="mb-2 primary" dark flat>
            <v-toolbar-title>Observações</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-title><v-list-item @click="dialogVerObservacoes = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
          </v-toolbar>
          <v-col cols="12 text-right">
            <img @click="criarObservacao" class="m-2" :width="35" src="../../assets/icons_aplicacao/Add_Notas.png" alt="Add_Notas">
          </v-col>
          <v-col cols="12">
            <v-data-table class="elevation-1" :headers="headersObservacoes" :items="observacoes">
              <template v-slot:[`item.enviado_mail`]="{ item }">
                <img v-if="item.enviado_mail == true" @click="verObsEmailEnviado(item.id)" class="m-2" :width="25" src="../../assets/icons_aplicacao/Email_Enviado.png" alt="Email_Enviado">
              </template>
              <template v-slot:[`item.data_registo`]="{ item }">
                {{ apresentarData(item.data_registo) }}
              </template>
              <template v-slot:[`item.descricao`]="{ item }">
                <span v-if="item.descricao != null" v-html="item.descricao.substring(0,75)"></span>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <img @click="editarObservacao(item.id)" class="m-2" :width="25" src="../../assets/icons_aplicacao/Editar.png" alt="Editar">
                <img @click="apagarObservacao(item.id)" class="m-2" :width="25" src="../../assets/icons_aplicacao/Apagar.png" alt="Apagar">
              </template>
            </v-data-table>
          </v-col>
        </v-card>
      </v-dialog>
      <!-- Dialog criar observação-->
      <v-dialog v-model="dialogCriarObservacao">
        <v-card>
          <v-toolbar class="mb-2" color="purple" dark flat>
            <v-toolbar-title>Criar observação</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-title><v-list-item @click="dialogCriarObservacao = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
          </v-toolbar>
          <v-col cols="12">
              <label>Descrição</label>
              <wysiwyg v-model="criarObservacoes.descricao"/>
          </v-col>
          <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="purple" class="white--text" @click="confirmarCriarObservacao">Criar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Dialog apagar observação -->
      <v-dialog v-model="dialogApagarObservacao">
        <v-card>
          <v-toolbar class="mb-2" color="red" dark flat>
              <v-toolbar-title></v-toolbar-title>
              <v-spacer>Apagar observação</v-spacer>
              <v-toolbar-title><v-list-item @click="dialogApagarObservacao = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
          </v-toolbar>
          <v-col cols="12">
              <span>Quer mesmo apagar a observação?</span>
          </v-col>
          <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red" class="white--text" @click="confirmarApagarObservacao">Apagar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Dialog editar observação -->
      <v-dialog v-model="dialogEditarObservacao">
        <v-card>
          <v-toolbar class="mb-2" color="green" dark flat>
              <v-toolbar-title>Editar observação</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-title><v-list-item @click="dialogEditarObservacao = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
          </v-toolbar>
          <v-col cols="12">
              <label>Data Registo</label>
              <MYdatetime type="date" :value="editarObservacoes.data_registo" disabled="disabled"></MYdatetime>
          </v-col>
          <v-col cols="12">
              <label>Registada por</label>
              <v-text-field v-model="editarObservacoes.Funcionario.nome" disabled filled outlined></v-text-field>
          </v-col>
          <v-col cols="12">
              <label>Descrição</label>
              <wysiwyg v-model="editarObservacoes.descricao"/>
          </v-col>
          <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green" class="white--text" @click="confirmarEditarObservacao">Editar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Dialog ver email enviado observação-->
      <v-dialog v-model="dialogVerObsEmailEnviado">
        <v-card>
          <v-toolbar class="mb-2 primary" dark flat>
            <v-toolbar-title>Observações da tarefa enviada por e-mail para o(s) seguinte(s) endereço(s)</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-title><v-list-item @click="dialogVerObsEmailEnviado = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
          </v-toolbar>
          <v-col cols="12">
            <span>{{observacaoemail.enviado_mail_para_quem}} em {{apresentarData(observacaoemail.enviado_mail_data)}} às <span v-if="observacaoemail.enviado_mail_data != null">{{apresentarHoras(observacaoemail.enviado_mail_data)}}</span> horas.</span>
          </v-col>
        </v-card>
      </v-dialog>
      <!-- Dialog ver tempos -->
      <v-dialog v-model="dialogVerTempos">
        <v-card>
          <v-toolbar class="mb-2 primary" dark flat>
            <v-toolbar-title>Tempos</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-title><v-list-item @click="dialogVerTempos = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                  <span class="totais-size"><b>Total tempo: {{totaltempostarefas}}</b></span>
              </v-col>
              <v-col cols="12">
                <v-data-table class="elevation-1" :headers="headersTempos" :items="tempos">
                  <template v-slot:[`item.descricao`]="{ item }">
                    <span v-if="item.descricao != null" v-html="item.descricao.substring(0,75)"></span>
                  </template>
                  <template v-slot:[`item.trabalho_realizado`]="{ item }">
                    <span v-if="item.trabalho_realizado != null" v-html="item.trabalho_realizado.substring(0,75)"></span>
                  </template>
                  <template v-slot:[`item.data_inicio`]="{ item }">
                    <span v-if="item.data_inicio != null">{{ apresentarData(item.data_inicio) }} {{ apresentarHoras(item.data_inicio) }}</span>
                  </template>
                  <template v-slot:[`item.data_fim`]="{ item }">
                    <span v-if="item.data_fim != null">{{ apresentarData(item.data_fim) }} {{ apresentarHoras(item.data_fim) }}</span>
                  </template>
                  <template v-slot:[`item.tempo_efetivo`]="{ item }">
                    <span v-if="item.tempo_efetivo != null">{{ apresentarHoras(item.tempo_efetivo) }}</span>
                  </template>
                  <template v-slot:[`item.concluido`]="{ item }">
                    <img v-if="item.concluido == false" class="m-2" :width="25" src="../../assets/icons_aplicacao/Nao_Concluido.png" alt="Nao_Concluido">
                    <img v-else class="m-2" :width="25" src="../../assets/icons_aplicacao/Concluido.png" alt="Concluido">
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <img @click="verTempo(item.id)" class="m-2" :width="25" src="../../assets/icons_aplicacao/Ver.png" alt="Ver">
                    <img v-if="item.concluido != true && item.stamp_intervencao == null" @click="navigateToTempoEditar({name: 'editartempostarefas', params:{idTempoEnviado: item.id}})" class="m-2" :width="25" src="../../assets/icons_aplicacao/Editar.png" alt="Editar">
                    <img v-if="item.concluido == true && item.stamp_intervencao == null" @click="navigateToTempoModificar({name: 'modificartempos', params:{idTempoEnviado: item.id}})" class="m-2" :width="25" src="../../assets/icons_aplicacao/Editar.png" alt="Editar">
                    <img v-if="item.stamp_intervencao == null" @click="apagarTempo(item)" class="m-2" :width="25" src="../../assets/icons_aplicacao/Apagar.png" alt="Apagar">
                    <img v-if="item.stamp_intervencao != null && item.intervencao_faturado == false" @click="apagarIntervencaoPat(item)" class="m-2" :width="25" src="../../assets/icons_aplicacao/Apagar_Intervencao.png" alt="Apagar_Intervencao">
                    <img v-if="item.stamp_intervencao == null && item.concluido == true" @click="verPatsCliente(item)" class="m-2" :width="25" src="../../assets/icons_aplicacao/Add_Intervencao.png" alt="Add_Intervencao">
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text> 
        </v-card>
      </v-dialog>
      <!-- Dialog ver tempo -->
      <v-dialog v-model="dialogVerTempo">
        <v-card>
          <v-toolbar class="mb-2 primary" dark flat>
            <v-toolbar-title>Visualizar tempo</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-toolbar-title><v-list-item @click="dialogVerTempo = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form>
              <v-row>
                <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                  <label>Início</label>
                  <v-text-field v-if="tempo.data_inicio != null" :value="apresentarData(tempo.data_inicio) + ' ' + apresentarHoras(tempo.data_inicio)" disabled filled outlined></v-text-field>
                  <v-text-field v-else disabled filled outlined></v-text-field>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                  <label>Fim</label>
                  <v-text-field v-if="tempo.data_fim != null" :value="apresentarData(tempo.data_fim) + ' ' + apresentarHoras(tempo.data_fim)" disabled filled outlined></v-text-field>
                  <v-text-field v-else disabled filled outlined></v-text-field>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                  <label>Tempo total</label>
                  <v-text-field v-if="tempo.tempo_efetivo != null" :value="apresentarHoras(tempo.tempo_efetivo)" disabled filled outlined></v-text-field>
                  <v-text-field v-else disabled filled outlined></v-text-field>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                  <label>Deslocação/Remoto/Telefone do tempo</label><br>
                  <v-icon v-if="tempo.deslocacao == 1" color="green" class="pr-2">mdi-car</v-icon>
                  <v-icon v-if="tempo.remoto == 1" color="green" class="pr-2">mdi-laptop</v-icon>
                  <v-icon v-if="tempo.telefone == 1" color="green" class="pr-2">mdi-phone</v-icon>
                </v-col>
                <v-col cols="12">
                  <label>Funcionário</label>
                  <v-text-field v-model="tempo.Funcionario.nome" disabled filled outlined></v-text-field>
                </v-col>
                <v-col cols="12">
                  <label>Descrição</label>
                  <wysiwyg v-model="tempo.descricao"/>
                </v-col>
                <v-col cols="12">
                  <label>Trabalho Realizado</label>
                  <wysiwyg v-model="tempo.trabalho_realizado"/>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- Dialog apagar tempo -->
      <v-dialog v-model="dialogApagarTempo">
        <v-card>
          <v-toolbar class="mb-2" color="red" dark flat>
              <v-toolbar-title></v-toolbar-title>
              <v-spacer>Apagar tempo</v-spacer>
              <v-toolbar-title><v-list-item @click="dialogApagarTempo = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
          </v-toolbar>
          <v-col cols="12">
              <span>Quer mesmo apagar o tempo?</span>
          </v-col>
          <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red" class="white--text" @click="confirmarApagarTempo">Apagar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Dialog ver pats cliente -->
      <v-dialog v-model="dialogVerPatsCliente">
          <v-card>
            <v-toolbar class="mb-2 primary" dark flat>
              <v-toolbar-title>Pats abertas do cliente</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-title><v-list-item @click="dialogVerPatsCliente = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-row>
                <v-col cols="8">
                  <label>Faturar intervenção</label>
                  <v-switch v-model="criarIntervencao.faturar" color="primary" inset></v-switch>
                </v-col>
                <v-col cols="4 text-right" v-if="tarefatempoglobal.id_equipamento != null">
                  <img @click="criarPatIntervencao" class="m-2" :width="35" src="../../assets/icons_aplicacao/Add_Pats.png" alt="Add_Pats">
                </v-col>
                <v-col cols="4 text-right" v-if="tarefatempoglobal.id_equipamento == null">
                  <span>Tarefa sem equipamento não podes criar pat</span>
                </v-col>
                <v-col cols="12">
                  <v-data-table class="elevation-1" :headers="headersPatsCliente" :items="patscliente">
                    <template v-slot:[`item.actions`]="{ item }">
                      <img @click="editarPat(item)" class="m-2" :width="25" src="../../assets/icons_aplicacao/Editar.png" alt="Editar">
                      <img @click="adicionarIntervencao(item)" class="m-2" :width="25" src="../../assets/icons_aplicacao/Add.png" alt="Add">
                      <img v-if="item.fechado == false && item.tem_intervencoes == false" @click="apagarPat(item)" class="m-2" :width="25" src="../../assets/icons_aplicacao/Apagar.png" alt="Apagar">
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card-text> 
          </v-card>
        </v-dialog>
        <!-- Dialog adicionar intervenção -->
        <v-dialog v-model="dialogAdicionarIntervencao">
          <v-card>
            <v-toolbar class="mb-2" color="orange" dark flat>
                <v-toolbar-title>Adicionar intervenção</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-title><v-list-item @click="dialogAdicionarIntervencao = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
            </v-toolbar>
            <v-col cols="12">
                <span>Quer mesmo adicionar intervenção a esta pat?</span>
            </v-col>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="orange" class="white--text" @click="confirmarAdicionarIntervencao">Adicionar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- Dialog apagar intervenção -->
        <v-dialog v-model="dialogApagarIntervencao">
          <v-card>
            <v-toolbar class="mb-2" color="red" dark flat>
                <v-toolbar-title>Apagar intervenção do erp</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-title><v-list-item @click="dialogApagarIntervencao = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
            </v-toolbar>
            <v-col cols="12">
                <span>Quer mesmo apagar a intervenção do erp que está nesta pat?</span>
            </v-col>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" class="white--text" @click="confirmarApagarIntervencao">Apagar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- Dialog editar pat -->
        <v-dialog v-model="dialogEditarPat">
          <v-card>
            <v-toolbar class="mb-2" color="green" dark flat>
                <v-toolbar-title>Editar pat</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-title><v-list-item @click="dialogEditarPat = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
            </v-toolbar>
            <v-col cols="12">
                <label>Estado</label>
                <v-select v-model="editarPats.estado_pat" item-text="descricao" item-value="descricao" :items="estadospats" filled outlined></v-select>
            </v-col>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green" class="white--text" @click="confirmarEditarPat">Guardar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- Dialog criar pat -->
        <v-dialog v-model="dialogCriarPatIntervencao">
          <v-card>
            <v-toolbar class="mb-2" color="purple" dark flat>
                <v-toolbar-title>Criar pat</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-title><v-list-item @click="dialogCriarPatIntervencao = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
            </v-toolbar>
            <v-col cols="12">
                <label>Equipamento</label>
                <v-text-field v-model="criarPatsIntervencao.stamp_equipamento" disabled filled outlined></v-text-field>
            </v-col>
            <v-col cols="12">
                <label>Estado</label>
                <v-select v-model="criarPatsIntervencao.estado_pat" item-text="descricao" item-value="descricao" :items="estadospats" filled outlined></v-select>
            </v-col>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="purple" class="white--text" @click="confirmarCriarPatIntervencao">Criar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- Dialog apagar pat -->
        <v-dialog v-model="dialogApagarPat">
          <v-card>
            <v-toolbar class="mb-2" color="red" dark flat>
                <v-toolbar-title>Apagar pat do erp</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-title><v-list-item @click="dialogApagarPat = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
            </v-toolbar>
            <v-col cols="12">
                <span>Quer mesmo apagar a pat do erp?</span>
            </v-col>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" class="white--text" @click="confirmarApagarPat">Apagar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </v-flex>
  </v-layout>
</template>
  
<script>
//  Importar o service necessário
import TarefasService from '@/services/TarefasService'
import TarefasObsService from '@/services/TarefasObsService'
import FuncionariosService from '@/services/FuncionariosService'
import ClientesService from '@/services/ClientesService'
import TemposService from '@/services/TemposService'
import PatsService from '@/services/PatsService'
import EstadosPatsService from '@/services/EstadosPatsService'
import EquipamentosService from '@/services/EquipamentosService'
import store from '@/store/store'
import moment from 'moment-timezone'
export default {
  async mounted () {
    if (!store.state.isUserLoggedIn) {
      this.$router.push({
        name: 'mylogin'
      })
    }else if (this.$store.state.cliente == null || this.$store.state.cliente.numerocliente == null) {
      this.tarefas = (await TarefasService.index(this.filtrarTarefas.concluido, this.filtrarTarefas.numero_cliente, this.filtrarTarefas.concluido_funcionario, this.filtrarTarefas.id_funcionario)).data
    } else {
      this.cliente = (await ClientesService.show(this.$store.state.cliente.numerocliente)).data
      this.filtrarTarefas.numero_cliente = this.$store.state.cliente.numerocliente
      this.tarefas = (await TarefasService.index(this.filtrarTarefas.concluido, this.filtrarTarefas.numero_cliente, this.filtrarTarefas.concluido_funcionario, this.filtrarTarefas.id_funcionario)).data
    }
    this.funcionarios = (await FuncionariosService.index()).data
    this.clientes = (await ClientesService.index()).data
  },
  data () {
    return {
      fab: false,
      funcionarios: [],
      tarefas: [],
      clientes: [],
      cliente: {},
      observacoes: [],
      observacaoemail: {},
      tempos: [],
      patscliente: [],
      estadospats: [],
      pats: [],
      tempo: {
        Funcionario: {}
      },
      filtrarTarefas: {
        concluido: false,
        numero_cliente: null,
        concluido_funcionario: false,
        id_funcionario: this.$store.state.funcionarioselect.idfuncionario
      },
      editarFuncionarios: {
          telefone: null,
          remoto: null,
          deslocacao: null,
          estado: null
      },
      criarObservacoes: {
          descricao: '',
          quem_registou: this.$store.state.user.id,
          data_registo: this.formatarDataMoment(Date.now()),
          id_tarefa: null
      },
      editarObservacoes: {
          descricao: null,
          Funcionario: {}
      },
      criarIntervencao: {
          stamp_pat: null,
          numero_tecnico: this.$store.state.user.numero_tecnico,
          relatorio: null,
          tipo_intervencao: null,
          faturar: true,
          id_tempo: null,
          id_tarefa: null,   
      },
      criarPatsIntervencao: {
          stamp_equipamento: null,
          estado_pat: null,
          numero_tecnico: this.$store.state.user.numero_tecnico,
          problema: null,
          numero_cliente: null,
          tipo_intervencao: null,
          faturar: null,
          id_tempo: null,
          id_tarefa: null,
          relatorio: null
      },
      tempoApagarIntervencao: {
          id_tempo: null,
          stamp_intervencao: null,
          id_tarefa: null,
          stamp_pat: null
      },
      editarPats: {
          estado_pat: null
      },
      headersTarefas: [
        { text: "Observações", value: "Tarefas_Obs", sortable: true },
        { text: "Nº tarefa", value: "numero", sortable: true },
        { text: "Cliente", value: "nome_cliente", sortable: true },
        { text: "Descrição da Tarefa", value: "descricao", sortable: true },
        { text: "Funcionarios", value: "Funcionarios", sortable: true },
        { text: "Data Registo", value: "data_registo", sortable: true },
        { text: "Estado", value: "Estado.descricao", sortable: true },
        { text: "Grau Urgência", value: "grau_urgencia", sortable: true },
        { text: "Concluido", value: "concluido", sortable: true },
        { text: 'Ações', value: 'actions', sortable: false },
      ],
      headersObservacoes: [
        { text: "Email", value: "enviado_mail", sortable: true },
        { text: "Data Registo", value: "data_registo", sortable: true },
        { text: "Descrição", value: "descricao", sortable: true },
        { text: "Funcionário", value: "Funcionario.nome", sortable: true },
        { text: 'Ações', value: 'actions', sortable: false },
      ],
      headersTempos: [
        { text: "Funcionário", value: "Funcionario.nome", sortable: false },
        { text: "Descrição", value: "descricao", sortable: false },
        { text: "Trabalho Realizado", value: "trabalho_realizado", sortable: false },
        { text: "Inicio", value: "data_inicio", sortable: false },
        { text: "Fim", value: "data_fim", sortable: false },
        { text: "Tempo", value: "tempo_efetivo", sortable: false },
        { text: "P.A.T", value: "numero_pat", sortable: false },
        { text: "Concluido", value: "concluido", sortable: true },
        { text: 'Ações', value: 'actions', sortable: false },
      ],

      headersPatsCliente: [
          { text: "Nº PAT", value: "numero_pat", sortable: true },
          { text: "Cliente", value: "nome_cliente", sortable: true },
          { text: "Recebido por", value: "recebido_por", sortable: true },
          { text: "Equipamento", value: "equipamento", sortable: true },
          { text: "Nº série", value: "equipamento_serie", sortable: true },
          { text: "Tipo de assistência", value: "tipo_pat", sortable: true },
          { text: "Estado PAT", value: "estado_pat", sortable: true },
          { text: "Problema", value: "problema", sortable: true },
          { text: 'Ações', value: 'actions', sortable: false },
      ],

      dialogFiltrarTarefas: false,
      dialogApagarTarefa: false,
      dialogVerObsEmailEnviado: false,
      dialogVerObservacoes: false,
      dialogCriarObservacao: false,
      dialogApagarObservacao: false,
      dialogEditarObservacao: false,
      dialogVerTempos: false,
      dialogVerTempo: false,
      dialogApagarTempo: false,
      dialogVerPatsCliente: false,
      dialogAdicionarIntervencao: false,
      dialogApagarIntervencao: false,
      dialogEditarPat: false,
      dialogCriarPatIntervencao: false,
      dialogApagarPat: false,

      idtarefaglobal: '',
      idobservacaoglobal: '',
      idtempoglobal: '',
      textobotaoconcluido: 'Tarefas por concluir',
      botaoconcluido: false,
      totaltempostarefas: '',
      tarefatempoglobal: {
          numero_cliente: null,
          id_equipamento: null,
          descricao: null,
      },
      patglobal: '',
    }
  },
  methods: {
    // formatar data
    formatarDataMoment(data) {
      return moment(data).tz('Europe/Lisbon').format('YYYY-MM-DDTHH:mm:ss[Z]');
    },
    apresentarData (date) {
      const options = {year: 'numeric', month: 'numeric', day: 'numeric'}
      return new Date(date).toLocaleDateString('pt-PT', options)
    },
    apresentarHoras (date) {
      return new Date(date).toJSON().slice(11, 19)
    },
    
    //cores da urgência
    getColor (grauurgencia) {
        if (grauurgencia === 'Média') return 'yellow'
        else if (grauurgencia === 'Baixa') return 'green'
        else if (grauurgencia === 'Alta') return 'red'
        else return 'white'
    },
    navigateToTarefaEditar (route) {
      this.$store.dispatch('setTarefa', {  
        idtarefa: route.params.idTarefaEnviado,
      })
      this.$router.push(route)
    },
    navigateToTempoCriar (route) {
      this.$store.dispatch('setTempoTarefa', {  
        idtarefatempo: route.params.idTarefaEnviado,
      })
      this.$router.push(route)
    },
    navigateToTempoEditar (route) {
      this.$store.dispatch('setTempo', {  
          idtempo: route.params.idTempoEnviado,
          finalizadomobile: 1
      })
      this.$router.push(route)
    },
    navigateToTempoModificar (route) {
      this.$store.dispatch('setTempo', {  
          idtempo: route.params.idTempoEnviado,
          finalizadomobile: null
      })
      this.$router.push(route)
    },
    // filtrar tarefas
    async confirmarFiltrarTarefas () {
      if (this.filtrarTarefas.numero_cliente != null) {
        this.cliente = (await ClientesService.show(this.filtrarTarefas.numero_cliente)).data
      } else {
        this.cliente.nome = null
      }
      this.tarefas = (await TarefasService.index(this.filtrarTarefas.concluido, this.filtrarTarefas.numero_cliente, this.filtrarTarefas.concluido_funcionario, this.filtrarTarefas.id_funcionario)).data
      this.dialogFiltrarTarefas = false
    },
    // filtrar concluidos
    async filtrarConcluidos () {
      if (this.botaoconcluido == false) {
          this.filtrarTarefas.concluido_funcionario = false
          this.filtrarTarefas.concluido = false
          this.textobotaoconcluido = 'Tarefas por concluir'
      }
      if (this.botaoconcluido == true) {
          this.filtrarTarefas.concluido_funcionario = null
          this.filtrarTarefas.concluido = null
          this.textobotaoconcluido = 'Mostrar todas'
      }
      this.tarefas = (await TarefasService.index(this.filtrarTarefas.concluido, this.filtrarTarefas.numero_cliente, this.filtrarTarefas.concluido_funcionario, this.filtrarTarefas.id_funcionario)).data
    },
    //-------------------------------------------tarefas------------------------------//
    //-------------------------------------------tarefas------------------------------//
    //-------------------------------------------tarefas------------------------------//
    async apagarTarefa (id) {
      this.dialogApagarTarefa = true
      this.idtarefaglobal = id
    },
    async confirmarApagarTarefa () {
      try{
        await TarefasService.delete(this.idtarefaglobal)
        this.dialogApagarTarefa = false
        if (this.$store.state.cliente == null || this.$store.state.cliente.numerocliente == null) {
          this.tarefas = (await TarefasService.index(this.filtrarTarefas.concluido, this.filtrarTarefas.numero_cliente, this.filtrarTarefas.concluido_funcionario, this.filtrarTarefas.id_funcionario)).data
        } else {
          this.filtrarTarefas.numero_cliente = this.$store.state.cliente.numerocliente
          this.tarefas = (await TarefasService.index(this.filtrarTarefas.concluido, this.filtrarTarefas.numero_cliente, this.filtrarTarefas.concluido_funcionario, this.filtrarTarefas.id_funcionario)).data
        }
          this.$store.dispatch("setSnackBar", {
              color: "success",
              text: "Tarefa apagada com sucesso"
          });
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a apagar tarefa, pode ter coisas associadas"
          });
      }
    },
    //-------------------------------------------observações------------------------------//
    //-------------------------------------------observações------------------------------//
    //-------------------------------------------observações------------------------------//
    async verObservacoes (idTarefa) {
      this.idtarefaglobal = idTarefa
      this.dialogVerObservacoes = true
      this.observacoes = (await TarefasObsService.index(idTarefa)).data
    },
    async verObsEmailEnviado (id) {
      this.dialogVerObsEmailEnviado = true
      this.observacaoemail = (await TarefasObsService.show(id)).data
    },
    async criarObservacao (idTarefa) {
        if (idTarefa.type != 'click') {
          this.idtarefaglobal = idTarefa
        }
        this.dialogCriarObservacao = true
        this.criarObservacoes.id_tarefa = this.idtarefaglobal
    },
    async confirmarCriarObservacao () {
      try{
          await TarefasObsService.post(this.criarObservacoes)
          this.dialogCriarObservacao = false
          if (this.$store.state.cliente == null || this.$store.state.cliente.numerocliente == null) {
            this.tarefas = (await TarefasService.index(this.filtrarTarefas.concluido, this.filtrarTarefas.numero_cliente, this.filtrarTarefas.concluido_funcionario, this.filtrarTarefas.id_funcionario)).data
          } else {
            this.filtrarTarefas.numero_cliente = this.$store.state.cliente.numerocliente
            this.tarefas = (await TarefasService.index(this.filtrarTarefas.concluido, this.filtrarTarefas.numero_cliente, this.filtrarTarefas.concluido_funcionario, this.filtrarTarefas.id_funcionario)).data
          }
          this.observacoes = (await TarefasObsService.index(this.idtarefaglobal)).data
          this.$store.dispatch("setSnackBar", {
              color: "success",
              text: "Observação criada com sucesso"
          });
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a criar observação"
          });
      }
    },
    async apagarObservacao (id) {
      this.dialogApagarObservacao = true
      this.idobservacaoglobal = id
    },
    async confirmarApagarObservacao () {
      try{
          await TarefasObsService.delete(this.idobservacaoglobal)
          this.dialogApagarObservacao = false
          if (this.$store.state.cliente == null || this.$store.state.cliente.numerocliente == null) {
            this.tarefas = (await TarefasService.index(this.filtrarTarefas.concluido, this.filtrarTarefas.numero_cliente, this.filtrarTarefas.concluido_funcionario, this.filtrarTarefas.id_funcionario)).data
          } else {
            this.filtrarTarefas.numero_cliente = this.$store.state.cliente.numerocliente
            this.tarefas = (await TarefasService.index(this.filtrarTarefas.concluido, this.filtrarTarefas.numero_cliente, this.filtrarTarefas.concluido_funcionario, this.filtrarTarefas.id_funcionario)).data
          }
          this.observacoes = (await TarefasObsService.index(this.idtarefaglobal)).data
          this.$store.dispatch("setSnackBar", {
              color: "success",
              text: "Observação apagada com sucesso"
          });
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a apagar observação"
          });
      }
    },
    async editarObservacao (id) {
        this.dialogEditarObservacao = true
        this.editarObservacoes = (await TarefasObsService.show(id)).data
    },
    async confirmarEditarObservacao () {
      try{
          await TarefasObsService.put(this.editarObservacoes)
          this.dialogEditarObservacao = false
          this.observacoes = (await TarefasObsService.index(this.idtarefaglobal)).data
          this.$store.dispatch("setSnackBar", {
              color: "success",
              text: "Observação editada com sucesso"
          });
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a editar observação"
          });
      }
    },
    //-------------------------------------------tempos------------------------------//
    //-------------------------------------------tempos------------------------------//
    //-------------------------------------------tempos------------------------------//
    async verTempos (idTarefa) {
      this.idtarefaglobal = idTarefa
      this.dialogVerTempos = true
      this.tempos = (await TemposService.index(idTarefa)).data
      var totalSegundos = 0; // Inicializa o total de segundos

      for (var tt = 0; tt < this.tempos.length; tt++) {
        var tempoefetivo = this.apresentarHoras(this.tempos[tt].tempo_efetivo);
        var partesTempo = tempoefetivo.split(':'); // Divide o tempo em partes (horas, minutos, segundos)
        var horas = parseInt(partesTempo[0], 10);
        var minutos = parseInt(partesTempo[1], 10);
        var segundos = parseInt(partesTempo[2], 10);

        // Converte tudo para segundos e soma
        totalSegundos += horas * 3600 + minutos * 60 + segundos;
      }

      // Converte o total de segundos de volta para o formato de tempo desejado
      var totalFormatado = this.formatarTempo(totalSegundos);

      this.totaltempostarefas = totalFormatado;
    },
    
    async verTempo (idTempo) {
      this.dialogVerTempo = true
      this.tempo = (await TemposService.show(idTempo)).data
    },

    // Função para formatar o tempo no formato HH:mm:ss
    formatarTempo(totalSegundos) {
      var horas = Math.floor(totalSegundos / 3600);
      var minutos = Math.floor((totalSegundos % 3600) / 60);
      var segundos = totalSegundos % 60;

      return horas.toString().padStart(2, '0') + ':' + minutos.toString().padStart(2, '0') + ':' + segundos.toString().padStart(2, '0');
    },

    async apagarTempo (item) {
      this.dialogApagarTempo = true
      this.idtempoglobal = item.id
    },
    async confirmarApagarTempo () {
      try{
        await TemposService.delete(this.idtempoglobal)
        this.dialogApagarTempo = false
        this.verTempos(this.idtarefaglobal)

        //ver se este utilizador tem tempos abertos, se não tiver mete na tabela dos Funcionarios telefone, remoto, deslocao a false, Livre e cliente vazio, se não for vazio mete na coluna cliente os clientes que estão a ser tratados
        this.editarFuncionarios = (await FuncionariosService.show(this.$store.state.user.id)).data
        const temposabertos = (await TemposService.index(null, null, null, this.$store.state.user.id, null, false)).data
        if (temposabertos == '') {
            this.editarFuncionarios.telefone = false
            this.editarFuncionarios.remoto = false
            this.editarFuncionarios.deslocacao = false
            this.editarFuncionarios.estado = 'Livre'
            this.editarFuncionarios.cliente = ''
            await FuncionariosService.put(this.editarFuncionarios)
        } else {
            this.editarFuncionarios.cliente = ''
            await FuncionariosService.put(this.editarFuncionarios)
            for (var i = 0; i < temposabertos.length; i++) {
                this.editarFuncionarios.cliente = this.editarFuncionarios.cliente + temposabertos[i].nome_cliente + '; '
            }  
            await FuncionariosService.put(this.editarFuncionarios) 
        }

        this.$store.dispatch("setSnackBar", {
            color: "success",
            text: "Tempo apagado com sucesso"
        });
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a apagar tempo"
          });
      }
    },
    //-------------------------------------------pats------------------------------//
    //-------------------------------------------pats------------------------------//
    //-------------------------------------------pats------------------------------//
    async verPatsCliente (item) {
        this.criarIntervencao.id_tempo = item.id
        if (item.deslocacao == true) {
            this.criarIntervencao.tipo_intervencao = 'Deslocação a Cliente'
        } else {
            if (item.remoto == true) {
            this.criarIntervencao.tipo_intervencao = 'Assistência Remota'
            } else {
            if (item.telefone == true) {
                this.criarIntervencao.tipo_intervencao = 'Assistência Telefónica'
            } else {
                this.criarIntervencao.tipo_intervencao = 'Assistência Interna'
            }
            }
        }    
        this.criarIntervencao.relatorio = this.criarIntervencao.tipo_intervencao + ':\n\n' + item.trabalho_realizado
        this.criarIntervencao.id_tarefa = item.id_tarefa
        
        this.dialogVerPatsCliente = true
        this.patscliente = (await PatsService.importarpatserp(item.numero_cliente, null, false)).data
        this.tarefatempoglobal.numero_cliente = item.Tarefa.numero_cliente
        this.tarefatempoglobal.id_equipamento = item.Tarefa.id_equipamento
        this.tarefatempoglobal.descricao = item.Tarefa.descricao
    },
    async adicionarIntervencao (item) {
        this.dialogAdicionarIntervencao = true
        this.criarIntervencao.stamp_pat = item.stamp_pat
    },
    async confirmarAdicionarIntervencao () {
        try{
            const response = await PatsService.criarintervencoeserp(this.criarIntervencao)

            if (response.data == 'ERRO') {
                this.$store.dispatch("setSnackBar", {
                    color: "error",
                    text: "Campos estão a ir errados"
                });
            } else {
                this.verTempos(this.idtarefaglobal)
                this.dialogAdicionarIntervencao = false
                this.dialogVerPatsCliente = false

                this.$store.dispatch("setSnackBar", {
                    color: "success",
                    text: "Intervenção adicionada à pat com sucesso"
                });
            }
            
        } catch (error) {
            this.$store.dispatch("setSnackBar", {
                color: "error",
                text: "Erro a adicionar intervenção à pat"
            });
        }
    },
    async apagarIntervencaoPat (item) {
        this.dialogApagarIntervencao = true
        this.tempoApagarIntervencao.id_tempo = item.id
        this.tempoApagarIntervencao.stamp_intervencao = item.stamp_intervencao
        this.tempoApagarIntervencao.stamp_pat = item.stamp_pat
        this.tempoApagarIntervencao.id_tarefa = item.id_tarefa
    },
    async confirmarApagarIntervencao () {
        try{
            //Apagar intervenção da pat
            const response = await PatsService.apagarintervencoeserp(this.tempoApagarIntervencao)
            
            if (response.data == 'ERRO') {
                this.$store.dispatch("setSnackBar", {
                    color: "error",
                    text: "Campos estão a ir errados"
                });
            } else {
                this.verTempos(this.idtarefaglobal)
                this.dialogApagarIntervencao = false

                this.$store.dispatch("setSnackBar", {
                    color: "success",
                    text: "Intervenção apagada da pat com sucesso"
                });
            }

            
        } catch (error) {
            this.$store.dispatch("setSnackBar", {
                color: "error",
                text: "Erro a apagar intervenção da pat"
            });
        }
    },
    async editarPat (item) {
        this.editarPats = (await PatsService.verpatserp(item.stamp_pat)).data
        this.estadospats = (await EstadosPatsService.index()).data
        this.dialogEditarPat = true
    },
    async confirmarEditarPat () {
        try{
            //Editar pat
            const response = await PatsService.editarpatserp(this.editarPats)    

            if (response.data == 'ERRO') {
                this.$store.dispatch("setSnackBar", {
                    color: "error",
                    text: "Campos estão a ir errados"
                });
            } else {
                this.patscliente = (await PatsService.importarpatserp(this.tarefatempoglobal.numero_cliente, null, false)).data
                this.dialogEditarPat = false

                this.$store.dispatch("setSnackBar", {
                    color: "success",
                    text: "Pat editada com sucesso"
                });
            }
            
        } catch (error) {
            this.$store.dispatch("setSnackBar", {
                color: "error",
                text: "Erro a editar pat"
            });
        }
    },
    async criarPatIntervencao () {
        this.equipamentos = (await EquipamentosService.index(null, null, null, null, null, null, null, this.tarefatempoglobal.id_equipamento)).data
        this.criarPatsIntervencao.stamp_equipamento = this.equipamentos[0].serial
        this.estadospats = (await EstadosPatsService.index()).data
        this.dialogCriarPatIntervencao = true
    },
    async confirmarCriarPatIntervencao () {
        try{
            this.criarPatsIntervencao.numero_cliente = this.tarefatempoglobal.numero_cliente
            this.criarPatsIntervencao.problema = this.tarefatempoglobal.descricao
            this.criarPatsIntervencao.faturar = this.criarIntervencao.faturar
            this.criarPatsIntervencao.tipo_intervencao = this.criarIntervencao.tipo_intervencao
            this.criarPatsIntervencao.id_tempo = this.criarIntervencao.id_tempo
            this.criarPatsIntervencao.id_tarefa = this.criarIntervencao.id_tarefa
            this.criarPatsIntervencao.relatorio = this.criarIntervencao.tipo_intervencao + ':\n\n' + this.criarIntervencao.relatorio
            this.criarPatsIntervencao.stamp_equipamento = this.equipamentos[0].stamp_equipamento
            const response = await PatsService.criarpatsintervencaoerp(this.criarPatsIntervencao)

            if (response.data == 'ERRO') {
                this.$store.dispatch("setSnackBar", {
                    color: "error",
                    text: "Campos estão a ir errados"
                });
            } else {
                this.verTempos(this.idtarefaglobal)
                this.dialogCriarPatIntervencao = false
                this.dialogVerPatsCliente = false
                
                this.$store.dispatch("setSnackBar", {
                    color: "success",
                    text: "Pat criada com sucesso"
                });
            }     
        } catch (error) {
            this.$store.dispatch("setSnackBar", {
                color: "error",
                text: "Erro a criar pat"
            });
        }
    },
    async apagarPat (item) {
        this.dialogApagarPat = true
        this.patglobal = item
    },
    async confirmarApagarPat () {
        try{
            //Apagar pat do erp
            const response = await PatsService.apagarpatserp(this.patglobal)
            
            if (response.data == 'ERRO') {
                this.$store.dispatch("setSnackBar", {
                    color: "error",
                    text: "Campos estão a ir errados"
                });
            } else {
                this.patscliente = (await PatsService.importarpatserp(this.tarefatempoglobal.numero_cliente, null, false)).data
                this.dialogApagarPat = false
                this.$store.dispatch("setSnackBar", {
                    color: "success",
                    text: "Pat apagada do erp com sucesso"
                });
            }

            
        } catch (error) {
            this.$store.dispatch("setSnackBar", {
                color: "error",
                text: "Erro a apagar pat do erp"
            });
        }
    },
  }
}
</script>
<style>
</style>