import Api from '@/services/Api'

export default {
  index () {
    return Api().get('equipamentosmarcas')
  },
  post (elementos) {
    return Api().post('equipamentosmarcas', elementos)
  },
  put (elementos, id) {
    return Api().put(`equipamentosmarcas/${id}`, elementos)
  },
  show (id) {
    return Api().get(`equipamentosmarcas/${id}`)
  },
  deleteall () {
    return Api().delete(`equipamentosmarcas`)
  },
  showmarca (nome) {
    return Api().get(`showmarca`,{
      params: {
        nome
      }
    })
  },
}
