//  Outros
import Vue from 'vue'
import Router from 'vue-router'
//  Login
import MyLogin from '@/components/MyLogin'
//  Home
import MyHome from '@/components/MyHome'
//  Tarefas
import MyTarefas from '@/components/Tarefas/MyTarefas'
import MyCriarTarefas from '@/components/Tarefas/MyCriarTarefas'
import MyEditarTarefas from '@/components/Tarefas/MyEditarTarefas'
//  Marcações
import MyMarcacoes from '@/components/Marcacoes/MyMarcacoes'
import MyCriarMarcacoes from '@/components/Marcacoes/MyCriarMarcacoes'
import MyEditarMarcacoes from '@/components/Marcacoes/MyEditarMarcacoes'
// Projetos
import MyProjetos from '@/components/Projetos/MyProjetos'
import MyCriarProjetos from '@/components/Projetos/MyCriarProjetos'
import MyEditarProjetos from '@/components/Projetos/MyEditarProjetos'
// Funcionarios
import MyFuncionariosEstado from '@/components/Funcionarios/MyFuncionariosEstado'
import MyFuncionarios from '@/components/Funcionarios/MyFuncionarios'
// Tempos
import MyTempos from '@/components/Tempos/MyTempos'
import MyCriarTemposTarefas from '@/components/Tempos/MyCriarTemposTarefas'
import MyCriarTemposClientes from '@/components/Tempos/MyCriarTemposClientes'
import MyEditarTemposTarefas from '@/components/Tempos/MyEditarTemposTarefas'
import MyEditarTemposClientes from '@/components/Tempos/MyEditarTemposClientes'
import MyModificarTempos from '@/components/Tempos/MyModificarTempos'
// Artigos
import MyArtigos from '@/components/Artigos/MyArtigos'
// Clientes
import MyClientes from '@/components/Clientes/MyClientes'
import MyCriarClientes from '@/components/Clientes/MyCriarClientes'
import MyEditarClientes from '@/components/Clientes/MyEditarClientes'
import MyVerClientes from '@/components/Clientes/MyVerClientes'
import MyClientesOutrosContactos from '@/components/Clientes/MyClientesOutrosContactos'
import MyClientesOutrasMoradas from '@/components/Clientes/MyClientesOutrasMoradas'
// Contas Correntess
import MyContasCorrentes from '@/components/ContasCorrentes/MyContasCorrentes'
// Equipamentos
import MyEquipamentos from '@/components/Equipamentos/MyEquipamentos'
import MyCriarEquipamentos from '@/components/Equipamentos/MyCriarEquipamentos'
// Calendario
//import MyCalendario from '@/components/Calendario/MyCalendario'
// Notas
import MyNotas from '@/components/Notas/MyNotas'
// Mensagens
import MyMensagens from '@/components/Mensagens/MyMensagens'
import MyCriarMensagens from '@/components/Mensagens/MyCriarMensagens'
import MyChatMensagens from '@/components/Mensagens/MyChatMensagens'
// Tickets
import MyTickets from '@/components/Tickets/MyTickets'
// Configurações
import MyConfiguracoesModulos from '@/components/Configuracoes/MyConfiguracoesModulos'
import MyEmpresaTitular from '@/components/Configuracoes/MyEmpresaTitular'

Vue.use(Router)

export default new Router({
  //  path: link que vamos usar
  //  name: nome que vai ser chamado no componets*
  //  component: nome que damos la em cima para chamar o componets
  routes: [
    //Login
    {
      path: '/',
      name: 'mylogin',
      component: MyLogin
    },
    //  Home
    {
      path: '/home',
      name: 'myhome',
      component: MyHome
    },
    //  Editar Marcações
    {
      path: '/editarprojetos',
      name: 'editarprojetos',
      component: MyEditarProjetos
    },
    //  Funcionarios Estados
    {
      path: '/funcionariosestado',
      name: 'funcionariosestado',
      component: MyFuncionariosEstado
    },
    //  Funcionarios
    {
      path: '/funcionarios',
      name: 'funcionarios',
      component: MyFuncionarios
    },
    //  Notas
    {
      path: '/notas',
      name: 'notas',
      component: MyNotas
    },
    //  Mensagens 
    {path: '/mensagens',name: 'mensagens',component: MyMensagens},
    {path: '/criarmensagens', name: 'criarmensagens', component: MyCriarMensagens},
    {path: '/chatmensagens', name: 'chatmensagens', component: MyChatMensagens},
    
    
    //  Artigos
    {path: '/artigos', name: 'artigos', component: MyArtigos},

    //Tempos
    {path: '/tempos', name: 'tempos', component: MyTempos},
    {path: '/criartempostarefas', name: 'criartempostarefas', component: MyCriarTemposTarefas},
    {path: '/criartemposclientes', name: 'criartemposclientes', component: MyCriarTemposClientes},
    {path: '/editartempostarefas', name: 'editartempostarefas', component: MyEditarTemposTarefas},
    {path: '/editartemposclientes', name: 'editartemposclientes', component: MyEditarTemposClientes},
    {path: '/modificartempos', name: 'modificartempos', component: MyModificarTempos},

    //Clientes
    {path: '/clientes', name: 'clientes', component: MyClientes},
    {path: '/criarclientes', name: 'criarclientes', component: MyCriarClientes},
    {path: '/editarclientes', name: 'editarclientes', component: MyEditarClientes},
    {path: '/verclientes', name: 'verclientes', component: MyVerClientes},

    //Tarefas
    {path: '/tarefas', name: 'tarefas', component: MyTarefas},
    {path: '/criartarefas', name: 'criartarefas', component: MyCriarTarefas},
    {path: '/editartarefas', name: 'editartarefas', component: MyEditarTarefas},

    //Marcações
    {path: '/marcacoes', name: 'marcacoes', component: MyMarcacoes},
    {path: '/criarmarcacoes', name: 'criarmarcacoes', component: MyCriarMarcacoes},
    {path: '/editarmarcacoes', name: 'editarmarcacoes', component: MyEditarMarcacoes},

    //Projetos
    {path: '/projetos', name: 'projetos', component: MyProjetos},
    {path: '/criarprojetos', name: 'criarprojetos', component: MyCriarProjetos},
    {path: '/editarprojetos', name: 'editarprojetos', component: MyEditarProjetos},

    //Equipamentos
    {path: '/equipamentos', name: 'equipamentos', component: MyEquipamentos},
    {path: '/criarequipamentos', name: 'criarequipamentos', component: MyCriarEquipamentos},
    
    //Configurações
    {path: '/configuracoesmodulos', name: 'configuracoesmodulos', component: MyConfiguracoesModulos},
    {path: '/empresatitular', name: 'empresatitular', component: MyEmpresaTitular},

    //Clientes Outros Contactos
    {
      path: '/clientesoutroscontactos',
      name: 'clientesoutroscontactos',
      component: MyClientesOutrosContactos
    },
    //Clientes Outras Moradas
    {
      path: '/clientesoutrasmoradas',
      name: 'clientesoutrasmoradas',
      component: MyClientesOutrasMoradas
    },
    //Contas Correntes
    {
      path: '/contascorrentes',
      name: 'contascorrentes',
      component: MyContasCorrentes
    },
    
    //Calendario
    /*
    {
      path: '/calendario',
      name: 'calendario',
      component: MyCalendario
    },
    */
    //Tickets
    {
      path: '/tickets',
      name: 'tickets',
      component: MyTickets
    },
  ]
})
