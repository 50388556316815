<template>
  <v-layout justify-center v-if="$store.state.isUserLoggedIn && this.$store.state.tarefa != null || this.$store.state.tarefa.idtarefa != null">
    <v-speed-dial fab fixed v-model="fab" :bottom="true" :right="true" direction="top" :open-on-hover="false">
      <template v-slot:activator>
        <v-btn v-model="fab" color="blue darken-2" dark large fab>
          <v-icon v-if="fab">mdi-close</v-icon>
          <v-icon v-else>mdi-filter-variant</v-icon>
        </v-btn>
      </template>
      <v-btn fab dark large color="blue" class="mb-2" @click="verTempos">
        <img :width="45" src="../../assets/icons_aplicacao/Tempos.png" alt="Tempos">
      </v-btn>
      <v-btn fab dark large color="blue" class="mb-2" @click="verObservacoes">
        <img :width="45" src="../../assets/icons_aplicacao/Notas.png" alt="Observações">
      </v-btn>
      <v-btn fab dark large color="blue" class="mb-2" @click="verMarcacoes">
        <img :width="45" src="../../assets/icons_aplicacao/Marcacoes.png" alt="Marcações">
      </v-btn>
      <v-btn fab dark large color="blue" class="mb-2" @click="verProjetos">
        <img :width="45" src="../../assets/icons_aplicacao/Projetos.png" alt="Projetos">
      </v-btn>
      <v-btn fab dark large color="blue" class="mb-2">
        <img :width="45" src="../../assets/icons_aplicacao/Logs.png" alt="Logs">
      </v-btn>
      <v-btn fab dark large color="blue" class="mb-2">
        <img :width="45" src="../../assets/icons_aplicacao/Mensagens.png" alt="Mensagens">
      </v-btn>
      <v-btn fab dark large color="blue" class="mb-2" @click="verEquipamentos">
        <img :width="45" src="../../assets/icons_aplicacao/Equipamentos.png" alt="Equipamentos">
      </v-btn>
      <v-btn fab dark large color="blue" class="mb-2" @click="verPats">
        <img :width="45" src="../../assets/icons_aplicacao/Pats.png" alt="Pats">
      </v-btn>
      <v-btn fab dark large color="blue" class="mb-2" @click="verArtigosTarefa">
        <img :width="45" src="../../assets/icons_aplicacao/Artigos.png" alt="Artigos">
      </v-btn>
    </v-speed-dial>
    <v-flex>
        <v-card>
          <v-toolbar class="mb-2" color="green" dark flat>
            <v-toolbar-title>Editar tarefa</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form ref="form">
                <v-row>
                  <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 4">
                    <label>Cliente</label>
                    <v-text-field v-model="editarTarefas.nome_cliente" disabled filled outlined></v-text-field>
                  </v-col>
                  <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                    <label>Quem Registou</label>
                    <v-text-field v-model="editarTarefas.quemRegistou.nome" disabled filled outlined></v-text-field>
                  </v-col>
                  <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                    <label>Data de Registo</label>
                    <MYdatetime type="date" :value="editarTarefas.data_registo" disabled="disabled"></MYdatetime>
                  </v-col>
                  <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                    <label>Quem Pediu</label>
                    <v-text-field type="text" v-model="editarTarefas.quem_pediu" clearable filled outlined></v-text-field>
                  </v-col>
                  <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                    <label>Pedido Como</label>
                    <v-select v-model="editarTarefas.como_pediu" :items="['E-mail', 'Telefone', 'WhatsApp', 'Formação', 'Ticket', 'Reunião']" filled outlined></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 8">
                    <v-row>
                      <v-col cols="12">
                        <label>Descrição</label>
                        <wysiwyg v-model="editarTarefas.descricao"/>
                      </v-col>
                      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                        <label>Urgência</label>
                        <v-select v-model="editarTarefas.grau_urgencia" :items="['Alta', 'Média', 'Baixa']" filled outlined></v-select>
                      </v-col>
                      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                        <label>Tópico</label>
                        <v-select v-model="editarTarefas.id_topico" :items="topicos" item-text="descricao" item-value="id" filled outlined></v-select>
                      </v-col>
                      <v-col v-if="editarTarefas.concluido == false" :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                        <label>Estado</label>
                        <v-select v-model="editarTarefas.id_estado" :items="estados" item-text="descricao" item-value="id" filled outlined></v-select>
                      </v-col>
                      <v-col v-else :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                        <label>Estado</label>
                        <v-text-field type="text" value="Terminada" clearable filled outlined disabled></v-text-field>
                      </v-col>
                      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                        <label>Data de Inicio</label>
                        <MYdatetime type="date" v-model="editarTarefas.data_inicio"></MYdatetime>
                        <v-text-field class="d-none" type="date" v-model="editarTarefas.data_inicio" clearable filled outlined :rules="[rules.datamenor]"></v-text-field>
                        <br>
                      </v-col>
                      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                        <label>Data de fim</label>
                        <MYdatetime type="date" v-model="editarTarefas.data_fim"></MYdatetime>
                        <span v-if="editarTarefas.data_inicio > editarTarefas.data_fim" class="errodata">Data fim deve ser maior que a data de início.</span>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 4">
                    <v-row>
                      <v-col cols="12" class="text-center">
                        <label>INTERVENIENTES </label>
                        <img v-if="editarTarefas.concluido == false" @click="dialogCriarInterveniente = true" :width="35" src="../../assets/icons_aplicacao/Add_Interveniente.png" alt="Add_Interveniente">
                      </v-col>
                      <v-col cols="12 pt-0" v-for="funcionario in editarTarefas.Tarefas_Funcionarios" :key="funcionario.id">
                        <v-list class="interve">
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>{{funcionario.Funcionario.nome}}</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action v-if="funcionario.enviado_mail == true">
                              <img :width="25" src="../../assets/icons_aplicacao/Email_Enviado.png" alt="Email_Enviado" class="mr-5">
                            </v-list-item-action>
                            <v-list-item-action v-if="funcionario.concluido == true" @click="intervenienteNaoTerminada(funcionario.id)">
                              <img :width="18" src="../../assets/icons_aplicacao/Certo.png" alt="Certo">
                            </v-list-item-action>
                            <v-list-item-action v-else @click="intervenienteTerminada(funcionario.id)">
                              <img :width="18" src="../../assets/icons_aplicacao/Errado.png" alt="Errado">
                            </v-list-item-action>
                            <v-list-item-action v-if="editarTarefas.concluido == false">
                              <img @click="apagarInterveniente(funcionario.id)" :width="25" src="../../assets/icons_aplicacao/Apagar.png" alt="Apagar">
                            </v-list-item-action>
                          </v-list-item>
                        </v-list>
                        <v-divider></v-divider>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" v-if="editarTarefas.concluido == false">
                      <v-card-actions>
                          <v-spacer></v-spacer>
                          <img @click="editarTarefa" :width="50" src="../../assets/icons_aplicacao/Guardar.png" alt="Guardar">
                      </v-card-actions>
                  </v-col>
                </v-row>
            </v-form>
          </v-card-text>
        </v-card>
        <!-- Dialog ver observações -->
        <v-dialog v-model="dialogVerObservacoes">
          <v-card>
            <v-toolbar class="mb-2 primary" dark flat>
              <v-toolbar-title>Observações</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-title><v-list-item @click="dialogVerObservacoes = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
            </v-toolbar>
            <v-col cols="12 text-right">
              <img @click="criarObservacao" class="m-2" :width="35" src="../../assets/icons_aplicacao/Add_Notas.png" alt="Add_Notas">
            </v-col>
            <v-col cols="12">
              <v-data-table class="elevation-1" :headers="headersObservacoes" :items="observacoes">
                <template v-slot:[`item.enviado_mail`]="{ item }">
                  <img v-if="item.enviado_mail == true" @click="verObsEmailEnviado(item.id)" class="m-2" :width="25" src="../../assets/icons_aplicacao/Email_Enviado.png" alt="Email_Enviado">
                </template>
                <template v-slot:[`item.data_registo`]="{ item }">
                  {{ apresentarData(item.data_registo) }}
                </template>
                <template v-slot:[`item.descricao`]="{ item }">
                  <span v-if="item.descricao != null" v-html="item.descricao.substring(0,75)"></span>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <img @click="editarObservacao(item.id)" class="m-2" :width="25" src="../../assets/icons_aplicacao/Editar.png" alt="Editar">
                  <img @click="apagarObservacao(item.id)" class="m-2" :width="25" src="../../assets/icons_aplicacao/Apagar.png" alt="Apagar">
                </template>
              </v-data-table>
            </v-col>
          </v-card>
        </v-dialog>
        <!-- Dialog criar observação-->
        <v-dialog v-model="dialogCriarObservacao">
          <v-card>
            <v-toolbar class="mb-2" color="purple" dark flat>
              <v-toolbar-title>Criar observação</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-title><v-list-item @click="dialogCriarObservacao = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
            </v-toolbar>
            <v-col cols="12">
                <label>Descrição</label>
                <wysiwyg v-model="criarObservacoes.descricao"/>
            </v-col>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="purple" class="white--text" @click="confirmarCriarObservacao">Criar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- Dialog apagar observação -->
        <v-dialog v-model="dialogApagarObservacao">
          <v-card>
            <v-toolbar class="mb-2" color="red" dark flat>
                <v-toolbar-title>Apagar observação</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-title><v-list-item @click="dialogApagarObservacao = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
            </v-toolbar>
            <v-col cols="12">
                <span>Quer mesmo apagar a observação?</span>
            </v-col>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" class="white--text" @click="confirmarApagarObservacao">Apagar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- Dialog editar observação -->
        <v-dialog v-model="dialogEditarObservacao">
          <v-card>
            <v-toolbar class="mb-2" color="green" dark flat>
                <v-toolbar-title>Editar observação</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-title><v-list-item @click="dialogEditarObservacao = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
            </v-toolbar>
            <v-col cols="12">
                <label>Data Registo</label>
                <MYdatetime type="date" :value="editarObservacoes.data_registo" disabled="disabled"></MYdatetime>
            </v-col>
            <v-col cols="12">
                <label>Registada por</label>
                <v-text-field v-model="editarObservacoes.Funcionario.nome" disabled filled outlined></v-text-field>
            </v-col>
            <v-col cols="12">
                <label>Descrição</label>
                <wysiwyg v-model="editarObservacoes.descricao"/>
            </v-col>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green" class="white--text" @click="confirmarEditarObservacao">Editar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- Dialog ver email enviado observação-->
        <v-dialog v-model="dialogVerObsEmailEnviado">
          <v-card>
            <v-toolbar class="mb-2 primary" dark flat>
              <v-toolbar-title>Observações da tarefa enviada por e-mail para o(s) seguinte(s) endereço(s)</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-title><v-list-item @click="dialogVerObsEmailEnviado = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
            </v-toolbar>
            <v-col cols="12">
              <span v-if="observacaoemail.enviado_mail_data != null">{{observacaoemail.enviado_mail_para_quem}} em {{apresentarData(observacaoemail.enviado_mail_data)}} às {{apresentarHoras(observacaoemail.enviado_mail_data)}} horas.</span>
            </v-col>
          </v-card>
        </v-dialog>
        <!-- Dialog ver tempos -->
        <v-dialog v-model="dialogVerTempos">
          <v-card>
            <v-toolbar class="mb-2 primary" dark flat>
              <v-toolbar-title>Tempos</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-title><v-list-item @click="dialogVerTempos = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-row>
                <v-col cols="10">
                    <span class="totais-size"><b>Total tempo: {{totaltempostarefas}}</b></span>
                </v-col>
                <v-col cols="2 text-right" v-if="editarTarefas.concluido == false">
                  <img @click="navigateToTempoCriar({name: 'criartempostarefas', params:{idTarefaEnviado: idtarefaglobal}})" class="m-2" :width="35" src="../../assets/icons_aplicacao/Add_Tempos.png" alt="Add_Tempos">
                </v-col>
                <v-col cols="12">
                  <v-data-table class="elevation-1" :headers="headersTempos" :items="tempos">
                    <template v-slot:[`item.descricao`]="{ item }">
                      <span v-if="item.descricao != null" v-html="item.descricao.substring(0,75)"></span>
                    </template>
                    <template v-slot:[`item.trabalho_realizado`]="{ item }">
                      <span v-if="item.trabalho_realizado != null" v-html="item.trabalho_realizado.substring(0,75)"></span>
                    </template>
                    <template v-slot:[`item.data_inicio`]="{ item }">
                      <span v-if="item.data_inicio != null">{{ apresentarData(item.data_inicio) }} {{ apresentarHoras(item.data_inicio) }}</span>
                    </template>
                    <template v-slot:[`item.data_fim`]="{ item }">
                      <span v-if="item.data_fim != null">{{ apresentarData(item.data_fim) }} {{ apresentarHoras(item.data_fim) }}</span>
                    </template>
                    <template v-slot:[`item.tempo_efetivo`]="{ item }">
                      <span v-if="item.tempo_efetivo != null">{{ apresentarHoras(item.tempo_efetivo) }}</span>
                    </template>
                    <template v-slot:[`item.concluido`]="{ item }">
                      <img v-if="item.concluido == false" class="m-2" :width="25" src="../../assets/icons_aplicacao/Nao_Concluido.png" alt="Nao_Concluido">
                      <img v-else class="m-2" :width="25" src="../../assets/icons_aplicacao/Concluido.png" alt="Concluido">
                    </template>                    
                    <template v-slot:[`item.actions`]="{ item }">
                      <img @click="verTempo(item.id)" class="m-2" :width="25" src="../../assets/icons_aplicacao/Ver.png" alt="Ver">
                      <img v-if="item.concluido != true && item.stamp_intervencao == null" @click="navigateToTempoEditar({name: 'editartempostarefas', params:{idTempoEnviado: item.id}})" class="m-2" :width="25" src="../../assets/icons_aplicacao/Editar.png" alt="Editar">
                      <img v-if="item.concluido == true && item.stamp_intervencao == null" @click="navigateToTempoModificar({name: 'modificartempos', params:{idTempoEnviado: item.id}})" class="m-2" :width="25" src="../../assets/icons_aplicacao/Editar.png" alt="Editar">
                      <img v-if="item.stamp_intervencao == null" @click="apagarTempo(item)" class="m-2" :width="25" src="../../assets/icons_aplicacao/Apagar.png" alt="Apagar">
                      <img v-if="item.stamp_intervencao != null && item.intervencao_faturado == false" @click="apagarIntervencaoPat(item)" class="m-2" :width="25" src="../../assets/icons_aplicacao/Apagar_Intervencao.png" alt="Apagar_Intervencao">
                      <img v-if="item.stamp_intervencao == null && item.concluido == true" @click="verPatsCliente(item)" class="m-2" :width="25" src="../../assets/icons_aplicacao/Add_Intervencao.png" alt="Add_Intervencao">
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card-text> 
          </v-card>
        </v-dialog>
        <!-- Dialog ver tempo -->
        <v-dialog v-model="dialogVerTempo">
          <v-card>
            <v-toolbar class="mb-2 primary" dark flat>
              <v-toolbar-title>Visualizar tempo</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-toolbar-title><v-list-item @click="dialogVerTempo = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-form>
                <v-row>
                  <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                    <label>Início</label>
                    <v-text-field v-if="tempo.data_inicio != null" :value="apresentarData(tempo.data_inicio) + ' ' + apresentarHoras(tempo.data_inicio)" disabled filled outlined></v-text-field>
                    <v-text-field v-else disabled filled outlined></v-text-field>
                  </v-col>
                  <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                    <label>Fim</label>
                    <v-text-field v-if="tempo.data_fim != null" :value="apresentarData(tempo.data_fim) + ' ' + apresentarHoras(tempo.data_fim)" disabled filled outlined></v-text-field>
                    <v-text-field v-else disabled filled outlined></v-text-field>
                  </v-col>
                  <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                    <label>Tempo total</label>
                    <v-text-field v-if="tempo.tempo_efetivo != null" :value="apresentarHoras(tempo.tempo_efetivo)" disabled filled outlined></v-text-field>
                    <v-text-field v-else disabled filled outlined></v-text-field>
                  </v-col>
                  <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                    <label>Deslocação/Remoto/Telefone do tempo</label><br>
                    <v-icon v-if="tempo.deslocacao == 1" color="green" class="pr-2">mdi-car</v-icon>
                    <v-icon v-if="tempo.remoto == 1" color="green" class="pr-2">mdi-laptop</v-icon>
                    <v-icon v-if="tempo.telefone == 1" color="green" class="pr-2">mdi-phone</v-icon>
                  </v-col>
                  <v-col cols="12">
                    <label>Funcionário</label>
                    <v-text-field v-model="tempo.Funcionario.nome" disabled filled outlined></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <label>Descrição</label>
                    <wysiwyg v-model="tempo.descricao"/>
                  </v-col>
                  <v-col cols="12">
                    <label>Trabalho Realizado</label>
                    <wysiwyg v-model="tempo.trabalho_realizado"/>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!-- Dialog apagar tempo -->
        <v-dialog v-model="dialogApagarTempo">
          <v-card>
            <v-toolbar class="mb-2" color="red" dark flat>
                <v-toolbar-title></v-toolbar-title>
                <v-spacer>Apagar tempo</v-spacer>
                <v-toolbar-title><v-list-item @click="dialogApagarTempo = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
            </v-toolbar>
            <v-col cols="12">
                <span>Quer mesmo apagar o tempo?</span>
            </v-col>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" class="white--text" @click="confirmarApagarTempo">Apagar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- Dialog Criar Interveniente -->
        <v-dialog v-model="dialogCriarInterveniente">
          <v-card>
            <v-toolbar class="mb-2" color="purple" dark flat>
              <v-toolbar-title>Criar intervenientes</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-title><v-list-item @click="dialogCriarInterveniente = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
            </v-toolbar>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-list>
                    <v-list-item-group color="primary">
                      <div v-for="tec in tecnicos" :key="tec.id">
                        <v-divider></v-divider>
                        <v-list-item>
                          <template>
                            <v-list-item-action>
                              <v-checkbox v-model="intervenientesSelecionados" :value="tec"></v-checkbox>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>{{tec.nome}}</v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </v-list-item>
                      </div>
                    </v-list-item-group>
                  </v-list>
                </v-col>
              </v-row>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="purple" class="white--text" @click="confirmarcriarInterveniente">Criar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- Dialog apagar interveniente -->
        <v-dialog v-model="dialogApagarInterveniente">
          <v-card>
            <v-toolbar class="mb-2" color="red" dark flat>
                <v-toolbar-title>Apagar interveniente</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-title><v-list-item @click="dialogApagarInterveniente = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
            </v-toolbar>
            <v-col cols="12">
                <span>Quer mesmo apagar o interveniente?</span>
            </v-col>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" class="white--text" @click="confirmarApagarInterveniente">Apagar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- Dialog ver marcações -->
        <v-dialog v-model="dialogVerMarcacoes">
          <v-card>
            <v-toolbar class="mb-2 primary" dark flat>
              <v-toolbar-title>Marcações</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-title><v-list-item @click="dialogVerMarcacoes = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-row>
                <v-col cols="12 text-right" v-if="editarTarefas.concluido == false">
                  <img @click="navigateToMarcacaoCriar({name: 'criarmarcacoes'})" class="m-2" :width="35" src="../../assets/icons_aplicacao/Add_Marcacoes.png" alt="Add_Marcações">
                </v-col>
                <v-col cols="12">
                  <v-data-table class="elevation-1" :headers="headersMarcacoes" :items="marcacoes">
                    <template v-slot:[`item.descricao`]="{ item }">
                      <span v-if="item.descricao != null" v-html="item.descricao.substring(0,75)"></span>
                    </template>
                    <template v-slot:[`item.data_registo`]="{ item }">
                      {{ apresentarData(item.data_registo) }}
                    </template>
                    <template v-slot:[`item.Marcacoes_Funcionarios`]="{ item }">
                      <div v-for="func in item.Marcacoes_Funcionarios" :key="func.id">
                          {{ func.Funcionario.nome }}
                      </div>
                    </template>
                    <template v-slot:[`item.concluido`]="{ item }">
                      <img v-if="item.concluido == false" class="m-2" :width="25" src="../../assets/icons_aplicacao/Nao_Concluido.png" alt="Nao_Concluido">
                      <img v-else class="m-2" :width="25" src="../../assets/icons_aplicacao/Concluido.png" alt="Concluido">
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                      <img @click="navigateToMarcacaoEditar({name: 'editarmarcacoes', params:{idMarcacaoEnviado: item.id}})" class="m-2" :width="25" src="../../assets/icons_aplicacao/Editar.png" alt="Editar">
                      <img @click="apagarMarcacao(item.id)" class="m-2 d-none" :width="25" src="../../assets/icons_aplicacao/Apagar.png" alt="Apagar">
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card-text> 
          </v-card>
        </v-dialog>
        <!-- Dialog apagar marcacao -->
        <v-dialog v-model="dialogApagarMarcacao">
          <v-card>
            <v-toolbar class="mb-2" color="red" dark flat>
                <v-toolbar-title>Apagar marcação</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-title><v-list-item @click="dialogApagarMarcacao = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
            </v-toolbar>
            <v-col cols="12">
                <span>Quer mesmo apagar a marcação?</span>
            </v-col>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" class="white--text" @click="confirmarApagarMarcacao">Apagar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- Dialog ver projetos -->
        <v-dialog v-model="dialogVerProjetos">
          <v-card>
            <v-toolbar class="mb-2 primary" dark flat>
              <v-toolbar-title>Projeto</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-title><v-list-item @click="dialogVerProjetos = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-row>
                <v-col cols="12 text-right">
                  <img @click="verProjetosCliente" class="m-2" :width="35" src="../../assets/icons_aplicacao/Add_Projetos.png" alt="Add_Projetos">
                </v-col>
                <v-col cols="12">
                  <v-data-table class="elevation-1" :headers="headersProjetos" :items="projetos">
                    <template v-slot:[`item.numero`]="{ item }">
                      <span>{{ item.ano }} / {{ item.numero }}</span>
                    </template>
                    <template v-slot:[`item.descricao`]="{ item }">
                      <span v-if="item.descricao != null" v-html="item.descricao.substring(0,75)"></span>
                    </template>
                    <template v-slot:[`item.data_registo`]="{ item }">
                      {{ apresentarData(item.data_registo) }}
                    </template>
                    <template v-slot:[`item.Funcionarios`]="{ item }">
                      <div v-for="func in item.Funcionarios" :key="func.id">
                          {{ func.nome }}
                      </div>
                    </template>
                    <template v-slot:[`item.concluido`]="{ item }">
                      <img v-if="item.concluido == false" class="m-2" :width="25" src="../../assets/icons_aplicacao/Nao_Concluido.png" alt="Nao_Concluido">
                      <img v-else class="m-2" :width="25" src="../../assets/icons_aplicacao/Concluido.png" alt="Concluido">
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                      <img @click="navigateToProjetoEditar({name: 'editarprojetos', params:{idProjetoEnviado: item.id}})" class="m-2" :width="25" src="../../assets/icons_aplicacao/Editar.png" alt="Editar">
                      <img @click="apagarProjeto(item.id)" class="m-2 d-none" :width="25" src="../../assets/icons_aplicacao/Apagar.png" alt="Apagar">
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card-text> 
          </v-card>
        </v-dialog>
        <!-- Dialog ver projetos cliente -->
        <v-dialog v-model="dialogVerProjetosCliente">
          <v-card>
            <v-toolbar class="mb-2 primary" dark flat>
              <v-toolbar-title>Projetos cliente</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-title><v-list-item @click="dialogVerProjetosCliente = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-data-table class="elevation-1" :headers="headersProjetosCliente" :items="projetoscliente">
                    <template v-slot:[`item.numero`]="{ item }">
                      <span>{{ item.numero }} / {{ item.ano }}</span>
                    </template>
                    <template v-slot:[`item.descricao`]="{ item }">
                      <span v-if="item.descricao != null" v-html="item.descricao.substring(0,75)"></span>
                    </template>
                    <template v-slot:[`item.data_registo`]="{ item }">
                      {{ apresentarData(item.data_registo) }}
                    </template>
                    <template v-slot:[`item.Funcionarios`]="{ item }">
                      <div v-for="func in item.Funcionarios" :key="func.id">
                          {{ func.nome }}
                      </div>
                    </template>
                    <template v-slot:[`item.concluido`]="{ item }">
                      <img v-if="item.concluido == false" class="m-2" :width="25" src="../../assets/icons_aplicacao/Nao_Concluido.png" alt="Nao_Concluido">
                      <img v-else class="m-2" :width="25" src="../../assets/icons_aplicacao/Concluido.png" alt="Concluido">
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                      <img @click="adicionarProjeto(item.id)" class="m-2" :width="25" src="../../assets/icons_aplicacao/Add.png" alt="Add">
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card-text> 
          </v-card>
        </v-dialog>
        <!-- Dialog adicionar projeto -->
        <v-dialog v-model="dialogAdicionarProjeto">
          <v-card>
            <v-toolbar class="mb-2" color="orange" dark flat>
                <v-toolbar-title>Adicionar projeto</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-title><v-list-item @click="dialogAdicionarProjeto = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
            </v-toolbar>
            <v-col cols="12">
                <span>Quer mesmo adicionar projeto a esta tarefa?</span>
            </v-col>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="orange" class="white--text" @click="confirmarAdicionarProjeto">Adicionar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- Dialog apagar projeto -->
        <v-dialog v-model="dialogApagarProjeto">
          <v-card>
            <v-toolbar class="mb-2" color="red" dark flat>
                <v-toolbar-title>Apagar projeto</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-title><v-list-item @click="dialogApagarProjeto = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
            </v-toolbar>
            <v-col cols="12">
                <span>Quer mesmo apagar o projeto?</span>
            </v-col>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" class="white--text" @click="confirmarApagarProjeto">Apagar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- Dialog ver equipamentos -->
        <v-dialog v-model="dialogVerEquipamentos">
          <v-card>
            <v-toolbar class="mb-2 primary" dark flat>
              <v-toolbar-title>Equipamento</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-title><v-list-item @click="dialogVerEquipamentos = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-row>
                <v-col cols="12 text-right" v-if="editarTarefas.concluido == false">
                  <img @click="verEquipamentosCliente" class="m-2" :width="35" src="../../assets/icons_aplicacao/Add_Equipamentos.png" alt="Add_Equipamentos">
                </v-col>
                <v-col cols="12">
                  <v-data-table class="elevation-1" :headers="headersEquipamentos" :items="equipamentos">
                    <template v-slot:[`item.data_venda`]="{ item }">
                      {{ apresentarData(item.data_venda) }}
                    </template>
                    <template v-slot:[`item.data_fim_garantia`]="{ item }">
                      {{ apresentarData(item.data_fim_garantia) }}
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card-text> 
          </v-card>
        </v-dialog>
        <!-- Dialog ver equipamentos cliente -->
        <v-dialog v-model="dialogVerEquipamentosCliente">
          <v-card>
            <v-toolbar class="mb-2 primary" dark flat>
              <v-toolbar-title>Equipamentos cliente</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-title><v-list-item @click="dialogVerEquipamentosCliente = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-data-table class="elevation-1" :headers="headersEquipamentosCliente" :items="equipamentoscliente">
                    <template v-slot:[`item.data_venda`]="{ item }">
                      {{ apresentarData(item.data_venda) }}
                    </template>
                    <template v-slot:[`item.data_fim_garantia`]="{ item }">
                      {{ apresentarData(item.data_fim_garantia) }}
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                      <img @click="adicionarEquipamento(item.id)" class="m-2" :width="25" src="../../assets/icons_aplicacao/Add.png" alt="Add">
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card-text> 
          </v-card>
        </v-dialog>
        <!-- Dialog adicionar equipamento -->
        <v-dialog v-model="dialogAdicionarEquipamento">
          <v-card>
            <v-toolbar class="mb-2" color="orange" dark flat>
                <v-toolbar-title>Adicionar equipamento</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-title><v-list-item @click="dialogAdicionarEquipamento = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
            </v-toolbar>
            <v-col cols="12">
                <span>Quer mesmo adicionar equipamento a esta tarefa?</span>
            </v-col>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="orange" class="white--text" @click="confirmarAdicionarEquipamento">Adicionar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- Dialog ver pats -->
        <v-dialog v-model="dialogVerPats">
          <v-card>
            <v-toolbar class="mb-2 primary" dark flat>
              <v-toolbar-title>Pats tarefa</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-title><v-list-item @click="dialogVerPats = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-data-table class="elevation-1" :headers="headersPats" :items="pats">
                    <template v-slot:[`item.actions`]="{ item }">
                      <img v-if="item.fechado == false" @click="editarPat(item)" class="m-2" :width="25" src="../../assets/icons_aplicacao/Editar.png" alt="Editar">
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card-text> 
          </v-card>
        </v-dialog>
        <!-- Dialog ver pats cliente -->
        <v-dialog v-model="dialogVerPatsCliente">
          <v-card>
            <v-toolbar class="mb-2 primary" dark flat>
              <v-toolbar-title>Pats abertas do cliente</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-title><v-list-item @click="dialogVerPatsCliente = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-row>
                <v-col cols="8">
                  <label>Faturar intervenção</label>
                  <v-switch v-model="criarIntervencao.faturar" color="primary" inset></v-switch>
                </v-col>
                <v-col cols="4 text-right" v-if="editarTarefas.id_equipamento != null">
                  <img @click="criarPatIntervencao" class="m-2" :width="35" src="../../assets/icons_aplicacao/Add_Pats.png" alt="Add_Pats">
                </v-col>
                <v-col cols="4 text-right" v-if="editarTarefas.id_equipamento == null">
                  <span>Tarefa sem equipamento não podes criar pat</span>
                </v-col>
                <v-col cols="12">
                  <v-data-table class="elevation-1" :headers="headersPatsCliente" :items="patscliente">
                    <template v-slot:[`item.actions`]="{ item }">
                      <img v-if="item.fechado == false" @click="editarPat(item)" class="m-2" :width="25" src="../../assets/icons_aplicacao/Editar.png" alt="Editar">
                      <img @click="adicionarIntervencao(item)" class="m-2" :width="25" src="../../assets/icons_aplicacao/Add.png" alt="Add">
                      <img v-if="item.fechado == false && item.tem_intervencoes == false" @click="apagarPat(item)" class="m-2" :width="25" src="../../assets/icons_aplicacao/Apagar.png" alt="Apagar">
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card-text> 
          </v-card>
        </v-dialog>
        <!-- Dialog adicionar intervenção -->
        <v-dialog v-model="dialogAdicionarIntervencao">
          <v-card>
            <v-toolbar class="mb-2" color="orange" dark flat>
                <v-toolbar-title>Adicionar intervenção</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-title><v-list-item @click="dialogAdicionarIntervencao = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
            </v-toolbar>
            <v-col cols="12">
                <span>Quer mesmo adicionar intervenção a esta pat?</span>
            </v-col>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="orange" class="white--text" @click="confirmarAdicionarIntervencao">Adicionar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- Dialog apagar intervenção -->
        <v-dialog v-model="dialogApagarIntervencao">
          <v-card>
            <v-toolbar class="mb-2" color="red" dark flat>
                <v-toolbar-title>Apagar intervenção do erp</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-title><v-list-item @click="dialogApagarIntervencao = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
            </v-toolbar>
            <v-col cols="12">
                <span>Quer mesmo apagar a intervenção do erp que está nesta pat?</span>
            </v-col>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" class="white--text" @click="confirmarApagarIntervencao">Apagar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- Dialog editar pat -->
        <v-dialog v-model="dialogEditarPat">
          <v-card>
            <v-toolbar class="mb-2" color="green" dark flat>
                <v-toolbar-title>Editar pat</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-title><v-list-item @click="dialogEditarPat = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
            </v-toolbar>
            <v-col cols="12">
                <label>Estado</label>
                <v-select v-model="editarPats.estado_pat" item-text="descricao" item-value="descricao" :items="estadospats" filled outlined></v-select>
            </v-col>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green" class="white--text" @click="confirmarEditarPat">Guardar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- Dialog criar pat -->
        <v-dialog v-model="dialogCriarPatIntervencao">
          <v-card>
            <v-toolbar class="mb-2" color="purple" dark flat>
                <v-toolbar-title>Criar pat</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-title><v-list-item @click="dialogCriarPatIntervencao = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
            </v-toolbar>
            <v-col cols="12">
                <label>Equipamento</label>
                <v-text-field v-model="criarPatsIntervencao.stamp_equipamento" disabled filled outlined></v-text-field>
            </v-col>
            <v-col cols="12">
                <label>Estado</label>
                <v-select v-model="criarPatsIntervencao.estado_pat" item-text="descricao" item-value="descricao" :items="estadospats" filled outlined></v-select>
            </v-col>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="purple" class="white--text" @click="confirmarCriarPatIntervencao">Criar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- Dialog apagar pat -->
        <v-dialog v-model="dialogApagarPat">
          <v-card>
            <v-toolbar class="mb-2" color="red" dark flat>
                <v-toolbar-title>Apagar pat do erp</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-title><v-list-item @click="dialogApagarPat = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
            </v-toolbar>
            <v-col cols="12">
                <span>Quer mesmo apagar a pat do erp?</span>
            </v-col>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" class="white--text" @click="confirmarApagarPat">Apagar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- Dialog ver artigos -->
        <v-dialog v-model="dialogVerArtigosTarefas">
          <v-card>
            <v-toolbar class="mb-2 primary" dark flat>
              <v-toolbar-title>Artigos</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-title><v-list-item @click="dialogVerArtigosTarefas = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
            </v-toolbar>
            <v-col cols="12 text-right">
              <img @click="AdicionarArtigoTarefa" class="m-2" :width="35" src="../../assets/icons_aplicacao/Add_Artigos.png" alt="Add_Artigos">
            </v-col>
            <v-col cols="12">
              <v-data-table class="elevation-1" :headers="headersArtigosTarefas" :items="artigostarefa">
                <template v-slot:[`item.descricao`]="{ item }">
                  <span v-if="item.descricao != null" v-html="item.descricao.substring(0,75)"></span>
                </template>
                
                <template v-slot:[`item.preco_sem_iva`]="{ item }">
                  {{ formatNumeroEuro(item.preco_sem_iva) }}
                </template>
                <template v-slot:[`item.preco_com_iva`]="{ item }">
                  {{ formatNumeroEuro(item.preco_com_iva) }}
                </template>
                <template v-slot:[`item.total_linha_sem_iva`]="{ item }">
                  {{ formatNumeroEuro(item.total_linha_sem_iva) }}
                </template>
                <template v-slot:[`item.total_linha_com_iva`]="{ item }">
                  {{ formatNumeroEuro(item.total_linha_com_iva) }}
                </template>
                <template v-slot:[`item.iva`]="{ item }">
                  {{ item.iva }} %
                </template>
                <template v-slot:[`item.desconto`]="{ item }">
                  {{ item.desconto }} %
                </template>
                <template v-slot:[`item.tipo_stock`]="{ item }">
                  <v-btn v-if="item.tipo_stock == 'Saída'" style="width: 75% !important;" color="red" rounded>{{ item.tipo_stock }}</v-btn>
                  <v-btn v-else color="green" style="width: 75% !important;" rounded>{{ item.tipo_stock }}</v-btn>
                </template>
                <template v-slot:[`item.propriedade`]="{ item }">
                  <img v-if="item.propriedade == false" class="m-2" :width="25" src="../../assets/icons_aplicacao/Nao_Concluido.png" alt="Sem propriedade">
                  <img v-else class="m-2" :width="25" src="../../assets/icons_aplicacao/Concluido.png" alt="Com propriedade">
                </template>
                <template v-slot:[`item.inativo`]="{ item }">
                  <img v-if="item.inativo == true" class="m-2" :width="25" src="../../assets/icons_aplicacao/Nao_Concluido.png" alt="Nao_Concluido">
                  <img v-else class="m-2" :width="25" src="../../assets/icons_aplicacao/Concluido.png" alt="Concluido">
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <img v-if="item.documento_erp == null" @click="editarArtigoTarefa(item.id)" class="m-2" :width="25" src="../../assets/icons_aplicacao/Editar.png" alt="Editar">
                  <img v-if="item.documento_erp == null" @click="apagarArtigoTarefa(item.id)" class="m-2" :width="25" src="../../assets/icons_aplicacao/Apagar.png" alt="Apagar">
                </template>
              </v-data-table>
            </v-col>
          </v-card>
        </v-dialog>
        <!-- Dialog adicionar artigo tarefa -->
        <v-dialog v-model="dialogAdicionarArtigoTarefa">
          <v-card>
            <v-toolbar class="mb-2" color="orange" dark flat>
                <v-toolbar-title>Adicionar artigo tarefa</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-title><v-list-item @click="dialogAdicionarArtigoTarefa = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <v-form ref="form">
                    <v-row>
                      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                          <label>Tipo de stock</label>
                          <v-select v-model="adicionarArtigosTarefas.tipo_stock" item-text="text" item-value="value" :items="tipos_stock" filled outlined :rules="[rules.tipostock]"></v-select>
                      </v-col>
                      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                          <label>Referência</label>
                          <v-autocomplete v-model="adicionarArtigosTarefas.id_artigo" :items="artigos" item-text="codigo" item-value="id" clearable filled outlined @change="artigoTarefaReferencia"></v-autocomplete>
                      </v-col>
                      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 6">
                        <label>Descrição</label>
                        <v-autocomplete v-model="adicionarArtigosTarefas.id_artigo" :items="artigos" item-text="descricao" item-value="id" clearable filled outlined @change="artigoTarefaDescricao"></v-autocomplete>
                      </v-col>
                      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                          <label>Qtd</label>
                          <v-text-field v-model="adicionarArtigosTarefas.qtd" @blur="atualizarQuantidade" filled outlined></v-text-field>
                      </v-col>
                      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                          <label>Uni</label>
                          <v-text-field v-model="adicionarArtigosTarefas.unidade" filled outlined disabled></v-text-field>
                      </v-col>
                      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                          <label>Sem Iva (€)</label>
                          <v-text-field v-model="adicionarArtigosTarefas.preco_sem_iva" @blur="atualizarSemIva" filled outlined></v-text-field>
                      </v-col>
                      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                          <label>Iva (%)</label>
                          <v-text-field v-model="adicionarArtigosTarefas.iva" filled outlined disabled></v-text-field>
                      </v-col>
                      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                          <label>Com Iva (€)</label>
                          <v-text-field v-model="adicionarArtigosTarefas.preco_com_iva" @blur="atualizarComIva" filled outlined></v-text-field>
                      </v-col>
                      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                          <label>Desconto (%)</label>
                          <v-text-field v-model="adicionarArtigosTarefas.desconto" @blur="atualizarDesconto" filled outlined></v-text-field>
                      </v-col>
                      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                          <label>Total sem Iva (€)</label>
                          <v-text-field v-model="adicionarArtigosTarefas.total_linha_sem_iva" filled outlined></v-text-field>
                      </v-col>
                      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 2">
                          <label>Total com Iva (€)</label>
                          <v-text-field v-model="adicionarArtigosTarefas.total_linha_com_iva" filled outlined></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="orange" class="white--text" @click="confirmarAdicionarArtigoTarefa">Adicionar</v-btn>
                        </v-card-actions>
                      </v-col>
                    </v-row>
                </v-form>
            </v-card-text>       
          </v-card>
        </v-dialog>
        <!-- Dialog apagar artigo tarefa -->
        <v-dialog v-model="dialogApagarArtigoTarefa">
          <v-card>
            <v-toolbar class="mb-2" color="red" dark flat>
                <v-toolbar-title>Apagar artigo tarefa</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-title><v-list-item @click="dialogApagarArtigoTarefa = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
            </v-toolbar>
            <v-col cols="12">
                <span>Quer mesmo apagar o artigo da tarefa?</span>
            </v-col>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" class="white--text" @click="confirmarApagarArtigoTarefa">Apagar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- Dialog editar artigo tarefa -->
        <v-dialog v-model="dialogEditarArtigoTarefa">
          <v-card>
            <v-toolbar class="mb-2" color="green" dark flat>
                <v-toolbar-title>Editar artigo tarefa</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-title><v-list-item @click="dialogEditarArtigoTarefa = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
            </v-toolbar>
            <v-col cols="12">
                <label>Descrição</label>
                <v-text-field v-model="editarArtigosTarefas.descricao" filled outlined></v-text-field>
            </v-col>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green" class="white--text" @click="confirmarEditarArtigoTarefa">Editar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </v-flex>
  </v-layout>
</template> 
<script>
//  Importar o service necessário
import TarefasService from '@/services/TarefasService'
import EstadosService from '@/services/EstadosService'
import TemposService from '@/services/TemposService'
import TopicosService from '@/services/TopicosService'
import FuncionariosService from '@/services/FuncionariosService'
import TarefasObsService from '@/services/TarefasObsService'
import TarefasFuncionarioService from '@/services/TarefasFuncionarioService'
import MarcacoesService from '@/services/MarcacoesService'
import ProjetosService from '@/services/ProjetosService'
import EquipamentosService from '@/services/EquipamentosService'
import PatsService from '@/services/PatsService'
import EstadosPatsService from '@/services/EstadosPatsService'
import TarefasArtigosService from '@/services/TarefasArtigosService'
import ArtigosService from '@/services/ArtigosService'
import store from '@/store/store'
import moment from 'moment-timezone'

export default {
  async mounted () {
    if (!store.state.isUserLoggedIn) {
      this.$router.push({
        name: 'mylogin'
      })
    } else if (this.$store.state.tarefa == null || this.$store.state.tarefa.idtarefa == null) {
      this.$router.push({
        name: 'tarefas'
      })
    } else {
      this.editarTarefas = (await TarefasService.show(this.$store.state.tarefa.idtarefa)).data
      this.estados = (await EstadosService.index()).data
      this.topicos = (await TopicosService.index()).data
      this.tecnicos = (await FuncionariosService.index(false, ' ', null)).data
      if (this.editarTarefas.concluido != true) {
        this.todosIntervenientesTerminados()
      }
    }
  },
  data () {
    return {
      fab: false,
      estados: [],
      estadospats: [],
      topicos: [],
      tecnicos: [],
      observacoes: [],
      tempos: [],
      marcacoes: [],
      projetos: [],
      artigostarefa: [],
      artigos: [],
      artigo: {},
      projetoscliente: [],
      equipamentos: [],
      equipamentoscliente: [],
      pats: [],
      patscliente: [],
      tipos_stock: [
        {text: 'Saída', value: 'Saída'},
        {text: 'Entrada', value: 'Entrada'}
      ],
      tempo: {
        Funcionario: {}
      },
      intervenientesSelecionados: [],
      verIntervenientes: [],
      observacaoemail: {},
      editarTarefas: {
        numero_cliente: null,
        nome_cliente: null,
        quemRegistou: {},
        data_registo: null,
        quem_pediu: null,
        como_pediu: null,
        descricao: null,
        grau_urgencia: null,
        id_topico: null,
        id_estado: null,
        id_projeto: null,
        id_equipamento: null,
        data_inicio: null,
        data_fim: null,
        numero_pat: null
      },
      criarObservacoes: {
          descricao: '',
          quem_registou: this.$store.state.user.id,
          data_registo: this.formatarDataMoment(Date.now()),
          id_tarefa: null
      },
      editarObservacoes: {
          descricao: null,
          Funcionario: {}
      },
      criarIntervenientes: {
        id_tarefa: this.$store.state.tarefa.idtarefa,
        id_funcionario: null,
        data_registo: this.formatarDataMoment(Date.now()),
        quem_registou: this.$store.state.user.id,
      },
      editarIntervenientes: {
          concluido: null,
          concluido_data: null
      },
      editarFuncionarios: {
          telefone: null,
          remoto: null,
          deslocacao: null,
          estado: null
      },
      criarIntervencao: {
        stamp_pat: null,
        numero_tecnico: this.$store.state.user.numero_tecnico,
        relatorio: null,
        tipo_intervencao: null,
        faturar: true,
        id_tempo: null,
        id_tarefa: this.$store.state.tarefa.idtarefa,   
      },
      criarPatsIntervencao: {
        stamp_equipamento: null,
        estado_pat: null,
        numero_tecnico: this.$store.state.user.numero_tecnico,
        problema: null,
        numero_cliente: null,
        tipo_intervencao: null,
        faturar: null,
        id_tempo: null,
        id_tarefa: null,
        relatorio: null
      },
      criarEquipamentos: {
          serial: null,
          serial2: null,
          id_equipamentos_tipo: null,
          id_equipamentos_marca: null,
          id_equipamentos_modelo: null,
          data_venda: null,
          data_fim_garantia: null,
          numero_cliente: null,
          descricao: null,
          inativo: null,
          local: null,
      },
      tempoApagarIntervencao: {
        id_tempo: null,
        stamp_intervencao: null,
        id_tarefa: this.$store.state.tarefa.idtarefa,
        stamp_pat: null
      },
      editarPats: {
        estado_pat: null
      },
      adicionarArtigosTarefas: {
          tipo_stock: null,
          codigo_artigo: null,
          id_artigo: null,
          qtd: null,
          unidade: null,
          preco_sem_iva: null,
          iva: null,
          preco_com_iva: null,
          desconto: null,
          total_linha_sem_iva: null,
          total_linha_com_iva: null,
          descricao: '',
          quem_registou: this.$store.state.user.id,
          data_registo: this.formatarDataMoment(Date.now()),
          id_tarefa: null
      },
      editarArtigosTarefas: {
          descricao: null
      },
      dialogVerObsEmailEnviado: false,
      dialogVerObservacoes: false,
      dialogVerArtigosTarefas: false,
      dialogAdicionarArtigoTarefa: false,
      dialogApagarArtigoTarefa: false,
      dialogEditarArtigoTarefa: false,
      dialogCriarObservacao: false,
      dialogApagarObservacao: false,
      dialogEditarObservacao: false,
      dialogCriarInterveniente: false,
      dialogApagarInterveniente: false,
      dialogVerTempos: false,
      dialogVerTempo: false,
      dialogApagarTempo: false,
      dialogVerMarcacoes: false,
      dialogApagarMarcacao: false,
      dialogVerProjetos: false,
      dialogVerProjetosCliente: false,
      dialogAdicionarProjeto: false,
      dialogApagarProjeto: false,
      dialogVerEquipamentos: false,
      dialogVerEquipamentosCliente: false,
      dialogAdicionarEquipamento: false,
      dialogVerPats: false,
      dialogVerPatsCliente: false,
      dialogAdicionarIntervencao: false,
      dialogApagarIntervencao: false,
      dialogEditarPat: false,
      dialogCriarPatIntervencao: false,
      dialogApagarPat: false,
      

      idtarefaglobal: '',
      idobservacaoglobal: '',
      idintervenienteglobal: '',
      idtempoglobal: '',
      idmarcacaoglobal: '',
      idprojetoglobal: '',
      idequipamentoglobal: '',
      idartigotarefaglobal: '',
      patglobal: '',

      totaltempostarefas: '',

      headersObservacoes: [
        { text: "Email", value: "enviado_mail", sortable: true },
        { text: "Data Registo", value: "data_registo", sortable: true },
        { text: "Descrição", value: "descricao", sortable: true },
        { text: "Funcionário", value: "Funcionario.nome", sortable: true },
        { text: 'Ações', value: 'actions', sortable: false },
      ],
      headersTempos: [
        { text: "Funcionário", value: "Funcionario.nome", sortable: false },
        { text: "Descrição", value: "descricao", sortable: false },
        { text: "Trabalho Realizado", value: "trabalho_realizado", sortable: false },
        { text: "Inicio", value: "data_inicio", sortable: false },
        { text: "Fim", value: "data_fim", sortable: false },
        { text: "Tempo", value: "tempo_efetivo", sortable: false },
        { text: "P.A.T", value: "numero_pat", sortable: false },
        { text: "Concluido", value: "concluido", sortable: true },
        { text: 'Ações', value: 'actions', sortable: false },
      ],
      headersMarcacoes: [
        { text: "Descrição da Marcacao", value: "descricao", sortable: true },
        { text: "Funcionarios", value: "Marcacoes_Funcionarios", sortable: true },
        { text: "Data Registo", value: "data_registo", sortable: true },
        { text: "Estado", value: "Estado.descricao", sortable: true },
        { text: "Concluido", value: "concluido", sortable: true },
        { text: 'Ações', value: 'actions', sortable: false },
      ],
      headersProjetos: [
        { text: "Projeto", value: "numero", sortable: false },
        { text: "Descrição do projeto", value: "descricao", sortable: true },
        { text: "Funcionarios", value: "Funcionarios", sortable: true },
        { text: "Data Registo", value: "data_registo", sortable: true },
        { text: "Estado", value: "Estado.descricao", sortable: true },
        { text: "Concluido", value: "concluido", sortable: true },
        { text: 'Ações', value: 'actions', sortable: false },
      ],

      headersProjetosCliente: [
        { text: "Projeto", value: "numero", sortable: false },
        { text: "Descrição do projeto", value: "descricao", sortable: true },
        { text: "Funcionarios", value: "Funcionarios", sortable: true },
        { text: "Data Registo", value: "data_registo", sortable: true },
        { text: "Estado", value: "Estado.descricao", sortable: true },
        { text: "Concluido", value: "concluido", sortable: true },
        { text: 'Ações', value: 'actions', sortable: false },
      ],

      headersEquipamentos: [
        { text: "Nº Série ", value: "serial", sortable: true },
        { text: "Descrição Equipamento", value: "descricao", sortable: true },
        { text: "Marca", value: "Equipamentos_Marca.nome", sortable: true },
        { text: "Modelo", value: "Equipamentos_Modelo.nome", sortable: true },
        { text: 'Tipo', value: 'Equipamentos_Tipo.nome', sortable: true },
        { text: 'Data Venda', value: 'data_venda', sortable: true },
        { text: 'Fim Grantia', value: 'data_fim_garantia', sortable: true },
        { text: 'Local', value: 'local', sortable: true },
      ],

      headersEquipamentosCliente: [
        { text: "Nº Série ", value: "serial", sortable: true },
        { text: "Descrição Equipamento", value: "descricao", sortable: true },
        { text: "Marca", value: "Equipamentos_Marca.nome", sortable: true },
        { text: "Modelo", value: "Equipamentos_Modelo.nome", sortable: true },
        { text: 'Tipo', value: 'Equipamentos_Tipo.nome', sortable: true },
        { text: 'Data Venda', value: 'data_venda', sortable: true },
        { text: 'Fim Grantia', value: 'data_fim_garantia', sortable: true },
        { text: 'Local', value: 'local', sortable: true },
        { text: 'Ações', value: 'actions', sortable: false },
      ],

      headersPats: [
        { text: "Nº PAT", value: "numero_pat", sortable: true },
        { text: "Cliente", value: "nome_cliente", sortable: true }, 
        { text: "Recebido por", value: "recebido_por", sortable: true },
        { text: "Equipamento", value: "equipamento", sortable: true },
        { text: "Nº série", value: "equipamento_serie", sortable: true },
        { text: "Tipo de assistência", value: "tipo_pat", sortable: true },
        { text: "Estado PAT", value: "estado_pat", sortable: true },
        { text: "Problema", value: "problema", sortable: true },
        { text: 'Ações', value: 'actions', sortable: false },
      ],

      headersPatsCliente: [
        { text: "Nº PAT", value: "numero_pat", sortable: true },
        { text: "Cliente", value: "nome_cliente", sortable: true },
        { text: "Recebido por", value: "recebido_por", sortable: true },
        { text: "Equipamento", value: "equipamento", sortable: true },
        { text: "Nº série", value: "equipamento_serie", sortable: true },
        { text: "Tipo de assistência", value: "tipo_pat", sortable: true },
        { text: "Estado PAT", value: "estado_pat", sortable: true },
        { text: "Problema", value: "problema", sortable: true },
        { text: 'Ações', value: 'actions', sortable: false },
      ],

      headersArtigosTarefas: [
        { text: "Referência", value: "codigo_artigo", sortable: false },
        { text: "Descricao", value: "descricao", sortable: true },
        { text: "Qtd", value: "qtd", sortable: true },
        { text: "Uni", value: "unidade", sortable: true },
        { text: "Sem Iva (€)", value: "preco_sem_iva", sortable: true },
        { text: "Iva", value: "iva", sortable: true },
        { text: "Com Iva (€)", value: "preco_com_iva", sortable: true },
        { text: "Desc (%)", value: "desconto", sortable: true },
        { text: "Total sem iva (€)", value: "total_linha_sem_iva", sortable: true },
        { text: "Total com iva (€)", value: "total_linha_com_iva", sortable: true },
        { text: "Tipo de stock", value: "tipo_stock", sortable: true },
        { text: "Documento ERP", value: "documento_erp", sortable: true },
        { text: "Ações", value: "actions", sortable: true }
      ],
      
      rules: {
        datamenor: value => {
          if (this.editarTarefas.data_fim) {
            if (value > this.editarTarefas.data_fim) {
              return "Data fim deve ser maior que a data de início.";
            }
          }
          return true;
        },
        tipostock: value => {
          if (!value) {
            return "O campo tipo de stock é obrigatório.";
          }
          return true;
        }
      }
    }
  },
  methods: {
    formatNumeroEuro(numero) {
      const euro = new Intl.NumberFormat('pt-PT', {
          style: 'currency',
          currency: 'EUR',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
          useGrouping: true, // Adiciona espaços de milhares
      });
      return euro.format(numero);
    },
    // formatar data
    formatarDataMoment(data) {
      return moment(data).tz('Europe/Lisbon').format('YYYY-MM-DDTHH:mm:ss[Z]');
    },
    apresentarData (date) {
      const options = {year: 'numeric', month: 'numeric', day: 'numeric'}
      return new Date(date).toLocaleDateString('pt-PT', options)
    },
    apresentarHoras (date) {
      return new Date(date).toJSON().slice(11, 19)
    },
    navigateToTempoCriar (route) {
      this.$store.dispatch('setTempoTarefa', {  
        idtarefatempo: route.params.idTarefaEnviado,
      })
      this.$router.push(route)
    },
    navigateToTempoEditar (route) {
      this.$store.dispatch('setTempo', {  
          idtempo: route.params.idTempoEnviado,
          finalizadomobile: 1
      })
      this.$router.push(route)
    },
    navigateToTempoModificar (route) {
      this.$store.dispatch('setTempo', {  
          idtempo: route.params.idTempoEnviado,
          finalizadomobile: null
      })
      this.$router.push(route)
    },
    navigateToMarcacaoCriar (route) {
      this.$store.dispatch('setMarcacaoTarefa', {  
          tarefamarcacao: this.editarTarefas
      })
      this.$router.push(route)
    },
    navigateToMarcacaoEditar (route) {
      this.$store.dispatch('setMarcacao', {  
        idmarcacao: route.params.idMarcacaoEnviado,
      })
      this.$router.push(route)
    },
    navigateToProjetoEditar (route) {
      this.$store.dispatch('setProjeto', {  
        idprojeto: route.params.idProjetoEnviado,
      })
      this.$router.push(route)
    },
    //-------------------------------------------tarefas------------------------------//
    //-------------------------------------------tarefas------------------------------//
    //-------------------------------------------tarefas------------------------------//
    async editarTarefa () {
      try{
        if (this.$refs.form.validate()) {
          await TarefasService.put(this.editarTarefas)
          this.editarTarefas = (await TarefasService.show(this.$store.state.tarefa.idtarefa)).data
          this.$store.dispatch("setSnackBar", {
              color: "success",
              text: "Tarefa editada com sucesso"
          });
        }
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a editar tarefa"
          });
      }
    },
    async simTarefaTerminada () {
      try{
        if (this.$refs.form.validate()) {
          this.editarTarefas.concluido = true
          this.editarTarefas.concluido_data = this.formatarDataMoment(Date.now()),
          this.editarTarefas.id_estado = 6
          await TarefasService.put(this.editarTarefas)
          this.editarTarefas = (await TarefasService.show(this.$store.state.tarefa.idtarefa)).data
          this.$store.dispatch("setSnackBar", {
              color: "success",
              text: "Tarefa terminada com sucesso"
          });
        }
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a terminar a tarefa"
          });
      }
    },
    async todosIntervenientesTerminados () {
      //ver se os intervenientes estão todos concluidos
      this.verIntervenientes = (await TarefasFuncionarioService.index(this.$store.state.tarefa.idtarefa)).data
      var intervconcluido = 0
      for (var vi = 0; vi < this.verIntervenientes.length; vi++) {
        if (this.verIntervenientes[vi].concluido == true) {
          intervconcluido = intervconcluido + 1
        }
        if (intervconcluido == this.verIntervenientes.length) {
          this.simTarefaTerminada()
        }
      }
    },
    async todosIntervenientesNaoTerminados () {
      //colocar a tareda como não concluida pois tiramos algum interveniente de concluido
      this.verIntervenientes = (await TarefasFuncionarioService.index(this.$store.state.tarefa.idtarefa)).data
      var intervconcluido = 0
      for (var vi = 0; vi < this.verIntervenientes.length; vi++) {
        if (this.verIntervenientes[vi].concluido == true) {
          intervconcluido = intervconcluido + 1
        }
        if (intervconcluido != this.verIntervenientes.length) {
          this.editarTarefas.concluido = false
          this.editarTarefas.concluido_data = null
          this.editarTarefas.id_estado = 1
          await TarefasService.put(this.editarTarefas)
          this.editarTarefas = (await TarefasService.show(this.$store.state.tarefa.idtarefa)).data
        }
      }
    },
    //-------------------------------------------observações------------------------------//
    //-------------------------------------------observações------------------------------//
    //-------------------------------------------observações------------------------------//
    async verObservacoes () {
      this.dialogVerObservacoes = true
      this.observacoes = (await TarefasObsService.index(this.$store.state.tarefa.idtarefa)).data
    },
    async verObsEmailEnviado (id) {
      this.dialogVerObsEmailEnviado = true
      this.observacaoemail = (await TarefasObsService.show(id)).data
    },
    async criarObservacao () {
        this.dialogCriarObservacao = true
    },
    async confirmarCriarObservacao () {
      try{
          this.criarObservacoes.id_tarefa = this.$store.state.tarefa.idtarefa
          await TarefasObsService.post(this.criarObservacoes)
          this.dialogCriarObservacao = false
          this.observacoes = (await TarefasObsService.index(this.$store.state.tarefa.idtarefa)).data
          this.$store.dispatch("setSnackBar", {
              color: "success",
              text: "Observação criada com sucesso"
          });
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a criar observação"
          });
      }
    },
    async apagarObservacao (id) {
      this.dialogApagarObservacao = true
      this.idobservacaoglobal = id
    },
    async confirmarApagarObservacao () {
      try{
          await TarefasObsService.delete(this.idobservacaoglobal)
          this.dialogApagarObservacao = false
          this.observacoes = (await TarefasObsService.index(this.$store.state.tarefa.idtarefa)).data
          this.$store.dispatch("setSnackBar", {
              color: "success",
              text: "Observação apagada com sucesso"
          });
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a apagar observação"
          });
      }
    },
    async editarObservacao (id) {
        this.dialogEditarObservacao = true
        this.editarObservacoes = (await TarefasObsService.show(id)).data
    },
    async confirmarEditarObservacao () {
      try{
        await TarefasObsService.put(this.editarObservacoes)
        this.dialogEditarObservacao = false
        this.observacoes = (await TarefasObsService.index(this.$store.state.tarefa.idtarefa)).data
          this.$store.dispatch("setSnackBar", {
              color: "success",
              text: "Observação editada com sucesso"
          });
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a editar observação"
          });
      }
    },
    //-------------------------------------------tempos------------------------------//
    //-------------------------------------------tempos------------------------------//
    //-------------------------------------------tempos------------------------------//
    async verTempos () {
      this.idtarefaglobal = this.$store.state.tarefa.idtarefa
      this.dialogVerTempos = true
      this.tempos = (await TemposService.index(this.$store.state.tarefa.idtarefa)).data
      var totalSegundos = 0; // Inicializa o total de segundos

      for (var tt = 0; tt < this.tempos.length; tt++) {
        var tempoefetivo = this.apresentarHoras(this.tempos[tt].tempo_efetivo);
        var partesTempo = tempoefetivo.split(':'); // Divide o tempo em partes (horas, minutos, segundos)
        var horas = parseInt(partesTempo[0], 10);
        var minutos = parseInt(partesTempo[1], 10);
        var segundos = parseInt(partesTempo[2], 10);

        // Converte tudo para segundos e soma
        totalSegundos += horas * 3600 + minutos * 60 + segundos;
      }

      // Converte o total de segundos de volta para o formato de tempo desejado
      var totalFormatado = this.formatarTempo(totalSegundos);

      this.totaltempostarefas = totalFormatado;
    },
    
    async verTempo (idTempo) {
      this.dialogVerTempo = true
      this.tempo = (await TemposService.show(idTempo)).data
    },

    // Função para formatar o tempo no formato HH:mm:ss
    formatarTempo(totalSegundos) {
      var horas = Math.floor(totalSegundos / 3600);
      var minutos = Math.floor((totalSegundos % 3600) / 60);
      var segundos = totalSegundos % 60;

      return horas.toString().padStart(2, '0') + ':' + minutos.toString().padStart(2, '0') + ':' + segundos.toString().padStart(2, '0');
    },
    async apagarTempo (item) {
      this.dialogApagarTempo = true
      this.idtempoglobal = item.id
    },
    async confirmarApagarTempo () {
      try{
        await TemposService.delete(this.idtempoglobal)
        this.dialogApagarTempo = false
        this.verTempos()

        //ver se este utilizador tem tempos abertos, se não tiver mete na tabela dos Funcionarios telefone, remoto, deslocao a false, Livre e cliente vazio, se não for vazio mete na coluna cliente os clientes que estão a ser tratados
        this.editarFuncionarios = (await FuncionariosService.show(this.$store.state.user.id)).data
        const temposabertos = (await TemposService.index(null, null, null, this.$store.state.user.id, null, false)).data
        if (temposabertos == '') {
            this.editarFuncionarios.telefone = false
            this.editarFuncionarios.remoto = false
            this.editarFuncionarios.deslocacao = false
            this.editarFuncionarios.estado = 'Livre'
            this.editarFuncionarios.cliente = ''
            await FuncionariosService.put(this.editarFuncionarios)
        } else {
            this.editarFuncionarios.cliente = ''
            await FuncionariosService.put(this.editarFuncionarios)
            for (var i = 0; i < temposabertos.length; i++) {
                this.editarFuncionarios.cliente = this.editarFuncionarios.cliente + temposabertos[i].nome_cliente + '; '
            }  
            await FuncionariosService.put(this.editarFuncionarios) 
        }

        this.$store.dispatch("setSnackBar", {
            color: "success",
            text: "Tempo apagado com sucesso"
        });
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a apagar tempo"
          });
      }
    },
    //-------------------------------------------intervenientes------------------------------//
    //-------------------------------------------intervenientes------------------------------//
    //-------------------------------------------intervenientes------------------------------//
    async confirmarcriarInterveniente () {
      try{
          for (var is = 0; is < this.intervenientesSelecionados.length; is++) {
            this.criarIntervenientes.id_funcionario = this.intervenientesSelecionados[is].id
            await TarefasFuncionarioService.post(this.criarIntervenientes)
          }
          this.intervenientesSelecionados = []
          this.editarTarefas = (await TarefasService.show(this.$store.state.tarefa.idtarefa)).data
          this.dialogCriarInterveniente = false
          this.todosIntervenientesNaoTerminados()
          this.$store.dispatch("setSnackBar", {
              color: "success",
              text: "Interveniente criado com sucesso"
          });
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a criar interveniente"
          });
      }
    },
    async apagarInterveniente (id) {
      this.dialogApagarInterveniente = true
      this.idintervenienteglobal = id
    },
    async confirmarApagarInterveniente () {
      try{
          await TarefasFuncionarioService.delete(this.idintervenienteglobal)
          this.dialogApagarInterveniente = false
          this.editarTarefas = (await TarefasService.show(this.$store.state.tarefa.idtarefa)).data
          this.todosIntervenientesTerminados()
          this.$store.dispatch("setSnackBar", {
              color: "success",
              text: "Interveniente apagado com sucesso"
          });
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a apagar interveniente"
          });
      }
    },
    async intervenienteNaoTerminada (id) {
      try{
          this.editarIntervenientes = (await TarefasFuncionarioService.show(id)).data
          this.editarIntervenientes.concluido = false
          this.editarIntervenientes.concluido_data = null
          await TarefasFuncionarioService.put(this.editarIntervenientes)
          this.todosIntervenientesNaoTerminados()
          this.editarTarefas = (await TarefasService.show(this.$store.state.tarefa.idtarefa)).data
          this.$store.dispatch("setSnackBar", {
              color: "success",
              text: "Interveniente não concluiu a tarefa"
          });
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a editado interveniente"
          });
      }
    },
    async intervenienteTerminada (id) {
      try{
          this.editarIntervenientes = (await TarefasFuncionarioService.show(id)).data
          this.editarIntervenientes.concluido = true
          this.editarIntervenientes.concluido_data = this.formatarDataMoment(Date.now()),
          await TarefasFuncionarioService.put(this.editarIntervenientes)
          this.todosIntervenientesTerminados()
          this.editarTarefas = (await TarefasService.show(this.$store.state.tarefa.idtarefa)).data
          this.$store.dispatch("setSnackBar", {
              color: "success",
              text: "Interveniente concluiu a tarefa"
          });
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a editado interveniente"
          });
      }
    },
    //-------------------------------------------marcacoes------------------------------//
    //-------------------------------------------marcacoes------------------------------//
    //-------------------------------------------marcacoes------------------------------//
    async verMarcacoes () {
      this.dialogVerMarcacoes = true
      this.marcacoes = (await MarcacoesService.index(null, null, null, null, this.$store.state.tarefa.idtarefa)).data
    },
    async apagarMarcacao (id) {
      this.dialogApagarMarcacao = true
      this.idmarcacaoglobal = id
    },
    async confirmarApagarMarcacao () {
      try{
        await MarcacoesService.delete(this.idmarcacaoglobal)
        this.dialogApagarMarcacao = false

        this.marcacoes = (await MarcacoesService.index(null, null, null, null, this.$store.state.tarefa.idtarefa)).data

          this.$store.dispatch("setSnackBar", {
              color: "success",
              text: "Marcacao apagada com sucesso"
          });
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a apagar marcacao, pode ter coisas associadas"
          });
      }
    },
    //-------------------------------------------projetos------------------------------//
    //-------------------------------------------projetos------------------------------//
    //-------------------------------------------projetos------------------------------//
    async verProjetos () {
      this.dialogVerProjetos = true
      if (this.editarTarefas.id_projeto != null) {
        this.projetos = (await ProjetosService.index(null, null, null, null, this.editarTarefas.id_projeto)).data
      }
    },
    async verProjetosCliente () {
      this.dialogVerProjetosCliente = true
      this.projetoscliente = (await ProjetosService.index(null, this.editarTarefas.numero_cliente, null, null)).data
    },
    async adicionarProjeto (id) {
      this.idprojetoglobal = id
      this.dialogAdicionarProjeto = true
      this.projetoscliente = (await ProjetosService.index(null, this.editarTarefas.numero_cliente, null, null)).data
    },
    async confirmarAdicionarProjeto () {
      try{
        this.editarTarefas.id_projeto = this.idprojetoglobal
        await TarefasService.put(this.editarTarefas)

        this.$store.dispatch("setSnackBar", {
            color: "success",
            text: "Projeto adicionado à tarefa"
        });

        this.dialogAdicionarProjeto = false
        this.dialogVerProjetosCliente = false
        if (this.editarTarefas.id_projeto != null) {
          this.projetos = (await ProjetosService.index(null, null, null, null, this.editarTarefas.id_projeto)).data
        }
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a adicionar projeto à tarefa"
          });
      }
    },
    async apagarProjeto (id) {
      this.dialogApagarProjeto = true
      this.idprojetoglobal = id
    },
    async confirmarApagarProjeto () {
      try{
        await ProjetosService.delete(this.idprojetoglobal)
        this.dialogApagarProjeto = false

        this.editarTarefas.id_projeto = 0
        await TarefasService.put(this.editarTarefas)
        this.editarTarefas = (await TarefasService.show(this.$store.state.tarefa.idtarefa)).data

        this.projetos = (await ProjetosService.index(null, null, null, null, this.editarTarefas.id_projeto)).data

          this.$store.dispatch("setSnackBar", {
              color: "success",
              text: "Projeto apagado com sucesso"
          });
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a apagar projeto, pode ter coisas associadas"
          });
      }
    },
    //-------------------------------------------equipamentos------------------------------//
    //-------------------------------------------equipamentos------------------------------//
    //-------------------------------------------equipamentos------------------------------//
    async verEquipamentos () {
      this.dialogVerEquipamentos = true
      if (this.editarTarefas.id_equipamento != null) {
        this.equipamentos = (await EquipamentosService.index(null, null, null, null, null, null, null, this.editarTarefas.id_equipamento)).data
      } 
    },
    async verEquipamentosCliente () {
      this.dialogVerEquipamentosCliente = true
      this.equipamentoscliente = (await EquipamentosService.index(null, null, this.editarTarefas.numero_cliente, null, null, null, null, null, null)).data
    },
    async adicionarEquipamento (id) {
      this.idequipamentoglobal = id
      this.dialogAdicionarEquipamento = true
      this.equipamentoscliente = (await EquipamentosService.index(null, null, this.editarTarefas.numero_cliente, null, null, null, null, null, null)).data
    },
    async confirmarAdicionarEquipamento () {
      try{
        this.editarTarefas.id_equipamento = this.idequipamentoglobal
        await TarefasService.put(this.editarTarefas)

        this.$store.dispatch("setSnackBar", {
            color: "success",
            text: "Equipamento adicionado à tarefa"
        });

        this.dialogAdicionarEquipamento = false
        this.dialogVerEquipamentosCliente = false
        if (this.editarTarefas.id_equipamento != null) {
          this.equipamentos = (await EquipamentosService.index(null, null, null, null, null, null, null, this.editarTarefas.id_equipamento)).data
        }
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a adicionar equipamento à tarefa"
          });
      }
    },
    //-------------------------------------------pats------------------------------//
    //-------------------------------------------pats------------------------------//
    //-------------------------------------------pats------------------------------//
    async verPats () {
      this.dialogVerPats = true
      if (this.editarTarefas.stamp_pat != null) {
        this.pats = (await PatsService.importarpatserp(null, this.editarTarefas.stamp_pat, null)).data
      } 
    },
    async verPatsCliente (item) {
      this.criarIntervencao.id_tempo = item.id
      if (item.deslocacao == true) {
        this.criarIntervencao.tipo_intervencao = 'Deslocação a Cliente'
      } else {
        if (item.remoto == true) {
          this.criarIntervencao.tipo_intervencao = 'Assistência Remota'
        } else {
          if (item.telefone == true) {
            this.criarIntervencao.tipo_intervencao = 'Assistência Telefónica'
          } else {
            this.criarIntervencao.tipo_intervencao = 'Assistência Interna'
          }
        }
      }    
      this.criarIntervencao.relatorio = this.criarIntervencao.tipo_intervencao + ':\n\n' + item.trabalho_realizado

      this.dialogVerPatsCliente = true
      this.patscliente = (await PatsService.importarpatserp(this.editarTarefas.numero_cliente, null, false)).data
    },
    async adicionarIntervencao (item) {
      this.dialogAdicionarIntervencao = true
      this.criarIntervencao.stamp_pat = item.stamp_pat
    },
    async confirmarAdicionarIntervencao () {
      try{
        const response = await PatsService.criarintervencoeserp(this.criarIntervencao)

        if (response.data == 'ERRO') {
          this.$store.dispatch("setSnackBar", {
            color: "error",
            text: "Campos estão a ir errados"
          });
        } else {
          this.verTempos()
          this.editarTarefas = (await TarefasService.show(this.$store.state.tarefa.idtarefa)).data
          this.dialogAdicionarIntervencao = false
          this.dialogVerPatsCliente = false

          this.$store.dispatch("setSnackBar", {
            color: "success",
            text: "Intervenção adicionada à pat com sucesso"
          });
        }
        
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a adicionar intervenção à pat"
          });
      }
    },
    async apagarIntervencaoPat (item) {
      this.dialogApagarIntervencao = true
      this.tempoApagarIntervencao.id_tempo = item.id
      this.tempoApagarIntervencao.stamp_intervencao = item.stamp_intervencao
      this.tempoApagarIntervencao.stamp_pat = item.stamp_pat
    },
    async confirmarApagarIntervencao () {
      try{
        //Apagar intervenção da pat
        const response = await PatsService.apagarintervencoeserp(this.tempoApagarIntervencao)
        
        if (response.data == 'ERRO') {
          this.$store.dispatch("setSnackBar", {
            color: "error",
            text: "Campos estão a ir errados"
          });
        } else {
          this.verTempos()
          this.editarTarefas = (await TarefasService.show(this.$store.state.tarefa.idtarefa)).data
          this.dialogApagarIntervencao = false

          this.$store.dispatch("setSnackBar", {
            color: "success",
            text: "Intervenção apagada da pat com sucesso"
          });
        }

        
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a apagar intervenção da pat"
          });
      }
    },
    async editarPat (item) {
      this.editarPats = (await PatsService.verpatserp(item.stamp_pat)).data
      this.estadospats = (await EstadosPatsService.index()).data
      this.dialogEditarPat = true
    },
    async confirmarEditarPat () {
      try{
        //Editar pat
        const response = await PatsService.editarpatserp(this.editarPats)    

        if (response.data == 'ERRO') {
          this.$store.dispatch("setSnackBar", {
            color: "error",
            text: "Campos estão a ir errados"
          });
        } else {
          this.patscliente = (await PatsService.importarpatserp(this.editarTarefas.numero_cliente, null, false)).data
          if (this.editarTarefas.stamp_pat != null) {
            this.pats = (await PatsService.importarpatserp(null, this.editarTarefas.stamp_pat, null)).data
          }
          this.dialogEditarPat = false

          this.$store.dispatch("setSnackBar", {
            color: "success",
            text: "Pat editada com sucesso"
          });
        }
        
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a editar pat"
          });
      }
    },
    async criarPatIntervencao () {
      this.equipamentos = (await EquipamentosService.index(null, null, null, null, null, null, null, this.editarTarefas.id_equipamento)).data
      this.criarPatsIntervencao.stamp_equipamento = this.equipamentos[0].serial
      this.estadospats = (await EstadosPatsService.index()).data
      this.dialogCriarPatIntervencao = true
    },
    async confirmarCriarPatIntervencao () {
      try{
        this.criarPatsIntervencao.numero_cliente = this.editarTarefas.numero_cliente
        this.criarPatsIntervencao.problema = this.editarTarefas.descricao
        this.criarPatsIntervencao.faturar = this.criarIntervencao.faturar
        this.criarPatsIntervencao.tipo_intervencao = this.criarIntervencao.tipo_intervencao
        this.criarPatsIntervencao.id_tempo = this.criarIntervencao.id_tempo
        this.criarPatsIntervencao.id_tarefa = this.criarIntervencao.id_tarefa
        this.criarPatsIntervencao.relatorio = this.criarIntervencao.tipo_intervencao + ':\n\n' + this.criarIntervencao.relatorio
        this.criarPatsIntervencao.stamp_equipamento = this.equipamentos[0].stamp_equipamento
        const response = await PatsService.criarpatsintervencaoerp(this.criarPatsIntervencao)

        if (response.data == 'ERRO') {
          this.$store.dispatch("setSnackBar", {
            color: "error",
            text: "Campos estão a ir errados"
          });
        } else {
          this.dialogCriarPatIntervencao = false
          this.dialogVerPatsCliente = false
          this.verTempos()
          this.editarTarefas = (await TarefasService.show(this.$store.state.tarefa.idtarefa)).data
          this.$store.dispatch("setSnackBar", {
            color: "success",
            text: "Pat criada com sucesso"
          });
        }     
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a criar pat"
          });
      }
    },
    async apagarPat (item) {
      this.dialogApagarPat = true
      this.patglobal = item
    },
    async confirmarApagarPat () {
      try{
        //Apagar pat do erp
        const response = await PatsService.apagarpatserp(this.patglobal)
        
        if (response.data == 'ERRO') {
          this.$store.dispatch("setSnackBar", {
            color: "error",
            text: "Campos estão a ir errados"
          });
        } else {
          this.patscliente = (await PatsService.importarpatserp(this.editarTarefas.numero_cliente, null, false)).data
          this.dialogApagarPat = false
          this.$store.dispatch("setSnackBar", {
            color: "success",
            text: "Pat apagada do erp com sucesso"
          });
        }

        
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a apagar pat do erp"
          });
      }
    },
    //-------------------------------------------artigos------------------------------//
    //-------------------------------------------artigos------------------------------//
    //-------------------------------------------artigos------------------------------//
    async verArtigosTarefa () {
      this.dialogVerArtigosTarefas = true
      this.artigostarefa = (await TarefasArtigosService.index(this.$store.state.tarefa.idtarefa)).data
    },
    async AdicionarArtigoTarefa () {
        this.dialogAdicionarArtigoTarefa = true
        this.artigos = (await ArtigosService.index(false)).data
    },
    async confirmarAdicionarArtigoTarefa () {
      try{
        if (this.$refs.form.validate()) {
          this.adicionarArtigosTarefas.id_tarefa = this.$store.state.tarefa.idtarefa
          await TarefasArtigosService.post(this.adicionarArtigosTarefas)
          this.dialogAdicionarArtigoTarefa = false
          this.artigostarefa = (await TarefasArtigosService.index(this.$store.state.tarefa.idtarefa)).data
          this.$store.dispatch("setSnackBar", {
              color: "success",
              text: "Artigo adicionado com sucesso"
          });
          this.clearAdicionarArtigosTarefas();
        }
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a adicionar artigo tarefa"
          });
      }
    },
    async apagarArtigoTarefa (id) {
      this.dialogApagarArtigoTarefa = true
      this.idartigotarefaglobal = id
    },
    async confirmarApagarArtigoTarefa () {
      try{
          await TarefasArtigosService.delete(this.idartigotarefaglobal)
          this.dialogApagarArtigoTarefa = false
          this.artigostarefa = (await TarefasArtigosService.index(this.$store.state.tarefa.idtarefa)).data
          this.$store.dispatch("setSnackBar", {
              color: "success",
              text: "Artigo tarefa apagado com sucesso"
          });
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a apagar artigo tarefa"
          });
      }
    },
    async editarArtigoTarefa (id) {
        this.dialogEditarArtigoTarefa = true
        this.editarArtigosTarefas = (await TarefasArtigosService.show(id)).data
    },
    async confirmarEditarArtigoTarefa () {
      try{
        await TarefasArtigosService.put(this.editarArtigosTarefas)
        this.dialogEditarArtigoTarefa = false
        this.artigostarefa = (await TarefasArtigosService.index(this.$store.state.tarefa.idtarefa)).data
          this.$store.dispatch("setSnackBar", {
              color: "success",
              text: "Artigo tarefa editado com sucesso"
          });
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a editar artigo tarefa"
          });
      }
    },
    async artigoTarefaReferencia (item) {
      if (item != null) {
        this.artigo = (await ArtigosService.show(item)).data
        this.adicionarArtigosTarefas.codigo_artigo = this.artigo.codigo
        this.adicionarArtigosTarefas.id_artigo = this.artigo.id
        this.adicionarArtigosTarefas.qtd = 1
        this.adicionarArtigosTarefas.unidade = this.artigo.unidade
        this.adicionarArtigosTarefas.preco_sem_iva = this.artigo.preco_venda_sem_iva
        this.adicionarArtigosTarefas.iva = this.artigo.iva
        this.adicionarArtigosTarefas.preco_com_iva = this.artigo.preco_venda_com_iva
        this.adicionarArtigosTarefas.desconto = 0
        this.adicionarArtigosTarefas.total_linha_sem_iva = (this.artigo.preco_venda_sem_iva * this.adicionarArtigosTarefas.qtd).toFixed(2);
        this.adicionarArtigosTarefas.total_linha_com_iva = (this.artigo.preco_venda_com_iva * this.adicionarArtigosTarefas.qtd).toFixed(2);
        this.adicionarArtigosTarefas.descricao = this.artigo.descricao
      }
    },
    async artigoTarefaDescricao (item) {
      if (item != null) {
        this.artigo = (await ArtigosService.show(item)).data
        this.adicionarArtigosTarefas.codigo_artigo = this.artigo.codigo
        this.adicionarArtigosTarefas.id_artigo = this.artigo.id
        this.adicionarArtigosTarefas.qtd = 1
        this.adicionarArtigosTarefas.unidade = this.artigo.unidade
        this.adicionarArtigosTarefas.preco_sem_iva = this.artigo.preco_venda_sem_iva
        this.adicionarArtigosTarefas.iva = this.artigo.iva
        this.adicionarArtigosTarefas.preco_com_iva = this.artigo.preco_venda_com_iva
        this.adicionarArtigosTarefas.desconto = 0
        this.adicionarArtigosTarefas.total_linha_sem_iva = this.artigo.preco_venda_sem_iva
        this.adicionarArtigosTarefas.total_linha_com_iva = this.artigo.preco_venda_com_iva
        this.adicionarArtigosTarefas.descricao = this.artigo.descricao
      }
    },
    atualizarQuantidade () {
      this.adicionarArtigosTarefas.total_linha_sem_iva = ((this.adicionarArtigosTarefas.preco_sem_iva * this.adicionarArtigosTarefas.qtd) - (this.adicionarArtigosTarefas.preco_sem_iva * this.adicionarArtigosTarefas.qtd * (this.adicionarArtigosTarefas.desconto / 100))).toFixed(2);
      this.adicionarArtigosTarefas.total_linha_com_iva = ((this.adicionarArtigosTarefas.preco_com_iva * this.adicionarArtigosTarefas.qtd) - (this.adicionarArtigosTarefas.preco_com_iva * this.adicionarArtigosTarefas.qtd * (this.adicionarArtigosTarefas.desconto / 100))).toFixed(2);
    },
    atualizarDesconto () {
      this.adicionarArtigosTarefas.total_linha_sem_iva = ((this.adicionarArtigosTarefas.preco_sem_iva * this.adicionarArtigosTarefas.qtd) - (this.adicionarArtigosTarefas.preco_sem_iva * this.adicionarArtigosTarefas.qtd * (this.adicionarArtigosTarefas.desconto / 100))).toFixed(2);
      this.adicionarArtigosTarefas.total_linha_com_iva = ((this.adicionarArtigosTarefas.preco_com_iva * this.adicionarArtigosTarefas.qtd) - (this.adicionarArtigosTarefas.preco_com_iva * this.adicionarArtigosTarefas.qtd * (this.adicionarArtigosTarefas.desconto / 100))).toFixed(2);
    },
    atualizarSemIva () {
      this.adicionarArtigosTarefas.preco_com_iva = (this.adicionarArtigosTarefas.preco_sem_iva * (1 + (this.adicionarArtigosTarefas.iva / 100))).toFixed(2);
      this.adicionarArtigosTarefas.total_linha_sem_iva = ((this.adicionarArtigosTarefas.preco_sem_iva * this.adicionarArtigosTarefas.qtd) - (this.adicionarArtigosTarefas.preco_sem_iva * this.adicionarArtigosTarefas.qtd * (this.adicionarArtigosTarefas.desconto / 100))).toFixed(2);
      this.adicionarArtigosTarefas.total_linha_com_iva = ((this.adicionarArtigosTarefas.preco_com_iva * this.adicionarArtigosTarefas.qtd) - (this.adicionarArtigosTarefas.preco_com_iva * this.adicionarArtigosTarefas.qtd * (this.adicionarArtigosTarefas.desconto / 100))).toFixed(2);
    },
    atualizarComIva () {
      this.adicionarArtigosTarefas.preco_sem_iva = (this.adicionarArtigosTarefas.preco_com_iva / (1 + (this.adicionarArtigosTarefas.iva / 100))).toFixed(2);
      this.adicionarArtigosTarefas.total_linha_sem_iva = ((this.adicionarArtigosTarefas.preco_sem_iva * this.adicionarArtigosTarefas.qtd) - (this.adicionarArtigosTarefas.preco_sem_iva * this.adicionarArtigosTarefas.qtd * (this.adicionarArtigosTarefas.desconto / 100))).toFixed(2);
      this.adicionarArtigosTarefas.total_linha_com_iva = ((this.adicionarArtigosTarefas.preco_com_iva * this.adicionarArtigosTarefas.qtd) - (this.adicionarArtigosTarefas.preco_com_iva * this.adicionarArtigosTarefas.qtd * (this.adicionarArtigosTarefas.desconto / 100))).toFixed(2);
    },
    clearAdicionarArtigosTarefas () {
      this.adicionarArtigosTarefas.tipo_stock = null,
      this.adicionarArtigosTarefas.codigo_artigo = null,
      this.adicionarArtigosTarefas.id_artigo = null,
      this.adicionarArtigosTarefas.qtd = null,
      this.adicionarArtigosTarefas.unidade = null,
      this.adicionarArtigosTarefas.preco_sem_iva = null,
      this.adicionarArtigosTarefas.iva = null,
      this.adicionarArtigosTarefas.preco_com_iva = null,
      this.adicionarArtigosTarefas.desconto = null,
      this.adicionarArtigosTarefas.total_linha_sem_iva = null,
      this.adicionarArtigosTarefas.total_linha_com_iva = null,
      this.adicionarArtigosTarefas.descricao = '',
      this.adicionarArtigosTarefas.id_tarefa = null
    }
  }
}
</script>
<style>
</style>