<template>
    <v-layout justify-center v-if="$store.state.isUserLoggedIn">
      <v-flex>
          <v-card>
            <v-toolbar class="mb-2" color="purple" dark flat>
              <v-toolbar-title>Criar mensagem</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-form ref="form">
                <v-row>
                  <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 8">
                    <v-row>
                      <v-col cols="12">
                        <label>Clientes</label>
                        <v-autocomplete v-model="criarMensagens.numero_cliente" :items="clientes" item-text="nome" item-value="numero" clearable filled outlined></v-autocomplete>
                        <template v-if="criarMensagens.numero_cliente == empresaTitular.numero_consumidor_final">
                            <label>Nome consumidor final</label>
                            <v-text-field type="text" v-model="criarMensagens.nome_cliente" clearable filled outlined></v-text-field>
                        </template>
                      </v-col>
                      <v-col cols="12">
                        <label>Assunto</label>
                        <wysiwyg v-model="criarMensagens.assunto"/>
                      </v-col>
                      <v-col cols="12">
                        <label>Mensagem</label>
                        <wysiwyg v-model="criarMensagens.mensagem"/>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 4">
                      <v-row>
                        <v-col cols="12" class="text-center">
                          <label>INTERVENIENTES </label>
                          <img @click="criarMensagem" :width="35" src="../../assets/icons_aplicacao/Add_Interveniente.png" alt="Add_Interveniente">
                        </v-col>
                      </v-row>
                    </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <!-- Dialog Criar Interveniente -->
            <v-dialog v-model="dialogCriarInterveniente">
              <v-card>
                <v-toolbar class="mb-2" color="purple" dark flat>
                  <v-toolbar-title>Criar intervenientes</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-toolbar-title><v-list-item @click="dialogCriarInterveniente = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
                </v-toolbar>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-list>
                        <v-list-item-group color="primary">
                          <div v-for="tec in tecnicos" :key="tec.id">
                            <v-divider></v-divider>
                            <v-list-item>
                              <template>
                                <v-list-item-action>
                                  <v-checkbox v-model="intervenientesSelecionados" :value="tec"></v-checkbox>
                                </v-list-item-action>
                                <v-list-item-content>
                                  <v-list-item-title>{{tec.nome}}</v-list-item-title>
                                </v-list-item-content>
                              </template>
                            </v-list-item>
                          </div>
                        </v-list-item-group>
                      </v-list>
                    </v-col>
                  </v-row>
                </v-container>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="purple" class="white--text" @click="confirmarCriarMensagem">Criar</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card>
      </v-flex>
    </v-layout>
  </template>
  
  <script>
  //  Importar o service necessário
  import MensagensService from '@/services/MensagensService'
  import MensagensFuncionarioService from '@/services/MensagensFuncionarioService'
  import ClientesService from '@/services/ClientesService'
  import FuncionariosService from '@/services/FuncionariosService'
  import EmpresaTitutarService from '@/services/EmpresaTitutarService'
  import store from '@/store/store'
  import moment from 'moment-timezone'
  
  export default {
    async mounted () {
      if (!store.state.isUserLoggedIn) {
        this.$router.push({
          name: 'mylogin'
        })
      }
      this.tecnicos = (await FuncionariosService.index(false, ' ', null)).data
      this.clientes = (await ClientesService.index(false)).data
      this.empresaTitular = (await EmpresaTitutarService.index()).data
    },
    data () {
      return {
        clientes: [],
        tecnicos: [],
        empresaTitular: {},
        intervenientesSelecionados: [],
        criarMensagens: {
          numero_cliente: null,
          data_registo: this.formatarDataMoment(Date.now()),
          enviado_de: this.$store.state.user.id,
          assunto: '',
          mensagem: '',
          mensagem_resposta: 0,
          id_mensagem: 0,
          id_marcacao: 0,
          id_projeto: 0,
          nome_cliente: null,
          email: null,
          destinatarios: null,
          mensagem_original: null
        },
        editarMensagens: {
            mensagem_original: null
        },
        criarIntervenientes: {
          enviado_de: this.$store.state.user.id,
          enviado_para: null,
          id_mensagem: null,
          destinatarios: null,
          email: null,
          lido_data: null,
          lido: 0,
          data_registo: this.formatarDataMoment(Date.now()),
          
        },
  
        dialogCriarInterveniente: false,
        
        rules: {
          cliente: value => !!value || "Cliente é Obrigatório.",    
          consumidorfinal: value => !!value || "Nome consumidor final é Obrigatório."
        },
      }
    },
    methods: {
      formatarDataMoment(data) {
        return moment(data).tz('Europe/Lisbon').format('YYYY-MM-DDTHH:mm:ss[Z]');
      },
      //-------------------------------------------mensagem------------------------------//
      //-------------------------------------------mensagem------------------------------//
      //-------------------------------------------mensagem------------------------------//
      async criarMensagem () {
        if (this.criarMensagens.assunto != '' && this.criarMensagens.mensagem != '') {
          this.dialogCriarInterveniente = true
        }
      },
      async confirmarCriarMensagem () {
        try{
          if (this.$refs.form.validate()) {
            if (this.criarMensagens.numero_cliente != null) {
              let clienteselecionado = (await ClientesService.show(this.criarMensagens.numero_cliente)).data
              if (clienteselecionado.numero != this.empresaTitular.numero_consumidor_final) {
                this.criarMensagens.nome_cliente = clienteselecionado.nome
              }
            } else {
              this.criarMensagens.numero_cliente = 0
            }
            

            this.criarMensagens.destinatarios = this.intervenientesSelecionados.map(item => item.id).join(',');
         
            this.editarMensagens = (await MensagensService.post(this.criarMensagens)).data
            this.editarMensagens.mensagem_original = this.editarMensagens.id
            await MensagensService.put(this.editarMensagens)
            this.dialogCriarInterveniente = false
  
            for (var is = 0; is < this.intervenientesSelecionados.length; is++) {
              this.criarIntervenientes.enviado_para = this.intervenientesSelecionados[is].id
              this.criarIntervenientes.id_mensagem = this.editarMensagens.id
              this.criarIntervenientes.destinatarios = this.criarMensagens.destinatarios
              await MensagensFuncionarioService.post(this.criarIntervenientes)
            }
            this.$store.dispatch("setSnackBar", {
                color: "success",
                text: "Mensagem criada com sucesso"
            });
            /*
            this.$store.dispatch('setMensagem', {
              idmensagem: this.mensagensultima.id,
            })
            
            this.$router.push({
              name: 'editarmensagens'
            })
            */
        } else {
          this.dialogCriarInterveniente = false
        }
        } catch (error) {
            this.$store.dispatch("setSnackBar", {
                color: "error",
                text: "Erro a criar mensagem"
            });
        }
      }
    }
  }
  </script>
  <style>
  </style>