import Api from '@/services/Api'

export default {
  index (numero_cliente, total, data_primeiro_documento, data_ultimo_documento) {
    return Api().get('contascorrentes',{
      params: {
        numero_cliente,
        total,
        data_primeiro_documento,
        data_ultimo_documento
      }
    })
  },
  post (elementos) {
    return Api().post('contascorrentes', elementos)
  },
  put (elementos, contacorrenteStamp) {
    return Api().put(`contascorrentes/${contacorrenteStamp}`, elementos)
  },
  show (contacorrenteStamp) {
    return Api().get(`contascorrentes/${contacorrenteStamp}`)
  },
  deleteall () {
    return Api().delete(`contascorrentes`)
  },


  //IMPORTAR ERP'S
  importarcontascorrenteserp (numero_cliente, data_atualizacao) {
    return Api().get('importarcontascorrenteserp', {
      params: {
        numero_cliente,
        data_atualizacao
      }
    })
  },
}
