<template>
    <v-layout justify-center v-if="$store.state.isUserLoggedIn && this.$store.state.cliente != null || this.$store.state.cliente.numerocliente != null">
        <v-speed-dial fab fixed v-model="fab" :bottom="true" :right="true" direction="top" :open-on-hover="false">
        <template v-slot:activator>
            <v-btn v-model="fab" color="blue darken-2" dark large fab>
            <v-icon v-if="fab">mdi-close</v-icon>
            <v-icon v-else>mdi-filter-variant</v-icon>
            </v-btn>
        </template>
        <v-btn fab dark large color="purple" class="mb-2" @click="criarNota">
            <v-icon>mdi-plus</v-icon>
        </v-btn>
        </v-speed-dial>
        <v-flex>
        <v-card>
            <v-toolbar class="mb-2 primary" dark flat>
                <v-toolbar-title>Notas</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-col cols="12">
                <span>Notas do cliente {{cliente.nome}}</span>
            </v-col>
            <v-col cols="12">
                <v-data-table class="elevation-1" :headers="headersNotas" :items="notas">
                    <template v-slot:[`item.data_registo`]="{ item }">
                        {{ apresentarData(item.data_registo) }}
                    </template>
                    <template v-slot:[`item.descricao`]="{ item }">
                        <span v-html="item.descricao.substring(0,75)"></span>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-icon color="green" class="m-2" @click="editarNota(item.id)">mdi-pencil</v-icon>
                        <v-icon color="red" class="m-2" @click="apagarNota(item.id)">mdi-delete</v-icon>
                    </template>
                </v-data-table>
            </v-col>
        </v-card>
        <!-- Dialog editar notas -->
        <v-dialog v-model="dialogEditarNota">
            <v-card>
                <v-toolbar class="mb-2 primary" dark flat>
                    <v-toolbar-title>Visualizar/editar nota</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-toolbar-title><v-list-item @click="dialogEditarNota = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
                </v-toolbar>
                <v-col cols="12">
                    <label>Data Registo</label>
                    <MYdatetime type="date" :value="editarNotas.data_registo" disabled="disabled"></MYdatetime>
                </v-col>
                <v-col cols="12">
                    <label>Registada por</label>
                    <v-text-field v-model="editarNotas.Funcionario.nome" disabled filled outlined></v-text-field>
                </v-col>
                <v-col cols="12">
                    <label>Descrição</label>
                    <wysiwyg v-model="editarNotas.descricao"/>
                </v-col>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="confirmarEditarNota">Confirmar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Dialog criar notas -->
        <v-dialog v-model="dialogCriarNota">
            <v-card>
                <v-toolbar class="mb-2 primary" dark flat>
                    <v-toolbar-title>Criar nota</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-toolbar-title><v-list-item @click="dialogCriarNota = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
                </v-toolbar>
                <v-col cols="12">
                    <label>Descrição</label>
                    <wysiwyg v-model="criarNotas.descricao"/>
                </v-col>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="confirmarCriarNota">Confirmar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Dialog apagar notas -->
        <v-dialog v-model="dialogApagarNota">
            <v-card>
                <v-toolbar class="mb-2 primary" dark flat>
                    <v-toolbar-title></v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-toolbar-title><v-list-item @click="dialogApagarNota = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
                </v-toolbar>
                <v-col cols="12">
                    <span>Quer apagar a nota?</span>
                </v-col>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="confirmarApagarNota">Confirmar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        </v-flex>
    </v-layout>
</template>

<script>
//  Importar o service necessário
import NotasService from '@/services/NotasService'
import ClientesService from '@/services/ClientesService'
import store from '@/store/store'
import moment from 'moment-timezone'
export default {
    async mounted () {
        if (!store.state.isUserLoggedIn) {
            this.$router.push({
                name: 'mylogin'
            })
        } else if (this.$store.state.cliente == null || this.$store.state.cliente.numerocliente == null) {
            this.$router.push({
                name: 'clientes'
            })
        } else {
            this.cliente = (await ClientesService.show(this.$store.state.cliente.numerocliente)).data
            this.notas = (await NotasService.index(this.$store.state.cliente.numerocliente)).data
        }
    },
    data () {
        return {
            fab: false,
            editarNotas: {
                descricao: null,
                Funcionario: {}
            },
            criarNotas: {
                numero_cliente: this.$store.state.cliente.numerocliente,
                descricao: '',
                quem_registou: this.$store.state.user.id,
                data_registo: this.formatarDataMoment(Date.now()),
            },
            headersNotas: [
                { text: "Data Registo", value: "data_registo", sortable: true },
                { text: "Descrição", value: "descricao", sortable: true },
                { text: "Funcionário", value: "Funcionario.nome", sortable: true },
                { text: 'Ações', value: 'actions', sortable: false },
            ],
            cliente: {},
            notas: [],
            dialogEditarNota: false,
            dialogCriarNota: false,
            dialogApagarNota: false,
            idnotaglobal: null,
        }
    },
    methods: {
        formatarDataMoment(data) {
            return moment(data).tz('Europe/Lisbon').format('YYYY-MM-DDTHH:mm:ss[Z]');
        },
        apresentarData (date) {
            const options = {year: 'numeric', month: 'numeric', day: 'numeric'}
            return new Date(date).toLocaleDateString('pt-PT', options)
        },
        //-------------------------------------------notas------------------------------//
        //-------------------------------------------notas------------------------------//
        //-------------------------------------------notas------------------------------//
        async editarNota (id) {
            this.dialogEditarNota = true
            this.editarNotas = (await NotasService.show(id)).data
        },
        async confirmarEditarNota () {
            try{
                await NotasService.put(this.editarNotas)
                this.dialogEditarNota = false
                this.notas = (await NotasService.index(this.$store.state.cliente.numerocliente)).data
                this.$store.dispatch("setSnackBar", {
                    color: "success",
                    text: "Nota editada com sucesso"
                });
            } catch (error) {
                this.$store.dispatch("setSnackBar", {
                    color: "error",
                    text: "Erro a editar nota"
                });
            }
        },
        async criarNota () {
            this.dialogCriarNota = true
        },
        async confirmarCriarNota () {
            try{
                await NotasService.post(this.criarNotas)
                this.dialogCriarNota = false
                this.notas = (await NotasService.index(this.$store.state.cliente.numerocliente)).data
                this.$store.dispatch("setSnackBar", {
                    color: "success",
                    text: "Nota criada com sucesso"
                });
            } catch (error) {
                this.$store.dispatch("setSnackBar", {
                    color: "error",
                    text: "Erro a criar nota"
                });
            }
        },
        async apagarNota (id) {
            this.dialogApagarNota = true
            this.idnotaglobal = id;
        },
        async confirmarApagarNota () {
            try{
                await NotasService.delete(this.idnotaglobal)
                this.dialogApagarNota = false
                this.notas = (await NotasService.index(this.$store.state.cliente.numerocliente)).data
                this.$store.dispatch("setSnackBar", {
                    color: "success",
                    text: "Nota apagada com sucesso"
                });
            } catch (error) {
                this.$store.dispatch("setSnackBar", {
                    color: "error",
                    text: "Erro a apagar nota"
                });
            }
        },
    }
}
</script>
<style>
</style>