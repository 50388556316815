<template>
    <v-layout justify-center v-if="$store.state.isUserLoggedIn && this.$store.state.cliente != null || this.$store.state.cliente.numerocliente != null">
        <v-speed-dial fab fixed v-model="fab" :bottom="true" :right="true" direction="top" :open-on-hover="false">
        <template v-slot:activator>
            <v-btn v-model="fab" color="blue darken-2" dark large fab>
            <v-icon v-if="fab">mdi-close</v-icon>
            <v-icon v-else>mdi-filter-variant</v-icon>
            </v-btn>
        </template>
        <v-btn fab dark large color="blue" class="mb-2" @click="criarMorada">
            <img class="m-2" :width="45" src="../../assets/icons_aplicacao/Add.png" alt="Add">
        </v-btn>
        </v-speed-dial>
        <v-flex>
            <v-card> 
                <v-toolbar class="mb-2 primary" dark flat>
                    <v-toolbar-title>Outras moradas</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                </v-toolbar>
                <v-col cols="12">
                    <span>Moradas do cliente {{cliente.nome}}</span>
                </v-col>
                <v-card-text>
                    <h3 v-if="this.clientemoradas != ''" class="pb-3"></h3>
                    <h3 v-else class="pb-3"><b>Este cliente não tem outras moradas</b></h3>
                    <br>
                    <v-row v-for="cliemorad in clientemoradas" :key="cliemorad.id">
                        <v-col cols="12 col-md-6">
                            <h3 class="pb-3"><b>Estabelecimento {{ cliemorad.estabelecimento }}</b></h3>
                            <span class="pb-3"><b>Morada:</b> {{ cliemorad.morada }}</span><br>
                            <span class="pb-3"><b>Localidade:</b> {{ cliemorad.localidade }}</span><br>
                            <span class="pb-3"><b>Código Postal:</b> {{ cliemorad.codigo_postal }}</span><br>
                            <span class="pb-3"><b>Telefone:</b> <a :href="`tel:${cliemorad.telefone_empresa}`"> {{ cliemorad.telefone_empresa }}</a></span><br>
                            <span class="pb-3"><b>Telemóvel:</b> <a :href="`tel:${cliemorad.telemovel_empresa}`"> {{ cliemorad.telemovel_empresa }}</a></span><br>
                            <span class="pb-3"><b>Email:</b> <a :href="`mailto:${cliemorad.email_empresa}`"> {{ cliemorad.email_empresa }}</a></span>
                            <v-switch v-if="cliemorad.inativo == false" v-model="switchtrue" color="green" label="Ativo" disabled></v-switch>
                            <v-switch v-else v-model="switchtrue" color="red" label="Inativo" disabled></v-switch>
                        </v-col>
                        <v-col cols="12 col-md-6">
                            <span class="pb-3 text-center"><b>Observações</b></span><br>
                            <textarea rows="5" v-model="cliemorad.observacoes"></textarea>
                            <div class="text-right">
                                <img @click="editarMorada(cliemorad.id)" class="m-2" :width="25" src="../../assets/icons_aplicacao/Editar.png" alt="Editar">
                                <img @click="apagarMorada(cliemorad.id)" class="m-2" :width="25" src="../../assets/icons_aplicacao/Apagar.png" alt="Apagar">
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            <!-- Dialog editar moradas -->
            <v-dialog v-model="dialogEditarMorada">
                <v-card>
                    <v-toolbar class="mb-2" color="green" dark flat>
                        <v-toolbar-title>Editar morada</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                        <v-toolbar-title><v-list-item @click="dialogEditarMorada = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
                    </v-toolbar>
                    <v-form ref="form">
                        <v-col cols="12">
                            <label>Inativo</label>
                            <v-switch v-model="editarMoradas.inativo" color="red"></v-switch>
                        </v-col>
                        <v-col cols="12">
                            <label>Morada</label>
                            <v-text-field type="text" v-model="editarMoradas.morada" clearable filled outlined></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <label>Código postal</label>
                            <v-text-field type="text" v-model="editarMoradas.codigo_postal" clearable filled outlined></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <label>Localidade</label>
                            <v-text-field type="text" v-model="editarMoradas.localidade" clearable filled outlined></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <label>Telefone empresa</label>
                            <v-text-field type="text" v-model="editarMoradas.telefone_empresa" clearable filled outlined></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <label>Telemóvel empresa</label>
                            <v-text-field type="text" v-model="editarMoradas.telemovel_empresa" clearable filled outlined></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <label>Email empresa</label>
                            <v-text-field type="text" v-model="editarMoradas.email_empresa" clearable filled outlined :rules="[rules.email]"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <label>Estabelecimento</label>
                            <v-text-field type="text" v-model="editarMoradas.estabelecimento" clearable filled outlined></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <label>Observações</label>
                            <wysiwyg v-model="editarMoradas.observacoes"/>
                        </v-col>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <img @click="confirmarEditarMorada" :width="50" src="../../assets/icons_aplicacao/Guardar.png" alt="Guardar">
                        </v-card-actions>
                    </v-form>
                </v-card>
            </v-dialog>
            <!-- Dialog criar moradas -->
            <v-dialog v-model="dialogCriarMorada">
                <v-card>
                    <v-toolbar class="mb-2" color="purple" dark flat>
                        <v-toolbar-title>Criar morada</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                        <v-toolbar-title><v-list-item @click="dialogCriarMorada = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
                    </v-toolbar>
                    <v-form ref="form">
                        <v-col cols="12">
                            <label>Morada</label>
                            <v-text-field type="text" v-model="criarMoradas.morada" clearable filled outlined></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <label>Código postal</label>
                            <v-text-field type="text" v-model="criarMoradas.codigo_postal" clearable filled outlined></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <label>Localidade</label>
                            <v-text-field type="text" v-model="criarMoradas.localidade" clearable filled outlined></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <label>Telefone empresa</label>
                            <v-text-field type="text" v-model="criarMoradas.telefone_empresa" clearable filled outlined></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <label>Telemóvel empresa</label>
                            <v-text-field type="text" v-model="criarMoradas.telemovel_empresa" clearable filled outlined></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <label>Email empresa</label>
                            <v-text-field type="text" v-model="criarMoradas.email_empresa" clearable filled outlined :rules="[rules.email]"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <label>Estabelecimento</label>
                            <v-text-field type="text" v-model="criarMoradas.estabelecimento" clearable filled outlined></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <label>Observações</label>
                            <wysiwyg v-model="criarMoradas.observacoes"/>
                        </v-col>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="purple" class="white--text" @click="confirmarCriarMorada">Criar</v-btn>
                        </v-card-actions>
                    </v-form>
                </v-card>
            </v-dialog>
            <!-- Dialog apagar moradas -->
            <v-dialog v-model="dialogApagarMorada">
                <v-card>
                    <v-toolbar class="mb-2" color="red" dark flat>
                        <v-toolbar-title>Apagar morada</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-title><v-list-item @click="dialogApagarMorada = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
                    </v-toolbar>
                    <v-col cols="12">
                        <span>Quer mesmo apagar a morada?</span>
                    </v-col>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="red" class="white--text" @click="confirmarApagarMorada">Apagar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-flex>
    </v-layout>
</template>

<script>
//  Importar o service necessário
import ClientesMoradasService from '@/services/ClientesMoradasService'
import ClientesService from '@/services/ClientesService'

import store from '@/store/store'
export default {
    async mounted () {
        if (!store.state.isUserLoggedIn) {
            this.$router.push({
                name: 'mylogin'
            })
        } else if (this.$store.state.cliente == null || this.$store.state.cliente.numerocliente == null) {
            this.$router.push({
                name: 'clientes'
            })
        } else {
            this.cliente = (await ClientesService.show(this.$store.state.cliente.numerocliente)).data
            this.clientemoradas = (await ClientesMoradasService.clientemoradaexiste(this.$store.state.cliente.numerocliente)).data
        }
    },
    data () {
        return {
            switchtrue: true,
            fab: false,
            cliente: {},
            editarMoradas: {
                morada: null,
                localidade: null,
                codigo_postal: null,
                telefone_empresa: null,
                telemovel_empresa: null,
                email_empresa: null,
                observacoes: null,
                estabelecimento: null,
                inativo: false,
            },
            criarMoradas: {
                numero_cliente: this.$store.state.cliente.numerocliente,
                morada: null,
                localidade: null,
                codigo_postal: null,
                telefone_empresa: null,
                telemovel_empresa: null,
                email_empresa: null,
                observacoes: null,
                estabelecimento: null,
                inativo: false,
            },
            clientemoradas: [],
            dialogEditarMorada: false,
            dialogCriarMorada: false,
            dialogApagarMorada: false,
            idmoradaglobal: null,
            rules: {
                email: value => {if (value && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {return "Por favor, insira um endereço de email válido.";}return true;}
            }
        }
    },
    methods: {
        //-------------------------------------------moradas------------------------------//
        //-------------------------------------------moradas------------------------------//
        //-------------------------------------------moradas------------------------------//
        async editarMorada (id) {
            this.dialogEditarMorada = true
            this.editarMoradas = (await ClientesMoradasService.show(id)).data
            this.idmoradaglobal = id;
        },
        async confirmarEditarMorada () {
            if (this.$refs.form.validate()) {
                try{
                    await ClientesMoradasService.put(this.editarMoradas,  this.idmoradaglobal)
                    this.dialogEditarMorada = false
                    this.clientemoradas = (await ClientesMoradasService.clientemoradaexiste(this.$store.state.cliente.numerocliente)).data
                    this.$store.dispatch("setSnackBar", {
                    color: "success",
                    text: "Morada editada com sucesso"
                    });
                } catch (error) {
                    this.$store.dispatch("setSnackBar", {
                    color: "error",
                    text: "Erro a editar morada"
                    });
                }
            }
        },
        async criarMorada () {
            this.dialogCriarMorada = true
        },
        async confirmarCriarMorada () {
            if (this.$refs.form.validate()) {
                try {
                    await ClientesMoradasService.post(this.criarMoradas)          
                    this.dialogCriarMorada = false
                    this.clientemoradas = (await ClientesMoradasService.clientemoradaexiste(this.$store.state.cliente.numerocliente)).data
                    this.$store.dispatch("setSnackBar", {
                        color: "success",
                        text: "Morada criada com sucesso"
                    });
                } catch (error) {
                    this.$store.dispatch("setSnackBar", {
                        color: "error",
                        text: "Erro a criar morada"
                    });
                }
            }
        },
        async apagarMorada (id) {
            this.dialogApagarMorada = true
            this.idmoradaglobal = id;
        },
        async confirmarApagarMorada () {
            try{
                await ClientesMoradasService.delete(this.idmoradaglobal)
                this.dialogApagarMorada = false
                this.clientemoradas = (await ClientesMoradasService.clientemoradaexiste(this.$store.state.cliente.numerocliente)).data
                this.$store.dispatch("setSnackBar", {
                    color: "success",
                    text: "Morada apagada com sucesso"
                });
            } catch (error) {
                this.$store.dispatch("setSnackBar", {
                    color: "error",
                    text: "Erro a apagar morada"
                });
            }
        },
    }
}
</script>
<style>
</style>