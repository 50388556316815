<template>
  <v-layout justify-center v-if="$store.state.isUserLoggedIn">
    <v-flex>
      <v-card>
        <v-toolbar class="mb-2 primary" dark flat>
          <v-toolbar-title>Tickets</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-spacer> {{ tickets.length }} </v-spacer>
          <v-toolbar-title><v-list-item @click.stop="filterDialog = true" class="v-list-item--link"><v-icon x-large >mdi-magnify</v-icon></v-list-item></v-toolbar-title>
        </v-toolbar>
        <v-col cols="12">
          <v-data-table hide-default-footer class="elevation-1" v-infinite-scroll="getTickets" infinite-scroll-disabled="busy" infinite-scroll-distance="10" :headers="headersTickets" :items="tickets" :loading="loading" :items-per-page="tickets.length + 10" @click:row="handleClickTicket">
            <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
            <template v-slot:[`item.createdAt`]="{ item }">
              {{ formatDate3(item.createdAt) }}
            </template>
          </v-data-table>
        </v-col>
      </v-card>
      <!-- Dialog edit tickets -->
      <v-dialog v-model="editDialog" ref="test" max-width="800" width="800" scrollable transition="dialog-bottom-transition">
        <v-card v-if="editDialog">
          <v-toolbar dark color="primary">
            <v-toolbar-title>Converter</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn icon @click="editDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-card-text>
            <ticket-form-convert ref="qwerty" :ticket="selectedTicket"></ticket-form-convert>
            <v-card-actions>
              <v-row>
                <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 6">
                  <v-btn class="w-100" color="primary" @click="convertTicketSemTarefa()">Fechar ticket sem tarefa</v-btn>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 6">
                  <v-btn class="w-100" color="primary" @click="convertTicket({name: 'criartarefas'})">Criar tarefa através do ticket</v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card-text>

          
        </v-card>
      </v-dialog>
      <!-- Dialog pesquisa tickets -->
      <v-dialog v-model="filterDialog" persistent max-width="600px">
        <v-card>
          <v-toolbar class="mb-2 primary" dark flat>
            <v-toolbar-title>Filtros :</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-toolbar-title><v-list-item @click="filterDialog = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
          </v-toolbar>
          <v-col cols="12">
            <v-text-field label="Nome" clearable v-model="filter.name"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field label="Empresa" clearable v-model="filter.company"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field label="NIF" clearable v-model="filter.nif"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-row align="center" justify="center">
              <v-col cols="12" lg="6">
                <v-menu ref="menuFrom" v-model="menuFrom" :close-on-content-click="false" :return-value.sync="filter.sDate" transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="filter.sDate" label="De" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" clearable></v-text-field>
                  </template>
                  <v-date-picker :max="filter.eDate" v-model="filter.sDate" no-title scrollable @change="$refs.menuFrom.save(filter.sDate)">
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menuFrom = false">
                      Fechar
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" lg="6">
                <v-menu ref="menuUntil" v-model="menuUntil" :close-on-content-click="true" :return-value.sync="filter.eDate" transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="filter.eDate" label="Até" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" clearable></v-text-field>
                  </template>
                  <v-date-picker :min="filter.sDate" v-model="filter.eDate" no-title scrollable @change="$refs.menuUntil.save(filter.eDate)">
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menuUntil = false">
                      Fechar
                    </v-btn>
                    <v-btn text color="primary" @click="$refs.menuUntil.save(filter.eDate)">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-checkbox v-model="filter.converted" label="Convertidos"></v-checkbox>
          </v-col>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="filterDialog = false">Fechar</v-btn>
            <v-btn color="primary" @click="Filter()">Procurar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-flex>
  </v-layout>
  </template>
  
  <script>
  import infiniteScroll from "vue-infinite-scroll";
  import ServiceTickets from "@/services/TicketsService";
  import TicketFormConvert from "@/components/Tickets/MyTicketFormConvert.vue";
  import store from '@/store/store'
  export default {
    async mounted() {
      if (!store.state.isUserLoggedIn) {
        this.$router.push({
          name: 'mylogin'
        })
      }
    },
    name: "List-Tickets",
    directives: {
      infiniteScroll
    },
    components: {
      TicketFormConvert
    },
    data() {
      return {
        editDialog: false,
        editedIndex: -1,
        editedItem: {
        },
        defaultItem: {
        },
  
        value: "",
        fileName: "",
        filterDialog: false,
        fab: false,
        page: 1,
        headersTickets: [
          { text: "Nome", value: "name", sortable: false },
          { text: "Empresa", value: "company", sortable: false },
          { text: "NIF", value: "nif", sortable: false },
          { text: "Email", value: "email", sortable: false },
          { text: "Contacto", value: "contact", sortable: false },
          { text: "Descrição", value: "description", sortable: false },
          { text: "Técnico preferência", value: "preferedEmployee", sortable: false },
          { text: "Data", value: "createdAt", sortable: false }
        ],
        tickets: [],
        busy: false,
        loading: true,
        filter: {},
        menuFrom: false,
        menuUntil: false,
        selectedTicket: {},
        items: [
          { text: "Ver todos", value: 2 },
          { text: "Ver apenas activos", value: 0 },
          { text: "Ver apenas descontinuados", value: 1 }
        ]
      };
    },
    methods: {
      // formatar data3
      formatDate3 (date) {
        const options = {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric'}
        return new Date(date).toLocaleDateString('pt-PT', options)
      },
      async getTickets() {
        this.busy = true;
        this.loading = true;
        let converted = null;
        if (this.filter.converted) {
          converted = 1;
        }
        const response = await ServiceTickets.list(
          this.page,
          this.filter.name,
          this.filter.company,
          this.filter.nif,
          this.filter.sDate,
          this.filter.eDate,
          converted
        );
        response.data.forEach(element => {
          this.tickets.push(element);
        });
        this.page++;
        this.busy = false;
        if (response.data.length == 0) {
          this.busy = true;
        }
        this.loading = false;
      },
      async convertTicket(route) {
        try {
  
          //await ServiceTickets.convert(this.selectedTicket.id, 1);
  
          const index = this.tickets.indexOf(this.selectedTicket);
          if (index > -1) {
            this.tickets.splice(index, 1);
          }
          this.editDialog = false;
  
          this.$store.dispatch('setTicket', {  
            ticketitems: this.selectedTicket,
            idTicket: this.selectedTicket.id
          })
          this.$router.push(route)

        } catch (error) {
          console.log(error)
          this.$store.dispatch("setSnackBar", {
            color: "error"
          });
        }
      },
      async convertTicketSemTarefa() {
        try {
  
          await ServiceTickets.convert(this.selectedTicket.id, 1);
  
          const index = this.tickets.indexOf(this.selectedTicket);
          if (index > -1) {
            this.tickets.splice(index, 1);
          }
          this.editDialog = false;

        } catch (error) {
          console.log(error)
          this.$store.dispatch("setSnackBar", {
            color: "error"
          });
        }
      },
      handleClickTicket(value) {
        this.selectedTicket = value;
        this.editDialog = true;
      },
      Filter() {
        this.tickets = [];
        this.page = 1;
        this.filterDialog = false;
        this.getTickets();
      },
      testClass() {
        return 'rounded-xl';
      }
    }
  };
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style>
  /*bush fix the icon was pushed to the top*/
  .btn--floating .icon {
    height: 50%;
  }
  
  .row-pointer>>>tbody tr :hover {
    cursor: pointer;
  }
  </style>
  























