import Api from '@/services/Api'

export default {
  importarpatserp (numero_cliente, stamp_pat, fechado, data_inicio, data_fim) {
    return Api().get('importarpatserp',{
      params: {
        numero_cliente,
        stamp_pat,
        fechado,
        data_inicio,
        data_fim
      }
    })
  },
  importarintervencoeserp (numero_cliente, stamp_pat, stamp_intervencao) {
    return Api().get('importarintervencoeserp',{
      params: {
        numero_cliente,
        stamp_pat,
        stamp_intervencao
      }
    })
  },


  criarpatsintervencaoerp (elementos) {
    return Api().post('criarpatsintervencaoerp', elementos)
  },
  criarpatserp (elementos) {
    return Api().post('criarpatserp', elementos)
  },
  criarintervencoeserp (elementos) {
    return Api().post('criarintervencoeserp', elementos)
  },



  editarpatserp (elementos) {
    return Api().put(`editarpatserp/${elementos.stamp_pat}`, elementos)
  },
  editarestadopatserp (elementos) {
    return Api().put(`editarestadopatserp/${elementos.stamp_pat}`, elementos)
  },



  apagarintervencoeserp (elementos) {
    return Api().delete(`apagarintervencoeserp/${elementos.stamp_intervencao}`,{
      params: {
        id_tempo: elementos.id_tempo,
        id_tarefa: elementos.id_tarefa,
        stamp_pat: elementos.stamp_pat
      }
    })
  },

  apagarpatserp (elementos) {
    return Api().delete(`apagarpatserp/${elementos.stamp_pat}`)
  },
  

  verpatserp (stamp_pat) {
    return Api().get(`verpatserp/${stamp_pat}`)
  },
}
