import Api from '@/services/Api'

export default {
  index () {
    return Api().get('clientescontactos')
  },
  post (elementos) {
    return Api().post('clientescontactos', elementos)
  },
  put (elementos) {
    return Api().put(`clientescontactos/${elementos.id}`, elementos)
  },
  show (id) {
    return Api().get(`clientescontactos/${id}`)
  },
  delete (id) {
    return Api().delete(`clientescontactos/${id}`)
  },
  deleteall () {
    return Api().delete(`clientescontactos`)
  },
  clientecontactoexiste (numero_cliente) {
    return Api().get(`clientecontactoexiste`,{
      params: {
        numero_cliente
      }
    })
  }
}
