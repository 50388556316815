<template>
    <v-layout justify-center v-if="$store.state.isUserLoggedIn && this.$store.state.tempo != null || this.$store.state.tempo.idtempo != null">
      <v-flex>
          <v-card>
            <v-toolbar class="mb-2" color="green" dark flat>
              <v-toolbar-title>Alteração de tempo</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-form ref="form">
                <v-row>
                  <v-col cols="12 text-center">
                    <span>Alterar tempo do cliente {{editarTempos.nome_cliente}}?</span>
                  </v-col>
                  <v-col cols="12 text-center">
                    <h1>{{ formatTime }}</h1>
                  </v-col>
                  <v-col cols="12 text-center mt-5">
                    <label>Deslocação/Remoto/Telefone do funcionário</label><br>
                    <v-btn v-if="editarTempos.telefone != true" class="mr-5 mt-5" @click="telefone"><v-icon x-large>mdi-phone</v-icon></v-btn>
                    <v-btn v-else class="mr-5 mt-5" @click="offTelefone"><v-icon x-large color="green">mdi-phone</v-icon></v-btn>
                    <v-btn v-if="editarTempos.remoto != true" class="mr-5 mt-5" @click="remoto"><v-icon x-large>mdi-laptop</v-icon></v-btn>
                    <v-btn v-else class="mr-5 mt-5" @click="offRemoto"><v-icon x-large color="green">mdi-laptop</v-icon></v-btn>
                    <v-btn v-if="editarTempos.deslocacao != true" class="mr-5 mt-5" @click="deslocacao"><v-icon x-large>mdi-car</v-icon></v-btn>
                    <v-btn v-else class="mr-5 mt-5" @click="offDeslocacao"><v-icon x-large color="green">mdi-car</v-icon></v-btn>
                  </v-col>
                  <v-col cols="12">
                    <label>Tipo de Tempo</label>
                    <v-autocomplete v-model="editarTempos.tipo_tempo" :items="tempostipo" item-text="descricao" item-value="id" clearable filled outlined></v-autocomplete>
                  </v-col>
                  <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                    <label>Início</label>
                    <MYdatetime type="date" v-model="datainicioinserida" format="dd-MM-yyyy"></MYdatetime>
                    <br>
                    <MYdatetime type="time" v-model="horainicioinserida" format="HH:mm"></MYdatetime>
                    <v-text-field class="d-none" type="date" v-model="datainicioinserida" clearable filled outlined :rules="[rules.dataigual]"></v-text-field>
                  </v-col>
                  <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                    <label>Fim</label>
                    <MYdatetime type="date" v-model="datafiminserida" format="dd-MM-yyyy"></MYdatetime>
                    <span v-if="datainicioinserida.slice(0,10) != datafiminserida.slice(0,10)" class="errodata">Data fim tem de ser igual à data de início.</span>
                    <br>
                    <MYdatetime type="time" v-model="horafiminserida" format="HH:mm"></MYdatetime>
                  </v-col>
                  <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                    <label>Tempo descontar</label>
                    <MYdatetime type="time" v-model="tempodescontarinserido" format="HH:mm"></MYdatetime>
                  </v-col>
                  <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                    <label>Tempo total</label>
                    <MYdatetime type="time" v-model="tempoefetivoinserido" format="HH:mm" disabled></MYdatetime>
                  </v-col>
                  <v-col cols="12">
                    <label>Descrição</label>
                    <wysiwyg v-model="editarTempos.descricao"/>
                  </v-col>
                  <v-col cols="12">
                    <label>Trabalho realizado</label>
                    <wysiwyg v-model="editarTempos.trabalho_realizado"/>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                      <v-card-actions>
                        <img v-if="editarTempos.id_tarefa != 0 && editarTempos.id_tarefa != null && editarTempos.stamp_intervencao == null" class="m-2" :width="45" @click="editarTarefa" src="../../assets/icons_aplicacao/Tarefas.png" alt="Tarefas">
                        <!--Isto está a dizer se não tiver stamp_intervencao, ou seja se o tempo não tem pat mas vai para as tarefas para irmos aos tempos e adicionarmos pat-->
                        <!--<img v-if="editarTempos.id_tarefa != 0 && editarTempos.id_tarefa != null && editarTempos.stamp_intervencao == null" class="m-2" :width="45" @click="editarTarefa" src="../../assets/icons_aplicacao/Add_Intervencao.png" alt="Add_Intervencao">-->
                        <img v-if="editarTempos.id_tarefa == null || editarTempos.id_tarefa == 0" class="m-2" :width="45" @click="abrirModalTarefas" src="../../assets/icons_aplicacao/Add_Tarefas.png" alt="Add_Tarefas">
                        <v-spacer></v-spacer>
                        <img @click="editarTempo" :width="50" src="../../assets/icons_aplicacao/Guardar.png" alt="Guardar">
                      </v-card-actions>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
          <!-- Dialog ver tarefas -->
          <v-dialog v-model="dialogVerTarefas">
            <v-card>
              <v-toolbar class="mb-2 primary" dark flat>
                <v-toolbar-title>Tarefas</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-title><v-list-item @click="dialogVerTarefas = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
              </v-toolbar>
              <v-col cols="12">
                <img class="m-2" :width="35" @click="criarTarefa" src="../../assets/icons_aplicacao/Add_Tarefas.png" alt="Add_Tarefas">
              </v-col>
              <v-col cols="12">
                <v-data-table class="elevation-1" :headers="headersTarefas" :items="tarefas">
                  <template v-slot:[`item.numero`]="{ item }">
                    {{ item.numero + '/' + item.ano }}
                  </template>
                  <template v-slot:[`item.data_registo`]="{ item }">
                    {{ formatDate(item.data_registo) }}
                  </template>
                  <template v-slot:[`item.Funcionarios`]="{ item }">
                    <div v-for="func in item.Funcionarios" :key="func.id">
                        {{ func.nome }}
                    </div>
                  </template>
                  <template v-slot:[`item.descricao`]="{ item }">
                    <span v-if="item.descricao != null" v-html="item.descricao.substring(0,75)"></span>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <img class="m-2" :width="25" @click="adicionarTarefa(item.id)" src="../../assets/icons_aplicacao/Add.png" alt="Add">
                  </template>
                </v-data-table>
              </v-col>
            </v-card>
          </v-dialog>
          <!-- Dialog adicionar tarefa -->
          <v-dialog v-model="dialogAdicionarTarefa">
            <v-card>
              <v-toolbar class="mb-2" color="orange" dark flat>
                  <v-toolbar-title>Adicionar tarefa</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-toolbar-title><v-list-item @click="dialogAdicionarTarefa = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
              </v-toolbar>
              <v-col cols="12">
                  <span>Quer mesmo adicionar tempo a esta tarefa?</span>
              </v-col>
              <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="orange" class="white--text" @click="confirmarAdicionarTarefa">Adicionar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
      </v-flex>
    </v-layout>
  </template>
  
  <script>
  //  Importar o service necessário
  import TemposService from '@/services/TemposService'
  import TarefasService from '@/services/TarefasService'
  import TemposTipoService from '@/services/TemposTipoService'
  import moment from 'moment-timezone'
  import store from '@/store/store'
  
  export default {
    async mounted () {
      if (!store.state.isUserLoggedIn) {
        this.$router.push({
          name: 'mylogin'
        })
      } else if (this.$store.state.tempo == null || this.$store.state.tempo.idtempo == null) {
        this.$router.push({
          name: 'myhome'
        })
      } else {
        this.editarTempos = (await TemposService.show(this.$store.state.tempo.idtempo)).data
        this.tempostipo = (await TemposTipoService.index()).data
        //colocar as datas todas com segundos a 0
        this.editarTempos.data_inicio = this.resetSeconds(this.editarTempos.data_inicio)
        this.editarTempos.data_fim = this.resetSeconds(this.editarTempos.data_fim)
        this.editarTempos.tempo_descontar = this.resetSeconds(this.editarTempos.tempo_descontar)
        this.editarTempos.tempo_gasto = this.resetSeconds(this.editarTempos.tempo_gasto)
        this.editarTempos.tempo_efetivo = this.resetSeconds(this.editarTempos.tempo_efetivo)
        
        //inserir as datas e tempos nos inputs
        this.datainicioinserida = this.editarTempos.data_inicio
        this.datafiminserida = this.editarTempos.data_fim
        this.horainicioinserida = this.editarTempos.data_inicio.slice(11,16)
        this.horafiminserida = this.editarTempos.data_fim.slice(11,16)
        this.tempodescontarinserido = this.editarTempos.tempo_descontar.slice(11,16)
        


        //converter as datas que precisamos para numéricos
        const horainiciodoispontos = this.horainicioinserida.split(':')
        const horafimdoispontos = this.horafiminserida.split(':')
        const tempodescontardoispontos = this.tempodescontarinserido.split(':')
        const tempodescontartemponumerico = ((parseInt(tempodescontardoispontos[0], 10) * 60 * 60) + (parseInt(tempodescontardoispontos[1], 10) * 60)) * 1000
        const horainiciotemponumerico = ((parseInt(horainiciodoispontos[0], 10) * 60 * 60) + (parseInt(horainiciodoispontos[1], 10) * 60)) * 1000
        const horafimtemponumerico = ((parseInt(horafimdoispontos[0], 10) * 60 * 60) + (parseInt(horafimdoispontos[1], 10) * 60)) * 1000
        

        //fazer as contas em numéricos do tempo efetivo e do tempo gasto, e colocar tempo no cronometro
        const tempoefetivonumerico = horafimtemponumerico - horainiciotemponumerico - tempodescontartemponumerico
        const tempogastonumerico = horafimtemponumerico - horainiciotemponumerico
        this.time = horafimtemponumerico - horainiciotemponumerico - tempodescontartemponumerico

        // dar update na bd do tempo_efetivo, e do tempo_gasto
        this.editarTempos.tempo_efetivo = '1899-12-30T' + this.convertToTime(tempoefetivonumerico) + 'Z'
        this.editarTempos.tempo_gasto = '1899-12-30T' + this.convertToTime(tempogastonumerico) + 'Z'
        await TemposService.put(this.editarTempos)

        this.editarTempos = (await TemposService.show(this.$store.state.tempo.idtempo)).data
        //colocar tempo efetivo no input
        this.tempoefetivoinserido = this.editarTempos.tempo_efetivo.slice(11,16)
      }
    },
    data () {
      return {
        tempostipo: [],
        datainicioinserida: null,
        datafiminserida: null,
        horainicioinserida: null,
        horafiminserida: null,
        tempodescontarinserido: null,
        tempoefetivoinserido: null,
        editarTempos: {
            data_fim: null,
            tempo_gasto: null,
            concluido: null,
            descricao: null,
            trabalho_realizado: null,
            telefone: null,
            remoto: null,
            deslocacao: null,
            tempo_descontar: null,
            tempo_efetivo: null,
            inicio_pausa: null,
            pausa: null,
            updatedAt: null
        },
        tempoaosegundo: {
          concluido: null
        },
        rules: {
          dataigual: value => {if (value != this.datafiminserida) return "Data fim tem de ser igual à data de início.";}
        },
        time: 0,

        headersTarefas: [
          { text: "Nº tarefa", value: "numero", sortable: true },
          { text: "Descrição da Tarefa", value: "descricao", sortable: true },
          { text: "Funcionarios", value: "Funcionarios", sortable: true },
          { text: "Data Registo", value: "data_registo", sortable: true },
          { text: "Estado", value: "Estado.descricao", sortable: true },
          { text: 'Ações', value: 'actions', sortable: false },
        ],

        filtrarTarefas: {
          concluido: null,
          numero_cliente: null,
          concluido_funcionario: null,
          id_funcionario: null
        },

        tarefas: [],
        dialogVerTarefas: false,
        dialogAdicionarTarefa: false,
  
        idtarefaglobal: '',
      }
    },
    computed: {
      formatTime: function() {
        const totalSeconds = Math.floor(this.time / 1000);
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;
        return `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
      }
    },
    methods: {
      // formatar data
      formatDate (date) {
        const options = {year: 'numeric', month: 'numeric', day: 'numeric'}
        return new Date(date).toLocaleDateString('pt-PT', options)
      },
      formatarDataMoment(data) {
        return moment(data).tz('Europe/Lisbon').format('YYYY-MM-DDTHH:mm:ss[Z]');
      },
      convertToTime(tempo) {
        const totalsegundos = Math.floor(tempo / 1000);
        const horas = Math.floor(totalsegundos / 3600);
        const minutos = Math.floor((totalsegundos % 3600) / 60);
        const segundos = totalsegundos % 60;
        return `${this.pad(horas)}:${this.pad(minutos)}:${this.pad(segundos)}`;
      },
      pad(number) {
        return number.toString().padStart(2, '0');
      },
      resetSeconds(dateStr) {
        let date = new Date(dateStr);  // Converte a string para um objeto Date
        date.setUTCSeconds(0);         // Define os segundos para zero
        date.setUTCMilliseconds(0);    // Define os milissegundos para zero
        return date.toISOString();     // Retorna a data no formato ISO
      },
      async deslocacao () {
        this.editarTempos.deslocacao = true
        this.editarTempos.tempo_deslocacao = '1899-12-30T01:00:00Z'
        this.editarTempo();
      },
      async telefone () {
        this.editarTempos.telefone = true
        this.editarTempo();
      },
      async remoto () {
        this.editarTempos.remoto = true
        this.editarTempo();
      },
      async offDeslocacao () {
        this.editarTempos.deslocacao = false
        this.editarTempos.tempo_deslocacao = '1899-12-30T00:00:00Z'
        this.editarTempo();
      },
      async offTelefone () {
        this.editarTempos.telefone = false
        this.editarTempo();
      },
      async offRemoto () {
        this.editarTempos.remoto = false
        this.editarTempo();
      },
      async editarTempo () {
        try{
          if (this.$refs.form.validate()) {
            //inserir os dados na bd
            this.editarTempos.data_inicio = this.formatarDataMoment(this.datainicioinserida.slice(0,10) + 'T' + this.horainicioinserida.slice(11,16) + ':00Z')
            this.editarTempos.data_fim = this.formatarDataMoment(this.datafiminserida.slice(0,10) + 'T' + this.horafiminserida.slice(11,16) + ':00Z')
            const tempodescontar = this.formatarDataMoment(this.tempodescontarinserido)
            this.editarTempos.tempo_descontar = '1899-12-30T' + tempodescontar.slice(11,20)

            //inserir as datas e tempos nos inputs
            this.horainicioinserida = this.editarTempos.data_inicio.slice(11,16)
            this.horafiminserida = this.editarTempos.data_fim.slice(11,16)
            this.tempodescontarinserido = this.editarTempos.tempo_descontar.slice(11,16)

            //converter as datas que precisamos para numéricos
            const horainiciodoispontos = this.horainicioinserida.split(':')
            const horafimdoispontos = this.horafiminserida.split(':')
            const tempodescontardoispontos = this.tempodescontarinserido.split(':')
            const tempodescontartemponumerico = ((parseInt(tempodescontardoispontos[0], 10) * 60 * 60) + (parseInt(tempodescontardoispontos[1], 10) * 60)) * 1000
            const horainiciotemponumerico = ((parseInt(horainiciodoispontos[0], 10) * 60 * 60) + (parseInt(horainiciodoispontos[1], 10) * 60)) * 1000
            const horafimtemponumerico = ((parseInt(horafimdoispontos[0], 10) * 60 * 60) + (parseInt(horafimdoispontos[1], 10) * 60)) * 1000
            

            //fazer as contas em numéricos do tempo efetivo e do tempo gasto, e colocar tempo no cronometro
            const tempoefetivonumerico = horafimtemponumerico - horainiciotemponumerico - tempodescontartemponumerico
            const tempogastonumerico = horafimtemponumerico - horainiciotemponumerico
            this.time = horafimtemponumerico - horainiciotemponumerico - tempodescontartemponumerico

            // dar update na bd do tempo_efetivo, e do tempo_gasto
            this.editarTempos.tempo_efetivo = '1899-12-30T' + this.convertToTime(tempoefetivonumerico) + 'Z'
            this.editarTempos.tempo_gasto = '1899-12-30T' + this.convertToTime(tempogastonumerico) + 'Z'
            await TemposService.put(this.editarTempos)

            this.$store.dispatch("setSnackBar", {
                color: "success",
                text: "Tempo guardado com sucesso"
            });

            document.location.reload(true)

          }
        } catch (error) {
            this.$store.dispatch("setSnackBar", {
                color: "error",
                text: "Erro a guardar tempo, o tempo total pode ser inferior a 0"
            });
            
            document.location.reload(true)
        }
      },
      //-------------------------------------------tarefas------------------------------//
      //-------------------------------------------tarefas------------------------------//
      //-------------------------------------------tarefas------------------------------//
      async abrirModalTarefas () {
        this.tarefas = (await TarefasService.index(this.filtrarTarefas.concluido, this.editarTempos.numero_cliente, this.filtrarTarefas.concluido_funcionario, this.filtrarTarefas.id_funcionario)).data
        this.dialogVerTarefas = true
      },
      async adicionarTarefa (id) {
        this.idtarefaglobal = id
        this.dialogAdicionarTarefa = true
      },
      async confirmarAdicionarTarefa () {
        try{
          this.editarTempos.id_tarefa = this.idtarefaglobal
          this.editarTempos.updatedAt = this.formatarDataMoment(Date.now())
          await TemposService.put(this.editarTempos)
  
          this.dialogAdicionarTarefa = false
          this.dialogVerTarefas = false

          this.$store.dispatch("setSnackBar", {
              color: "success",
              text: "Tempo adicionado à tarefa"
          });
        } catch (error) {
            this.$store.dispatch("setSnackBar", {
                color: "error",
                text: "Erro a adicionar tempo à tarefa"
            });
        }
      },
      async criarTarefa () {
        this.$store.dispatch('setTempo', {
          idtempotarefa: this.editarTempos.id,
          finalizadomobile: null
        })
        this.$router.push({
            name: 'criartarefas'
        })
      },
      async editarTarefa () {
        this.$store.dispatch('setTarefa', {
          idtarefa: this.editarTempos.id_tarefa,
        })
        this.$router.push({
          name: 'editartarefas'
        })
      }
    },
  }
  </script>
  <style>
  </style>