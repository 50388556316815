<template>
  <v-layout justify-center v-if="$store.state.isUserLoggedIn">
    <v-speed-dial fab fixed v-model="fab" :bottom="true" :right="true" direction="top" :open-on-hover="false">
      <template v-slot:activator>
        <v-btn v-model="fab" color="blue darken-2" dark large fab>
          <v-icon v-if="fab">mdi-close</v-icon>
          <v-icon v-else>mdi-filter-variant</v-icon>
        </v-btn>
      </template>
      <v-btn fab dark large color="blue" class="mb-2" @click.stop="dialogFiltrarProjetos = true">
        <img :width="45" src="../../assets/icons_aplicacao/Pesquisa.png" alt="Pesquisa">
      </v-btn>
      <v-btn fab dark large color="blue" class="mb-2" to="criarprojetos">
        <img :width="45" src="../../assets/icons_aplicacao/Add_Projetos.png" alt="Add_Projetos">
      </v-btn>
    </v-speed-dial>
    <v-flex>
      <v-card>
        <v-toolbar class="mb-2 primary" dark flat>
          <v-toolbar-title>Projetos</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-title>{{textobotaoconcluido}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</v-toolbar-title>
          <v-switch class="pt-5" @change="filtrarConcluidos" v-model="botaoconcluido" color="green" inset></v-switch>
        </v-toolbar>
        <v-col cols="12">
            <span v-if="cliente.nome != null">Projetos do cliente {{cliente.nome}}</span>
            <span v-else>Projetos de todos os clientes</span>
        </v-col>
        <v-col cols="12">
          <v-data-table class="elevation-1" :headers="headersProjetos" :items="projetos">
            <template v-slot:[`item.Projetos_Obs`]="{ item }">
              <template v-for="(obs, index) in item.Projetos_Obs">
                <img :key="index" v-if="index === 0" @click="verObservacoes(item.id)" :width="25" src="../../assets/icons_aplicacao/Ver.png" alt="Ver">
              </template>
            </template>
            <template v-slot:[`item.numero`]="{ item }">
              <span>{{ item.numero }} / {{ item.ano }}</span>
            </template>
            <template v-slot:[`item.descricao`]="{ item }">
              <span v-if="item.descricao != null" v-html="item.descricao.substring(0,75)"></span>
            </template>
            <template v-slot:[`item.data_registo`]="{ item }">
              {{ apresentarData(item.data_registo) }}
            </template>
            <template v-slot:[`item.Funcionarios`]="{ item }">
              <div v-for="func in item.Funcionarios" :key="func.id">
                  {{ func.nome }}
              </div>
            </template>
            <template v-slot:[`item.concluido`]="{ item }">
              <img v-if="item.concluido == false" class="m-2" :width="25" src="../../assets/icons_aplicacao/Nao_Concluido.png" alt="Nao_Concluido">
              <img v-else class="m-2" :width="25" src="../../assets/icons_aplicacao/Concluido.png" alt="Concluido">
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <img @click="criarObservacao(item.id)" class="m-2" :width="25" src="../../assets/icons_aplicacao/Add_Notas.png" alt="Add_Notas">
              <img @click="navigateToProjetoEditar({name: 'editarprojetos', params:{idProjetoEnviado: item.id}})" class="m-2" :width="25" src="../../assets/icons_aplicacao/Editar.png" alt="Editar">
              <img v-if="item.concluido == false" @click="apagarProjeto(item.id)" class="m-2" :width="25" src="../../assets/icons_aplicacao/Apagar.png" alt="Apagar">
            </template>
          </v-data-table>
        </v-col>
      </v-card>
      <!-- Dialog filtrar projeto -->
      <v-dialog v-model="dialogFiltrarProjetos">
        <v-card>
          <v-toolbar class="mb-2" color="black" dark flat>
            <v-toolbar-title>Filtrar projetos</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-title><v-list-item @click="dialogFiltrarProjetos = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
          </v-toolbar>
          <v-col cols="12">
            <label>Funcionário</label>
            <v-autocomplete v-model="filtrarProjetos.id_funcionario" :items="funcionarios" item-text="nome" item-value="id" clearable filled outlined></v-autocomplete>
          </v-col>
          <v-col cols="12">
            <label>Cliente</label>
            <v-autocomplete v-model="filtrarProjetos.numero_cliente" :items="clientes" item-text="nome" item-value="numero" clearable filled outlined></v-autocomplete>
          </v-col>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="black" class="white--text" @click="confirmarFiltrarProjetos">Filtrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Dialog apagar projeto -->
      <v-dialog v-model="dialogApagarProjeto">
        <v-card>
          <v-toolbar class="mb-2" color="red" dark flat>
              <v-toolbar-title>Apagar projeto</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-title><v-list-item @click="dialogApagarProjeto = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
          </v-toolbar>
          <v-col cols="12">
              <span>Quer mesmo apagar a projeto?</span>
          </v-col>
          <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red" class="white--text" @click="confirmarApagarProjeto">Apagar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Dialog ver observações -->
      <v-dialog v-model="dialogVerObservacoes">
        <v-card>
          <v-toolbar class="mb-2 primary" dark flat>
            <v-toolbar-title>Observações</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-title><v-list-item @click="dialogVerObservacoes = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
          </v-toolbar>
          <v-col cols="12 text-right">
            <img @click="criarObservacao" class="m-2" :width="35" src="../../assets/icons_aplicacao/Add_Notas.png" alt="Add_Notas">
          </v-col>
          <v-col cols="12">
            <v-data-table class="elevation-1" :headers="headersObservacoes" :items="observacoes">
              <template v-slot:[`item.enviado_mail`]="{ item }">
                <img v-if="item.enviado_mail == true" @click="verObsEmailEnviado(item.id)" class="m-2" :width="25" src="../../assets/icons_aplicacao/Email_Enviado.png" alt="Email_Enviado">
              </template>
              <template v-slot:[`item.data_registo`]="{ item }">
                {{ apresentarData(item.data_registo) }}
              </template>
              <template v-slot:[`item.descricao`]="{ item }">
                <span v-if="item.descricao != null" v-html="item.descricao.substring(0,75)"></span>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <img @click="editarObservacao(item.id)" class="m-2" :width="25" src="../../assets/icons_aplicacao/Editar.png" alt="Editar">
                <img @click="apagarObservacao(item.id)" class="m-2" :width="25" src="../../assets/icons_aplicacao/Apagar.png" alt="Apagar">
              </template>
            </v-data-table>
          </v-col>
        </v-card>
      </v-dialog>
      <!-- Dialog criar observação-->
      <v-dialog v-model="dialogCriarObservacao">
        <v-card>
          <v-toolbar class="mb-2" color="purple" dark flat>
            <v-toolbar-title>Criar observação</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-title><v-list-item @click="dialogCriarObservacao = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
          </v-toolbar>
          <v-col cols="12">
              <label>Descrição</label>
              <wysiwyg v-model="criarObservacoes.descricao"/>
          </v-col>
          <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="purple" class="white--text" @click="confirmarCriarObservacao">Criar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Dialog apagar observação -->
      <v-dialog v-model="dialogApagarObservacao">
        <v-card>
          <v-toolbar class="mb-2" color="red" dark flat>
              <v-toolbar-title></v-toolbar-title>
              <v-spacer>Apagar observação</v-spacer>
              <v-toolbar-title><v-list-item @click="dialogApagarObservacao = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
          </v-toolbar>
          <v-col cols="12">
              <span>Quer mesmo apagar a observação?</span>
          </v-col>
          <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red" class="white--text" @click="confirmarApagarObservacao">Apagar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Dialog editar observação -->
      <v-dialog v-model="dialogEditarObservacao">
        <v-card>
          <v-toolbar class="mb-2" color="green" dark flat>
              <v-toolbar-title>Editar observação</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-title><v-list-item @click="dialogEditarObservacao = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
          </v-toolbar>
          <v-col cols="12">
              <label>Data Registo</label>
              <MYdatetime type="date" :value="editarObservacoes.data_registo" disabled="disabled"></MYdatetime>
          </v-col>
          <v-col cols="12">
              <label>Registada por</label>
              <v-text-field v-model="editarObservacoes.Funcionario.nome" disabled filled outlined></v-text-field>
          </v-col>
          <v-col cols="12">
              <label>Descrição</label>
              <wysiwyg v-model="editarObservacoes.descricao"/>
          </v-col>
          <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green" class="white--text" @click="confirmarEditarObservacao">Editar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Dialog ver email enviado observação-->
      <v-dialog v-model="dialogVerObsEmailEnviado">
        <v-card>
          <v-toolbar class="mb-2 primary" dark flat>
            <v-toolbar-title>Observações da projeto enviada por e-mail para o(s) seguinte(s) endereço(s)</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-title><v-list-item @click="dialogVerObsEmailEnviado = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
          </v-toolbar>
          <v-col cols="12">
            <span>{{observacaoemail.enviado_mail_para_quem}} em {{apresentarData(observacaoemail.enviado_mail_data)}} às <span v-if="observacaoemail.enviado_mail_data != null">{{apresentarHoras(observacaoemail.enviado_mail_data)}}</span> horas.</span>
          </v-col>
        </v-card>
      </v-dialog>
    </v-flex>
  </v-layout>
</template>
  
<script>
//  Importar o service necessário
import ProjetosService from '@/services/ProjetosService'
import ProjetosObsService from '@/services/ProjetosObsService'
import FuncionariosService from '@/services/FuncionariosService'
import ClientesService from '@/services/ClientesService'
import store from '@/store/store'
import moment from 'moment-timezone'
export default {
  async mounted () {
    if (!store.state.isUserLoggedIn) {
      this.$router.push({
        name: 'mylogin'
      })
    }else if (this.$store.state.cliente == null || this.$store.state.cliente.numerocliente == null) {
      this.projetos = (await ProjetosService.index(this.filtrarProjetos.concluido, this.filtrarProjetos.numero_cliente, this.filtrarProjetos.concluido_funcionario, this.filtrarProjetos.id_funcionario)).data
    } else {
      this.cliente = (await ClientesService.show(this.$store.state.cliente.numerocliente)).data
      this.filtrarProjetos.numero_cliente = this.$store.state.cliente.numerocliente
      this.projetos = (await ProjetosService.index(this.filtrarProjetos.concluido, this.filtrarProjetos.numero_cliente, this.filtrarProjetos.concluido_funcionario, this.filtrarProjetos.id_funcionario)).data
    }
    this.funcionarios = (await FuncionariosService.index()).data
    this.clientes = (await ClientesService.index()).data
  },
  data () {
    return {
      fab: false,
      funcionarios: [],
      projetos: [],
      clientes: [],
      cliente: {},
      observacoes: [],
      observacaoemail: {},
      filtrarProjetos: {
        concluido: false,
        numero_cliente: null,
        concluido_funcionario: false,
        id_funcionario: this.$store.state.funcionarioselect.idfuncionario
      },
      criarObservacoes: {
          descricao: '',
          quem_registou: this.$store.state.user.id,
          data_registo: this.formatarDataMoment(Date.now()),
          id_projeto: null
      },
      editarObservacoes: {
          descricao: null,
          Funcionario: {}
      },
      headersProjetos: [
        { text: "Observações", value: "Projetos_Obs", sortable: true },
        { text: "Projeto", value: "numero", sortable: false },
        { text: "Cliente", value: "nome_cliente", sortable: true },
        { text: "Descrição da Projeto", value: "descricao", sortable: true },
        { text: "Funcionarios", value: "Funcionarios", sortable: true },
        { text: "Data Registo", value: "data_registo", sortable: true },
        { text: "Estado", value: "Estado.descricao", sortable: true },
        { text: "Concluido", value: "concluido", sortable: true },
        { text: 'Ações', value: 'actions', sortable: false },
      ],
      headersObservacoes: [
        { text: "Email", value: "enviado_mail", sortable: true },
        { text: "Data Registo", value: "data_registo", sortable: true },
        { text: "Descrição", value: "descricao", sortable: true },
        { text: "Funcionário", value: "Funcionario.nome", sortable: true },
        { text: 'Ações', value: 'actions', sortable: false },
      ],

      dialogFiltrarProjetos: false,
      dialogApagarProjeto: false,
      dialogVerObsEmailEnviado: false,
      dialogVerObservacoes: false,
      dialogCriarObservacao: false,
      dialogApagarObservacao: false,
      dialogEditarObservacao: false,

      idprojetoglobal: '',
      idobservacaoglobal: '',
      
      textobotaoconcluido: 'Projetos por concluir',
      botaoconcluido: false,
    }
  },
  methods: {
    // formatar data
    formatarDataMoment(data) {
      return moment(data).tz('Europe/Lisbon').format('YYYY-MM-DDTHH:mm:ss[Z]');
    },
    apresentarData (date) {
      const options = {year: 'numeric', month: 'numeric', day: 'numeric'}
      return new Date(date).toLocaleDateString('pt-PT', options)
    },
    apresentarHoras (date) {
      return new Date(date).toJSON().slice(11, 19)
    },
    navigateToProjetoEditar (route) {
      this.$store.dispatch('setProjeto', {  
        idprojeto: route.params.idProjetoEnviado,
      })
      this.$router.push(route)
    },
    // filtrar projetos
    async confirmarFiltrarProjetos () {
      if (this.filtrarProjetos.numero_cliente != null) {
        this.cliente = (await ClientesService.show(this.filtrarProjetos.numero_cliente)).data
      } else {
        this.cliente.nome = null
      }
      this.projetos = (await ProjetosService.index(this.filtrarProjetos.concluido, this.filtrarProjetos.numero_cliente, this.filtrarProjetos.concluido_funcionario, this.filtrarProjetos.id_funcionario)).data
      this.dialogFiltrarProjetos = false
    },
    // filtrar concluidos
    async filtrarConcluidos () {
      if (this.botaoconcluido == false) {
          this.filtrarProjetos.concluido_funcionario = false
          this.filtrarProjetos.concluido = false
          this.textobotaoconcluido = 'Projetos por concluir'
      }
      if (this.botaoconcluido == true) {
          this.filtrarProjetos.concluido_funcionario = null
          this.filtrarProjetos.concluido = null
          this.textobotaoconcluido = 'Mostrar todos'
      }
      this.projetos = (await ProjetosService.index(this.filtrarProjetos.concluido, this.filtrarProjetos.numero_cliente, this.filtrarProjetos.concluido_funcionario, this.filtrarProjetos.id_funcionario)).data
    },
    //-------------------------------------------projetos------------------------------//
    //-------------------------------------------projetos------------------------------//
    //-------------------------------------------projetos------------------------------//
    async apagarProjeto (id) {
      this.dialogApagarProjeto = true
      this.idprojetoglobal = id
    },
    async confirmarApagarProjeto () {
      try{
        await ProjetosService.delete(this.idprojetoglobal)
        this.dialogApagarProjeto = false
        if (this.$store.state.cliente == null || this.$store.state.cliente.numerocliente == null) {
          this.projetos = (await ProjetosService.index(this.filtrarProjetos.concluido, this.filtrarProjetos.numero_cliente, this.filtrarProjetos.concluido_funcionario, this.filtrarProjetos.id_funcionario)).data
        } else {
          this.filtrarProjetos.numero_cliente = this.$store.state.cliente.numerocliente
          this.projetos = (await ProjetosService.index(this.filtrarProjetos.concluido, this.filtrarProjetos.numero_cliente, this.filtrarProjetos.concluido_funcionario, this.filtrarProjetos.id_funcionario)).data
        }
          this.$store.dispatch("setSnackBar", {
              color: "success",
              text: "Projeto apagada com sucesso"
          });
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a apagar projeto, pode ter coisas associadas"
          });
      }
    },
    //-------------------------------------------observações------------------------------//
    //-------------------------------------------observações------------------------------//
    //-------------------------------------------observações------------------------------//
    async verObservacoes (idProjeto) {
      this.idprojetoglobal = idProjeto
      this.dialogVerObservacoes = true
      this.observacoes = (await ProjetosObsService.index(idProjeto)).data
    },
    async verObsEmailEnviado (id) {
      this.dialogVerObsEmailEnviado = true
      this.observacaoemail = (await ProjetosObsService.show(id)).data
    },
    async criarObservacao (idProjeto) {
        if (idProjeto.type != 'click') {
          this.idprojetoglobal = idProjeto
        }
        this.dialogCriarObservacao = true
        this.criarObservacoes.id_projeto = this.idprojetoglobal
    },
    async confirmarCriarObservacao () {
      try{
          await ProjetosObsService.post(this.criarObservacoes)
          this.dialogCriarObservacao = false
          if (this.$store.state.cliente == null || this.$store.state.cliente.numerocliente == null) {
            this.projetos = (await ProjetosService.index(this.filtrarProjetos.concluido, this.filtrarProjetos.numero_cliente, this.filtrarProjetos.concluido_funcionario, this.filtrarProjetos.id_funcionario)).data
          } else {
            this.filtrarProjetos.numero_cliente = this.$store.state.cliente.numerocliente
            this.projetos = (await ProjetosService.index(this.filtrarProjetos.concluido, this.filtrarProjetos.numero_cliente, this.filtrarProjetos.concluido_funcionario, this.filtrarProjetos.id_funcionario)).data
          }
          this.observacoes = (await ProjetosObsService.index(this.idprojetoglobal)).data
          this.$store.dispatch("setSnackBar", {
              color: "success",
              text: "Observação criada com sucesso"
          });
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a criar observação"
          });
      }
    },
    async apagarObservacao (id) {
      this.dialogApagarObservacao = true
      this.idobservacaoglobal = id
    },
    async confirmarApagarObservacao () {
      try{
          await ProjetosObsService.delete(this.idobservacaoglobal)
          this.dialogApagarObservacao = false
          if (this.$store.state.cliente == null || this.$store.state.cliente.numerocliente == null) {
            this.projetos = (await ProjetosService.index(this.filtrarProjetos.concluido, this.filtrarProjetos.numero_cliente, this.filtrarProjetos.concluido_funcionario, this.filtrarProjetos.id_funcionario)).data
          } else {
            this.filtrarProjetos.numero_cliente = this.$store.state.cliente.numerocliente
            this.projetos = (await ProjetosService.index(this.filtrarProjetos.concluido, this.filtrarProjetos.numero_cliente, this.filtrarProjetos.concluido_funcionario, this.filtrarProjetos.id_funcionario)).data
          }
          this.observacoes = (await ProjetosObsService.index(this.idprojetoglobal)).data
          this.$store.dispatch("setSnackBar", {
              color: "success",
              text: "Observação apagada com sucesso"
          });
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a apagar observação"
          });
      }
    },
    async editarObservacao (id) {
        this.dialogEditarObservacao = true
        this.editarObservacoes = (await ProjetosObsService.show(id)).data
    },
    async confirmarEditarObservacao () {
      try{
          await ProjetosObsService.put(this.editarObservacoes)
          this.dialogEditarObservacao = false
          this.observacoes = (await ProjetosObsService.index(this.idprojetoglobal)).data
          this.$store.dispatch("setSnackBar", {
              color: "success",
              text: "Observação editada com sucesso"
          });
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a editar observação"
          });
      }
    },
  }
}
</script>
<style>
</style>