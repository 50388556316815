<template>
  <v-layout justify-center v-if="$store.state.isUserLoggedIn">
      <v-speed-dial fab fixed v-model="fab" :bottom="true" :right="true" direction="top" :open-on-hover="false">
      <template v-slot:activator>
          <v-btn v-model="fab" color="blue darken-2" dark large fab>
          <v-icon v-if="fab">mdi-close</v-icon>
          <v-icon v-else>mdi-filter-variant</v-icon>
          </v-btn>
      </template>
      <v-btn fab dark large color="blue" @click.stop="dialogFiltrarFuncionarios = true">
        <img class="m-2" :width="45" src="../../assets/icons_aplicacao/Pesquisa.png" alt="Pesquisa">
      </v-btn>
      <v-btn fab dark large color="blue" class="mb-2" @click="criarFuncionario">
        <img class="m-2" :width="45" src="../../assets/icons_aplicacao/Add_Funcionario.png" alt="Add_Funcionario">
      </v-btn>
      </v-speed-dial>
      <v-flex>
        <v-card>
            <v-toolbar class="mb-2 primary" dark flat>
                <v-toolbar-title>Funcionários</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-title>{{textobotaoinativo}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</v-toolbar-title>
                <v-switch class="pt-5" @change="filtrarInativos" v-model="botaoinativo" color="green" inset></v-switch>
            </v-toolbar>
            <v-col cols="12">
                <v-data-table class="elevation-1" :headers="headersFuncionarios" :items="funcionarios">
                    <template v-slot:[`item.inativo`]="{ item }">
                        <img v-if="item.inativo == true" class="m-2" :width="25" src="../../assets/icons_aplicacao/Nao_Concluido.png" alt="Nao_Concluido">
                        <img v-else class="m-2" :width="25" src="../../assets/icons_aplicacao/Concluido.png" alt="Concluido">
                    </template>
                    <template v-slot:[`item.senha`]>
                        <span>*******************</span>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <img @click="editarFuncionario(item.id)" class="m-2" :width="25" src="../../assets/icons_aplicacao/Editar.png" alt="Editar">
                        <img @click="apagarFuncionario(item.id)" class="m-2" :width="25" src="../../assets/icons_aplicacao/Apagar.png" alt="Apagar">
                    </template>
                </v-data-table>
            </v-col>
        </v-card>
        <!-- Dialog filtrar funcionario -->
        <v-dialog v-model="dialogFiltrarFuncionarios">
            <v-card>
            <v-toolbar class="mb-2" color="black" dark flat>
                <v-toolbar-title>Filtrar funcionários</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-title><v-list-item @click="dialogFiltrarFuncionarios = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
            </v-toolbar>
            <v-col cols="12">
                <label>Nome</label>
                <v-text-field type="text" v-model="filtrarFuncionarios.nome" clearable filled outlined></v-text-field>
            </v-col>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="black" class="white--text" @click="confirmarFiltrarFuncionarios">Filtrar</v-btn>
            </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Dialog editar funcionarios -->
        <v-dialog v-model="dialogEditarFuncionario">
            <v-card>
                <v-toolbar class="mb-2" color="green" dark flat>
                    <v-toolbar-title>Editar funcionário</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-title><v-list-item @click="dialogEditarFuncionario = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-form ref="form">
                        <v-row>
                            <v-col cols="12">
                                <label>Inativo</label>
                                <v-switch v-model="editarFuncionarios.inativo" color="red"></v-switch>
                            </v-col>
                            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 6">
                                <label>Nome</label>
                                <v-text-field type="text" v-model="editarFuncionarios.nome" clearable filled outlined :rules="[rules.nome]"></v-text-field>
                            </v-col>
                            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                                <label>Email</label>
                                <v-text-field type="text" v-model="editarFuncionarios.email" clearable filled outlined :rules="[rules.email]"></v-text-field>
                            </v-col>
                            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                                <label>Telemóvel</label>
                                <v-text-field type="number" v-model="editarFuncionarios.telemovel" clearable filled outlined></v-text-field>
                            </v-col>     
                            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                                <label>Número técnico</label>
                                <v-text-field type="number" v-model="editarFuncionarios.numero_tecnico" clearable filled outlined></v-text-field>
                            </v-col>
                            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                                <label>Número vendedor</label>
                                <v-text-field type="number" v-model="editarFuncionarios.numero_vendedor" clearable filled outlined></v-text-field>
                            </v-col>
                            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                                <label>Password</label>
                                <v-text-field @click="dialogEditarPassword = true" type="password" value="*******************" clearable filled outlined readonly></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <img @click="confirmarEditarFuncionario" :width="50" src="../../assets/icons_aplicacao/Guardar.png" alt="Guardar">
                                </v-card-actions>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- Dialog criar funcionarios -->
        <v-dialog v-model="dialogCriarFuncionario">
            <v-card>
                <v-toolbar class="mb-2" color="purple" dark flat>
                    <v-toolbar-title>Criar funcionário</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-toolbar-title><v-list-item @click="dialogCriarFuncionario = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-form ref="form">
                        <v-row>
                            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 6">
                                <label>Nome</label>
                                <v-text-field type="text" v-model="criarFuncionarios.nome" clearable filled outlined :rules="[rules.nome]"></v-text-field>
                            </v-col>
                            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                                <label>Email</label>
                                <v-text-field type="text" v-model="criarFuncionarios.email" clearable filled outlined :rules="[rules.email]"></v-text-field>
                            </v-col>
                            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                                <label>Telemóvel</label>
                                <v-text-field type="number" v-model="criarFuncionarios.telemovel" clearable filled outlined></v-text-field>
                            </v-col>
                            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                                <label>Password</label>
                                <v-text-field type="password" v-model="passwordInserida.password" clearable filled outlined :rules="[rules.senha]"></v-text-field>
                            </v-col>
                            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                                <label>Confirmar password</label>
                                <v-text-field type="password" v-model="passwordInserida.confirmpass" clearable filled outlined :rules="[rules.senhaconfirmar]"></v-text-field>
                            </v-col>
                            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                                <label>Número técnico</label>
                                <v-text-field type="number" v-model="criarFuncionarios.numero_tecnico" clearable filled outlined></v-text-field>
                            </v-col>
                            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                                <label>Número vendedor</label>
                                <v-text-field type="number" v-model="criarFuncionarios.numero_vendedor" clearable filled outlined></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-card-actions>  
                                    <v-spacer></v-spacer>
                                    <v-btn color="purple" class="white--text" @click="confirmarCriarFuncionario">Criar</v-btn>
                                </v-card-actions>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- Dialog apagar funcionarios -->
        <v-dialog v-model="dialogApagarFuncionario">
            <v-card>
                <v-toolbar class="mb-2" color="red" dark flat>
                    <v-toolbar-title>Apagar funcionário</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-title><v-list-item @click="dialogApagarFuncionario = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
                </v-toolbar>
                <v-col cols="12">
                    <span>Quer mesmo apagar o funcionario?</span>
                </v-col>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red" class="white--text" @click="confirmarApagarFuncionario">Apagar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Dialog editar password -->
        <v-dialog v-model="dialogEditarPassword">
            <v-card>
                <v-toolbar class="mb-2" color="green" dark flat>
                    <v-toolbar-title>Alterar password online</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-title><v-list-item @click="dialogEditarPassword = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-form ref="form">
                        <v-row>
                            <v-col cols="6">
                                <label>Password</label>
                                <v-text-field type="password" v-model="passwordInserida.password" clearable filled outlined :rules="[rules.senha]"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <label>Confirmar password</label>
                                <v-text-field type="password" v-model="passwordInserida.confirmpass" filled outlined :rules="[rules.senhaconfirmar]"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <img @click="updatePass" :width="50" src="../../assets/icons_aplicacao/Guardar.png" alt="Guardar">
                                </v-card-actions>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>
      </v-flex>
  </v-layout>
</template>

<script>
//  Importar o service necessário
import FuncionariosService from '@/services/FuncionariosService'
import store from '@/store/store'
export default {
  async mounted () {
      if (!store.state.isUserLoggedIn) {
          this.$router.push({
              name: 'mylogin'
          })
      }
      this.funcionarios = (await FuncionariosService.index(this.filtrarFuncionarios.inativo)).data
  },
  data () {
      return {
          fab: false,
          filtrarFuncionarios: {
              inativo: false,
              nome: null,
          },
          editarFuncionarios: {
              nome: null,
              email: null,
              telemovel: null,
              senha: null,
              senha_online: null,
              numero_tecnico: null,
              numero_vendedor: null,
              inativo: false
          },
          criarFuncionarios: {
              nome: null,
              email: null,
              telemovel: null,
              senha: null,
              senha_online: null,
              numero_tecnico: null,
              numero_vendedor: null,
              inativo: false,
          },
          passwordInserida: {
            password: '',
            confirmpass: ''
          },
          headersFuncionarios: [
              { text: "Nome", value: "nome", sortable: true },
              { text: "Email", value: "email", sortable: true },
              { text: "Técnico", value: "numero_tecnico", sortable: true },
              { text: "Vendedor", value: "numero_vendedor", sortable: true },
              { text: "Password", value: "senha", sortable: true },
              { text: "Activo", value: "inativo", sortable: true },
              { text: 'Ações', value: 'actions', sortable: false },
          ],
          funcionarios: [],

          dialogFiltrarFuncionarios: false,
          dialogEditarFuncionario: false,
          dialogCriarFuncionario: false,
          dialogApagarFuncionario: false,
          dialogEditarPassword: false,

          idfuncionarioglobal: null,

          textobotaoinativo: 'Mostrar activos',
          botaoinativo: false,

          rules: {
            nome: value => !!value || "Número é Obrigatório.",
            email: value => {if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {return "Por favor, insira um endereço de email válido.";}return true;},
            senha: value => !!value || "Password é Obrigatória.",
            senhaconfirmar: value => {if (value != this.passwordInserida.password) return "Passwords não são iguais";}
          }
      }
  },
  methods: {
      formatDate (date) {
          const options = {year: 'numeric', month: 'numeric', day: 'numeric'}
          return new Date(date).toLocaleDateString('pt-PT', options)
      },
      // filtrar funcionarios
    async confirmarFiltrarFuncionarios () {
        this.funcionarios = (await FuncionariosService.index(this.filtrarFuncionarios.inativo, null, null, null, null, this.filtrarFuncionarios.nome)).data
        this.dialogFiltrarFuncionarios = false
    },
    // filtrar inativos
    async filtrarInativos () {
        if (this.botaoinativo == false) {
            this.filtrarFuncionarios.inativo = false
            this.textobotaoinativo = 'Mostrar activos'
        }
        if (this.botaoinativo == true) {
        this.filtrarFuncionarios.inativo = null
        this.textobotaoinativo = 'Mostrar todos'
        }
        this.funcionarios = (await FuncionariosService.index(this.filtrarFuncionarios.inativo, null, null, null, null, this.filtrarFuncionarios.nome)).data
    },
      //-------------------------------------------funcionarios------------------------------//
      //-------------------------------------------funcionarios------------------------------//
      //-------------------------------------------funcionarios------------------------------//
      async editarFuncionario (id) {
          this.dialogEditarFuncionario = true
          this.editarFuncionarios = (await FuncionariosService.show(id)).data
          this.idfuncionarioglobal = id;
      },
      async confirmarEditarFuncionario () {
        if (this.$refs.form.validate()) {
            try{
                await FuncionariosService.put(this.editarFuncionarios)
                this.dialogEditarFuncionario = false
                this.funcionarios = (await FuncionariosService.index(this.filtrarFuncionarios.inativo)).data
                this.updatePassOnline()
                this.$store.dispatch("setSnackBar", {
                    color: "success",
                    text: "Funcionário editado com sucesso"
                });
            } catch (error) {
                this.$store.dispatch("setSnackBar", {
                    color: "error",
                    text: "Erro a editar funcionário"
                });
            }
        }
      },
      async criarFuncionario () {
          this.dialogCriarFuncionario = true
      },
      async confirmarCriarFuncionario () {
        if (this.$refs.form.validate()) {
            try{
                this.criarFuncionarios.senha = this.passwordInserida.password
                this.criarFuncionarios.senha_online = this.passwordInserida.password
                await FuncionariosService.post(this.criarFuncionarios)
                this.dialogCriarFuncionario = false
                this.funcionarios = (await FuncionariosService.index(this.filtrarFuncionarios.inativo)).data
                this.$store.dispatch("setSnackBar", {
                    color: "success",
                    text: "Funcionário criado com sucesso"
                });
            } catch (error) {
                this.$store.dispatch("setSnackBar", {
                    color: "error",
                    text: "Erro a criar funcionário"
                });
            }
        }
      },
      async apagarFuncionario (id) {
          this.dialogApagarFuncionario = true
          this.idfuncionarioglobal = id;
      },
      async confirmarApagarFuncionario () {
        try{
            await FuncionariosService.delete(this.idfuncionarioglobal)
            this.dialogApagarFuncionario = false
            this.funcionarios = (await FuncionariosService.index(this.filtrarFuncionarios.inativo)).data
            this.$store.dispatch("setSnackBar", {
                color: "success",
                text: "Funcionário apagado com sucesso"
            });
        } catch (error) {
            this.$store.dispatch("setSnackBar", {
                color: "error",
                text: "Erro a apagar funcionário, pode ter coisas associadas"
            });
        }
      },
      async updatePass() {
        try {
            if (this.$refs.form.validate()) {
                this.editarFuncionarios = (await FuncionariosService.show(this.idfuncionarioglobal)).data
                this.editarFuncionarios.senha = this.passwordInserida.password
                this.editarFuncionarios.senha_online = this.passwordInserida.password
                await FuncionariosService.put(this.editarFuncionarios)
                this.updatePassOnline()
            }
        } catch (error) {
            this.$store.dispatch("setSnackBar", {
                color: "error",
                text: "Erro a alterar password"
            });
        }
      },
      async updatePassOnline() {
        try {
            await FuncionariosService.changePassword(this.editarFuncionarios.id, this.editarFuncionarios.senha);
            this.dialogEditarPassword = false
            this.$store.dispatch("setSnackBar", {
                color: "success",
                text: "Password alterada com sucesso"
            });
        } catch (error) {
            this.$store.dispatch("setSnackBar", {
                color: "error",
                text: "Erro a alterar password"
            });
        }
      }
  }
}
</script>
<style>
</style>