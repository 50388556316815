<template>
  <v-layout justify-center v-if="$store.state.isUserLoggedIn && $store.state.tempotarefa != null || $store.state.tempotarefa.idtarefatempo != null">
    <v-flex>
        <v-card>
          <v-toolbar class="mb-2" color="purple" dark flat>
            <v-toolbar-title>Criar tempo</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form ref="form">
              <v-row>
                <v-col cols="12 text-center">
                  <span>Quer começar um tempo para o cliente {{tarefa.nome_cliente}}?</span>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 6" class="text-center mt-5">
                  <v-btn class="mr-5"><v-icon x-large @click="startTempo" color="green">mdi-play-circle</v-icon></v-btn>
                  <v-btn class="mr-5" disabled><v-icon x-large>mdi-pause-circle</v-icon></v-btn>
                  <v-btn class="mr-5" disabled><v-icon x-large>mdi-stop-circle</v-icon></v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
//  Importar o service necessário
import TemposService from '@/services/TemposService'
import TarefasService from '@/services/TarefasService'
import FuncionariosService from '@/services/FuncionariosService'
import store from '@/store/store'
import moment from 'moment-timezone'

export default {
  async mounted () {
    if (!store.state.isUserLoggedIn) {
      this.$router.push({
        name: 'mylogin'
      })
    } else if (this.$store.state.tempotarefa == null || this.$store.state.tempotarefa.idtarefatempo == null) {
      this.$router.push({
        name: 'myhome'
      })
    } else {
      this.tarefa = (await TarefasService.show(this.$store.state.tempotarefa.idtarefatempo)).data
      this.editarFuncionarios = (await FuncionariosService.show(this.$store.state.user.id)).data
    }
  },
  data () {
    return {
      tarefa:{},
      criarTempos: {
          data_inicio: this.formatarDataMoment(Date.now()),
          id_funcionario: this.$store.state.user.id,
          descricao: '',
          id_tarefa: null,
          numero_cliente: null,
          nome_cliente: null,
          tempo_gasto: null,
          tempo_efetivo: null,
          tempo_descontar: null,
          tempo_deslocacao: null,
          inicio_pausa: null,
          trabalho_realizado: '',
          updatedAt: this.formatarDataMoment(Date.now())
      },
      editarFuncionarios: {
        estado: null
      },
    }
  },
  methods: {
    formatarDataMoment(data) {
      return moment(data).tz('Europe/Lisbon').format('YYYY-MM-DDTHH:mm:ss[Z]');
    },
    async startTempo () {
      try{

        this.criarTempos.id_tarefa = this.tarefa.id
        this.criarTempos.descricao = this.tarefa.descricao
        this.criarTempos.numero_cliente = this.tarefa.numero_cliente
        this.criarTempos.nome_cliente = this.tarefa.nome_cliente
        this.criarTempos.tempo_gasto = '1899-12-30T00:00:00Z'
        this.criarTempos.tempo_efetivo = '1899-12-30T00:00:00Z'
        this.criarTempos.tempo_descontar = '1899-12-30T00:00:00Z'
        this.criarTempos.tempo_deslocacao = '1899-12-30T00:00:00Z'
        this.criarTempos.inicio_pausa = '1899-12-30T00:00:00Z'
        await TemposService.post(this.criarTempos)

        this.ultimotempo = (await TemposService.ultimotempo()).data

        //ver os tempos que estão abertos deste Funcionario, e adicionar á coluna do cliente
        this.editarFuncionarios.cliente = ''
        await FuncionariosService.put(this.editarFuncionarios)
        const temposabertos = (await TemposService.index(null, null, null, this.$store.state.user.id, null, false)).data
        for (var i = 0; i < temposabertos.length; i++) {
          this.editarFuncionarios.cliente = this.editarFuncionarios.cliente + temposabertos[i].nome_cliente + '; '
        }
        this.editarFuncionarios.estado = 'Ocupado'
        await FuncionariosService.put(this.editarFuncionarios)

        this.$store.dispatch('setTempo', {  
            idtempo: this.ultimotempo.id,
            finalizadomobile: 1
        })
        this.$store.dispatch('setTempoTarefa', {  
          idtarefatempo: null
        })

        this.$router.push({
            name: 'editartempostarefas'
        })

        this.$store.dispatch("setSnackBar", {
            color: "success",
            text: "tempo criado com sucesso"
        });
      } catch (error) {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Erro a criar tempo"
          });
      }  
    }
  }
}
</script>
<style>
</style>