<template>
  <v-layout align-center justify-center v-if="!$store.state.isUserLoggedIn">
     <v-flex xs12 sm12 md8>
        <v-card>
           <v-toolbar dark color="primary">
              <v-toolbar-title>Login</v-toolbar-title>
           </v-toolbar>
           <v-card-text>
              <v-form>
                <v-col cols="12">
                 <label>Login</label>
                 <v-text-field name="utilizador" clearable filled outlined type="text" v-model="utilizador"></v-text-field>
                </v-col>
                <v-col cols="12">
                 <label>Password</label>
                 <v-text-field name="password" clearable filled outlined type="password" v-model="password"></v-text-field>
                </v-col> 
                </v-form>
           </v-card-text>
           <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="loginn" color="primary">Login</v-btn>
           </v-card-actions>
        </v-card>
     </v-flex>
  </v-layout>
 </template>

<script>
import FuncionariosService from '@/services/FuncionariosService'
import NotificacoesService from '@/services/NotificacoesService';

export default {
  data () {
    return {
      utilizador: '',
      password: '',
      idfuncionarioglobal: null
    }
  },
  mounted() {
  },
  methods: {
    async loginn () {
      try {
        const response = await FuncionariosService.login(this.utilizador, this.password)
        if (response != '') {
          this.$store.dispatch("setSnackBar", {
              color: "success",
              text: "Logado com sucesso"
          });
          this.$store.dispatch('setToken', response.data.token)
          this.$store.dispatch('setUser', response.data.user)
          this.idfuncionarioglobal = response.data.user.id

          if ('Notification' in window && 'serviceWorker' in navigator) {
            this.checkPermission();
            console.log("1")
          }
          
          this.$router.push({
            name: 'myhome'
          })
          
        } 
        if(response == '') {
          this.$store.dispatch("setSnackBar", {
              color: "error",
              text: "Login inválido"
          });
        }
      } catch (error) {
        this.$store.dispatch("setSnackBar", {
            color: "error",
            text: "Login inválido"
        });
      }
    },
    // Função para converter chave pública VAPID para Uint8Array
    urlBase64ToUint8Array(base64String) {
      const padding = '='.repeat((4 - base64String.length % 4) % 4);
      const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
      const decoded = new Uint8Array(atob(base64).split("").map(c => c.charCodeAt(0)));
      return decoded;
    },

    // Função para verificar a permissão do usuário para notificações
    async checkPermission() {
      if (Notification.permission === 'granted') {
        // Já tem permissão, pode registrar o Service Worker
        this.registerServiceWorker();
        console.log("2")
      } else if (Notification.permission === 'default') {
        console.log("3")
        // Perguntar permissão
        const permission = await Notification.requestPermission();
        if (permission === 'granted') {
          // Permissão concedida
          this.registerServiceWorker();
        } else {
          console.log('Permissão para notificações não concedida');
        }
      } else {
        console.log('Permissão para notificações negada');
      }
    },

    // Função para registrar o Service Worker
    async registerServiceWorker() {
      try {
        const registration = await navigator.serviceWorker.ready;
        const vapidkeys = (await NotificacoesService.getVapidKeys()).data;
        const existingSubscription = await registration.pushManager.getSubscription();

        if (existingSubscription) {
          console.log("4")
          await this.sendTokenToBackend(existingSubscription);
        } else {
          console.log("5")
          // Caso não exista inscrição, crie uma nova
          const newSubscription = await registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: this.urlBase64ToUint8Array(vapidkeys.notifications_public_key),
          });
          await this.sendTokenToBackend(newSubscription);
        }
      } catch (error) {
        console.error('Erro ao registrar o Service Worker ou inscrição:', error);
      }
    },

    // Função para enviar a inscrição para o backend
    async sendTokenToBackend(subscription) {
      try {
        const p256dh = subscription.getKey('p256dh');
        const auth = subscription.getKey('auth');

        const tokenData = {
          endpoint: subscription.endpoint,
          keys: {
            p256dh: p256dh ? btoa(String.fromCharCode.apply(null, new Uint8Array(p256dh))) : null,
            auth: auth ? btoa(String.fromCharCode.apply(null, new Uint8Array(auth))) : null
          },
          id_funcionario: this.idfuncionarioglobal
        };

        await NotificacoesService.storeDeviceToken(tokenData);
      } catch (error) {
        console.error('Erro ao enviar token para o backend:', error);
      }
    }
  }
}
</script>
<style>
</style>